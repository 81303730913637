import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { TitleBlockValue, TitleBlockValueResolver } from "@ihr-radioedit/inferno-core";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { abTranslator } from "../lib/abHelper";
import { PageBlockInterface } from "../page-blocks/Block.component";
import { getThemingCssFromTags } from "../services/Sites.utils";
import { getTopic, LANGUAGES } from "@ihr-radioedit/inferno-core";
import { Container } from "../ui";
import { Remote } from "./remote/Remote.component";
import "./Title.style.scss";
import type { Store } from "@inferno/renderer-shared-core";

export interface TitleBlockProps extends PageBlockInterface {
  store?: Store;
}
export const Title = inject("store")(({ block, params, store }: TitleBlockProps) => {
  if (!store) {
    return null;
  }

  const { site, getSdkOpts } = store;
  // Decode block value
  const value = TitleBlockValueResolver.decode(block.value);
  const blockValue: TitleBlockValue = isLeft(value) ? {} : value.right;
  let { title, description } = blockValue;
  const { tags } = block;

  const className = classnames("component-title", getThemingCssFromTags(tags));
  const locale = site.sections.general?.locale?.taxo?.name ?? LANGUAGES.English;

  // Conditionally load taxonomy record
  if (tags?.includes("display-hints/topic") && params?.topic) {
    return (
      <Remote
        loader={() => getTopic(params.topic!, locale, getSdkOpts())}
        cacheKey={`topic-${params.topic}-${locale.toLowerCase()}`}
      >
        {({ data }) => {
          let topicTitle = "";

          if (data?.source) {
            topicTitle = data?.displayName || "";
            title = topicTitle;
            description = data.source.description || "";
          }

          return <TitleDisplay {...{ block, title, description, className, topicTitle, store }} />;
        }}
      </Remote>
    );
  }
  return <TitleDisplay {...{ block, title, description, className, store }} />;
});

interface TitleDisplayProps extends TitleBlockValue {
  className: string;
  block: BlockFragment;
  topicTitle?: string;
  store?: Store;
}

const TitleDisplay = ({ block, title, description, topicTitle, className, store }: TitleDisplayProps) => {
  const { t } = useTranslation();

  // Render components that have valid data
  useEffect(() => {
    store?.storeBlock({
      ...block,
      resolved: {
        value: { title, description },
        topicTitle,
      },
    });
  });

  if (!store) {
    return null;
  }

  return title || description ? (
    <Container className={className}>
      {title ? <h1>{abTranslator(title, store.testGroups, t)}</h1> : null}
      {description ? <p>{description}</p> : null}
    </Container>
  ) : null;
};
