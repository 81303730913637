import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject } from "mobx-react";
import { useEffect, useRef } from "react";
import "./CoastHtml.style.scss";

import { getCoastHints, HtmlBlockValueResolver } from "@ihr-radioedit/inferno-core";
import { useHashAnchorLinks } from "@inferno/renderer-shared-ui";
import { activateEmbeds } from "../../../../core/lib/embed";
import { PageBlockInterface } from "../../../../core/page-blocks/Block.component";
import { Container } from "../../../../core/ui";

export const Html = inject("store")(({ block, store }: PageBlockInterface) => {
  if (!store) {
    return null;
  }
  const contentRef = useRef<HTMLDivElement>(null);

  useHashAnchorLinks(contentRef, store);

  useEffect(() => {
    store.storeBlock(block);

    if (contentRef.current) {
      activateEmbeds(contentRef.current);
    }
  }, [block, store]);

  const value = HtmlBlockValueResolver.decode(block.value);
  if (isLeft(value) || !value.right.content) {
    return null;
  }

  const coastHints = getCoastHints(block.tags);
  const addClass = classnames("component-html", coastHints);

  return (
    <Container className={addClass}>
      <div dangerouslySetInnerHTML={{ __html: value.right.content }} ref={contentRef} />
    </Container>
  );
});
