import loadable from "@loadable/component";
import { isAuthorRecord } from "../../lib/guards";
import { ILog } from "@ihr-radioedit/inferno-core";
import { PageBlockInterface } from "../Block.component";
import { isItemBlock, isFeedResult, isPublishRecord, isCalendarPublishRecord } from "@inferno/renderer-shared-core";

const FrontMatter = loadable(() => import("./FrontMatter.component"));
const CalendarFrontMatter = loadable(() => import("./CalendarFrontMatter.component"));
const AuthorFrontMatter = loadable(() => import("./AuthorFrontMatter.component"));

const log = ILog.logger("ItemReference.component");

export const ItemReference = ({ block }: PageBlockInterface) => {
  if (!isItemBlock(block)) {
    log.error("No item on block", block);
    return null;
  }

  if (isFeedResult(block.item?.result)) {
    const content = block.item?.result.record;
    if (!isPublishRecord(content)) {
      return null;
    }

    if (isCalendarPublishRecord(content)) {
      return <CalendarFrontMatter content={content} block={block} />;
    }
    if (isAuthorRecord(content)) {
      return <AuthorFrontMatter content={content} block={block} />;
    }

    if (block.tags?.includes("display-hints/front-matter")) {
      return <FrontMatter block={block} content={content} />;
    }
  }
  // not sure what else might need to use this yet, so just returning null for now
  return null;
};
