import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { PageFragment } from "@ihr-radioedit/inferno-webapi";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import {
  getSitemapMonth,
  SitemapProps,
  sitemapResultsMaxLimit,
  sitemapResultsPerSection,
} from "@inferno/renderer-shared-core";
import { getSitemapContent } from "@ihr-radioedit/inferno-core";
import { Remote } from "../remote/Remote.component";
import "./Page.style.scss";
import "./Sitemap.style.scss";
import { MagicLink } from "../../ui";

export const SitemapByMonth = inject("store")(
  observer((props: SitemapProps) => {
    if (!props.store) {
      return null;
    }
    const { store, year, month } = props;
    if (!store || !year || !month) {
      return null;
    }

    if (props.store.page.currentPage) {
      const page: PageFragment = { ...props.store.page.currentPage, name: "sitemap_month" };
      props.store.storePage(page);
    }

    const { t } = useTranslation();

    const date = new Date(`${year}-${month}-01`);
    const searchStart = date.valueOf();
    const searchEnd = new Date(date.setMonth((date.getMonth() % 12) + 1)).valueOf();

    const query: Webapi.PubsubQueryInput = {
      limit: sitemapResultsMaxLimit,
      lookup: [`publish_origin:${store.site.index.ownBrand}`],
      offset: 0,
      pub_after: searchStart,
      pub_start: searchEnd,
      pub_until: Date.now(),
    };

    /* tslint:disable:react-a11y-anchors */
    const fallback = (
      <div className="sections">
        <h3>No Results Found</h3>
        <a href="/sitemap/">Go Back</a>
      </div>
    );

    const siteMapMonth = getSitemapMonth(month);

    return (
      <main className="component-page two-column">
        <section className="col-left">
          <div className="component-sitemap">
            <div className="component-container component-title block">
              <h1>{t("sitemap-title")}</h1>
              {siteMapMonth ? (
                <div className="breadcrumbs">
                  <a href="/sitemap/">Sitemap</a>
                  <span className="current-location">
                    &gt;&nbsp;{t(siteMapMonth)}&nbsp;{year}
                  </span>
                </div>
              ) : null}
            </div>
            <Remote
              loader={() => getSitemapContent({ query })}
              cacheKey={`sitemap-month-${month}`}
              showLoading={false}
              fallback={fallback}
            >
              {({ data: items }) => {
                if (!items || !siteMapMonth) {
                  return fallback;
                }

                const itemsCount = items.length;
                const sections = Math.ceil(itemsCount / sitemapResultsPerSection);

                if (sections === 0) {
                  return fallback;
                }

                if (itemsCount <= sitemapResultsPerSection) {
                  return (
                    <div className="component-container items">
                      {items.map((item, index) => (
                        <div key={index} className="item">
                          <MagicLink rel="canonical" to={`/content/${item.slug}/`}>
                            {item.summary.title}
                          </MagicLink>
                        </div>
                      ))}
                    </div>
                  );
                }

                return (
                  <div className="component-container sections">
                    {[...Array(sections).keys()].map(i => (
                      <div key={i + 1} className="section">
                        <MagicLink to={`/sitemap/${year}/${month}/${i + 1}/`} className="sublink">
                          Section {i + 1}
                        </MagicLink>
                      </div>
                    ))}
                  </div>
                );
              }}
            </Remote>
          </div>
        </section>
      </main>
    );
  }),
);
