import { SimpleEventDispatcher } from "ste-simple-events";
import { CatalogUrlProps } from "@ihr-radioedit/inferno-core";
import { BaseStore } from "./base-store.abstract";

export enum ListenLive {
  PLAY = "play",
  PAUSE = "pause",
  PROGRESS = "progress",
  ERROR = "error",
  ENDED = "ended",
  READY = "ready",
}

export type togglePlayerOverride = CatalogUrlProps & togglePlayerProps;

export interface togglePlayerProps {
  sectionName: string;
  slug?: string;
  override?: CatalogUrlProps;
  batch?: () => void;
}

export interface ListenLiveEvent {
  action: ListenLive.PAUSE | ListenLive.PLAY | ListenLive.PROGRESS;
}

export abstract class BaseStorePlayer {
  abstract loaded: boolean;
  abstract defaultId: string | null;
  abstract overrideId: string | number | undefined | null;
  abstract ready: boolean;
  abstract playback: boolean;
  abstract inProgress: boolean;
  abstract iframeUrl: string;

  externalAction = new SimpleEventDispatcher<ListenLiveEvent>();

  abstract onReady(store: BaseStore): void;
  abstract onError(store: BaseStore): void;
  abstract onPlay(): void;
  abstract onPause(): void;
  abstract onProgress(): void;
  abstract setIframeUrl(url: string): void;
  abstract init(store: BaseStore): void;
  abstract togglePlayback(store: BaseStore, toggleProps: togglePlayerProps): void;
  abstract getOverride(): togglePlayerOverride | null;
}
