import { Loader, google } from "google-maps";
import { GoogleMapsLoader } from "../abstracts/google-maps.abstract";
import { ILog } from "@ihr-radioedit/inferno-core";

const log = ILog.logger("HowToListen");

export class GoogleMapsLibLoader extends GoogleMapsLoader {
  protected loader: Loader;
  protected googleLib: google | null = null;

  constructor(apiKey: string) {
    super();
    this.loader = new Loader(apiKey, { libraries: ["places"], version: "3" });
    try {
      this.load();
    } catch (e) {
      log.debug("MapLoader init failed", e);
    }
  }

  protected async load() {
    return this.loader.load();
  }

  async getGoogle() {
    if (!this.googleLib) {
      this.googleLib = await this.load();
    }
    return this.googleLib;
  }
}
