import { getSdk, initSdkOpts, sdkReject } from "./sdk";

export const getTopic = (topic: string, locale: string, opts: initSdkOpts) => {
  const sdk = getSdk(opts);
  return sdk
    ? sdk.GetTopic.queryAsPromise({ topic, locale: locale.replace(/-\w+$/i, "").trim() }).then(
        r => r.data?.taxonomy?.topic,
      )
    : sdkReject();
};

export const getTag = (tag: string, locale: string, opts: initSdkOpts) => {
  const sdk = getSdk(opts);
  return sdk
    ? sdk.GetTag.queryAsPromise({ tag, locale: locale.replace(/-\w+$/i, "").trim() }).then(r => r.data?.taxonomy?.tag)
    : sdkReject();
};
