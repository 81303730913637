import type { ICache, RequestProps } from "@ihr-radioedit/inferno-core";
import {
  Environment,
  GlobalEnvironment,
  initSdkOpts,
  Microsite,
  ResolvedBlockInterface,
  sdkOpts,
  SharedEnvironment,
  Site,
} from "@ihr-radioedit/inferno-core";
import { SimpleEventDispatcher } from "ste-simple-events";
import { DefaultCategoriesCatalogResponse } from "../../lib/default-category-type";

import { PageFragment } from "@ihr-radioedit/inferno-webapi";
import { AdPlacement, AdsBase } from "../../lib/ads";
import { SessionBackend, SessionState } from "../../lib/backend";
import type { Tags } from "../../lib/tagging-type";
import { ContestExitType, ITags } from "../../lib/tagging-type";
import type { ABTestConfig } from "../../services/variant-test";
import { GoogleMapsLoader } from "../google-maps.abstract";
import { AdobeManage } from "../manager/adobe-manager.abstract";
import { ComScoreManage } from "../manager/comscore-manager.abstract";
import { OneTrustManage } from "../manager/onetrust-manager.abstract";
import { ParselyManage } from "../manager/parsley-manager.abstract";
import { BaseStoreBlock } from "./base-block.abstract";
import { BaseStoreDevice } from "./base-device.abstract";
import { BaseStorePage } from "./base-page.abstract";
import { BaseStorePlayer } from "./base-player.abstract";
import { BaseStoreSession } from "./base-session.abstract";

export enum CcpaStatus {
  OptIn = "optIn",
  OptOut = "optOut",
  Anonymous = "anonymous",
}

export interface AppEnvironment extends Environment, SharedEnvironment, GlobalEnvironment {
  AMP_HOST?: string;
  COMSCORE_CLIENT_ID: string;
  IMAGE_HOST: string;
  DEP_WEBAPI_HOST: string;
  DEP_SITES_HOST: string;
  DEP_HTL_HOST: string;
  DEP_PWS_HOST: string;
  PROTOCOL: string;
  DEFAULT_IMAGE: string;
  DEFAULT_TIMEZONE: string;
  GOOGLE_MAPS_API_KEY: string;
  COAST_RECAPTCHA_V3_PUBLIC_KEY: string;
  COAST_RECURLY_PUBLIC_KEY: string;
  GOOGLE_CSE_ACCOUNT_ID?: string;
  GOOGLE_CSE_AD_ID?: string;
  GOOGLE_CSE_MAX_RESULTS?: string;
  GOOGLE_RECAPTCHA_V3_PUBLIC_KEY?: string;
  ONETRUST_HOST: string;
  ONETRUST_CONSENT_PATH: string;
  ONETRUST_IAB_URL: string;
  ONETRUST_TOKEN_URL: string;
  POSTUP_CONTACT_TEMPLATE_ID?: string;
  PWS_STALE_TOKEN_AGE?: string;
  PWS_TOKEN_VALIDATION_INTERVAL?: string;
  IHEART_HELP_SITE: string | undefined;
  IHEART_HELP_SITE_CONTEST: string | undefined;
  PODCAST_EMBED_KEYID: string | undefined;
  ENVIRONMENT: string;
}

export interface IStore {
  site: Site;
  microsite?: Microsite;
  env: AppEnvironment;
  request: RequestProps;
  block?: BaseStoreBlock;
  page?: BaseStorePage;
  session?: BaseStoreSession<SessionState>;
  tags?: ITags;
  cache?: Map<string, ICache>;
  adPlacements?: Map<string, AdPlacement>;
  api_base_uri: string;
  testGroups?: ABTestConfig | null;
  disableThirdParty?: boolean;
  defaultCategoryCatalog?: DefaultCategoriesCatalogResponse;
}

export interface ClientRedirect {
  url: string;
  status: 301 | 302;
}

export interface AnalyticsEvent {
  sectionName: string;
  pageName: string;
  context?: string;
  action: AnalyticsEventAction;
  url?: string;
  referrer?: string;
}

export interface TagsEvent {
  ready?: boolean;
  from: "MetaTags" | "EmbedAssetLegacy";
  newState: any;
}

export interface EmbedScriptEvent {
  ready?: boolean;
}

export interface OnOutbrainLoad extends EmbedScriptEvent {}

export interface UploadEvent extends AnalyticsEvent {
  data: { [key: string]: any };
}

export interface ContestEvent extends AnalyticsEvent {
  name: string;
  id: number;
  exitType: ContestExitType;
}

export interface LeavingSiteEvent extends AnalyticsEvent {
  type?: "link_warning";
}

export type SharePlatform = "facebook" | "x" | "email" | "print" | "native" | "copyLink";
export interface ShareEvent extends AnalyticsEvent {
  share: {
    platform: SharePlatform;
  };
}

export type AnalyticsEventAction =
  | "share"
  | "screen_view"
  | "page_view"
  | "contest_open"
  | "contest_exit"
  | "item_selected"
  | "click"
  | "pageview";

export interface AuthEvent {
  action: AuthEventAction;
  message?: string;
}

export type AuthEventAction = "pending" | "resolve" | "reject";

export interface IntersectionEvent {
  entry: IntersectionObserverEntry;
}

export abstract class BaseStore {
  abstract site: Site;
  abstract tags: Tags;
  abstract adPlacements: Map<string, AdPlacement>;
  abstract testGroups?: ABTestConfig;
  abstract request: RequestProps;
  abstract page: BaseStorePage;
  abstract device: BaseStoreDevice;
  abstract player: BaseStorePlayer;
  abstract cache: Map<string, ICache>;

  microsite?: Microsite;
  env!: AppEnvironment;
  api_base_uri!: string;
  primaryBlockId?: string;
  googleMaps?: GoogleMapsLoader;
  adobe?: AdobeManage;
  parsely?: ParselyManage;
  comscore?: ComScoreManage;
  oneTrust?: OneTrustManage;
  ads?: AdsBase;
  redirect?: ClientRedirect;
  disableThirdParty = false;
  routeParams: Record<string, any> = {};
  ampLibs = new Set<string>();
  sdkOpts: initSdkOpts = sdkOpts();
  stackedNavState?: boolean = false;
  onIntersectAction = new SimpleEventDispatcher<IntersectionEvent>();
  onAnalyticsAction = new SimpleEventDispatcher<AnalyticsEvent>();
  onStoreTagsAction = new SimpleEventDispatcher<TagsEvent>();
  onEmbedScriptAction = new SimpleEventDispatcher<EmbedScriptEvent>();
  onOutbrainLoad = new SimpleEventDispatcher<OnOutbrainLoad>();
  onUploadAction = new SimpleEventDispatcher<UploadEvent>();
  onContestAction = new SimpleEventDispatcher<ContestEvent>();
  onAuthAction = new SimpleEventDispatcher<AuthEvent>();
  onShareAction = new SimpleEventDispatcher<ShareEvent>();
  block!: BaseStoreBlock;
  session!: BaseStoreSession<SessionState>;
  defaultCategoryCatalog?: DefaultCategoriesCatalogResponse;

  abstract storePage(page: PageFragment | null): void;
  abstract storeRouteParams(params: Record<string, string>): void;
  abstract storeMicrosite(microsite: Microsite): void;
  abstract clearMicrosite(): void;
  abstract storeSession(session: SessionBackend): void;
  abstract storePrimaryBlockId(primaryBlockId: string): void;
  abstract storeBlock(block: ResolvedBlockInterface): void;
  abstract storeRedirect(url: string, status: 301 | 302): void;
  abstract storeTags(tags: Tags): void;
  abstract getCcpaStatus(): CcpaStatus;
  abstract storeCacheValue(key: string, data: ICache): Map<string, ICache>;
  abstract getCacheValue(key: string): ICache | undefined;
  abstract hasCacheValue(key: string): boolean;
  abstract storeStackedNavState(stacked: boolean): boolean;
  abstract storeAdPlacement(key: string, data: AdPlacement): Map<string, ICache>;
  abstract getAdPlacement(key: string): AdPlacement | undefined;
  abstract removeAdPlacement(key: string): boolean;
  abstract hasAdPlacement(key: string): boolean;
  abstract getSdkOpts(): initSdkOpts;
  abstract getGoogleMaps(): GoogleMapsLoader;
  abstract getDefaultCategoryCatalog(): DefaultCategoriesCatalogResponse | undefined;
  abstract setDefaultCategoryCatalog(data: DefaultCategoriesCatalogResponse): void;
}
