import loadable from "@loadable/component";
import { Title } from "../../core/components/Title.component";
import { PageBlockRegistry } from "../../core/page-blocks/Block.component";
import { Subscribe } from "../../core/page-blocks/newsletter/Subscribe.component";
import { Unsubscribe } from "../../core/page-blocks/newsletter/Unsubscribe.component";
import { ShowLoader } from "../../core/page-blocks/show/ShowLoader.component";
import CoastItem from "./page-blocks/content/CoastItem.component";
import { DatasourceLoader } from "./page-blocks/datasource/DatasourceLoader.component";
import { CoastGeneric } from "./page-blocks/generic/CoastGeneric.component";
import { Html } from "./page-blocks/html/CoastHtml.component";

export const coastBlockComponents: PageBlockRegistry = {
  about: () => loadable(() => import("../../core/components/microsite/MicrositeInfo.component")),
  ad: () => loadable(() => import("../../core/page-blocks/Ad.component"), { ssr: false }),
  contact: () => loadable(() => import("./page-blocks/contact/CoastContact.component")),
  contest: () => loadable(() => import("../../core/page-blocks/contest/ContestLoader.component")),
  contests: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/page-blocks/contests/ContestsTileLoader.component"), { ssr: false })
      : loadable(() => import("../../core/page-blocks/contests/ContestsLoader.component")),
  feed: () => DatasourceLoader,
  header: () => loadable(() => import("../../core/page-blocks/header/Header.component")),
  html: () => Html,
  item: () => CoastItem,
  "item-reference": () => loadable(() => import("./page-blocks/item-reference/CoastItemReference.component")),
  newslettersubscribe: () => Subscribe,
  newsletterunsubscribe: () => Unsubscribe,
  onair: () => loadable(() => import("../../core/components/tiles/OnairTileLoader.component")),
  onairschedule: () => loadable(() => import("../../core/components/onair/OnAirSchedule.component")), // This is for the /schedule page, so you don't get confused
  playlist: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/page-blocks/music/MusicTile.component"))
      : loadable(() => import("../../core/page-blocks/music/MusicPage.component")),
  podcasts: () => loadable(() => import("../../core/page-blocks/podcast/PodcastsLoader.component")),
  catalog: () => loadable(() => import("../../core/page-blocks/catalog/Catalog.component")),
  recommendation: () =>
    loadable(() => import("./page-blocks/recommendation/CoastRecommendation.component"), { ssr: false }),
  search: () => loadable(() => import("./page-blocks/search/SearchLoader.component")),
  show: () => ShowLoader,
  title: () => Title,
  topsongs: () => loadable(() => import("../../core/page-blocks/music/MusicPage.component")),
  traffic: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/components/tiles/TrafficTile.component"))
      : loadable(() => import("../../core/page-blocks/traffic/Traffic.component")),
  weather: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/components/tiles/WeatherTileLoader.component"))
      : loadable(() => import("../../core/page-blocks/weather/WeatherLoader.component")),

  asset: () => loadable(() => import("../../core/page-blocks/asset/Asset.component")),
  instagram: () => () => null,
  twitter: () => loadable(() => import("../../core/page-blocks/twitter/Twitter.component")),
  howToListen: () =>
    loadable(() => import("../../core/page-blocks/howToListen/HowToListenLoader.component"), {
      ssr: false,
    }),
  fcc: () => loadable(() => import("../../core/page-blocks/fccApplication/fccApplication.component")),
  video: () => loadable(() => import("./components/coast-media/CoastMediaLoader.component")),
  videoPlaylist: () => loadable(() => import("./components/coast-media/CoastMediaPlaylistLoader.component")),
  generic: () => CoastGeneric,
};
