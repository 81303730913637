import { formatISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { isRight } from "fp-ts/lib/Either";
import i18n from "i18next";
import url from "url-parse";
import utf8 from "utf8";

import { PayloadResolverLegacy } from "@ihr-radioedit/inferno-core";
import { BlockBaseLegacy, matchPrefix, removePrefix } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { getParselyDate } from "@ihr-radioedit/inferno-core";
import { slugify } from "@ihr-radioedit/inferno-core";

import { sanitize } from "../../../utilities/sanitize";
import { isFeedResult, isItemBlock, isPublishRecord } from "../../../lib/guards";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import {
  DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA,
  generateMetaTag,
  generateStructuredDataMetaTag,
  getOriginType,
  processMetaImage,
  setGeneralMetaTags,
} from "../../util";
import { setCacheTtl } from "../../../utilities/header";

export const articleTemplateTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, env, request } = context;
  if (block && isItemBlock(block) && isFeedResult(block.item?.result) && isPublishRecord(block.item?.result.record)) {
    const content = block.item?.result.record;
    if (content) {
      const resolvedPayload = PayloadResolverLegacy.decode(content.payload);
      const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;
      const keywords = payload?.keywords?.map(removePrefix(/^\w+\//)) || [];

      const { surrogateKeys } = state;
      if (surrogateKeys) {
        surrogateKeys.add(`content/${content.slug}`);
        surrogateKeys.add(`content/${decodeURIComponent(content.ref_id).replace("/", "")}`);
      }

      let title = payload?.seo_title || content.summary.title || "";

      if (title) {
        title = `${title} | ${site.sections.general?.name}`;
      }

      let description = content.summary.description || "";
      if (payload?.is_sponsored) {
        description = `[${i18n.t("sponsored_content")}] ${description}`;
      }

      const categories: string[] = [];
      const topics: string[] = [];
      content.subscription.forEach((item: Webapi.Subscription) => {
        topics.push(
          ...item.tags.filter(matchPrefix(/^collections\//)).map((tag: string) => slugify(removePrefix(/^\w+\//)(tag))),
        );
        categories.push(...item.tags.filter(matchPrefix(/^categories\//)).map(removePrefix(/^categories\//)));
      });

      const galleries = payload?.blocks.filter((item: BlockBaseLegacy) => item.type === "gallery") || [];
      const galleryExists = !!galleries.length;
      const contentOrigin = removePrefix(/^brands\//)(payload?.publish_origin || "");

      let authorId = payload?.cuser?.split("/").pop() || "";
      const partner = payload?.feed_content_id;
      if (partner) {
        authorId = partner;
      }
      if (payload?.feed_vendor) {
        authorId = content.payload.feed_vendor;
      }

      const timezone = site.index.timeZone || "";
      const pubDate = zonedTimeToUtc(content.pub_start, timezone).getTime().toString();
      const contentId =
        typeof btoa !== "undefined"
          ? btoa(utf8.encode(decodeURIComponent(content.ref_id)))
          : Buffer.from(utf8.encode(decodeURIComponent(content.ref_id))).toString("base64");

      const image = processMetaImage(formatImage(content.summary.image || "", env.IMAGE_HOST), context);

      let { metadata } = state;
      metadata = setGeneralMetaTags(metadata, {
        titles: {
          default: title,
          social: payload?.social_title ? `${payload.social_title} | ${site.sections.general?.name}` : title,
          parsely: "",
        },
        url: site.getShareUrl(request),
        image: { url: image },
        description,
        keywords,
        types: {
          openGraph: "article",
          parsely: "post",
        },
        twitter: {
          card: "summary_large_image",
          tile: true,
        },
      });

      let canonicalUrl = payload?.canonical_url || "";
      if (canonicalUrl && !url(canonicalUrl).protocol) {
        canonicalUrl = `https:${canonicalUrl}`;
      }

      if (canonicalUrl) {
        metadata.set("canonical", { value: canonicalUrl, type: "link", rel: "canonical" });
        metadata.set(...generateMetaTag("name", "parsely-network-canonical", canonicalUrl));
      }

      const { sections } = site.config;

      if (sections) {
        let publisherImage = "";
        if (sections.design?.station_logo) {
          publisherImage = `${formatImage(
            sections.design?.station_logo?.id,
            env.IMAGE_HOST,
          )}?ops=gravity(%22center%22),contain(600,60),quality(65)`;
        }

        const newsArticleData: typeof DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA = {
          ...DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA,
          mainEntityOfPage: {
            ...DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA.mainEntityOfPage,
            "@id": canonicalUrl || site.getShareUrl(request),
          },
          headline: payload?.seo_title || content.summary.title || "",
          image: [image],
          datePublished: content.pub_start ? formatISO(zonedTimeToUtc(content.pub_start, timezone)) : "",
          dateModified: content.pub_changed ? formatISO(zonedTimeToUtc(content.pub_changed, timezone)) : "",
          author: {
            ...DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA.author,
            name: payload?.author || "",
          },
          publisher: {
            ...DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA.publisher,
            name: sections.general?.name ? sections.general?.name : "",
            logo: {
              ...DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA.publisher.logo,
              url: publisherImage,
            },
          },
          name: description,
          speakable: {
            ...DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA.speakable,
            url: canonicalUrl || site.getShareUrl(request),
          },
        };

        metadata.set(...generateStructuredDataMetaTag(newsArticleData));
      }

      metadata.set(...generateMetaTag("name", "parsely-pub-date", getParselyDate(content.pub_start)));
      metadata.set(...generateMetaTag("name", "robots", "max-image-preview:large"));

      if (content.summary.author) {
        metadata.set(...generateMetaTag("name", "parsely-author", content.summary.author));
      }

      if (block.eyebrow?.topic_name) {
        metadata.set(...generateMetaTag("name", "parsely-section", block.eyebrow.topic_name));
      }

      if (categories.length) {
        metadata.set(...generateMetaTag("name", "categories", categories.join(", ")));
      }

      let ampEnabled = payload?.amp_enabled || false;
      const domain = site.getPrimaryDomain();
      if (canonicalUrl && url(canonicalUrl).hostname !== domain) {
        ampEnabled = false;
      }

      if (ampEnabled && content.slug && !payload?.feed_content_id) {
        const ampUrl = `https://${domain}/alternate/amp/${content.slug}/`;
        metadata.set("amphtml", { value: ampUrl, type: "link", rel: "amphtml" });
      }

      const uniqueTopics = Array.from(new Set([...topics, ...categories])).map(item => slugify(item));

      return {
        ...state,
        surrogateKeys,
        ttls: {
          ...state.ttls,
          server: setCacheTtl(content.pub_start),
        },
        ads: {
          ...state.ads,
          keywords: keywords.map(item => slugify(item)),
          topics: uniqueTopics,
        } as AdTags,
        analytics: {
          ...state.analytics,
          view: {
            ...state.analytics?.view,
            asset: {
              name: sanitize(content.summary.title) || "",
              id: `content|${content.slug}`,
            },
            authorId,
            tags: keywords.map(item => slugify(item)),
            topics: uniqueTopics,
            photoGalleryExists: galleryExists.toString(),
            photoGalleryPageView: galleryExists.toString(),
            contentOrigin,
            contentOriginType: getOriginType(contentOrigin),
            contentId,
            pubDate,
          },
        } as AnalyticsTags,
        metadata,
      };
    }
  }

  return state;
};
