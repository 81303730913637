export const NativeShareIcon = ({ type = "ios" }) => {
  // ios-only share icon
  // IHRAL-6819 notes
  // type is defaulted to this for now because that's what is in mocks.
  // not adding os-sniffing logic unless product/ux makes strong case for it, then will assess
  if (type === "ios") {
    return (
      <svg
        tabIndex={-1}
        focusable="false"
        className="svg-icon icon-share-ios"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="primary-path"
          d="M12.3347 0.428615C12.1343 0.201808 11.7798 0.203876 11.5818 0.433017L8.25146 4.28926C7.8772 4.72267 7.92627 5.37638 8.36133 5.74938C8.79614 6.12237 9.45215 6.0734 9.82642 5.63999L11 4.28118V14.1482C11 14.7005 11.4478 15.1482 12 15.1482C12.5522 15.1482 13 14.7005 13 14.1482V4.31299L14.1814 5.64912C14.5608 6.07814 15.2173 6.11946 15.6479 5.74143C16.0784 5.36339 16.1199 4.70915 15.7405 4.28013L12.3347 0.428615Z"
          fill="#ffffff"
        />
        <path
          className="primary-path"
          d="M4 9.06873C4 8.03722 4.84082 7.21605 5.86035 7.21605H8C8.55225 7.21605 9 7.66377 9 8.21605C9 8.76834 8.55225 9.21605 8 9.21605H6V21H18V9.21605H16C15.4478 9.21605 15 8.76834 15 8.21605C15 7.66377 15.4478 7.21605 16 7.21605H18.1401C19.1594 7.21605 20 8.03759 20 9.06873V21.1478C20 22.1788 19.1592 23 18.1401 23H5.86035C4.84131 23 4 22.1792 4 21.1478V9.06873Z"
          fill="#ffffff"
        />
      </svg>
    );
  }
  // if type not ios, use generic share icon (android and others)
  return (
    <svg
      tabIndex={-1}
      focusable="false"
      className="svg-icon icon-share-android"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="primary-path"
        d="M17 16.08C16.24 16.08 15.56 16.38 15.04 16.85L7.91 12.7C7.96 12.47 8 12.24 8 12C8 11.76 7.96 11.53 7.91 11.3L14.96 7.19C15.5 7.69 16.21 8 17 8C18.66 8 20 6.66 20 5C20 3.34 18.66 2 17 2C15.34 2 14 3.34 14 5C14 5.24 14.04 5.47 14.09 5.7L7.04 9.81C6.5 9.31 5.79 9 5 9C3.34 9 2 10.34 2 12C2 13.66 3.34 15 5 15C5.79 15 6.5 14.69 7.04 14.19L14.16 18.35C14.11 18.56 14.08 18.78 14.08 19C14.08 20.61 15.39 21.92 17 21.92C18.61 21.92 19.92 20.61 19.92 19C19.92 17.39 18.61 16.08 17 16.08Z"
        fill="#ffffff"
      />
    </svg>
  );
};
