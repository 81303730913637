import { inject } from "mobx-react";

import { ResponsiveImage } from "../../../../core/components/ResponsiveImage.component";
import { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { MagicLink } from "../../../../core/ui";
import { CoastFeedItemType, getCoastFeedImg } from "../../lib/coastFeed";
import "./CoastCarouselItem.style.scss";
import { isLeadsCard, RESPONSIVE_IMAGE } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

interface CoastCarouselItemProps {
  item: FeedResultFragment;
  store?: Store;
}

export const CoastCarouselItem = inject("store")(({ item, store }: CoastCarouselItemProps) => {
  if (!store) {
    return null;
  }

  const { record: card } = item;
  if (!isLeadsCard(card)) {
    return null;
  }
  const articleUrl = card.link?.urls.web;
  const altText = card.title;
  const thumb = card.image || placeholderImage;
  const { imgSrc, srcset } = getCoastFeedImg(thumb, CoastFeedItemType.CAROUSEL, { quality: 80 });

  return (
    <figure className="carousel-item">
      <section className="thumb">
        <MagicLink to={articleUrl} context={articleUrl}>
          <ResponsiveImage
            src={imgSrc}
            srcset={srcset}
            alt={altText}
            initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
            initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
            lazy={false}
          />
        </MagicLink>
      </section>
      <figcaption>
        <span className="caption-wrapper">
          <MagicLink className="card-title" to={articleUrl} context={articleUrl}>
            {card.title}
          </MagicLink>
          <MagicLink className="card-subtitle" to={articleUrl} context={articleUrl}>
            {card.subtitle}
          </MagicLink>
        </span>
      </figcaption>
    </figure>
  );
});
