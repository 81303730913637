import { isRight } from "fp-ts/Either";
import i18n from "i18next";
import url from "url-parse";

import { CalendarPayloadResolver } from "@ihr-radioedit/inferno-core";
import { removePrefix, slugify } from "@ihr-radioedit/inferno-core";

import { TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { isCalendarPublishRecord, isFeedResult, isItemBlock } from "../../../lib/guards";
import { sanitize } from "../../../utilities/sanitize";
import { generateMetaTag, setGeneralMetaTags } from "../../util";
import { filterEventDates } from "../../../lib/calendar";
import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat } from "../../../lib/time";

export const ampCalendarArticleTemplateTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, env } = context;
  if (
    isItemBlock(block) &&
    block.item &&
    isFeedResult(block.item.result) &&
    isCalendarPublishRecord(block.item.result.record)
  ) {
    const content = block.item?.result.record;
    const resolvedPayload = CalendarPayloadResolver.decode(content.payload);
    const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;

    const keywords: string[] = [];
    payload?.fields.keywords?.value?.forEach(value => {
      if (value) {
        keywords.push(removePrefix(/^\w+\//)(value));
      }
    });

    let itemUrl = payload?.canonical_url || "";
    if (itemUrl && !url(itemUrl).protocol) {
      itemUrl = `https:${itemUrl}`;
    }

    let title = payload?.fields.seo_title?.value || content.summary.title || "";

    if (title) {
      title = `${title} | ${site.sections.general?.name}`;
    }

    const stationName = site.sections.general?.name || "";
    const venue = payload?.fields.venue_name?.value || "";
    const address = payload?.fields.venue_address?.value || "";
    const seoTitle = payload?.fields.social_title?.value || block.value.title || "";

    const eventDateValue =
      filterEventDates(payload?.fields.event_date?.value ?? [])[0] ?? payload?.fields.event_date?.value[0];
    let eventDate = null;

    if (eventDateValue?.begin) {
      eventDate = zonedTimeFormat({
        date: eventDateValue?.begin,
        timezone: site.index?.timeZone ? site.index.timeZone : env.DEFAULT_TIMEZONE,
        outputFormat: DATETIME_DISPLAY_FORMATS.SHORT_MONTH_DAY_WITH_SUFFIX_FULL_YEAR,
      });
    }

    if (seoTitle) {
      title = `${seoTitle} | ${eventDate} | ${venue}`;
      title = `${title} | ${stationName}`;
      state.surrogateKeys?.add(`event/${slugify(seoTitle)}`);
    }

    let socialTitle = payload?.fields.social_title?.value
      ? `${payload.fields.social_title.value} | ${site.sections.general?.name}`
      : title;

    if (eventDate && venue) {
      socialTitle = `${socialTitle} | ${eventDate} | ${venue}`;
    }

    if (venue) {
      keywords.push(...sanitize(venue.trim().toLowerCase()).split(" "));
    }

    if (address) {
      keywords.push(...sanitize(address.trim().toLowerCase().replace(/,/g, "")).split(" "));
    }

    let description = content.summary.description || "";
    if (!description) {
      const station = `${site.sections.general?.name} - ${site.sections.general?.positioner}`;
      description = i18n.t("event_description", { station, stationName });
    }

    let { metadata } = state;
    metadata = setGeneralMetaTags(metadata, {
      titles: {
        default: title,
        social: socialTitle,
      },
      description,
      keywords,
    });

    if (itemUrl) {
      metadata.set(...generateMetaTag("name", "parsely-link", itemUrl));
    }
    metadata.set(...generateMetaTag("name", "robots", "max-image-preview:large"));

    return {
      ...state,
      metadata,
    };
  }

  return state;
};
