import { environ } from "@ihr-radioedit/sdk-utils";
import loadable from "@loadable/component";
import { inject, observer } from "mobx-react";
import { Fragment, StrictMode } from "react";
import { match, useLocation, useRouteMatch } from "react-router-dom";

import { getPage, ILog, lookup } from "@ihr-radioedit/inferno-core";
import type { PageFragment } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import { matchPathByRoutes, SITE_THEME } from "@inferno/renderer-shared-core";
import { useHistoryTracking } from "../../client/useHistoryTracking";
import { MetaTags } from "../core/components/MetaTags.component";
import { Remote } from "../core/components/remote/Remote.component";
import { Spinner } from "../core/ui";
import "./App.style.scss";
import { buildRouteContext, getRoutes } from "./Routes";

const log = ILog.logger("App.component");
const LocalSiteTheme = loadable(() => import("./local"));
const PremiereSiteTheme = loadable(() => import("./premiere"));
const CoastSiteTheme = loadable(() => import("./coast"));
const EmbedSiteTheme = loadable(() => import("./embed"));
const PreviewSiteTheme = loadable(() => import("./preview"));
const fallback = <Spinner visible={true} />;

// Map of theme name to theme
const SiteThemes: { [key: string]: JSX.Element } = {
  [SITE_THEME.EMBED]: <EmbedSiteTheme siteTheme={SITE_THEME.EMBED} fallback={fallback} />,
  [SITE_THEME.DEFAULT]: <LocalSiteTheme siteTheme={SITE_THEME.DEFAULT} fallback={fallback} />,
  [SITE_THEME.PREMIERE]: <PremiereSiteTheme siteTheme={SITE_THEME.PREMIERE} fallback={fallback} />,
  [SITE_THEME.COAST]: <CoastSiteTheme siteTheme={SITE_THEME.COAST} fallback={fallback} />,
  [SITE_THEME.PREVIEW]: <PreviewSiteTheme siteTheme={SITE_THEME.PREVIEW} />,
};

const GetSiteTheme = ({ store, previewRouteMatch }: { store: Store; previewRouteMatch: match<object> | null }) => {
  const embedRouteMatch = useRouteMatch({ path: "/newsletter/embed/", exact: true });
  if (embedRouteMatch || store.page.currentPage?.layoutId === "plaintext") {
    return SiteThemes.embed;
  } else if (previewRouteMatch) {
    return SiteThemes._preview;
  }
  const theme = store.site.sections.design?.theme ?? "";
  return SiteThemes?.[theme] ?? SiteThemes.default;
};

const SiteThemeContainer = ({ store }: { store: Store }) => {
  const { pathname } = useLocation();
  const previewRouteMatch = useRouteMatch({ path: "/_preview", exact: true });

  const routePair = matchPathByRoutes(getRoutes(store), pathname);

  if (typeof window !== "undefined" || previewRouteMatch) {
    return <GetSiteTheme store={store} previewRouteMatch={previewRouteMatch} />;
  }

  if (routePair) {
    const { route, routeMatch } = routePair;
    let loader: () => Promise<0 | PageFragment | undefined>;

    try {
      const { context, scopes, from } = buildRouteContext(store, routeMatch);
      loader = () =>
        getPage(
          {
            name: route.name,
            lookup,
            slug: pathname.includes("/featured/")
              ? store.microsite?.index?.slug || store.site.index.slug
              : store.site.index.slug,
            context,
            scopes,
            from,
          },
          store.tags.surrogateKeys,
        ).catch(() => store.page.storeStatus(500) && store.page.notFoundPage);
    } catch (e) {
      log.debug(e);
      store.page.storeStatus(500);
      loader = async () => store.page.notFoundPage;
    }

    return (
      <Remote cacheKey={JSON.stringify(route.path)} loader={loader} fallback={fallback}>
        {({ data: page }) => {
          if (page) {
            store.storePage(page);
          }
          return <GetSiteTheme store={store} previewRouteMatch={previewRouteMatch} />;
        }}
      </Remote>
    );
  }
  if (store.page.notFoundPage) {
    store.storePage(store.page.notFoundPage);
  }
  return <GetSiteTheme store={store} previewRouteMatch={previewRouteMatch} />;
};

interface AppProps {
  store?: Store;
}

export const App = inject("store")(
  observer(({ store }: AppProps) => {
    if (store) {
      // Only show this warning during local development
      const Wrapper = ["production", "test"].includes(environ.get("NODE_ENV", "")) ? Fragment : StrictMode;

      useHistoryTracking(store);

      return (
        <Wrapper>
          <MetaTags />
          <SiteThemeContainer store={store} />
        </Wrapper>
      );
    } else {
      log.error("No store available");
    }
    return null;
  }),
);

export default App;
