import {
  OnAirScheduleBlockResolved,
  ResolvedBlockData,
  ResolvedChartListBlock,
  ResolvedTitle,
} from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { BlockFragment, SitesFeedPwsResult } from "@ihr-radioedit/inferno-webapi";

export function isItemBlock(block?: BlockFragment): block is Webapi.Block_SitesItemBlock_Fragment {
  return block && ["item-reference", "item"].includes(block.type) && (block as any).hasOwnProperty("item");
}

export function isLeadsCard(v?: Webapi.FeedResultFragment["record"] | undefined): v is Webapi.LeadsCardFragment {
  return !!(
    v &&
    v.hasOwnProperty("catalog") &&
    v.hasOwnProperty("link") &&
    v.hasOwnProperty("use_catalog_image") &&
    v.hasOwnProperty("subtitle") &&
    v.hasOwnProperty("title")
  );
}

export function isFeedResult(
  v?:
    | Webapi.FeedResult_SitesFeedLeadsResult_Fragment
    | Webapi.FeedResult_SitesFeedPubsubResult_Fragment
    | Webapi.FeedResult_SitesFeedPwsResult_Fragment
    | Pick<SitesFeedPwsResult, "id" | "type">
    | null
    | undefined,
): v is
  | Webapi.FeedResult_SitesFeedLeadsResult_Fragment
  | Webapi.FeedResult_SitesFeedPubsubResult_Fragment
  | Webapi.FeedResult_SitesFeedPwsResult_Fragment {
  return !!(v && v.hasOwnProperty("id") && v.hasOwnProperty("type") && v.hasOwnProperty("record"));
}

export function isPublishRecord(
  v?: Webapi.FeedResultFragment["record"] | undefined,
): v is Webapi.PublishRecordFragment {
  return !!(
    v &&
    v.hasOwnProperty("payload") &&
    v.hasOwnProperty("pub_start") &&
    v.hasOwnProperty("slug") &&
    v.hasOwnProperty("subscription") &&
    v.hasOwnProperty("summary")
  );
}

export function isFeedBlock(block?: BlockFragment): block is Webapi.Block_SitesFeedBlock_Fragment {
  return block && block.type === "feed" && (block as any).hasOwnProperty("feed");
}

export function isCalendarPublishRecord(
  v?: Webapi.FeedResultFragment["record"] | undefined,
): v is Webapi.PublishRecordFragment {
  return !!(v && isPublishRecord(v) && v.hasOwnProperty("type") && v.type === "content:calendar");
}

export function isResolvedTitle(v: any): v is ResolvedTitle {
  return !!v && v.hasOwnProperty("value") && v.hasOwnProperty("topicTitle");
}

export interface OnAirScheduleBlock {
  resolved: OnAirScheduleBlockResolved;
}

export function isOnAirScheduleBlock(block: any): block is OnAirScheduleBlock {
  return !!(
    block &&
    block.resolved &&
    block.resolved.schedule &&
    block.resolved.schedule.names &&
    block.resolved.schedule.names.length
  );
}

export function isAptivadaAppFragment(v?: ResolvedBlockData): v is Webapi.AptivadaAppFragment {
  return !!(
    v &&
    v.hasOwnProperty("appType") &&
    v.hasOwnProperty("appId") &&
    v.hasOwnProperty("parentAppId") &&
    v.hasOwnProperty("title") &&
    v.hasOwnProperty("shareTitle") &&
    v.hasOwnProperty("tags") &&
    v.hasOwnProperty("gridStatus") &&
    v.hasOwnProperty("gridRedirect") &&
    v.hasOwnProperty("pageUrl")
  );
}

export function isChartListBlock(v?: any): v is ResolvedChartListBlock {
  return !!(v && v.hasOwnProperty("chartTitle") && v.hasOwnProperty("list") && isChartList(v.list));
}

export function isChartList(v?: any): v is Webapi.ChartsListFragment {
  return !!(
    v &&
    v.hasOwnProperty("topItems") &&
    v.hasOwnProperty("slug") &&
    v.hasOwnProperty("image") &&
    v.hasOwnProperty("title") &&
    v.hasOwnProperty("songs")
  );
}

export function isChart(v?: any): v is Webapi.ChartV2Fragment {
  return !!(
    v &&
    v.hasOwnProperty("slug") &&
    v.hasOwnProperty("id") &&
    v.hasOwnProperty("title") &&
    v.hasOwnProperty("subtitle")
  );
}
