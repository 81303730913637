export const PrintIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-print"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="primary-path"
      d="M6 5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5V6.5H6V5Z"
      fill="#27292D"
    />
    <path
      className="primary-path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8C3.34315 8 2 9.34315 2 11V15C2 16.1046 2.89543 17 4 17H6V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V17H20C21.1046 17 22 16.1046 22 15V11C22 9.34315 20.6569 8 19 8H5ZM6 11C6 10.4477 5.55228 10 5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12C5.55228 12 6 11.5523 6 11ZM8 18V13H16V18C16 18.5523 15.5523 19 15 19H9C8.44772 19 8 18.5523 8 18Z"
      fill="#27292D"
    />
  </svg>
);
