import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat, type Store } from "@inferno/renderer-shared-core";
import { getLang } from "@ihr-radioedit/inferno-core";

export function DisplayTime({
  startDate,
  hideEventStartTime,
  store,
}: {
  startDate: number;
  hideEventStartTime?: boolean;
  store: Store;
}) {
  if (startDate) {
    const { env, site } = store;
    return (
      <time dateTime={`${startDate}`}>
        {zonedTimeFormat({
          date: startDate,
          timezone: site ? site.index.timeZone ?? env.DEFAULT_TIMEZONE : env.DEFAULT_TIMEZONE,
          outputFormat: hideEventStartTime
            ? DATETIME_DISPLAY_FORMATS.SHORT_DAY_OF_WEEK_SHORT_MONTH_SHORT_DAY_FULL_YEAR
            : DATETIME_DISPLAY_FORMATS.SHORT_DAY_OF_WEEK_SHORT_MONTH_SHORT_DAY_FULL_YEAR_TWELVE_HOUR_TZ_PADDED,
          locale: (site ? getLang(site) : "en") === "es" ? es : enUS,
        })}
      </time>
    );
  }

  return null;
}
