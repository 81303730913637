import "./Page.style.scss";
import "./Sitemap.style.scss";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { PageFragment } from "@ihr-radioedit/inferno-webapi";
import { SitemapMonth, sitemapMonths, SitemapProps } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../ui";

export const SitemapIndex = inject("store")(
  observer((props: SitemapProps) => {
    if (!props.store) {
      return null;
    }

    if (props.store.page.currentPage) {
      const page: PageFragment = { ...props.store.page.currentPage, name: "sitemap_index" };
      props.store.storePage(page);
    }

    const { t } = useTranslation();

    const currentYear = new Date().getFullYear();
    let years = [];
    let startYear = 2017;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    years = years.reverse();

    const currentMonth = new Date().getMonth() + 1;

    return (
      <main className="component-page two-column">
        <section className="col-left">
          <div className="component-sitemap">
            <div className="component-container component-title block">
              <h1>{t("sitemap-title")}</h1>
            </div>
            <div className="component-container block years">
              {years.map(year => (
                <div key={year} className="year">
                  <h2>{year}</h2>
                  <div className="months">
                    {sitemapMonths.map((month: SitemapMonth) => {
                      if (year === currentYear && month.number > currentMonth) {
                        return;
                      }

                      return (
                        <MagicLink key={month.number} to={`${year}/${month.number}/`}>
                          {t(month.name)}
                        </MagicLink>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    );
  }),
);
