import { TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, processMetaImage, setGeneralMetaTags } from "../util";
import { isChart } from "../../lib/guards";

export const chartTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block } = context;

  if (block && isChart(block.resolved)) {
    const chart = block.resolved;
    const firstList = chart.lists?.items[0];

    const title = `${chart.title} | ${site.sections.general?.name}`;
    const image = firstList?.image || firstList?.topItems || "";

    let { metadata } = state;
    metadata = setGeneralMetaTags(metadata, {
      titles: {
        default: title,
      },
      description: chart.subtitle || "",
      image: { url: processMetaImage(image, context) },
      types: {
        parsely: "sectionpage",
      },
    });

    metadata.set(...generateMetaTag("name", "parsely-section", "charts"));

    return {
      ...state,
      metadata,
    };
  }

  return state;
};
