import { useEffect, useState } from "react";
import { cloneElement, isValidElement, FunctionComponentElement } from "react";
import { AppTrayInjector } from "../chrome/AppTrayInjector";
import { cookies, isWindowDefined } from "@inferno/renderer-shared-core";

export const DismissContainer = ({
  identifier = "dismiss",
  duration = 1,
  children,
}: {
  children: FunctionComponentElement<any>;
  identifier?: string;
  duration?: number;
}) => {
  const [isDismissed, setDismissed] = useState<boolean>(cookies().get(identifier) !== "");

  useEffect(() => {
    if (isDismissed) {
      cookies().set(identifier, "1", duration);
    }
  }, [isDismissed, duration, identifier]);

  if (isDismissed || !isWindowDefined()) {
    return null;
  }

  return (
    <AppTrayInjector>
      <>
        {isValidElement(children) ? cloneElement(children, { open: true, callback: () => setDismissed(true) }) : null}
      </>
    </AppTrayInjector>
  );
};
