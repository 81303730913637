import { sub } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { inject } from "mobx-react";
import { Component } from "react";

import type { Store } from "@inferno/renderer-shared-core";
import { isFeedResult, isPublishRecord } from "@inferno/renderer-shared-core";
import { PayloadInterfaceLegacy } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { FeedResult_SitesFeedPubsubResult_Fragment, Sites_Lookup_By } from "@ihr-radioedit/inferno-webapi";
import { ILog } from "@ihr-radioedit/inferno-core";
import { lookup } from "@ihr-radioedit/inferno-core";
import { getFeedContent } from "@ihr-radioedit/inferno-core";

interface RelatedContentProps {
  content: Webapi.PublishRecordFragment;
  payload: PayloadInterfaceLegacy;
  store?: Store;
}

const log = ILog.logger("RelatedContent");
const seen = new Set<string>();

@inject("store")
export class RelatedContent extends Component<RelatedContentProps> {
  componentDidMount() {
    const { store, content, payload } = this.props;
    if (!store || !payload.keywords || !content.slug || typeof window === "undefined" || seen.has(content.slug)) {
      return null;
    }
    const tags: string[] = [];
    content.subscription.forEach(subscription => {
      subscription.tags.forEach(tag => tags.push(tag));
    });

    seen.add(content.slug);

    // TODO: assign this as the loader for a `Remote` component and render the results in a format TBD
    getFeedContent({
      lookup,
      type: Sites_Lookup_By.Slug,
      value: store.site.index.slug,
      usage: "USAGE:feed-usecases/Related Content",
      context: {
        "<lookups>": payload.keywords.map(keyword => `keyword:${keyword}`),
        "<tags>": tags,
        "<since>": zonedTimeToUtc(
          sub(new Date(), { days: 30 }),
          Intl.DateTimeFormat().resolvedOptions().timeZone || store.env.DEFAULT_TIMEZONE!,
        ).valueOf(),
      },
      size: 5,
    }).then(results => {
      if (results) {
        results
          .filter(
            (item): item is FeedResult_SitesFeedPubsubResult_Fragment =>
              isFeedResult(item) && isPublishRecord(item?.record) && item.record.slug !== content.slug,
          )
          .forEach(item =>
            log.info(
              `${item.record.summary.title}
                ${location.protocol}//${location.host}/content/${item.record.slug}`,
            ),
          );
      }
    });
  }

  render() {
    return null;
  }
}
