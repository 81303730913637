import i18n from "i18next";

import type { TagRef } from "@ihr-radioedit/inferno-webapi";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { slugify, removePrefix } from "@ihr-radioedit/inferno-core";

import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, processMetaImage, setGeneralMetaTags, twitterName } from "../util";

export const micrositeTags: Tagger = (state: Tags, context: TagContext) => {
  const { site: parent, microsite, env, request } = context;
  if (microsite?.sections && microsite?.index) {
    const keywords = microsite.sections?.general?.keywords?.map((i: TagRef) => removePrefix(/^\w+\//)(i.id)) || [];

    let { metadata } = state;
    const { sections } = microsite;
    if (sections && metadata) {
      const description =
        sections.general?.description && sections.general?.description.trim()
          ? sections.general.description.trim()
          : i18n.t("microsite_description");

      let image = "";
      if (sections.general?.sixteen_by_nine) {
        image = formatImage(sections.general?.sixteen_by_nine.id, env.IMAGE_HOST);
      } else if (sections.general?.thumbnail_image) {
        image = formatImage(sections.general?.thumbnail_image.id, env.IMAGE_HOST);
      } else if (parent.sections.design?.station_logo) {
        image = formatImage(parent.sections.design?.station_logo.id, env.IMAGE_HOST);
      } else {
        image = env.DEFAULT_IMAGE ? env.DEFAULT_IMAGE : "";
      }

      let twitterHandle = "";
      if (sections.social?.twitter_name) {
        twitterHandle = twitterName(sections.social.twitter_name);
      } else if (parent.sections.social?.twitter_name) {
        twitterHandle = twitterName(parent.sections.social.twitter_name);
      }

      metadata = setGeneralMetaTags(metadata, {
        titles: { default: `${sections.general?.name} - ${parent.sections.general?.name}` },
        image: { url: processMetaImage(image, context) },
        url: parent.getShareUrl(request),
        description,
        keywords,
        types: {
          parsely: "sectionpage",
        },
        twitter: {
          handle: twitterHandle,
        },
      });

      if (microsite.getPrimaryDomain()) {
        metadata.set("canonical", { value: microsite.getShareUrl(request), type: "link", rel: "canonical" });
        metadata.set(...generateMetaTag("property", "twitter:domain", microsite.getPrimaryDomain()));
      }

      if (sections.general?.name) {
        metadata.set(...generateMetaTag("property", "og:site_name", sections.general.name));
      }
    }

    return {
      ...state,
      ads: {
        ...state.ads,
        microsite: microsite.index.slug,
        keywords: keywords.map(item => slugify(item)),
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          stationCallLetter: parent.index.slug.toUpperCase(),
          personalityId: parent.index.slug.toUpperCase(),
          stationMicrosite: microsite.sections.general?.name || "",
          tags: keywords.map(item => slugify(item)),
        },
      } as AnalyticsTags,
      metadata,
    };
  }

  return state;
};
