import { BlockBaseLegacy, getOriginIsMicrosite, Microsite, Site } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import { isCalendarPublishRecord } from "@inferno/renderer-shared-core";
import { reverseRoute } from "@inferno/renderer-shared-core";

export interface ContentFeedItemProps {
  item: Webapi.PublishRecordFragment;
  index: number;
  showEyebrow: boolean;
  store?: Store;
  showDateStamp?: boolean;
  datetime: number;
  layout: ReturnType<typeof getLayout>;
}

export const getContentEyebrow = (
  site: Site,
  microsite: Microsite | undefined,
  content: Webapi.PublishRecordFragment,
  eyebrow?: Webapi.Eyebrow | null,
  showEyebrow = true,
) => {
  let onMicrosite = false;
  let microSlug = "";
  let itemUrl = "";
  let eyebrowUrl = "";
  let topicName = eyebrow?.topic_name || "";

  if (microsite && microsite.index && microsite.config) {
    onMicrosite = true;
    microSlug = microsite.index.slug;
    topicName = microsite.sections.general?.name || "";
  }

  const originIsMicrosite = getOriginIsMicrosite(site, content);

  const slug = content.slug;

  // Resolve item and eyebrow url based on render context
  if (onMicrosite) {
    itemUrl = `/featured/${microSlug}${reverseRoute(site, "detail", { slug })}`;
    eyebrowUrl = `/featured/${microSlug}/`;
  } else if (originIsMicrosite) {
    itemUrl = `/featured/${originIsMicrosite.slug}${reverseRoute(site, "detail", { slug })}`;
    eyebrowUrl = `/featured/${originIsMicrosite.slug}/`;
    topicName = originIsMicrosite.configByLookup?.common?.branding?.name || "";
  } else if (isCalendarPublishRecord(content)) {
    itemUrl = reverseRoute(site, "calendar_content", { slug }) || "";
    if (eyebrow?.topic) {
      eyebrowUrl = reverseRoute(site, "calendar_content_category", { topic: eyebrow.topic }) || "";
    }
  } else {
    itemUrl = reverseRoute(site, "detail", { slug }) || "";
    if (eyebrow?.topic) {
      eyebrowUrl = reverseRoute(site, "topic", { topic: eyebrow.topic }) || "";
    }
  }

  // Override url for external content
  if (content.payload.external_url) {
    itemUrl = content.payload.external_url;
  }

  // Hide eyebrow if specified by block config
  if (!showEyebrow) {
    eyebrowUrl = "";
    topicName = "";
  }

  return { itemUrl, eyebrowUrl, topicName };
};

export const getBlockSchedule = (block: BlockBaseLegacy | null) => {
  if (block?.schedule?.begin && block?.schedule?.end) {
    return block.schedule as Webapi.SitesSchedule;
  } else {
    return null;
  }
};

export const getLayout = (style: string, microsite?: Microsite) => {
  switch (style) {
    case "horizontal":
      return !microsite ? "thumb-left" : "thumb-left-microsite";
    case "horizontal-reverse":
      return !microsite ? "thumb-right" : "thumb-right-microsite";
    case "flag-layout":
      return "flag-tile";
    default:
      return "thumb-top";
  }
};
