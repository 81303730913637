import { isRight } from "fp-ts/lib/Either";
import url from "url-parse";

import { PayloadResolverLegacy } from "@ihr-radioedit/inferno-core";

import { isFeedResult, isItemBlock, isPublishRecord } from "../../../lib/guards";
import { TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { generateMetaTag } from "../../util";

export const ampArticleTemplateTags: Tagger = (state: Tags, context: TagContext) => {
  const { block } = context;
  if (
    isItemBlock(block) &&
    block.item &&
    isFeedResult(block.item.result) &&
    isPublishRecord(block.item.result.record)
  ) {
    const content = block.item?.result.record;
    const resolvedPayload = PayloadResolverLegacy.decode(content.payload);
    const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;

    let itemUrl = payload?.canonical_url || "";
    if (itemUrl && !url(itemUrl).protocol) {
      itemUrl = `https:${itemUrl}`;
    }

    const { metadata } = state;
    if (itemUrl) {
      metadata.set(...generateMetaTag("name", "parsely-link", itemUrl));
    }
    metadata.set(...generateMetaTag("name", "robots", "max-image-preview:large"));
    return {
      ...state,
      metadata,
    };
  }

  return state;
};
