import { Site } from "../decoders/Sites.types";
import * as Webapi from "@ihr-radioedit/inferno-webapi";

export const getOriginIsMicrosite = (
  site: Site,
  content: Webapi.SitemapContentItemFragment | Webapi.PublishRecordFragment,
) => {
  let originIsMicrosite = content.payload?.publish_origin
    ? site.getMicrosite(content.payload.publish_origin)
    : undefined;

  // if publish_origin did not return a result for microsite
  // check for intersection of default_shows and content distribution
  // pick first result as winner
  if (!originIsMicrosite) {
    const intersection = site.sections.general?.default_shows?.filter(show =>
      content.subscription
        .map(sub => sub.tags)
        .flat()
        .includes(show.id),
    );
    if (intersection?.length) {
      originIsMicrosite = site.getMicrosite(intersection[0].id);
    }
  }

  return originIsMicrosite;
};
