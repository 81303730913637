enum AdFrequencyType {
  AD_DENSITY_LOW = 5,
  AD_DENSITY_MEDIUM = 4,
  AD_DENSITY_HIGH = 2,
}

export const getAdFrequency = (tags: string[] | null | undefined): number => {
  let adHint: keyof typeof AdFrequencyType = "AD_DENSITY_LOW";
  tags?.forEach(tag => {
    if (tag.includes("ad-density")) {
      adHint = tag.replace("display-hints/", "").replace(/-/g, "_").toUpperCase() as keyof typeof AdFrequencyType;
    }
  });
  return AdFrequencyType[adHint];
};
