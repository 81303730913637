import * as E from "fp-ts/lib/Either";
import type { ICache } from "../lib/cache";
import { Microsite, Site } from "../decoders/Sites.types";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { getSdk, sdkOpts } from "./sdk";
import { addSurrogateKeys } from "../lib/cache";
import { Cursor } from "../lib/cursor";
import { CONFIG_ERRORS, SiteConfigError } from "../lib/error";
import { ILog } from "../lib/logging";
import { lookup } from "../lib/lookup";

const sdk = getSdk(sdkOpts());

const log = ILog.logger("services/Sites");

export const getSiteByQuery = async (query: Webapi.GetSiteByQueryQueryVariables, surrogateKeys: Set<string>) => {
  const start = Date.now();
  const siteResponse = await sdk!.GetSiteByQuery.query(query, { tolerateErrors: false })();
  log.debug(`getSiteByQuery took ${Date.now() - start}ms`);
  if (E.isRight(siteResponse) && siteResponse.right.data) {
    addSurrogateKeys(siteResponse.right, surrogateKeys);

    if (!siteResponse.right.data?.sites?.find) {
      log.error(`${CONFIG_ERRORS.site.notFound}: ${JSON.stringify(query)}`);
      return null;
    }

    return new Site(siteResponse.right.data);
  }

  if (E.isLeft(siteResponse)) {
    log.error(
      `${CONFIG_ERRORS.site.invalid}:`,
      (siteResponse.left as any).response?.errors?.[0]?.message || siteResponse.left.message,
    );
    throw new SiteConfigError(CONFIG_ERRORS.site.invalid);
  }

  throw new SiteConfigError(CONFIG_ERRORS.site.unknown);
};

export const getMicrositeByQuery = async (
  query: Webapi.GetMicrositeByQueryQueryVariables,
  surrogateKeys: Set<string>,
) => {
  const siteResponse = await sdk!.GetMicrositeByQuery.query(query, { tolerateErrors: false })();
  if (E.isRight(siteResponse) && siteResponse.right.data?.sites.find?.inboundRef) {
    addSurrogateKeys(siteResponse.right, surrogateKeys);
    return new Microsite(siteResponse.right.data);
  } else if (E.isRight(siteResponse)) {
    throw new SiteConfigError(CONFIG_ERRORS.microsite.distribution);
  }

  if (E.isLeft(siteResponse)) {
    log.error(
      `${CONFIG_ERRORS.microsite.invalid}:`,
      (siteResponse.left as any).response?.errors?.[0]?.message || siteResponse.left.message,
    );
    throw new SiteConfigError(CONFIG_ERRORS.microsite.invalid);
  }

  throw new SiteConfigError(CONFIG_ERRORS.microsite.unknown);
};

interface GqlError {
  response: {
    errors: {
      extensions: {
        code: string;
      };
      locations: {
        column: number;
        line: number;
      }[];
      message: string;
      path: (string | number)[];
    }[];
  };
}

function isGqlError(error: any): error is GqlError {
  return !!error?.response?.errors?.length;
}

export const getPage = async (query: Webapi.GetPageQueryVariables, surrogateKeys: Set<string>) => {
  const start = Date.now();
  const pageResponse = await sdk!.GetPage.query(query, { tolerateErrors: false })();
  log.debug(`getPage took ${Date.now() - start}ms`);
  if (E.isRight(pageResponse) && pageResponse.right.data) {
    addSurrogateKeys(pageResponse.right, surrogateKeys);
    const page = pageResponse.right.data?.sites.find?.configByLookup?.page;
    if (page) {
      return page;
    }
  } else if (E.isLeft(pageResponse)) {
    if (isGqlError(pageResponse.left)) {
      log.debug("query: ", query);
      log.error(pageResponse.left.response.errors.map(({ message }) => message).join("\n"));
      throw new Error("There was an issue with the graphql request");
    }
  }

  throw new Error("Could not find page");
};

export const getNavigationMicrosites = (query: Webapi.GetNavigationMicrositesQueryVariables) =>
  sdk!.GetNavigationMicrosites.queryAsPromise(query).then(r => r.data?.sites.findMany || []);

export const getSiteChildren = (site: Site): Promise<Webapi.SiteChildRefFragment[]> => {
  const children: Webapi.SitesFindManyLookup[] = site.index.keys
    .filter(k => k.includes("primaryParentOf"))
    .map(k => ({
      type: Webapi.Sites_Lookup_By.Id,
      value: k.split(":")[1],
    }));
  return sdk!.GetSiteChildren.queryAsPromise({ children }, { tolerateErrors: false })
    .then(r => r.data?.sites.findMany.filter(child => child.status.isActive) || [])
    .catch(e => {
      log.error(`Could not fetch children for ${JSON.stringify(children)}: ${e.message}`);
      return [];
    });
};

export const getSitePages = (query: Webapi.GetSitePagesQueryVariables) =>
  sdk!.GetSitePages.queryAsPromise(query).then(r => r.data?.sites.find?.configByLookup?.pages);

export const getFeedContent = (query: Webapi.GetFeedContentQueryVariables) =>
  sdk!.GetFeedContent.queryAsPromise(query).then(r => r.data?.sites.find?.configByLookup?.feed?.results);

type GetDatasourceBySlugVariables = Omit<Webapi.GetDatasourceBySlugQueryVariables, "resume"> &
  Partial<Pick<Webapi.GetDatasourceBySlugQueryVariables, "resume">>;

export const getDatasourceBySlug =
  (cache: Map<string, ICache>, initialData: Webapi.FeedResponseFragment, feedId: string, blockId: string) =>
  (variables: GetDatasourceBySlugVariables) =>
    new Cursor<Webapi.FeedResponseFragment, GetDatasourceBySlugVariables, Webapi.FeedResultFragment>(
      variables,
      async opts => {
        // Short-circuit if resume is not provided
        const { resume } = opts;
        if (!resume) {
          log.debug("Load more called with no resume", opts);
          return { results: [] };
        }

        const response = await sdk!.GetDatasourceBySlug.queryAsPromise({ ...opts, lookup, resume });

        const feed = response.data?.sites.find?.configByLookup?.feed;
        if (feed) {
          return feed;
        }
        return { results: [] };
      },
      (newData, opts) => {
        const hasMore = !!newData.resume;
        if (newData.resume) {
          if (!opts.resume) {
            opts.resume = newData.resume;
            delete (opts.resume as any).__typename;
            delete (opts.resume as any)._cache;
          }
          opts.resume.from = newData.resume.from;
        }
        return {
          opts,
          hasMore,
          newData: newData.results,
        };
      },
      opts => `getDatasourceBySlug-${opts.slug}-${feedId}-${blockId}-${JSON.stringify(opts.resume)}`,
      cache,
      { initialData },
    );

export const getSlugByBrandTag = (query: Webapi.GetSlugByBrandTagQueryVariables) =>
  sdk!.GetSlugByBrandTag.queryAsPromise(query).then(r => r.data?.sites.find?.slug);
