import { AnalyticsEvent, ContestEvent, ShareEvent } from "../store/base-store.abstract";
import { ManageCommon } from "./base-manager.abstract";

export abstract class AdobeManage extends ManageCommon {
  protected launchLibId = "adobe-launch-lib";
  protected abstract analyticsSnapshot: string;
  protected abstract ccpaStatus?: string;

  abstract trackClickAction(event: AnalyticsEvent): void;
  abstract trackContestAction(event: ContestEvent): void;
  abstract trackShareAction(event: ShareEvent): void;
  abstract collectData(): void;
}
