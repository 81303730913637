import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet-async";

import { renderMetaTags } from "@inferno/renderer-shared-ui";
import type { Store } from "@inferno/renderer-shared-core";

export interface MetaTagsProps {
  store?: Store;
}

export const MetaTags = inject("store")(
  observer(({ store }: MetaTagsProps) => {
    if (!store) {
      return null;
    }

    return (
      <Helmet
        onChangeClientState={(newState: any) =>
          store.onStoreTagsAction.dispatch({ ready: true, from: "MetaTags", newState })
        }
      >
        {renderMetaTags(store.tags.metadata)}
      </Helmet>
    );
  }),
);

export default MetaTags;
