import { inject } from "mobx-react";
import { Component, Suspense } from "react";

import { CursorLike } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { Spinner } from "../../ui";
import { AsyncFallbackWrapper } from "./AsyncFallbackWrapper.component";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("RemoteList.component");

type RefreshArgs<U> = { quiet: boolean; useEmptyResults?: boolean; options?: U };

export interface RemoteListChildProps<V, U = unknown> {
  data: V[];
  cursor: CursorLike<V, U>;
  next: () => Promise<V[]>;
  refresh: (v?: RefreshArgs<U>) => Promise<V[]>;
  loading: boolean;
  hasNext: boolean;
}

interface RemoteListProps<V, U> {
  cursor: CursorLike<V, U>;
  showLoading: boolean;
  fallback?: JSX.Element;
  children: (props: RemoteListChildProps<V, U>) => JSX.Element | null;
  store?: Store;
}

@inject("store")
export class RemoteList<V, U> extends Component<RemoteListProps<V, U>> {
  render() {
    const { cursor, showLoading, children, store } = this.props;
    const fallback = this.props.fallback || <Spinner visible={showLoading || false} />;

    // On SSR, we don't want suspense because the cache is primed.
    const cacheKey = cursor.cacheKey();
    if (typeof window !== "undefined" && store?.cache.has(cacheKey)) {
      const cacheData = store.getCacheValue(cacheKey);
      if (cacheData && cacheData.data) {
        return (
          <RemoteListImpl
            cacheKey={cursor.cacheKey()}
            cursor={cursor}
            showLoading={showLoading}
            key={cursor.cacheKey()}
          >
            {props => children(props)}
          </RemoteListImpl>
        );
      }
    }

    return (
      <Suspense fallback={<AsyncFallbackWrapper>{fallback}</AsyncFallbackWrapper>}>
        <RemoteListImpl cacheKey={cursor.cacheKey()} cursor={cursor} showLoading={showLoading} key={cursor.cacheKey()}>
          {props => children(props)}
        </RemoteListImpl>
      </Suspense>
    );
  }
}

interface RemoteListImplProps<V, U> {
  cursor: CursorLike<V, U>;
  showLoading: boolean;
  children: (props: RemoteListChildProps<V, U>) => JSX.Element | null;
  cacheKey: string;
  store?: Store;
}
interface RemoteListImplState<V> {
  loading: boolean;
  data?: V[];
  error?: string;
}

@inject("store")
class RemoteListImpl<V, U> extends Component<RemoteListImplProps<V, U>, RemoteListImplState<V>> {
  private mounted = false;
  constructor(props: RemoteListImplProps<V, U>) {
    super(props);

    let error: string | undefined;
    if (this.props?.store?.cache) {
      const { cache } = this.props.store;
      const cacheData = cache.get(this.props.cacheKey);
      if (cacheData) {
        error = cacheData.error;
      }
    }

    this.state = {
      loading: false,
      data: props.cursor.current,
      error,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  // Cannot use generics in static methods
  static getDerivedStateFromProps(props: any) {
    return { data: props.cursor.current };
  }

  next = async () => {
    if (this.mounted) {
      this.setState({ loading: true });
    }

    try {
      const data = await this.props.cursor.next();
      if (this.mounted) {
        this.setState({ data, loading: false });
      }
      return data;
    } catch (error) {
      if (this.mounted) {
        this.setState({ error: error.toString(), loading: false });
      }
      return error;
    }
  };

  refresh = async ({ quiet, useEmptyResults, options }: RefreshArgs<U> = { quiet: true }) => {
    if (this.mounted && !quiet) {
      this.setState({ loading: true });
    }

    const newState: RemoteListImplState<V> = {
      loading: false,
    };

    try {
      const data = await this.props.cursor.refresh(options);
      if (data.length || useEmptyResults) {
        newState.data = data;
        if (this.mounted) {
          this.setState(newState);
        }
      } else {
        log.debug("Not updating data with refreshed data: ", this.props.cacheKey);
      }
      return data;
    } catch (error) {
      newState.error = error.toString();
      if (this.mounted) {
        this.setState(newState);
      }
      return error;
    }
  };

  render() {
    const { cursor, children, showLoading } = this.props;
    const { data, error, loading } = this.state;

    if (error) {
      log.error(this.props.cacheKey, error);
      return null;
    } else if (data) {
      return children({
        data,
        cursor,
        next: this.next,
        refresh: this.refresh,
        loading: loading && showLoading,
        hasNext: cursor.hasMore(),
      });
    } else if (cursor.ready) {
      log.debug(`RemoteList with cacheKey ${this.props.cacheKey} has no data.`, { data, error });
      return null;
    } else {
      throw this.next();
    }
  }
}
