import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import classnames from "classnames";
import * as Cookies from "es-cookie";
import { inject, observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { useElementOnScreen } from "../lib/embed";
import "./Ad.style.scss";
import type { PageBlockInterface } from "./Block.component";

export interface AdComponentProps {
  store?: Store;
  batchId?: string;
  block: BlockFragment;
}

export const Ad = inject("store")(({ block, store, batchId }: AdComponentProps & PageBlockInterface) => {
  const noAds = useRef<string | undefined | null>(null);

  if (!store || block?.value?.position === "0") {
    return null;
  }

  useEffect(() => store.storeBlock({ ...block }));

  useEffect(() => {
    if (isWindowDefined()) {
      // TODO: Can add another check to utilize X-No-Ads cookie set by inferno
      noAds.current = Cookies.get("no-ads");
    }
  }, [noAds]);

  if (noAds.current && noAds.current !== "0") {
    return null;
  }

  return <AdPosition block={block} batchId={batchId || block.id} />;
});

export const AdPosition = inject("store")(
  observer(({ store, block: { value }, batchId }: AdComponentProps) => {
    const [containerRef, isViewable, entryRef] = useElementOnScreen<HTMLDivElement>({
      rootMargin: "100px 0px 100px 0px",
      threshold: 0, // Only need to know when any part becomes visible
    });

    const id = `dfp-ad-${value.position}${batchId ? `-${batchId}` : ""}`;

    if (!store || !value || !value.position) {
      return null;
    }

    useEffect(() => {
      if (isViewable && entryRef.current && store.gptReady) {
        store.onIntersectAction.dispatch({ entry: entryRef.current });
      }
    }, [isViewable, entryRef, store.onIntersectAction, store.gptReady]);

    const classNames = classnames("component-ad-unit", {
      "lazy-load": !isViewable,
      "visible-refresh": isViewable,
    });

    return (
      <div
        ref={containerRef}
        tabIndex={-1}
        className={classNames}
        data-position={value.position}
        data-split={value.ad_split}
        id={id}
      />
    );
  }),
);

export default Ad;
