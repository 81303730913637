import type { DOMWindow } from "jsdom";
import { createElement, FunctionComponent } from "react";
import { Provider } from "mobx-react";
import ReactDOMServer from "react-dom/server";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import { getAdFrequency, makeAd } from "@inferno/renderer-shared-core";

interface InsertAdsConfig {
  contentDetailContainer: Element | null | undefined;
  tags: string[] | null | undefined;
  adElement: FunctionComponent<any>;
  adId?: string | null;
  adSplit?: number | null;
  store: Store;
  win?: typeof window | DOMWindow;
}

export function insertAds(config: InsertAdsConfig) {
  const { contentDetailContainer, tags, adElement, adSplit, store, win = window } = config;
  const adFirstPosition = 2;
  const adId = config.adId || "3327";
  const adBlockData = makeAd(adId, adSplit);
  const adInsertFrequency = getAdFrequency(tags);

  if (contentDetailContainer && !contentDetailContainer.querySelector(`#dfp-ad-${adId}`)) {
    let currentPosition = 0;
    for (const node of Array.from(contentDetailContainer.childNodes)) {
      if (node instanceof win.HTMLElement) {
        if (node.classList.contains("content-block") && node.classList.contains("type-html")) {
          const htmlEmbed = node.querySelector(".component-embed-html");
          if (htmlEmbed) {
            for (const hNode of Array.from(htmlEmbed.childNodes)) {
              if (hNode instanceof win.HTMLElement) {
                currentPosition++;
                if (isAdPosition(currentPosition, adFirstPosition, adInsertFrequency)) {
                  insertAd({
                    adBlockData,
                    node: hNode,
                    currentPosition,
                    doc: win.document,
                    adElement,
                    store,
                  });
                }
              }
            }
          }
        } else if (node.classList.contains("block")) {
          currentPosition++;
          if (isAdPosition(currentPosition, adFirstPosition, adInsertFrequency)) {
            insertAd({ adBlockData, node, currentPosition, doc: win.document, adElement, store });
          }
        }
      }
    }
  }
}

export function isAdPosition(currentPosition: number, adFirstPosition: number, adInsertFrequency: number) {
  return currentPosition === adFirstPosition || (currentPosition - adFirstPosition) % adInsertFrequency === 0;
}

interface InsertAdConfig {
  adBlockData: BlockFragment;
  node: HTMLElement;
  currentPosition: number;
  doc: typeof document;
  adElement: FunctionComponent<any>;
  store: Store;
}

function insertAd(config: InsertAdConfig) {
  const { adElement, adBlockData, currentPosition, store, node, doc = document } = config;
  const ad = doc.createElement("div");

  ad.innerHTML = ReactDOMServer.renderToString(
    <Provider store={store}>
      {createElement(adElement, {
        block: adBlockData,
        batchId: currentPosition.toString(),
      })}
    </Provider>,
  ).trim();

  node.after(ad.firstChild || ad);
}
