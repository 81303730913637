import * as Webapi from "@ihr-radioedit/inferno-webapi";

export const getNavMicrosites = (menus: Webapi.SitesMenu[] | null | undefined) => {
  if (!menus) {
    return null;
  }
  const navReferences: string[] = [];
  menus.forEach(menuItem => {
    if (menuItem.children) {
      menuItem.children.forEach(menuItemChild => {
        if (menuItemChild.type === "brand" && menuItemChild.ref) {
          navReferences.push(menuItemChild.ref);
        }
      });
    }
    if (menuItem.type === "brand" && menuItem.ref) {
      navReferences.push(menuItem.ref);
    }
  });
  return navReferences;
};

export const getCoastHints = (tags?: string[] | null) => {
  return (
    tags
      ?.filter(tag => tag.startsWith("display-hints/coast") || tag.includes("tile"))
      .map(tag => tag.replace("display-hints/", "")) || []
  );
};

export const isPrimaryBlock = (tags: string[] | null = []): boolean => {
  return tags ? tags.includes("display-hints/primary") : false;
};
