import { isRight } from "fp-ts/Either";
import url from "url-parse";

import { PayloadResolver } from "@ihr-radioedit/inferno-core";

import { isFeedResult, isItemBlock, isPublishRecord } from "../../../lib/guards";
import { TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { generateMetaTag } from "../../util";
import { coastArticleTemplateTags } from "./coast-article";

export const coastShowTemplateTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block } = context;
  if (block && isItemBlock(block) && isFeedResult(block.item?.result) && isPublishRecord(block.item?.result.record)) {
    const content = block.item?.result.record;
    if (content) {
      const resolvedPayload = PayloadResolver.decode(content.payload);
      const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;

      state = coastArticleTemplateTags(state, context);

      const { metadata } = state;
      let canonicalUrl = payload?.canonical_url || "";
      if (canonicalUrl && !url(canonicalUrl).protocol) {
        canonicalUrl = `https:${canonicalUrl}`;
      }

      if (canonicalUrl) {
        canonicalUrl = canonicalUrl.replace("content", "show");
        metadata.set("canonical", { value: canonicalUrl, type: "link", rel: "canonical" });
        metadata.set(...generateMetaTag("name", "parsely-network-canonical", canonicalUrl));
      }

      if (content.slug) {
        const ampUrl = `https://${site.getPrimaryDomain()}/alternate/amp/show/${content.slug}/`;
        metadata.set("amphtml", { value: ampUrl, type: "link", rel: "amphtml" });
      }

      return {
        ...state,
        metadata,
      };
    }
  }

  return state;
};
