import { SyntheticEvent } from "react";
import * as React from "react";
import classnames from "classnames";
import "./Button.style.scss";

export enum ButtonKind {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  CUSTOMIZED = "customized",
}

/*
 * ButtonProps
 * kind: visual style correlates to UX naming, customized has no default user-agent styles (text or icon only buttons)
 * type: html button attribute
 * */
export interface ButtonProps {
  kind?: ButtonKind;
  size?: "default" | "small";
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  children?: React.ReactNode;
  label?: string;
  hidden?: boolean;
  className?: string;
  icon?: boolean;
  click?: (e: SyntheticEvent) => void;
}

export const Button = ({
  children,
  label = "",
  className,
  click,
  type = "button",
  kind = ButtonKind.PRIMARY,
  size = "default",
  disabled = false,
  icon = false,
  hidden = false,
}: ButtonProps) => {
  const buttonCss = classnames("component-button", {
    [`${kind}`]: kind,
    [`${size}`]: size !== "default",
    "with-icon": icon,
    [`${className}`]: className,
    hidden,
  });

  const ariaProps = {
    "aria-label": label,
    "aria-hidden": hidden,
  };

  return (
    <button
      className={buttonCss}
      {...ariaProps}
      type={type}
      onClick={(e: SyntheticEvent) => !disabled && click && click(e)}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
