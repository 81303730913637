import loadable from "@loadable/component";
import classnames from "classnames";
import { createElement, useMemo } from "react";
import { blockMap } from "../../../../core/content-blocks";
import { BlockBase } from "@ihr-radioedit/inferno-core";
import type { BlockFragment, PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";
import { isBlockScheduled } from "../../../../core/lib/guards";
import { ILog } from "@ihr-radioedit/inferno-core";
import { Content } from "../../../../core/page-blocks/content/Content.component";
import { getCoastHints } from "@ihr-radioedit/inferno-core";
import { COAST_CONTENT_TYPES } from "../../lib/coastCommon";
import "./CoastContent.style.scss";

const CoastContentGuest = loadable(() => import("./CoastContentGuest.component"));
const CoastContentShow = loadable(() => import("./CoastContentShow.component"));
const CoastHostDetail = loadable(() => import("./CoastHostDetail.component"));
const CoastContentArticle = loadable(() => import("./CoastContentArticle.component"));

export type CoastContentProps = {
  content: PublishRecordFragment;
  block: BlockFragment;
};

export const CoastContent = ({ content, block }: CoastContentProps) => {
  const hints = useMemo(() => getCoastHints(block.tags), [block.tags]);

  switch (content.type) {
    case COAST_CONTENT_TYPES.show:
      return <CoastContentShow content={content} />;
    case COAST_CONTENT_TYPES.guest:
      return <CoastContentGuest content={content} />;
    case COAST_CONTENT_TYPES.article:
      if (hints.includes("coast-host-detail")) {
        return <CoastHostDetail content={content} />;
      }
      return <CoastContentArticle content={content} coastHints={hints} />;
    default:
      return <Content content={content} block={block} />;
  }
};

export const CoastContentBlock = ({ block }: { block: BlockBase }) => {
  const log = ILog.logger("Coast Content Block");

  if (!block) {
    log.error("No Block given to CoastContentBlock");
  }

  if (!isBlockScheduled(block) || !blockMap[block.type]) {
    return null;
  }

  try {
    const el = createElement<BlockBase>(blockMap[block.type], block);

    const blockClass = classnames("content-block", { [`type-${block.type}`]: block.type });

    return <section className={blockClass}>{el}</section>;
  } catch (e) {
    log.debug(e.message);
  }

  return null;
};
