import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { loadableReady } from "@loadable/component";
import { Provider } from "mobx-react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Route, Router } from "react-router-dom";

import { StartupEventMap } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";

import { App } from "../app/sites/App.component";
import { OneTrustManager } from "../app/core/lib/onetrust";
import { UspApiFunc } from "../app/core/lib/iab";
import { performance } from "./helpers";
import {
  initABTestGroups,
  initIntegrations,
  initLocale,
  initSession,
  bootstrap,
  initLazySizes,
  loadIntegrationScripts,
} from "./setup";
import { withTimeout } from "../app/core/lib/promise-timeout";

const log = ILog.logger("client/index.tsx");

declare global {
  interface Window {
    __PRELOADED_STATE__: any;
    bootEvents: StartupEventMap;
    moatYieldReady: () => void;
    domCompleteReady: () => void;
    bridgeReady: () => void;
    lazySizesConfig: {
      preloadAfterLoad: boolean;
      init: boolean;
    };
    lazySizes: {
      init: () => void;
      cfg: any;
    };
    recaptchaCallback: (result: string) => void;
    invokeAnalyticsVendorBeacons?: (opts: { ccpa: boolean }) => void;
    deferAnalyticsVendorBeacons?: boolean;
    __uspapi?: UspApiFunc;
    newrelic: typeof import("newrelic");
  }
}

const oneTrustTimeout = 3000;

loadableReady(async () => {
  performance("mark", ["Inferno - Loadable Ready"]);

  const { store, browserHistory, no3rdParty } = bootstrap();

  initSession(store, no3rdParty);

  await initABTestGroups(store, no3rdParty);

  await initLocale(store);

  loadIntegrationScripts(store);

  log.debug("6.) Hydrating App Start (Synchronously)");
  ReactDOM.hydrate(
    <HelmetProvider>
      <Provider store={store}>
        <Router history={browserHistory}>
          <Route component={App} />
        </Router>
      </Provider>
    </HelmetProvider>,
    document.getElementById("app"),
    async () => {
      log.debug("6.) Hydrating App Finished", "(React will now queue useEffect hooks)");

      initLazySizes();

      const oneTrust = new OneTrustManager(store);
      try {
        log.debug("8.) Initializating OneTrust (Fourth Await)");
        await withTimeout(
          oneTrust.init(),
          oneTrustTimeout,
          `OneTrust initialization timed out after ${oneTrustTimeout / 1000} seconds`,
        );
        log.debug("8.) Initializating OneTrust finished");
        log.debug("8.) Loading OneTrust Libs (Fifth Await)");
        await withTimeout(
          Promise.all([oneTrust.loadConsentNoticeLibrary(), oneTrust.loadLibrary(), oneTrust.setBootstrap()]),
          oneTrustTimeout,
          `OneTrust libraries loading timed out after ${oneTrustTimeout / 1000} seconds`,
        );
        log.debug("8.) Loading OneTrust Libs finished");
      } catch (err) {
        log.error(`8.) Error Initializing OneTrust`, err);
        window.newrelic?.noticeError(err);
        oneTrust.disable();
      } finally {
        log.debug("8.) OneTrust Complete, Calling Integration Callback");
        initIntegrations(store, no3rdParty);
        log.debug("10.) Firing On Load Page Views");
        store.trackPageViews();
      }
      log.debug(
        "9.) Loadable Ready Finished - Event Loop Callback/MicroTasks Queue from Integrations Callback Starts Running",
      );
    },
  );
});
