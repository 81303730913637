import { AnalyticsEvent, BaseStore } from "../store/base-store.abstract";

export abstract class ManageCommon {
  protected abstract _library?: Promise<void>;
  protected abstract store: BaseStore;

  abstract loadLibrary(payload?: any): Promise<void>;
  abstract trackPageView(payload?: any): void;
  abstract trigger(event: AnalyticsEvent): void;
}
