import { zonedTimeToUtc } from "date-fns-tz";
import { isRight } from "fp-ts/Either";
import i18n from "i18next";
import url from "url-parse";
import utf8 from "utf8";

import { CalendarPayloadResolver } from "@ihr-radioedit/inferno-core";
import { BlockBaseLegacy, slugify } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";

import { filterEventDates } from "../../../lib/calendar";
import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat } from "../../../lib/time";
import { sanitize } from "../../../utilities/sanitize";
import { isCalendarPublishRecord, isFeedResult, isItemBlock } from "../../../lib/guards";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { getParselyDate } from "@ihr-radioedit/inferno-core";
import { matchPrefix, removePrefix } from "@ihr-radioedit/inferno-core";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { generateMetaTag, getOriginType, processMetaImage, setGeneralMetaTags } from "../../util";
import { setCacheTtl } from "../../../utilities/header";

export const calendarArticleTemplateTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, env, request } = context;
  if (
    block &&
    isItemBlock(block) &&
    isFeedResult(block.item?.result) &&
    isCalendarPublishRecord(block.item?.result.record)
  ) {
    const content = block.item?.result.record;
    if (content) {
      const resolvedPayload = CalendarPayloadResolver.decode(content.payload);
      const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;

      const keywords: string[] = [];
      payload?.fields.keywords?.value?.forEach(value => {
        if (value) {
          keywords.push(removePrefix(/^\w+\//)(value));
        }
      });

      const { surrogateKeys } = state;
      if (surrogateKeys) {
        surrogateKeys.add(`calendar/${content.slug}`);
        surrogateKeys.add(`calendar/${decodeURIComponent(content.ref_id).replace("/", "")}`);
      }

      let title = payload?.fields.seo_title?.value || content.summary.title || "";

      if (title) {
        title = `${title} | ${site.sections.general?.name}`;
      }

      const stationName = site.sections.general?.name || "";
      const venue = payload?.fields.venue_name?.value || "";
      const address = payload?.fields.venue_address?.value || "";
      const seoTitle = payload?.fields.social_title?.value || block.value.title || "";

      const eventDateValue =
        filterEventDates(payload?.fields.event_date?.value ?? [])[0] ?? payload?.fields.event_date?.value[0];
      let eventDate = null;

      if (eventDateValue?.begin) {
        eventDate = zonedTimeFormat({
          date: eventDateValue?.begin,
          timezone: site.index?.timeZone ? site.index.timeZone : env.DEFAULT_TIMEZONE,
          outputFormat: DATETIME_DISPLAY_FORMATS.SHORT_MONTH_DAY_WITH_SUFFIX_FULL_YEAR,
        });
      }

      if (seoTitle) {
        title = `${seoTitle} | ${eventDate} | ${venue}`;
        title = `${title} | ${stationName}`;
        state.surrogateKeys?.add(`event/${slugify(seoTitle)}`);
      }

      let socialTitle = payload?.fields.social_title?.value
        ? `${payload.fields.social_title.value} | ${site.sections.general?.name}`
        : title;

      if (eventDate && venue) {
        socialTitle = `${socialTitle} | ${eventDate} | ${venue}`;
      }

      if (venue) {
        keywords.push(...sanitize(venue.trim().toLowerCase()).split(" "));
      }

      if (address) {
        keywords.push(...sanitize(address.trim().toLowerCase().replace(/,/g, "")).split(" "));
      }

      let description = content.summary.description || "";
      if (!description) {
        const station = `${site.sections.general?.name} - ${site.sections.general?.positioner}`;
        description = i18n.t("event_description", { station, stationName });
      }

      const image = formatImage(content.summary.image || "", env.IMAGE_HOST);

      const categories: string[] = [];
      const topics: string[] = [];
      content.subscription.forEach((item: Webapi.Subscription) => {
        const tags = item.tags.filter(matchPrefix(/^calendar-categories\//)).map(removePrefix(/^[\w-]+\//));
        if (!!tags?.length) {
          topics.push(...tags);
          categories.push(...tags);
        }
      });

      const galleries = payload?.fields.blocks?.value?.filter((item: BlockBaseLegacy) => item.type === "gallery") || [];
      const galleryExists = !!galleries.length;
      const contentOrigin = removePrefix(/^brands\//)(payload?.targeting.publish_origin || "");

      let authorId = payload?.cuser?.split("/").pop() || "";
      const partner = payload?.ingestion.feed_content_id;
      if (partner) {
        authorId = partner;
      }
      if (payload?.ingestion.feed_vendor) {
        authorId = content.payload.feed_vendor;
      }

      const timezone = site.index.timeZone || "";
      const pubDate = zonedTimeToUtc(content.pub_start, timezone).getTime().toString();
      const contentId =
        typeof btoa !== "undefined"
          ? btoa(utf8.encode(decodeURIComponent(content.ref_id)))
          : Buffer.from(utf8.encode(decodeURIComponent(content.ref_id))).toString("base64");

      let { metadata } = state;
      metadata = setGeneralMetaTags(metadata, {
        titles: {
          default: title,
          social: socialTitle,
        },
        url: site.getShareUrl(request),
        image: { url: processMetaImage(image, context) },
        description,
        keywords,
        types: {
          openGraph: "article",
          parsely: "post",
        },
        twitter: {
          card: "summary_large_image",
        },
      });

      let canonicalUrl = payload?.canonical_url || "";
      if (canonicalUrl && !url(canonicalUrl).protocol) {
        canonicalUrl = `https:${canonicalUrl}`;
      }

      if (canonicalUrl) {
        metadata.set("canonical", { value: canonicalUrl, type: "link", rel: "canonical" });
        metadata.set(...generateMetaTag("name", "parsely-network-canonical", canonicalUrl));
      }

      metadata.set(...generateMetaTag("name", "parsely-pub-date", getParselyDate(content.pub_start)));

      if (content.summary.author) {
        metadata.set(...generateMetaTag("name", "parsely-author", content.summary.author));
      }

      let parselySection = "calendar";
      if (block.eyebrow?.topic_name) {
        parselySection = block.eyebrow?.topic_name;
      }

      metadata.set(...generateMetaTag("name", "parsely-section", parselySection));
      metadata.set(...generateMetaTag("name", "robots", "max-image-preview:large"));

      if (categories.length) {
        metadata.set(...generateMetaTag("name", "categories", categories.join(", ")));
      }

      let ampEnabled = true;
      const domain = site.getPrimaryDomain();
      if (canonicalUrl && url(canonicalUrl).hostname !== domain) {
        ampEnabled = false;
      }

      if (ampEnabled && content.slug && !partner) {
        const ampUrl = `https://${domain}/alternate/amp/calendar/${content.slug}/`;
        metadata.set("amphtml", { value: ampUrl, type: "link", rel: "amphtml" });
      }

      const uniqueTopics = Array.from(new Set([...topics, ...categories])).map(item => slugify(item));

      return {
        ...state,
        surrogateKeys,
        ttls: {
          ...state.ttls,
          server: setCacheTtl(content.pub_start),
        },
        ads: {
          ...state.ads,
          keywords: keywords.map(item => slugify(item)),
          topics: uniqueTopics,
        } as AdTags,
        analytics: {
          ...state.analytics,
          view: {
            ...state.analytics?.view,
            asset: {
              name: sanitize(content.summary.title) || "",
              id: `content|${content.slug}`,
            },
            authorId,
            tags: keywords.map(item => slugify(item)),
            topics: uniqueTopics,
            photoGalleryExists: galleryExists.toString(),
            photoGalleryPageView: galleryExists.toString(),
            contentOrigin,
            contentOriginType: getOriginType(contentOrigin),
            contentId,
            pubDate,
          },
        } as AnalyticsTags,
        metadata,
      };
    }
  }

  return state;
};
