import type { PayloadInterface, PayloadInterfaceLegacy } from "@ihr-radioedit/inferno-core";
import { BlockBase } from "@ihr-radioedit/inferno-core";
import type { ErrorResponse, Recipient } from "../decoders/PostUp.types";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { isCalendarPublishRecord, isFeedBlock, isPublishRecord } from "@inferno/renderer-shared-core";

export function isAuthorRecord(v?: Webapi.FeedResultFragment["record"] | undefined): v is Webapi.PublishRecordFragment {
  return !!(v && isPublishRecord(v) && v.hasOwnProperty("type") && v.type === "content:author");
}

export function isAudienceAppRecord(
  v?: Webapi.FeedResultFragment["record"] | undefined,
): v is Webapi.AudienceRecordFragment {
  return !!(v && v.hasOwnProperty("terms"));
}

function isPWSGuestRecordSummary(v: any): v is Webapi.PwsGuestRecordFragment {
  return (
    !!v.summary &&
    v.summary.hasOwnProperty("title") &&
    v.summary.hasOwnProperty("description") &&
    v.summary.hasOwnProperty("image")
  );
}

export function isPWSGuestRecord(
  v?: Webapi.FeedResultFragment["record"] | undefined,
): v is Webapi.PwsGuestRecordFragment {
  return !!(
    v &&
    v.hasOwnProperty("id") &&
    v.hasOwnProperty("slug") &&
    v.hasOwnProperty("summary") &&
    isPWSGuestRecordSummary(v)
  );
}

export function isCalendarFeedBlock(block?: BlockFragment): block is Webapi.Block_SitesFeedBlock_Fragment {
  return !!(
    block &&
    isFeedBlock(block) &&
    (block?.value.feed_id === "ID:calendar-topics-feed-matsubs" ||
      isCalendarPublishRecord(block.feed?.results[0]?.record))
  );
}

export function isAudienceFeedBlock(block?: BlockFragment): block is Webapi.Block_SitesFeedBlock_Fragment {
  return !!(
    block &&
    isFeedBlock(block) &&
    (block?.value.feed_id === "ID:contest-rules-feed-audience-promobuilder" ||
      isAudienceAppRecord(block.feed?.results[0]?.record))
  );
}

// Block_SitesKeywordContestBlock_Fragment
// KeywordContestFragment
export function isKeywordContestBlock(block?: BlockFragment): block is Webapi.Block_SitesKeywordContestBlock_Fragment {
  return block && block.type === "keywordcontest" && (block as any).hasOwnProperty("schedule");
}

export function isRecipientResponse(response: any): response is Recipient {
  return !!(response && response.recipientId && response.status);
}

export function isErroneousResponse(response: any): response is ErrorResponse {
  return response && response.data && response.status === "error";
}

export function isCurrentlyPlayingTrackDetailFragment(v: any): v is Webapi.CurrentlyPlayingTrackDetailFragment {
  return (
    v &&
    v.hasOwnProperty("dataSource") &&
    v.hasOwnProperty("startTime") &&
    v.hasOwnProperty("endTime") &&
    v.hasOwnProperty("status") &&
    v.hasOwnProperty("offset") &&
    v.hasOwnProperty("trackDurationInSec")
  );
}

export function isTopTrackDetailFragmentList(v: any[]): v is Webapi.TopTrackDetailFragment[] {
  return v.map(track => !isCurrentlyPlayingTrackDetailFragment(track)).length === v.length;
}

export const isPayloadInterface = (
  v: PayloadInterfaceLegacy | Webapi.ContentPayload | PayloadInterface,
): v is PayloadInterface => v.hasOwnProperty("fields");

export function isYouTubeBlock(block: BlockFragment): block is Webapi.Block_SitesYoutubeBlock_Fragment {
  return block && block.type === "youtube" && block.hasOwnProperty("default_video_id");
}

export function isYouTubePlaylist(block: BlockFragment): block is Webapi.Block_SitesYoutubeBlock_Fragment {
  return block && block.type === "youtube" && block.hasOwnProperty("playlist");
}

export function isYouTubeChannel(block: BlockFragment): block is Webapi.Block_SitesYoutubeBlock_Fragment {
  return block && block.type === "youtube" && block.hasOwnProperty("channel");
}

export function isBlockScheduled({ schedule }: BlockBase, now = Date.now()) {
  if (!schedule || !schedule.enabled) {
    return true;
  }

  const begin = schedule.begin || now;
  const end = schedule.end || Number.MAX_SAFE_INTEGER;

  return begin <= now && end > now;
}

export function isCatalogPodcastRecord(v?: Webapi.SitesCatalogRef["record"] | undefined): v is Webapi.CatalogPodcast {
  return !!(v && v.hasOwnProperty("kind") && v.hasOwnProperty("amp") && v.kind === Webapi.CatalogKind.Podcast);
}

export function isCatalogAmpPodcast(v?: any): v is Webapi.AmpPodcastFragment {
  return !!(
    v &&
    v.hasOwnProperty("brand") &&
    v.hasOwnProperty("podcastDescription") &&
    v.hasOwnProperty("podcastId") &&
    v.hasOwnProperty("imageUrl") &&
    v.hasOwnProperty("slug")
  );
}

export function isCatalogStationRecord(v?: Webapi.SitesCatalogRef["record"] | undefined): v is Webapi.CatalogStation {
  return !!(v && v.hasOwnProperty("kind") && v.hasOwnProperty("amp") && v.kind === Webapi.CatalogKind.Station);
}

export function isCatalogAmpStation(v?: any): v is Webapi.AmpStationFragment {
  return !!(
    v &&
    v.hasOwnProperty("callLetters") &&
    v.hasOwnProperty("country") &&
    v.hasOwnProperty("country") &&
    v.hasOwnProperty("logo") &&
    v.hasOwnProperty("isActive") &&
    v.hasOwnProperty("stationId")
  );
}

export function isCatalogPlayListRecord(v?: Webapi.SitesCatalogRef["record"] | undefined): v is Webapi.CatalogPlaylist {
  return !!(v && v.hasOwnProperty("kind") && v.hasOwnProperty("amp") && v.kind === Webapi.CatalogKind.Playlist);
}

export function isCatalogAmpPlayList(v?: any): v is Webapi.AmpCollectionFragment {
  return !!(
    v &&
    v.hasOwnProperty("id") &&
    v.hasOwnProperty("userId") &&
    v.hasOwnProperty("curated") &&
    v.hasOwnProperty("premium") &&
    v.hasOwnProperty("name") &&
    v.hasOwnProperty("description") &&
    v.hasOwnProperty("slug") &&
    v.hasOwnProperty("trackId")
  );
}

export function isCatalogAlbumRecord(v?: Webapi.SitesCatalogRef["record"] | undefined): v is Webapi.CatalogAlbum {
  return !!(v && v.hasOwnProperty("kind") && v.hasOwnProperty("amp") && v.kind === Webapi.CatalogKind.Album);
}

export function isCatalogAmpAlbum(v?: any): v is Webapi.AmpAlbumFragment {
  return !!(
    v &&
    v.hasOwnProperty("albumId") &&
    v.hasOwnProperty("artistId") &&
    v.hasOwnProperty("artistName") &&
    v.hasOwnProperty("copyright") &&
    v.hasOwnProperty("image") &&
    v.hasOwnProperty("title") &&
    v.hasOwnProperty("displayable") &&
    v.hasOwnProperty("explicitLyrics") &&
    v.hasOwnProperty("publisher") &&
    v.hasOwnProperty("releaseDate") &&
    v.hasOwnProperty("totalSongs")
  );
}

export function isCatalogTrackRecord(v?: Webapi.SitesCatalogRef["record"] | undefined): v is Webapi.CatalogTrack {
  return !!(v && v.hasOwnProperty("kind") && v.hasOwnProperty("amp") && v.kind === Webapi.CatalogKind.Track);
}

export function isCatalogAmpTrack(v?: any): v is Webapi.AmpTrackFragment {
  return !!(
    v &&
    v.hasOwnProperty("albumId") &&
    v.hasOwnProperty("albumName") &&
    v.hasOwnProperty("artistId") &&
    v.hasOwnProperty("artistName") &&
    v.hasOwnProperty("duration") &&
    v.hasOwnProperty("lyricsId") &&
    v.hasOwnProperty("publisher") &&
    v.hasOwnProperty("title") &&
    v.hasOwnProperty("trackNumber") &&
    v.hasOwnProperty("version") &&
    v.hasOwnProperty("releaseDate") &&
    v.hasOwnProperty("volume") &&
    v.hasOwnProperty("imageUrl")
  );
}

export function isCatalogArtistRecord(v?: Webapi.SitesCatalogRef["record"] | undefined): v is Webapi.CatalogArtist {
  return !!(v && v.hasOwnProperty("kind") && v.hasOwnProperty("amp") && v.kind === Webapi.CatalogKind.Artist);
}

export function isCatalogAmpArtist(v?: any): v is Webapi.CatalogArtistFragment {
  return !!(
    v &&
    v.hasOwnProperty("albums") &&
    v.hasOwnProperty("artistName") &&
    v.hasOwnProperty("bio") &&
    v.hasOwnProperty("image") &&
    v.hasOwnProperty("tracks")
  );
}
