import { isAxiosError } from "@ihr-radioedit/sdk-network";
import { ILog } from "@ihr-radioedit/inferno-core";
import { COAST_MESSAGES } from "@inferno/renderer-shared-core";

const log = ILog.logger("CoastCommon.lib");

export interface AxiosLoaderResponse<T> {
  error?: string;
  data: T | null;
}

export const axiosErrorReason = (e: any) => e.response?.data?.reason;

const processAxiosLoaderError = (e: any, defaultError?: string) => {
  log.error(e);

  return {
    error: ((isAxiosError(e) && axiosErrorReason(e)) || defaultError || COAST_MESSAGES.FETCHING_ERROR) as string,
    data: null,
  };
};

export async function axiosLoaderWrapper<T>(cb: () => Promise<T>, defaultError?: string) {
  try {
    return { data: await cb() };
  } catch (e) {
    return processAxiosLoaderError(e, defaultError);
  }
}

export const COAST_CONTENT_TYPES = {
  article: "content:coast-to-coast-article",
  guest: "content:coast-to-coast-guest",
  show: "content:coast-to-coast-show",
};
