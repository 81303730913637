import { Container, Skeleton, Card, Grid } from "../../ui";
import "./HomePageSkeleton.style.scss";

interface HomePageSkeletonProps {
  pulse?: boolean;
}
const PromotionalFeedItems = ({ pulse = true }) => {
  const cards = [];
  for (let counter = 0; counter < 4; counter++) {
    cards.push(<PromotionalFeedSkeleton pulse={pulse} key={counter} />);
  }
  return (
    <Container className="feed-collection-skeleton-container">
      <Grid columns={4}>{cards.map(card => card)}</Grid>
    </Container>
  );
};

const LargeFeedItem = ({ pulse = true }) => {
  const cards = [];
  for (let counter = 0; counter < 2; counter++) {
    cards.push(<PromotionalFeedSkeleton pulse={pulse} key={counter} />);
  }
  return (
    <Container className="feed-style-large-skeleton-container">
      <Grid columns={2}>{cards.map(card => card)}</Grid>
    </Container>
  );
};

const PromotionalFeedSkeleton = ({ pulse = true }) => {
  return (
    <div className="feed-style-small">
      <Card>
        <figure className="content-tile-skeleton">
          <section className="thumb">
            <Skeleton pulse={pulse} type="image" name="card thumbnail" shape="16x9" />
          </section>
          <figcaption>
            <Skeleton pulse={pulse} type="text" name="title line 1" size={5} />
          </figcaption>
        </figure>
      </Card>
    </div>
  );
};

export const HomePageSkeleton = ({ pulse = true }: HomePageSkeletonProps) => {
  return (
    <Container className="homepage-skeleton-container">
      <section className="col-top">
        <div className="onAir-tile">
          <figure className="onAir-current-skeleton">
            <section className="onAirCurrent-thumb">
              <Skeleton pulse={pulse} type="image" name="card thumbnail" shape="circle" width="40px" />
            </section>
            <figcaption>
              <Skeleton pulse={pulse} type="text" name="title line 1" size={6} width="40%" />
              <Skeleton pulse={pulse} type="text" name="byline" size={6} width="30%" />
            </figcaption>
          </figure>
          <figure className="onAir-UpNext-skeleton">
            <section className="onAirUpNext-thumb">
              <Skeleton pulse={pulse} type="image" name="card thumbnail" shape="circle" width="40px" />
            </section>
            <figcaption>
              <Skeleton pulse={pulse} type="text" name="title line 1" size={6} width="40%" />
              <Skeleton pulse={pulse} type="text" name="byline" size={6} width="30%" />
            </figcaption>
          </figure>
        </div>
      </section>
      <section className="col-left">
        <div className="feed-block">
          <Grid>
            <Card>
              <figure className="feed-tile-skeleton">
                <section className="thumb">
                  <Skeleton pulse={pulse} type="image" name="card thumbnail" shape="16x9" />
                </section>
                <figcaption>
                  <Skeleton pulse={pulse} type="text" name="title line 1" size={5} />
                  <Skeleton pulse={pulse} type="text" name="title line 1" size={6} width="100px" />
                </figcaption>
              </figure>
            </Card>
          </Grid>
        </div>
        <PromotionalFeedItems pulse={true} />
        <LargeFeedItem pulse={true} />
        <PromotionalFeedItems pulse={true} />
      </section>
    </Container>
  );
};
