import { inject } from "mobx-react";
import { createElement, Fragment } from "react";
import { Div } from "react-amphtml";
import { isCurrentlyPlayingTrackDetailFragment } from "../lib/guards";
import { placeholderImage, generateCatalogUrl, CatalogLegacyProps, QueryProps } from "@ihr-radioedit/inferno-core";

export const AmpCatalogLegacy = inject("store")(
  ({ store, track, id, kind, related_id, query, isPodcastEmbed }: { query?: QueryProps } & CatalogLegacyProps) => {
    if (!store) {
      return null;
    }

    const currentTrack = track && isCurrentlyPlayingTrackDetailFragment(track) ? track : track?.track;

    const catalogUrl = generateCatalogUrl(
      { national_site_url: store.env.NATIONAL_SITE_URL, node_env: store.env.NODE_ENV },
      {
        track: currentTrack,
        id,
        kind,
        embed: true,
        related_id: related_id || "",
        query: {
          ...query,
          keyid: isPodcastEmbed ? store.env.PODCAST_EMBED_KEYID : store.request.query?.keyid,
        },
      },
    );

    if (catalogUrl) {
      return (
        <Div specName="default" className="component-catalog-container">
          {createElement(
            "amp-iframe",
            {
              src: catalogUrl,
              sandbox: "allow-scripts allow-same-origin allow-presentation",
              width: 300,
              height: 400,
              layout: "responsive",
              title: "Music Catalog",
            },
            <Fragment>
              {createElement("amp-img", {
                layout: "fill",
                src: placeholderImage,
                width: 300,
                height: 400,
                placeholder: "placeholder",
              })}
              {createElement("div", { placeholder: "placeholder" })}
            </Fragment>,
          )}
        </Div>
      );
    }
    return null;
  },
);

export default AmpCatalogLegacy;
