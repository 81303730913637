export const CONFIG_ERRORS = {
  site: {
    notFound: "Could not load site config",
    invalid: "Site config response is invalid",
    unknown: "An unknown error occurred while fetching site config",
    inactive: "Site is Inactive",
    hidden: "Site is Hidden",
    primaryDomain: "Not Sites Primary Domain",
  },
  microsite: {
    notFound: "Could not load microsite config",
    invalid: "Microsite config response is invalid",
    unknown: "An unknown error occurred while fetching microsite config",
    inactive: "Microsite is Inactive",
    hidden: "Microsite is Hidden",
    changed: "MicroSite slug has been changed",
    distribution: "Microsite is not distributed to the current site",
  },
};

export class SiteConfigError extends Error {
  constructor(msg: string) {
    super(msg);

    Object.setPrototypeOf(this, SiteConfigError.prototype);
  }
}
