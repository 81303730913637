import { connectionRequestFactory } from "@ihr-radioedit/gql-client";
import { Connection, ConnectionConfig } from "@ihr-radioedit/sdk-network";
import { AuthenticatedConnection } from "@ihr-radioedit/service-auth";
import { getSdk as sdk } from "@ihr-radioedit/inferno-webapi";
import { environ } from "@ihr-radioedit/sdk-utils";

/** Why do we have to keep fetching this sdk config? well, storybook sucks.
 *  During its bootstrap/require phase on the CLIENT, it tries to parse/execute inferno package(s) before any env variables get set on the Environment Instance from sdk-utils
 *  Our Storybook is configured to initialize its env vars further down the pipeline: bootstrap/require phase -> manager phase -> preview phase in (.storybook/wrapper.tsx -- environ.reset())
 *  Tried setting it earlier via webpack config, but no luck. Tried setting a flag through webpack/npm script but it just gets discarded; issue: https://github.com/storybookjs/storybook/issues/17336
 *  Consequently, we cant cache this config export and must always retrieve on demand.. Performance drop should be negilble; so will revert back to old impl when fully migrated to monorepo.
 */
export const sdkOpts = (): initSdkOpts => ({
  auth: false,
  protocol: environ.has("ENVIRONMENT") ? environ.get("PROTOCOL", "https") : null,
  webapi: environ.has("ENVIRONMENT") ? environ.dep("webapi") : null,
  requestTimeout: environ.has("ENVIRONMENT") ? environ.getInt("REQUEST_TIMEOUT", 0) : null,
  debug: environ.has("ENVIRONMENT") ? environ.getBoolean("DEBUG", false) : null,
});

export interface initSdkOpts {
  protocol: string | null;
  webapi: string | null;
  requestTimeout: number | undefined | null;
  auth: boolean | null;
  debug: boolean | null;
}

export const sdkReject = () => Promise.reject("Invalid Sdk Init Opts");

const init = (opts: initSdkOpts) => {
  const options: ConnectionConfig = {
    baseURL: `${opts.protocol}://${opts.webapi}/graphql`,
    timeout: opts.requestTimeout!,
  };

  if (opts.debug) {
    options.headers = { "fastly-debug": 1 };
  }

  const client = opts.auth ? new AuthenticatedConnection(options) : new Connection(options);
  const requestFactory = connectionRequestFactory(client);
  return sdk(requestFactory);
};

export const getSdk = (opts: initSdkOpts) => {
  if (
    opts &&
    opts.hasOwnProperty("protocol") &&
    opts.hasOwnProperty("webapi") &&
    opts.hasOwnProperty("requestTimeout") &&
    opts.hasOwnProperty("debug")
  ) {
    return init(opts);
  }
};
