import { useEffect } from "react";
import { toJS } from "mobx";
import { inject } from "mobx-react";

import type { PageBlockInterface } from "../../../../core/page-blocks/Block.component";
import CoastCalendarContent from "./CoastCalendarContent";
import { CoastContent } from "./CoastContent.component";
import { ILog } from "@ihr-radioedit/inferno-core";

import { Store } from "@inferno/renderer-shared-core";
import { isCalendarPublishRecord, isFeedResult, isItemBlock, isPublishRecord } from "@inferno/renderer-shared-core";

const log = ILog.logger("CoastItem");

interface CoastItemProps extends PageBlockInterface {
  store?: Store;
}

export const CoastItem = inject("store")(({ store, block }: CoastItemProps) => {
  if (!isItemBlock(block)) {
    log.error("No item on block", block);
    return null;
  }

  useEffect(() => {
    if (store && block) {
      store.storeBlock(block);
    }
  });

  if (isFeedResult(block.item?.result)) {
    const content = block.item?.result?.record;

    if (isCalendarPublishRecord(content)) {
      return <CoastCalendarContent content={content} block={block} />;
    }

    if (isPublishRecord(content)) {
      return <CoastContent content={content} block={block} />;
    }

    if (content) {
      // If we don't know what it is, and it's not falsy, log.
      log.error("Unknown item block", toJS(block.item));
    }
  }

  return null;
});

export default CoastItem;
