import * as io from "io-ts";
import { MaybeNumberResolver, MaybeStringArrayResolver, MaybeStringResolver } from "./util.types";

export const BlockScheduleResolver = io.partial({
  enabled: io.boolean,
  begin: io.number,
  end: io.number,
});
export type BlockScheduleInterface = io.TypeOf<typeof BlockScheduleResolver>;

export const BlockBaseResolverLegacy = io.intersection([
  io.type({
    type: io.string,
  }),
  io.partial({
    schedule: io.union([BlockScheduleResolver, io.null]),
    embed_type: io.string,
    provider: io.string,
    value: io.unknown,
  }),
  io.partial({ isPodcastEmbed: io.union([io.boolean, io.undefined]) }),
]);
export type BlockBaseLegacy = io.TypeOf<typeof BlockBaseResolverLegacy>;

const BlockBaseResolver = io.intersection([
  io.type({
    type: io.string,
  }),
  io.partial({
    schedule: io.union([BlockScheduleResolver, io.null]),
    value: io.unknown,
  }),
]);
export type BlockBase = io.TypeOf<typeof BlockBaseResolver>;

export const FlexFieldValueResolver = io.array(BlockBaseResolver);
export const MaybeFlexFieldValueResolver = io.union([FlexFieldValueResolver, io.null]);

export const FlexFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("flex"),
    value: MaybeFlexFieldValueResolver,
  }),
]);
export type FlexField = io.TypeOf<typeof FlexFieldResolver>;

export const MaybeFlexFieldResolver = io.union([FlexFieldResolver, io.null]);
export type MaybeFlexField = io.TypeOf<typeof MaybeFlexFieldResolver>;

export const AuthorFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("author"),
    value: io.array(io.UnknownRecord),
  }),
]);
export type AuthorField = io.TypeOf<typeof AuthorFieldResolver>;

export const MaybeAuthorFieldResolver = io.union([AuthorFieldResolver, io.null]);
export type MaybeAuthorField = io.TypeOf<typeof MaybeAuthorFieldResolver>;

export const CheckboxFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("checkbox"),
    value: io.boolean,
  }),
]);
export type CheckboxField = io.TypeOf<typeof CheckboxFieldResolver>;

export const MaybeCheckboxFieldResolver = io.union([CheckboxFieldResolver, io.null]);
export type MaybeCheckboxField = io.TypeOf<typeof MaybeCheckboxFieldResolver>;

export const SlugFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("slug"),
    value: MaybeStringResolver,
  }),
]);
export type SlugField = io.TypeOf<typeof SlugFieldResolver>;

export const MaybeSlugFieldResolver = io.union([SlugFieldResolver, io.null]);
export type MaybeSlugField = io.TypeOf<typeof MaybeSlugFieldResolver>;

export const PlainFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("plain"),
    value: MaybeStringResolver,
  }),
]);
export type PlainField = io.TypeOf<typeof PlainFieldResolver>;

export const MaybePlainFieldResolver = io.union([PlainFieldResolver, io.null]);
export type MaybePlainField = io.TypeOf<typeof MaybePlainFieldResolver>;

export const TitleFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("title"),
    value: MaybeStringResolver,
  }),
]);
export type TitleField = io.TypeOf<typeof TitleFieldResolver>;

export const MaybeTitleFieldResolver = io.union([TitleFieldResolver, io.null]);
export type MaybeTitleField = io.TypeOf<typeof MaybeTitleFieldResolver>;

export const HtmlFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("html"),
    value: MaybeStringResolver,
  }),
]);
export type HtmlField = io.TypeOf<typeof HtmlFieldResolver>;

export const MaybeHtmlFieldResolver = io.union([HtmlFieldResolver, io.null]);
export type MaybeHtmlField = io.TypeOf<typeof MaybeHtmlFieldResolver>;

export const TagFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("tag"),
    value: MaybeStringArrayResolver,
  }),
]);
export type TagField = io.TypeOf<typeof TagFieldResolver>;

export const MaybeTagFieldResolver = io.union([TagFieldResolver, io.null]);
export type MaybeTagField = io.TypeOf<typeof MaybeTagFieldResolver>;

// this does looks like AssetResolver in PubSub, but it's not!
export const AssetFieldValueResolver = io.intersection([
  io.type({
    new_tab: io.boolean,
    url: io.string,
    public_uri: io.string,
    attributes: io.partial({
      mimetype: io.string,
      type: io.string,
    }),
    title: io.string,
    caption: io.string,
    alt_text: io.string,
  }),
  io.partial({
    bucket: io.string,
    id: io.string,
  }),
]);

export const AssetFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("asset"),
    value: io.union([AssetFieldValueResolver, io.null]),
  }),
]);
export type AssetField = io.TypeOf<typeof AssetFieldResolver>;

export const MaybeAssetFieldResolver = io.union([AssetFieldResolver, io.null]);
export type MaybeAssetField = io.TypeOf<typeof MaybeAssetFieldResolver>;

export const EmbedAttributesResolver = io.type({
  author_name: MaybeStringResolver,
  author_url: MaybeStringResolver,
  height: io.union([MaybeNumberResolver, MaybeStringResolver]),
  width: io.union([MaybeNumberResolver, MaybeStringResolver]),
  thumbnail_height: MaybeNumberResolver,
  thumbnail_url: MaybeStringResolver,
  thumbnail_width: MaybeNumberResolver,
  video_id: MaybeStringResolver,
  title: MaybeStringResolver,
});

export const EmbedResolver = io.type({
  alt_text: MaybeStringResolver,
  attributes: io.union([EmbedAttributesResolver, io.null]),
  embed_type: MaybeStringResolver,
  html: MaybeStringResolver,
  provider: MaybeStringResolver,
  rendering_hint: MaybeStringResolver,
  url: MaybeStringResolver,
});

export const EmbedFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("embed"),
    value: io.union([EmbedResolver, io.null]),
  }),
]);
export type EmbedField = io.TypeOf<typeof EmbedFieldResolver>;

export const MaybeEmbedFieldResolver = io.union([EmbedFieldResolver, io.null]);
export type MaybeEmbedField = io.TypeOf<typeof MaybeEmbedFieldResolver>;

export const CatalogResolver = io.type({
  country: MaybeStringResolver,
  description: MaybeStringResolver,
  device_link: MaybeStringResolver,
  id: MaybeStringResolver,
  related_id: MaybeStringResolver,
  img: MaybeStringResolver,
  kind: MaybeStringResolver,
  name: MaybeStringResolver,
  web_link: MaybeStringResolver,
});
export const MaybeCatalogResolver = io.union([CatalogResolver, io.null]);

export const CatalogFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("catalog"),
    value: io.union([CatalogResolver, io.null]),
  }),
]);
export type CatalogField = io.TypeOf<typeof CatalogFieldResolver>;

export const MaybeCatalogFieldResolver = io.union([CatalogFieldResolver, io.null]);
export type MaybeCatalogField = io.TypeOf<typeof MaybeCatalogFieldResolver>;

export const LinkFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("link"),
    value: MaybeStringArrayResolver,
  }),
]);
export type LinkField = io.TypeOf<typeof LinkFieldResolver>;

export const MaybeLinkFieldResolver = io.union([LinkFieldResolver, io.null]);
export type MaybeLinkField = io.TypeOf<typeof MaybeLinkFieldResolver>;

export const DateFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("date"),
    value: MaybeNumberResolver,
  }),
]);
export type DateField = io.TypeOf<typeof DateFieldResolver>;

export const MaybeDateFieldResolver = io.union([DateFieldResolver, io.null]);
export type MaybeDateField = io.TypeOf<typeof MaybeDateFieldResolver>;

export const BumperMusicTrackResolver = io.type({
  artist: io.string,
  title: io.string,
  url: io.string,
});

export const BumperMusicFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("bumpermusic"),
    value: io.union([io.array(BumperMusicTrackResolver), io.null]),
  }),
]);
export type BumperMusicField = io.TypeOf<typeof BumperMusicFieldResolver>;

export const MaybeBumperMusicFieldResolver = io.union([BumperMusicFieldResolver, io.null]);
export type MaybeBumperMusicField = io.TypeOf<typeof MaybeBumperMusicFieldResolver>;

export const MultiDateValueResolver = io.type({
  begin: MaybeNumberResolver,
  enabled: io.boolean,
  end: MaybeNumberResolver,
});

export type MultiDateValue = io.TypeOf<typeof MultiDateFieldResolver>;

export const MultiDateFieldResolver = io.intersection([
  BlockBaseResolver,
  io.type({
    type: io.literal("multidate"),
    value: io.array(MultiDateValueResolver),
  }),
]);
export type MultiDateField = io.TypeOf<typeof MultiDateFieldResolver>;

export const MaybeMultiDateFieldResolver = io.union([MultiDateFieldResolver, io.null]);
export type MaybeMultiDate = io.TypeOf<typeof MaybeMultiDateFieldResolver>;
