import { type Store, isWindowDefined } from "@inferno/renderer-shared-core";
import { useEffect } from "react";

import { SITE_THEME } from "@inferno/renderer-shared-core";
import * as React from "react";

export const getTargetScrollContainer = () => {
  const coastContainer = document.querySelector(".template-coast");
  return coastContainer ? window : document.querySelector(".component-routes");
};

export const getThemeHeaderContainer = () => {
  return {
    [SITE_THEME.DEFAULT]: [".component-site-header"],
    [SITE_THEME.PREMIERE]: [".component-premiere-site-header"],
    [SITE_THEME.COAST]: [".component-site-header"],
    [SITE_THEME.PREVIEW]: [],
  } as { [key: string]: string[] };
};

export const scrollToAnchor = (element: HTMLElement | null | undefined, store: Store) => {
  if (element && store) {
    const top = element.offsetTop;
    const left = element.offsetLeft;
    const theme = store.site.sections.design?.theme;
    const themeHeaders = getThemeHeaderContainer()[theme ?? SITE_THEME.DEFAULT];
    let headerHeight = 0;

    themeHeaders.forEach(header => {
      const headerElement = document.querySelector(header);
      if (headerElement) {
        headerHeight += headerElement.clientHeight;
      }
    });

    getTargetScrollContainer()?.scrollTo(left, top - headerHeight);
  }
};

export const bindHashAnchorLinks = (contentRef: React.RefObject<HTMLDivElement>, store: Store) => {
  contentRef.current?.querySelectorAll("a[href^='#']").forEach(element => {
    (element as HTMLAnchorElement).onclick = event => {
      event.preventDefault();
      const id = element.getAttribute("href")?.split("#")[1];
      if (id) {
        scrollToAnchor(document.getElementById(id), store);
        history.pushState(null, "", `#${id}`);
      }
      return false;
    };
  });
};

export const useHashAnchorLinks = (htmlRef: React.RefObject<HTMLDivElement>, store: Store | undefined) => {
  useEffect(() => {
    if (!isWindowDefined() || !htmlRef.current || !store) {
      return;
    }

    bindHashAnchorLinks(htmlRef, store);

    const handleLoad = () => {
      const id = window.location.hash.substring(1);
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          scrollToAnchor(element, store);
        } else {
          const observer = new MutationObserver(() => {
            const el = document.getElementById(id);
            if (el) {
              scrollToAnchor(el, store);
              observer.disconnect();
            }
          });
          observer.observe(htmlRef.current as Element, { childList: true, subtree: true });
        }
      }
    };

    const handleHashChange = () => {
      const id = window.location.hash.substring(1);
      if (id) {
        scrollToAnchor(document.getElementById(id), store);
      }
    };

    handleLoad();

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [htmlRef, store]);
};
