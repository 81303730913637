import * as React from "react";
import "./Tabs.style.scss";
import { Tab, TabProps } from "./Tab.component";

interface TabsProps {
  selectedTab?: string;
  children: React.ReactNode;
  onSelect?: (context: { selectedTab: string }) => void;
}

interface TabState {
  selectedTab?: string;
}

interface TabElement {
  props: TabProps;
}

export function isTabElement(element: any): element is TabElement {
  return element && element.hasOwnProperty("props") && element.props.hasOwnProperty("label");
}

export class Tabs extends React.Component<TabsProps, TabState> {
  mounted = false;

  constructor(props: TabsProps) {
    super(props);

    const [firstChild] = React.Children.toArray(this.props.children);

    let selectedTab = this.props.selectedTab;
    if (!selectedTab && isTabElement(firstChild)) {
      selectedTab = firstChild.props.label;
    }

    this.state = {
      selectedTab,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  selectTab = (selectedTab: string) => {
    if (this.mounted) {
      this.setState({ selectedTab });
      if (this.props.onSelect) {
        this.props.onSelect({ selectedTab });
      }
    }
  };

  render() {
    if (!this.props.children) {
      return null;
    }
    return (
      <div className={`component-tabs`}>
        <div className="tab-controls">
          <ul className="tab-selector">
            {React.Children.map(this.props.children, child => {
              if (child && isTabElement(child)) {
                return (
                  <Tab
                    active={child.props.label === this.state.selectedTab}
                    key={child.props.label}
                    label={child.props.label}
                    onClick={(label: string) => {
                      this.selectTab(label);
                      child.props.onClick?.(label);
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>

        <div className="tab-content">
          {React.Children.map(this.props.children, child => {
            if (isTabElement(child)) {
              if (child.props.label !== this.state.selectedTab) {
                return null;
              }
              return child.props.children;
            }
            return null;
          })}
        </div>
      </div>
    );
  }
}
