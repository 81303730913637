import * as React from "react";
import { Translation } from "react-i18next";
import { SearchIcon } from "../components/icons/SearchIcon.component";
import { resizeWatcher } from "@inferno/renderer-shared-core";
import "./SearchBox.style.scss";
import { inject, observer } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";

interface SearchProps {
  scrollIntoView?: boolean;
  location: string;
  store?: Store;
}

export interface SearchState {
  keyword: string;
  inputFocus: boolean;
  heightChanged: boolean;
}
@inject("store")
@observer
export class SearchBox extends React.Component<SearchProps, SearchState> {
  private elementRef: React.RefObject<HTMLInputElement>;
  static defaultProps = { scrollIntoView: false };
  private elementId = "search-input-field";
  mounted = false;

  constructor(props: SearchProps) {
    super(props);

    this.elementRef = React.createRef();
    if (props.location) {
      this.elementId = `search-input-field-${props.location}`;
    }

    this.state = {
      keyword: "",
      inputFocus: false,
      heightChanged: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.scrollIntoView) {
      resizeWatcher.onHeightChange.subscribe(this.handleHeightChange);
    }
  }

  componentDidUpdate() {
    if (this.state.heightChanged && this.state.inputFocus && this.props.scrollIntoView && this.elementRef.current) {
      this.elementRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.props.scrollIntoView) {
      resizeWatcher.onHeightChange.unsubscribe(this.handleHeightChange);
    }
  }

  handleHeightChange = () => {
    if (this.props.scrollIntoView && this.mounted) {
      this.setState({
        heightChanged: true,
      });
    }
  };

  focusHandler = () => {
    if (this.props.scrollIntoView && typeof window === "object" && this.mounted) {
      this.setState({
        inputFocus: true,
      });
    }
    const page = this.props.store?.page.currentPage;
    if (page) {
      const pageName = page ? `${this.props.store?.microsite ? "microsite_" : ""}${page.name}` : "";
      let referrer = "";
      if (typeof window !== "undefined") {
        referrer = window.location.href;
      }

      const sectionName = this.props.location;
      this.props.store?.onAnalyticsAction.dispatch({
        sectionName,
        pageName,
        context: "search_input_field",
        action: "click",
        url: "",
        referrer,
      });
    }
  };

  handleClick = () => {
    const page = this.props.store?.page.currentPage;
    if (page) {
      const pageName = page ? `${this.props.store?.microsite ? "microsite_" : ""}${page.name}` : "";
      let referrer = "";
      if (typeof window !== "undefined") {
        referrer = window.location.href;
      }

      const sectionName = this.props.location;
      this.props.store?.onAnalyticsAction.dispatch({
        sectionName,
        pageName,
        context: "search_icon",
        action: "click",
        url: "",
        referrer,
      });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="search-form" name="search-form" role="search">
        <section className="search-container">
          <Translation>
            {t => (
              <input
                type="search"
                name="keyword"
                id={this.elementId}
                aria-label={t("search text")}
                placeholder={t("search")}
                value={this.state.keyword}
                onChange={this.handleChange}
                maxLength={250}
                ref={this.elementRef}
                onFocus={() => this.focusHandler()}
              />
            )}
          </Translation>
          <button className="search-button search-submit" aria-label="Search" onClick={this.handleClick} type="submit">
            <SearchIcon />
          </button>
        </section>
      </form>
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.mounted) {
      this.setState({
        keyword: event.target.value,
      });
    }
  };

  getAction(keyword: string) {
    if (typeof window !== "undefined") {
      return `${window.location.protocol}//${window.location.host}/search/${encodeURIComponent(keyword)}/`;
    } else {
      return "";
    }
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { keyword } = this.state;

    event.preventDefault();
    if (this.validateSearch(keyword)) {
      const actionUrl = this.getAction(keyword);
      if (typeof window !== "undefined") {
        window.location.assign(actionUrl);
      }
    }
  };

  validateSearch(keyword: string): boolean {
    return !!keyword && !!keyword.trim();
  }
}
