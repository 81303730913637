// based on https://github.com/30-seconds/30-seconds-of-code#-string-1
export class StringUtils {
  static isBlank(str: string | null | undefined): boolean {
    return !(str || "").trim();
  }

  static isNotBlank(str: string | null | undefined): boolean {
    return !!(str || "").trim();
  }

  /**
   * Capitalizes the first letter of a string. Omit the lowerRest parameter
   * to keep the rest of the string intact, or set it to true to convert to lowercase.
   *
   * @param str the string to capitalize
   * @param lowerRest if set to true the the rest of the string will be lower cased.
   */
  static capitalize(str: string, lowerRest = false): string {
    const first = str[0] || "";
    const rest = str.substr(1);
    return first.toUpperCase() + (lowerRest ? rest.toLowerCase() : rest);
  }

  /**
   * Capitalizes the first letter of every word in a string.
   *
   * @param str the string to capitalize
   */
  static capitalizeEveryWord(str: string): string {
    return str.replace(/\b[a-z]/g, char => char.toUpperCase());
  }

  /**
   * Decapitalizes the first letter of a string. Omit the upperRest parameter
   * to keep the rest of the string intact, or set it to true to convert to uppercase.
   *
   * @param str the string decapitalize
   * @param upperRest if set to true the reset of the string will be upper cased.
   */
  static decapitalize(str: string, upperRest = false): string {
    const first = str[0] || "";
    const rest = str.substr(1);
    return first.toLowerCase() + (upperRest ? rest.toUpperCase() : rest);
  }

  /**
   * Truncates a string up to a specified length.
   * Determine if the string's length is greater than num. Return the string
   * truncated to the desired length, with '...' appended to the end or the original string.
   *
   * @param str the string to truncate
   * @param num the max number of characters to stay in the string.
   */
  static truncateString(str: string, num: number): string {
    return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + "..." : str;
  }

  static takeUntil(str: string, stop: string): string {
    const ix = str.indexOf(stop);
    return ix >= 0 ? str.substring(0, ix) : str;
  }

  /**
   * Converts the given string to kebab-case. For example:
   *  - 'DatePickerWidget' -> 'date-picker-widget'
   *  - 'Something With Spaces' -. 'something-with-spaces'.
   *
   * @param str the string to convert.
   */
  static toKebabCase(str: string): string {
    return str
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/\s+/g, "-")
      .toLowerCase();
  }
}
