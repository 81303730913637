import i18n from "i18next";

import { slugify } from "@ihr-radioedit/inferno-core";

import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../util";

export const weatherTags: Tagger = (state: Tags, context: TagContext) => {
  const { site } = context;
  const weatherMarket = site.sections.general?.weather_market ? `${site.sections.general?.weather_market} ` : "";

  const keywords = [i18n.t("weather").toLowerCase(), i18n.t("forecast_keyword")];
  if (weatherMarket) {
    keywords.push(...weatherMarket.trim().toLowerCase().split(", "));
  }

  const parselyTitle = i18n.t("weather_parsely_title", { weatherMarket });

  let { metadata } = state;
  metadata = setGeneralMetaTags(metadata, {
    titles: {
      default: `${parselyTitle} | ${site.sections.general?.name}`,
      parsely: parselyTitle,
    },
    description: i18n.t("weather_description", { weatherMarket: weatherMarket.trim() }) || "",
    keywords,
    types: { parsely: "sectionpage" },
  });

  metadata.set(...generateMetaTag("name", "parsely-section", "weather"));

  return {
    ...state,
    ads: {
      ...state.ads,
      keywords: keywords.map(item => slugify(item)),
    } as AdTags,
    analytics: {
      ...state.analytics,
      view: {
        ...state.analytics?.view,
        tags: keywords.map(item => slugify(item)),
      },
    } as AnalyticsTags,
    metadata,
  };
};
