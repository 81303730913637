export function formatRecursive(value: any, ...args: Array<string | object>): unknown {
  if (Array.isArray(value)) {
    return value.map(v => formatRecursive(v, ...args));
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((obj: { [key: string]: any }, key) => {
      obj[key] = formatRecursive(value[key], ...args);
      return obj;
    }, {});
  } else if (typeof value === "string") {
    return format(value, ...args);
  }
  return value;
}

// Based on https://github.com/davidchambers/string-format
export const format = (template: string, ...args: Array<string | object>) =>
  template.replace(/[{]([A-Za-z\._]+)[}]/g, (match, key) => {
    //  1.  Split the key into a lookup path.
    //  2.  If the first path component is not an index, prepend '0'.
    //  3.  Reduce the lookup path to a single result. If the lookup
    //      succeeds the result is a singleton array containing the
    //      value at the lookup path; otherwise the result is [].
    //  4.  Unwrap the result by reducing with '' as the default value.
    const path: string[] = key.split(".");
    const normalizedPath = /^\d+$/.test(path[0]) ? path : ["0"].concat(path);
    const value = normalizedPath.reduce(
      (maybe, k) => {
        return maybe.reduce((_: unknown, x) => {
          return x && k in Object(x) ? [(x as any)[k]] : [];
        }, []);
      },
      [args],
    );

    return value.reduce((_: unknown, x) => {
      return x;
    }, "") as string;
  });
