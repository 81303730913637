import { differenceInDays, format } from "date-fns";

export const expiryHeader = (ttl = 900) => {
  const expiryTime = new Date();
  expiryTime.setSeconds(expiryTime.getSeconds() + ttl);
  return format(expiryTime, "YYYY-MM-dd hh:mm:ss", { useAdditionalWeekYearTokens: true });
};

export const setCacheTtl = (published: number) => {
  const diffDays = differenceInDays(new Date(), published);
  const baseTtl = 3701;

  if (diffDays === 1) {
    // less than 1 day = 1 hour
    return baseTtl;
  } else if (diffDays > 1 && diffDays <= 7) {
    // 1 day to 7 days (1 week) = 1 hour
    return baseTtl;
  } else if (diffDays > 7 && diffDays <= 30) {
    // 7 - 30 days = 24 hours
    return baseTtl * 24;
  } else if (diffDays > 30 && diffDays <= 365) {
    // 30 - 365 days = 24 hours
    return baseTtl * 24 * 7;
  } else if (diffDays > 365) {
    // 7 - 30 days = 28 days
    return baseTtl * 24 * 28;
  }
  return baseTtl;
};
