import i18n from "i18next";
import type { TagRef } from "@ihr-radioedit/inferno-webapi";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { slugify, matchPrefix, removePrefix } from "@ihr-radioedit/inferno-core";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import {
  generateMetaTag,
  GOOGLE_SITE_VERIFICATION,
  META_IMAGE_HEIGHT,
  META_IMAGE_WIDTH,
  processMetaImage,
  setGeneralMetaTags,
  twitterName,
} from "../util";
import { getSiteTheme } from "../../client/utils";

export const siteTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, request, env } = context;
  if (site) {
    const surrogateKeys = new Set(state.surrogateKeys);
    if (site.surrogateKeys) {
      site.surrogateKeys.forEach(key => surrogateKeys.add(key));
      surrogateKeys.add(`domain/${site.getPrimaryDomain()}`);
    }

    const format = (site.index.facets.filter(matchPrefix(/^formats\//)).map(removePrefix(/^formats\//)) || []).join(
      ", ",
    );
    const keywords = site.sections?.general?.keywords?.map((i: TagRef) => removePrefix(/^\w+\//)(i.id)) || [];

    let { metadata } = state;
    const { sections } = site;
    if (sections && metadata) {
      let image = "";
      if (sections.design?.station_logo) {
        image = `${formatImage(
          sections.design?.station_logo?.id,
          env.IMAGE_HOST,
        )}?ops=gravity(%22center%22),contain(${META_IMAGE_WIDTH},${META_IMAGE_HEIGHT}),quality(65)`;

        const theme = getSiteTheme(sections);
        if (theme === "night") {
          image = `${image},new(),flood(%22%23444444%22),swap(),merge(%22over%22)`;
        }
      }

      if (site.sections.design?.accent_color) {
        metadata.set(...generateMetaTag("name", "theme-color", site.sections.design?.accent_color));
      }

      const faviconOps = "ops=gravity(%22center%22),contain(32,32),quality(65)";
      let favicon = "";
      let appleTouchIcon = "";

      if (site.sections.design?.logo_favicon) {
        const faviconLogo = formatImage(site.sections.design?.logo_favicon.id, env.IMAGE_HOST);
        favicon = `${faviconLogo}?${faviconOps}`;
        appleTouchIcon = `${faviconLogo}`;
      } else if (site.sections.design?.station_logo) {
        const stationLogo = formatImage(site.sections.design?.station_logo.id, env.IMAGE_HOST);
        favicon = `${stationLogo}?${faviconOps}`;
        appleTouchIcon = `${stationLogo}`;
      }

      if (favicon) {
        metadata.set("shortcut icon", { value: favicon, type: "link", rel: "shortcut icon" });
        metadata.set("appletouch icon", {
          value: `${appleTouchIcon}?ops=new(),flood(%22white%22),swap(),merge(%22over%22),gravity(%22center%22),contain(76,76),quality(80),format(%22png%22)}`,
          type: "link",
          rel: "apple-touch-icon",
          size: "76x76",
        });
        metadata.set("appletouch icon", {
          value: `${appleTouchIcon}?ops=new(),flood(%22white%22),swap(),merge(%22over%22),gravity(%22center%22),contain(120,120),quality(80),format(%22png%22)}`,
          type: "link",
          rel: "apple-touch-icon",
          size: "120x120",
        });
        metadata.set("appletouch icon", {
          value: `${appleTouchIcon}?ops=new(),flood(%22white%22),swap(),merge(%22over%22),gravity(%22center%22),contain(152,152),quality(80),format(%22png%22)}`,
          type: "link",
          rel: "apple-touch-icon",
          size: "152x152",
        });
        metadata.set("appletouch icon", {
          value: `${appleTouchIcon}?ops=new(),flood(%22white%22),swap(),merge(%22over%22),gravity(%22center%22),contain(180,180),quality(80),format(%22png%22)`,
          type: "link",
          rel: "apple-touch-icon",
          size: "180x180",
        });
        metadata.set("appletouch icon", {
          value: `${appleTouchIcon}?ops=new(),flood(%22white%22),swap(),merge(%22over%22),gravity(%22center%22),contain(167,167),quality(80),format(%22png%22)`,
          type: "link",
          rel: "apple-touch-icon",
          size: "167x167",
        });
      }

      const description = sections.general?.description
        ? sections.general.description
        : i18n.t("site_description", {
            station: sections.general?.name,
            positioner: sections.general?.positioner,
          });

      metadata = setGeneralMetaTags(metadata, {
        titles: { default: `${sections.general?.name} - ${sections.general?.positioner}` },
        image: { url: processMetaImage(image, context) },
        url: site.getShareUrl(request),
        description,
        keywords,
        types: { parsely: "frontpage" },
        twitter: {
          handle: sections.social?.twitter_name ? twitterName(sections.social.twitter_name) : "",
        },
      });

      metadata.set("canonical", { value: site.getShareUrl(request), type: "link", rel: "canonical" });
      metadata.set(...generateMetaTag("property", "twitter:domain", site.getPrimaryDomain()));

      if (sections.general?.name) {
        metadata.set(...generateMetaTag("property", "og:site_name", sections.general.name));
        metadata.set(...generateMetaTag("name", "application-name", sections.general.name));
      }

      if (sections.social?.facebook_app_id) {
        metadata.set(...generateMetaTag("property", "fb:app_id", sections.social.facebook_app_id));
      }

      if (sections.social?.facebook_fan_page_id) {
        metadata.set(...generateMetaTag("property", "fb:pages", sections.social.facebook_fan_page_id));
      }

      metadata.set(
        ...generateMetaTag(
          "name",
          "google-site-verification",
          sections.partners?.google_webmaster_verification || GOOGLE_SITE_VERIFICATION,
        ),
      );
    }

    return {
      ...state,
      surrogateKeys,
      ads: {
        ...state.ads,
        keywords: keywords.map(item => slugify(item)),
        genre:
          site.index.facets
            .filter(matchPrefix(/^genres\//))
            .map(item => removePrefix(/^genres\//)(item).toLowerCase()) || [],
        format,
        type: "",
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          stationCallLetter: site.index.slug.toUpperCase(),
          stationFormat: format,
          tags: keywords.map(item => slugify(item)),
        },
      } as AnalyticsTags,
      metadata,
    };
  }

  return state;
};
