import { slugify } from "@ihr-radioedit/inferno-core";

import { Tagger, Tags, TagContext, AdTags, AnalyticsTags } from "../../lib/tagging-type";
import { stripTags } from "../../utilities/strip-tags";
import { setGeneralMetaTags } from "../util";

export const aboutTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, microsite } = context;

  if (microsite && microsite.config) {
    const parselyTitle = `About ${microsite.sections.general?.name}`;
    const title = `${parselyTitle} | ${site.sections.general?.name}`;
    let description = "";

    if (microsite.sections.general?.description) {
      description = stripTags(microsite.sections.general?.description.trim());
    } else {
      description = `Get everything you need to know about ${microsite.sections.general?.name} on ${site.sections.general?.name}`;
    }

    const keywords = ["about", "biography", "bio", "contact", "connect"];

    let { metadata } = state;
    metadata = setGeneralMetaTags(metadata, {
      titles: {
        default: title,
        parsely: "",
      },
      keywords,
      description,
      types: {
        openGraph: "profile",
        parsely: "post",
      },
    });

    return {
      ...state,
      ads: {
        ...state.ads,
        keywords: keywords.map(item => slugify(item)),
        microsite: microsite?.index?.slug || "",
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          tags: keywords.map(item => slugify(item)),
        },
      } as AnalyticsTags,
      metadata,
    };
  }

  return state;
};
