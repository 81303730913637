import * as io from "io-ts";
import {
  BlockBaseResolverLegacy,
  CheckboxFieldResolver,
  FlexFieldResolver,
  HtmlFieldResolver,
  MaybeAssetFieldResolver,
  MaybeCatalogResolver,
  MaybeCheckboxFieldResolver,
  MaybeHtmlFieldResolver,
  MaybeMultiDateFieldResolver,
  MaybePlainFieldResolver,
  MaybeTitleFieldResolver,
  PlainFieldResolver,
  TagFieldResolver,
  TitleFieldResolver,
} from "./ContentBlocks.types";
import { MaybeNumberResolver, MaybeStringArrayResolver, MaybeStringResolver } from "./util.types";

export const DistributionResolver = io.partial({
  tags: io.array(io.string),
});

export type DistributionInterface = io.TypeOf<typeof DistributionResolver>;

export const ImageResolverLegacy = io.type({
  bucket: io.string,
  id: io.string,
});

export type ImageInterfaceLegacy = io.TypeOf<typeof ImageResolverLegacy>;

export const AppleNewsResolverLegacy = io.type({
  ref_id: io.string,
  revision_id: io.string,
});

export type AppleNewsInterface = io.TypeOf<typeof AppleNewsResolverLegacy>;

export const PrimaryTargetResolverLegacy = io.partial({
  categories: io.array(io.string),
  distribution: io.array(io.string),
});

export type PrimaryTargetInterfaceLegacy = io.TypeOf<typeof PrimaryTargetResolverLegacy>;

const TargetResolverLegacy = io.type({
  tags: MaybeStringArrayResolver,
});

export const PayloadResolverLegacy = io.intersection([
  io.type({
    blocks: io.array(BlockBaseResolverLegacy),
  }),
  io.partial({
    amp_enabled: io.boolean,
    apple_news: io.union([AppleNewsResolverLegacy, io.null]),
    author: MaybeStringResolver,
    seo_title: MaybeStringResolver,
    social_title: MaybeStringResolver,
    external_url: MaybeStringResolver,
    simplereach_enabled: io.boolean,
    permalink: MaybeStringResolver,
    feed_permalink: MaybeStringResolver,
    feed_vendor: MaybeStringResolver,
    feed_partner_content: io.boolean,
    feed_content_id: MaybeStringResolver,
    feed_type: MaybeStringResolver,
    show_updated_timestamp: io.boolean,
    primary_image: io.union([ImageResolverLegacy, io.null]),
    primary_target: io.union([PrimaryTargetResolverLegacy, io.null]),
    summary: MaybeStringResolver,
    targets: io.array(PrimaryTargetResolverLegacy),
    publish_end_date: MaybeNumberResolver,
    exclusion: io.union([TargetResolverLegacy, io.null]),
    slug: MaybeStringResolver,
    title: MaybeStringResolver,
    publish_date: MaybeNumberResolver,
    include_recommendations: io.boolean,
    fb_allow_comments: io.boolean,
    publish_origin: MaybeStringResolver,
    keywords: io.array(io.string),
    canonical_url: MaybeStringResolver,
    cuser: MaybeStringResolver,
    is_sponsored: io.boolean,
    enable_featured_widget: io.boolean,
    featured_widget: MaybeCatalogResolver,
  }),
]);
export type PayloadInterfaceLegacy = io.TypeOf<typeof PayloadResolverLegacy>;

export const PayloadIngestionResolver = io.partial({
  external_url: MaybeStringResolver,
  feed_permalink: MaybeStringResolver,
  feed_vendor: MaybeStringResolver,
  feed_content_id: MaybeStringResolver,
});
export type PayloadIngestion = io.TypeOf<typeof PayloadIngestionResolver>;

export const PayloadFieldsBaseResolver = io.intersection([
  io.type({
    blocks: FlexFieldResolver,
  }),
  io.partial({
    amp_enabled: io.boolean,
    primary_image: MaybeAssetFieldResolver,
    seo_title: MaybeTitleFieldResolver,
    social_title: MaybeTitleFieldResolver,
    simplereach_enabled: CheckboxFieldResolver,
    summary: io.union([MaybeHtmlFieldResolver, MaybePlainFieldResolver]),
    include_recommendations: CheckboxFieldResolver,
    fb_allow_comments: CheckboxFieldResolver,
    keywords: TagFieldResolver,
    is_sponsored: CheckboxFieldResolver,
    enable_featured_widget: CheckboxFieldResolver,
    featured_widget: MaybeCatalogResolver,
  }),
]);
export type PayloadFieldsBase = io.TypeOf<typeof PayloadFieldsBaseResolver>;

export const PayloadTargetingTargetResolver = io.intersection([
  io.partial({ readonly: io.boolean, remove: io.boolean, update: io.boolean }),
  io.type({
    id: io.string,

    what: io.array(io.string),
    where: io.array(io.string),
  }),
]);

export const PayloadTargetingResolver = io.type({
  publish_origin: MaybeStringResolver,
  publish_date: MaybeNumberResolver,
  publish_end_date: MaybeNumberResolver,
  targets: io.array(PayloadTargetingTargetResolver),
  exclusion: DistributionResolver,
});
export type PayloadTargeting = io.TypeOf<typeof PayloadTargetingResolver>;

export const PayloadSummaryImageResolver = io.type({
  new_tab: io.boolean,
  url: io.string,
  attributes: io.partial({
    mimetype: io.string,
    type: io.string,
  }),
  title: io.string,
  caption: io.string,
  alt_text: io.string,
});

export const PayloadSummaryResolver = io.type({
  author: MaybeStringResolver,
  description: MaybeStringResolver,
  image: PayloadSummaryImageResolver,
  slugs: MaybeStringArrayResolver,
  title: MaybeStringResolver,
});
export type PayloadSummary = io.TypeOf<typeof PayloadSummaryResolver>;

export const PayloadResolver = io.intersection([
  io.partial({
    permalink: MaybeStringResolver,
  }),
  io.type({
    fields: PayloadFieldsBaseResolver,
    ingestion: PayloadIngestionResolver,
    canonical_url: MaybeStringResolver,
    cuser: MaybeStringResolver,
    targeting: PayloadTargetingResolver,
    summary: PayloadSummaryResolver,
  }),
]);
export type PayloadInterface = io.TypeOf<typeof PayloadResolver>;

export const CalendarPayloadFieldsResolver = io.intersection([
  PayloadFieldsBaseResolver,
  io.partial({
    event_date: MaybeMultiDateFieldResolver,
    venue_name: MaybePlainFieldResolver,
    venue_address: MaybePlainFieldResolver,
    hide_event_start_time: MaybeCheckboxFieldResolver,
  }),
]);
export type CalendarPayloadFields = io.TypeOf<typeof CalendarPayloadFieldsResolver>;

export const CalendarPayloadResolver = io.intersection([
  PayloadResolver,
  io.type({
    fields: CalendarPayloadFieldsResolver,
  }),
]);

export type CalendarPayload = io.TypeOf<typeof CalendarPayloadResolver>;

export const AuthorPagePayloadFieldsResolver = io.intersection([
  io.type({
    name: TitleFieldResolver,
    bio: HtmlFieldResolver,
    link: PlainFieldResolver,
    photo: MaybeAssetFieldResolver,
  }),
  io.partial({
    author_facebook: MaybePlainFieldResolver,
    author_instagram: MaybePlainFieldResolver,
    author_tiktok: MaybePlainFieldResolver,
    author_twitter: MaybePlainFieldResolver,
    youtube: MaybePlainFieldResolver,
  }),
]);

export const AuthorPagePayloadResolver = io.type({
  fields: AuthorPagePayloadFieldsResolver,
});

export type AuthorPagePayload = io.TypeOf<typeof AuthorPagePayloadResolver>;
