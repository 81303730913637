import { Component } from "react";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { FeedDisplayHints } from "../../../../core/services/Sites.utils";
import { getCoastHints } from "@ihr-radioedit/inferno-core";
import { HeadingProps } from "../../../../core/ui/Heading.component";
import { CoastFeed } from "../../components/feed/CoastFeed.component";
import { CoastCarousel } from "../carousel/CoastCarousel.component";
import { isFeedResult } from "@inferno/renderer-shared-core";

interface DatasourceProps {
  content: FeedResultFragment[] | null;
  block: BlockFragment;
  feedId: string;
  displayHints: FeedDisplayHints;
  title: string;
  heading?: HeadingProps;
}

export class Datasource extends Component<DatasourceProps> {
  constructor(props: DatasourceProps) {
    super(props);
  }

  render() {
    const { title, content, block } = this.props;

    // check hints here and throw it to appropriate component
    const hints = getCoastHints(block.tags);
    if (hints?.includes("coast-carousel")) {
      if (content && isFeedResult(content[0])) {
        return <CoastCarousel content={content} />;
      }
      // not a valid feed, so return null
      return null;
    }

    return <CoastFeed block={block} title={title} content={content} />;
  }
}
