import { isChartListBlock } from "../../lib/guards";
import { TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, processMetaImage, setGeneralMetaTags } from "../util";

export const chartlistTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block } = context;

  if (block && isChartListBlock(block.resolved)) {
    const { list, chartTitle } = block.resolved;
    const title = (chartTitle ? `${chartTitle} - ` : "") + `${list.title} | ${site.sections.general?.name}`;

    let image = "";
    if (list.image) {
      image = list.image;
    } else if (list.topItems) {
      image = list.topItems;
    } else {
      const song = list.songs.find(item => !!item.image);
      if (song?.image) {
        image = song.image;
      }
    }

    let { metadata } = state;
    metadata = setGeneralMetaTags(metadata, {
      titles: {
        default: title,
      },
      image: { url: processMetaImage(image, context) },
    });

    metadata.set(...generateMetaTag("name", "parsely-section", "charts"));

    return {
      ...state,
      metadata,
    };
  }

  return state;
};
