import { format, utcToZonedTime } from "date-fns-tz";
import enUS from "date-fns/locale/en-US";

export enum DATETIME_DISPLAY_FORMATS {
  DEFAULT = "M/d/yyyy h:mm a",
  INFO = "yyyy-MM-dd'T'HH:mm:ss",
  TWELVE_HOUR_UNPADDED = "h:mm a",
  TWELVE_HOUR_PADDED = "hh:mm a",
  TWENTYFOUR_HOUR_PADDED = "HH:mm",
  SHORT_MONTH_DAY_FULL_YEAR = "MMM dd, yyyy",
  SHORT_MONTH_SHORT_DAY_FULL_YEAR = "MMM d, yyyy",
  SHORT_MONTH = "MMM",
  SHORT_MONTH_DAY_WITH_SUFFIX_FULL_YEAR = "MMM do, yyyy",
  SHORT_DAY_FULL_YEAR = "dd, yyyy",
  SHORT_DAY_UNPADDED = "d",
  DAY_OF_WEEK = "EEEE",
  SHORT_DAY_OF_WEEK = "EEE",
  FULL_MONTH_YEAR = "MMMM yyyy",
  FULL_MONTH_SHORT_DAY = "MMMM dd",
  FULL_MONTH_SHORT_DAY_FULL_YEAR = "MMMM dd, yyyy",
  SHORT_DAY_OF_WEEK_SHORT_MONTH_SHORT_DAY_FULL_YEAR = "EEE, MMM d, yyyy",
  SHORT_DAY_OF_WEEK_SHORT_MONTH_SHORT_DAY_FULL_YEAR_TWELVE_HOUR_TZ_PADDED = "EEE, MMM d, yyyy @ hh:mm a zzz",
  SHORT_DAY_FULL_MONTH_FULL_YEAR = "dd, MM, yyyy",
  SHORT_DAY_OF_WEEK_TWELVE_HOUR_UNPADDED = "EEE h:mm a",
  FULL_MONTH = "MMMM",
  FULL_DAY_FULL_MONTH_FULL_YEAR = "EEEE - MMMM d, yyyy",
}

export interface ZonedTimeFormatArgs {
  date: Date | string | number;
  timezone: string;
  outputFormat?: string;
  locale?: Locale;
}

export const zonedTimeFormat = ({
  date,
  timezone,
  outputFormat = DATETIME_DISPLAY_FORMATS.DEFAULT,
  locale = enUS,
}: ZonedTimeFormatArgs) => {
  if (!date || !timezone) {
    return "";
  }

  return format(utcToZonedTime(date, timezone), outputFormat, { locale, timeZone: timezone });
};
