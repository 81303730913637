import { MetaTags, TagType } from "@inferno/renderer-shared-core";

export function renderMetaTags(metatags: MetaTags, isAmp = false) {
  if (metatags) {
    const tags = Array.from(metatags.values());

    return tags.map((tag: TagType, i) => {
      switch (tag.type) {
        case "meta": {
          const attributes = {
            [tag.attrName]: tag.name,
            content: tag.value,
          };
          return <meta key={i} {...attributes} />;
        }
        case "script": {
          const attributes = {
            [tag.attrName]: tag.attrValue,
          };

          if (isAmp) {
            // This is to solve an escaping issue with the ld+json content that is happening only in AMP pages
            // see: IHRAL-7732
            return <script key={i} {...attributes} dangerouslySetInnerHTML={{ __html: JSON.stringify(tag.value) }} />;
          }

          return (
            <script key={i} {...attributes}>
              {JSON.stringify(tag.value)}
            </script>
          );
        }
        case "title": {
          return <title key={i}>{tag.value}</title>;
        }
        case "link": {
          return <link key={i} rel={tag.rel || ""} type={tag.mime || ""} title={tag.title || ""} href={tag.value} />;
        }
        case "pagemap":
        default: {
          return null;
        }
      }
    });
  }
}
