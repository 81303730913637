import { EmbedLegacyProps } from "@ihr-radioedit/inferno-core";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { ILog } from "@ihr-radioedit/inferno-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";

declare global {
  interface Window {
    FB: { XFBML: { parse?: () => void }; init: (opts: any) => void };
    twttr: { widgets: { load: () => void } };
    instgrm: { Embeds: { process: () => void } };
  }
}

export function activateEmbeds(div: HTMLDivElement) {
  div.querySelectorAll(".component-embed-script").forEach(placeholder => {
    if (!placeholder.classList.contains("activated") && placeholder instanceof HTMLDivElement) {
      const script = document.createElement("script");
      script.setAttribute("src", placeholder.dataset.src || "");
      script.setAttribute("async", "true");
      placeholder.appendChild(script);
      placeholder.classList.add("activated");
    }
  });
}

export const attributeDimensionToInt = (attr: string | number | null | undefined) => {
  if (typeof attr === "number") {
    return attr;
  } else if (typeof attr === "string" && !attr.includes("%")) {
    // if string is only number or is px value, use parseInt (IHRAL-6431)
    if (/^\d+$/i.test(attr) || attr.includes("px")) {
      return parseInt(attr, 10);
    }
  }
  return 0;
};

export const getResponsiveStyles = (width: string, height: string) => {
  const styles: CSSProperties = {};
  const reg = RegExp("%", "i");
  const modifier = 1.1;

  if (width && height) {
    const intWidth = attributeDimensionToInt(width);
    const intHeight = attributeDimensionToInt(height);
    const percentageWidth = reg.test(width);
    const percentageHeight = reg.test(height);
    styles.height = 0;
    if (percentageWidth && !percentageHeight) {
      styles.paddingBottom = `${intHeight * modifier}px`;
    } else if (!percentageWidth && percentageHeight) {
      styles.paddingBottom = `${intHeight * modifier}%`;
    } else {
      const aspect_ratio = (intHeight / intWidth) * 100 * modifier;
      styles.paddingBottom = `${aspect_ratio}%`;
    }
  }
  return styles;
};

export interface GenericEmbedDetails {
  embed_type: string;
  provider: string;
  url: string | undefined;
}

export const getGenericEmbed = (props: EmbedLegacyProps) => {
  const details: GenericEmbedDetails = {
    embed_type: props.embed_type,
    provider: props.provider || "",
    url: props.url,
  };
  if (!props.url) {
    return details;
  }
  try {
    const url = new URL(props.url);
    if (url.hostname.includes("facebook.com")) {
      details.provider = "facebook";
      if (url.pathname.includes("video.php")) {
        details.embed_type = "video";
        const href = url.searchParams.get("href");
        if (href) {
          details.url = href;
        }
      }
    } else if (url.hostname.includes("youtube.com")) {
      details.provider = "youtube";
      details.embed_type = "video";
    }

    return details;
  } catch (err) {
    return details;
  }
};

export const vendorRefreshFns: any = {
  Facebook: () => window.FB && window.FB.XFBML.parse && window.FB.XFBML.parse(),
  Twitter: () => window.twttr && window.twttr.widgets.load(),
  Instagram: () => window.instgrm && window.instgrm.Embeds.process(),
};

export const useElementOnScreen = <T extends HTMLElement>(options: IntersectionObserverInit) => {
  const log = ILog.logger("useElementOnScreen/embed.ts");
  const [isViewable, setIsViewable] = useState<boolean>(false);
  const containerRef = useRef<T>(null);
  const entryRef = useRef<IntersectionObserverEntry | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      log.debug(
        `id: ${entry.target.id ?? "no-id"} // class: ${entry.target.className} // intersecting: ${
          entry.isIntersecting
        }`,
      );

      if (entry.intersectionRatio > 0) {
        setIsViewable(entry.isIntersecting);
        entryRef.current = entry;
        observer.current?.disconnect();
      }
    },
    [log, observer, entryRef],
  );

  useEffect(() => {
    if (isWindowDefined()) {
      observer.current = new IntersectionObserver(e => handleIntersect(e), options);
      if (containerRef.current) {
        observer.current.observe(containerRef.current);
      }
      return () => observer.current?.disconnect();
    }
  }, [containerRef, options, handleIntersect, observer]);

  return [containerRef, isViewable, entryRef] as const;
};
