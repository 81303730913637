import axios from "axios";
import { ILog } from "@ihr-radioedit/inferno-core";
import { ComScoreManage, AnalyticsEvent, CcpaStatus } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("comScore.ts");

declare global {
  interface Window {
    COMSCORE: {
      beacon: (opts: object) => void;
    };
    _comscore: [
      {
        c1: string;
        c2: string;
        cs_ucfr: string;
        options: {
          enableFirstPartyCookie: boolean;
          bypassUserConsentRequirementFor1PCookie: boolean;
        };
      },
    ];
  }
}

const getUserConsent = (status: CcpaStatus) => {
  const enableFirstPartyCookie = true;

  switch (status) {
    case CcpaStatus.OptOut:
      return {
        value: "0",
        options: {
          enableFirstPartyCookie,
          bypassUserConsentRequirementFor1PCookie: false,
        },
      };
    case CcpaStatus.Anonymous:
    case CcpaStatus.OptIn:
      return {
        value: "1",
        options: {
          enableFirstPartyCookie,
          bypassUserConsentRequirementFor1PCookie: true,
        },
      };
    default:
      log.error("Invalid CCPA status", status);
      break;
  }
};

/** Add new members to Base Abstract */
export class ComScoreManager extends ComScoreManage {
  protected _library?: Promise<void>;

  constructor(protected store: Store) {
    super();
    // Check if the current site is configured for ComScore analytics
    const { env } = this.store;

    if (!env.COMSCORE_CLIENT_ID) {
      log.debug("ComScore Client ID not configured");
      this._library = Promise.reject(new Error("ComScore Client ID not configured"));
      return;
    }

    store.onAnalyticsAction.subscribe((event: AnalyticsEvent) => {
      if (event.context === "load_more") {
        this.trackPageView();
      }
    });

    store.comscore = this;
  }

  get payload() {
    const tagType = "2";
    const publisherID = this.store.env.COMSCORE_CLIENT_ID;
    const userConsent = getUserConsent(this.store.getCcpaStatus());
    if (!userConsent) {
      log.error("User consent not available, skipping call");
      return null;
    }

    return {
      c1: tagType,
      c2: publisherID,
      cs_ucfr: userConsent.value,
      options: {
        ...userConsent.options,
      },
    };
  }

  // Ensure library is loaded once and only once
  loadLibrary() {
    if (this._library) {
      return this._library;
    }

    const { env } = this.store;
    this._library = new Promise((resolve, reject) => {
      window._comscore = window._comscore || [];
      const body = document.querySelector("body");
      const clientId = this.store.env.COMSCORE_CLIENT_ID;

      if (clientId && body) {
        const srcUrl = `${
          document.location.protocol === "https:" ? "https://sb" : "http://b"
        }.scorecardresearch.com/cs/${clientId}/beacon.js`;
        const script = document.createElement("script");

        script.id = this.comscoreLibId;
        script.async = true;
        script.defer = true;
        script.src = srcUrl;
        script.onload = () => {
          log.debug("ComScore library loaded");

          if (this.payload) {
            window._comscore.push(this.payload);
          }

          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug("ComScore library could not be loaded", error.message);
          }
          reject(new Error("ComScore library could not be loaded"));
        };

        body.appendChild(script);
      } else {
        log.debug(`ComScore library could not be loaded: body? ${!!body} configured? ${env.COMSCORE_CLIENT_ID}`);
        reject(new Error("ComScore library could not be loaded"));
      }
    });

    return this._library;
  }

  trackPageView() {
    log.debug("Trigger ComScore page view");
    this.loadLibrary()
      .then(() => this.trigger())
      .catch((e: Error) => log.debug(e.message));
  }

  trigger = () => {
    try {
      const { env } = this.store;

      if (env.COMSCORE_CLIENT_ID && typeof window !== "undefined" && window.COMSCORE) {
        const timeout = parseInt(this.store.env.REQUEST_TIMEOUT || "0", 10);
        if (this.payload) {
          window.COMSCORE.beacon(this.payload);
        }

        return axios.get("/api/v4/analytics/comscore/", {
          timeout,
        });
      }
    } catch (err) {
      log.error("Failed to load ComScore", err.message);
    }
  };
}
