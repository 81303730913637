import type { FeedResultFragment, PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";
import { isPublishRecord, zonedTimeFormat } from "@inferno/renderer-shared-core";
import { CoastShowPayloadFields } from "../page-blocks/content/CoastContent.types";

export const getCoastBlockType = <T>(hints: string[], mapping: Record<string, T>): T | null => {
  for (const hint of hints) {
    if (hint in mapping) {
      return mapping[hint];
    }
  }

  return null;
};

export const getCoastItemDate = (timezone: string, outputFormat: string, item?: FeedResultFragment) => {
  if (!item || !isPublishRecord(item.record)) {
    return null;
  }

  const itemDate = item.record.payload.fields?.show_date?.value || item.record.pub_start;
  return zonedTimeFormat({ date: itemDate, timezone, outputFormat });
};

export const getCoastLinks = (content: PublishRecordFragment, linkName: string) =>
  content.linksData.find(link => link.name === linkName)?.result.items;

export const getShowZypeSlugs = ({ zype_episodes }: CoastShowPayloadFields): string[] =>
  (zype_episodes?.value ?? "").split(",").map(item => item.trim());
