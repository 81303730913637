import { useCallback, useRef } from "react";
import { ILog } from "@ihr-radioedit/inferno-core";

const log = ILog.logger("Window");

export const isWindowDefined = () => typeof window !== "undefined";
export const objectToKeyValueString = (object: { [key: string]: unknown }) => {
  if (object) {
    const params = Object.entries(object)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${key}=${String(value)}`);

    return params.length > 0 ? params.join(",") : "";
  }

  return "";
};

interface OpenWindowOptions {
  url: string;
  target?: string;
  features?: {
    [key: string]: string | number;
  };
}

export const useNewWindow = () => {
  const windowRef = useRef<Window | null>(null);
  const open = useCallback(
    ({ url, target, features = {} }: OpenWindowOptions) => {
      if (isWindowDefined() && window.parent === window.top) {
        log.debug(`Opening Window: ${target}`);
        windowRef.current = window.open(url, target, objectToKeyValueString(features));
      }
    },
    [windowRef],
  );

  const close = useCallback(
    (name: string) => {
      if (windowRef.current?.name === name) {
        log.debug(`Closing window: ${windowRef.current?.top?.name}`);
        windowRef.current?.close();
      }
    },
    [windowRef],
  );

  if (isWindowDefined() && !windowRef.current) {
    windowRef.current = window.parent;
  }

  return [open, close] as const;
};
