import i18n from "i18next";

import { getLang } from "@ihr-radioedit/inferno-core";
import { slugify } from "@ihr-radioedit/inferno-core";

import { stripSpace } from "../../utilities/strip-space";
import { isOnAirScheduleBlock } from "../../lib/guards";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { setGeneralMetaTags } from "../util";

export const scheduleTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block } = context;
  if (block && isOnAirScheduleBlock(block)) {
    let selectedDay = "";
    let { metadata } = state;
    const lang = getLang(site);

    if (block.value) {
      selectedDay =
        block.value.selected_day && typeof block.value.selected_day === "string" ? block.value.selected_day : "Today";
    }

    state.surrogateKeys.add(`schedule/${site.index.slug}`);
    state.surrogateKeys.add(`schedule/${site.index.canonicalHostname}`);

    const { schedule } = block.resolved;
    let keywords: string[] = [];
    const market = site.sections.general?.weather_market || "";
    if (selectedDay && selectedDay !== "Today") {
      let localeDay = i18n.t(selectedDay);
      if (lang === "en") {
        const [d, ...ay] = localeDay;
        localeDay = d.toUpperCase() + ay.join("");
      } else if (lang === "es") {
        localeDay = localeDay.toLowerCase();
      }

      const parselyTitle = i18n
        .t("schedule_parsely_title", {
          station: site.sections.general?.name,
          selectedDay: localeDay,
        })
        .trim();

      const description = i18n.t("schedule_description", {
        station: site.sections.general?.name,
        selectedDay: localeDay,
        market,
      });
      keywords = [
        site.sections.general?.name?.toLowerCase() || "",
        localeDay.toLowerCase(),
        i18n.t("schedule"),
        i18n.t("dj"),
        i18n.t("on-air"),
        i18n.t("jock"),
      ];
      keywords.push(...schedule.names.map(name => name.toLowerCase()));

      metadata = setGeneralMetaTags(metadata, {
        titles: {
          default: `${parselyTitle} | ${site.sections.general?.name}`,
          parsely: parselyTitle,
        },
        description,
        keywords,
      });
    } else {
      const jocks: string = schedule.names.slice(0, 2).join(", ");
      const title = i18n.t("upcoming_parsely_title", { station: site.sections.general?.name, jocks });
      const parselyTitle = title;

      keywords = [
        site.sections.general?.name?.toLowerCase() || "",
        stripSpace(i18n.t("onair").toLowerCase()),
        i18n.t("on-air"),
        i18n.t("personality"),
        i18n.t("personalities"),
        i18n.t("hosts"),
        i18n.t("dj"),
        i18n.t("djs"),
        i18n.t("jock"),
      ];
      keywords.push(...schedule.names.map((name: string) => name.toLowerCase()));
      if (market) {
        keywords.push(market.toLowerCase());
      }

      metadata = setGeneralMetaTags(metadata, {
        titles: {
          default: title,
          parsely: parselyTitle,
        },
        description: i18n.t("upcoming_description", { jocks, station: site.sections.general?.name }) || "",
        keywords,
      });
    }

    return {
      ...state,
      ads: {
        ...state.ads,
        keywords: keywords.map(item => slugify(item)),
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          tags: keywords.map(item => slugify(item)),
          filter: {
            type: "day_of_week",
            name: selectedDay.toLowerCase(),
          },
        },
      } as AnalyticsTags,
      metadata,
    };
  }

  return state;
};
