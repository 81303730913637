import loadable from "@loadable/component";
import classnames from "classnames";
import { isLeft } from "fp-ts/Either";
import { inject } from "mobx-react";
import { Fragment, useEffect } from "react";
import { ShareButtons } from "../../../../core/components/integrations/ShareButtons.component";
import { CalendarPayloadResolver } from "@ihr-radioedit/inferno-core";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { getSocialShareInsertion } from "../../../../core/lib/utilities";
import { ContentProps } from "../../../../core/page-blocks/content/Content.component";
import { Container } from "../../../../core/ui";
import { ILog } from "@ihr-radioedit/inferno-core";
import { CoastContentBlock } from "./CoastContent.component";
import "./CoastCalendarContent.style.scss";

const log = ILog.logger("CoastCalendarContent");

const CoastCalendarFrontMatter = loadable(() => import("./CoastCalendarFrontMatter.component"));
export const CoastCalendarContent = inject("store")(({ content, block, store }: ContentProps) => {
  if (!store || content === null) {
    return null;
  }

  useEffect(() => {
    store.storeBlock(block);
  });

  const { site, request } = store;
  const resolvedPayload = CalendarPayloadResolver.decode(content.payload);
  if (isLeft(resolvedPayload)) {
    log.error(`Payload did not validate! ${getPaths(resolvedPayload)}`);
    return null;
  }
  const payload = resolvedPayload.right;
  const { fields } = payload;

  if (!fields) {
    return null;
  }

  const { insertSocialButtons, insertSocialButtonsPosition } = getSocialShareInsertion(payload);
  const url = site.getShareUrl(request);
  const containerCss = classnames("coast-content-layout-container event-detail");

  return (
    <Container className={containerCss}>
      {!block.tags?.includes("display-hints/no-front-matter") ? (
        <CoastCalendarFrontMatter content={content} block={block} />
      ) : null}
      {!insertSocialButtons ? (
        <ShareButtons url={url} title={content.summary.title} desc={content.summary.description} />
      ) : null}
      <article className="content-detail-container">
        {payload.fields.blocks.value?.map((b, i) => (
          <Fragment key={i}>
            <CoastContentBlock block={b} />
            {insertSocialButtons && i === insertSocialButtonsPosition ? (
              <ShareButtons url={url} title={content.summary.title} desc={content.summary.description} />
            ) : null}
          </Fragment>
        ))}
      </article>
      <ShareButtons url={url} title={content.summary.title} desc={content.summary.description} />
      <hr className="divider" />
    </Container>
  );
});

export default CoastCalendarContent;
