import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject } from "mobx-react";
import { createElement, Fragment, useEffect } from "react";

import { blockMapLegacy } from "../../core/content-blocks";
import { PayloadResolverLegacy } from "@ihr-radioedit/inferno-core";
import { BlockBaseLegacy, getPaths } from "@ihr-radioedit/inferno-core";
import { getBlockSchedule } from "../../core/lib/content";
import { ILog } from "@ihr-radioedit/inferno-core";
import { isScheduled, PageBlockInterface } from "../../core/page-blocks/Block.component";
import { Container } from "../../core/ui";
import type { Store } from "@inferno/renderer-shared-core";
import { isFeedResult, isItemBlock, isPublishRecord } from "@inferno/renderer-shared-core";

const log = ILog.logger("ContentEmbed.component");

export interface ContentProps extends PageBlockInterface {
  store?: Store;
}

export const Content = inject("store")(({ block, store }: ContentProps) => {
  if (!isItemBlock(block)) {
    log.error("No item on embedded content block", block);
    return null;
  }

  if (!isFeedResult(block.item?.result)) {
    log.error("Item on embedded content block has no record", block);
    return null;
  }

  const content = block.item?.result?.record;
  if (!store || !content || !isPublishRecord(content)) {
    return null;
  }

  useEffect(() => {
    store.storeBlock(block);
  });

  const resolvedPayload = PayloadResolverLegacy.decode(content.payload);
  if (isLeft(resolvedPayload)) {
    const errorMessage = `Payload did not validate! ${getPaths(resolvedPayload)}`;
    log.info(errorMessage);
    return null;
  }
  const payload = resolvedPayload.right;

  return (
    <Container className={`content-layout-container`}>
      {payload.blocks.map((b, i) => (
        <Fragment key={i}>
          <ContentBlock block={b} />
        </Fragment>
      ))}
    </Container>
  );
});

(Content as any).defaultProps = {
  adInsertPosition: 6,
  adId: "3331",
};

export const ContentBlock = ({ block }: { block: BlockBaseLegacy }) => {
  const schedule = getBlockSchedule(block);

  if (schedule && !isScheduled(Date.now(), schedule)) {
    return null;
  }

  const el = createElement<BlockBaseLegacy>(blockMapLegacy[block.type], block);
  const blockClass = classnames("content-block", {
    [`type-${block.type}`]: block.type,
    [`embed-type-${block.embed_type}`]: block.embed_type,
    [`provider-${block.provider}`]: block.provider,
  });

  return <section className={blockClass}>{el}</section>;
};
