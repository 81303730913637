import { getUnixTime, addDays, endOfMonth, startOfMonth } from "date-fns";
import { getQueryDate, MonthNumber } from "./time";

interface Context {
  "<upcoming_pub_start>": number;
  "<upcoming_pub_after>": number;
  "<pub_start>"?: number;
  "<pub_after>"?: number;
  "<tonight_pub_start>"?: number;
  "<tonight_pub_after>"?: number;
}

const validateNumberRouteParam = (
  value: string,
  { min = null, max = null }: { max?: number | null; min?: number | null } = {},
) => {
  const parsed = Number(value);

  if (isNaN(parsed)) {
    throw new Error(`Invalid path param. Expected number, got ${value}`);
  }

  if (max !== null && parsed > max) {
    throw new Error(`Invalid path param. Expected max number ${max}, got ${value}`);
  }

  if (min !== null && parsed < min) {
    throw new Error(`Invalid path param. Expected min number ${min}, got ${value}`);
  }

  return parsed;
};

export const getPubDatesContext = (path: string, routeParams: Record<string, string>) => {
  const year = "year" in routeParams ? validateNumberRouteParam(routeParams.year, { min: 2000 }) : null;
  const month = "month" in routeParams ? validateNumberRouteParam(routeParams.month, { min: 1, max: 12 }) : null;

  const d = new Date();
  const now = d.getTime();
  const interval = 3600 * 1000;
  const timestamp = Math.ceil(now / interval) * interval;

  const context: Context = {
    "<upcoming_pub_start>": getUnixTime(addDays(d, 14)) * 1000,
    "<upcoming_pub_after>": timestamp,
    "<pub_start>": 0,
    "<pub_after>": 0,
    ...(path === "/" // needed only for the homepage
      ? {
          "<tonight_pub_start>": getUnixTime(addDays(d, 2)) * 1000,
          "<tonight_pub_after>": timestamp,
        }
      : {}),
  };

  if (year !== null) {
    let pubStart = endOfMonth(getQueryDate(year, (month || 12) as MonthNumber));
    let pubAfter = startOfMonth(getQueryDate(year, (month || 1) as MonthNumber));

    if (path.includes("shows")) {
      pubStart = addDays(pubStart, 1);
      pubAfter = addDays(pubAfter, 1);
    }

    context["<pub_start>"] = getUnixTime(pubStart) * 1000;
    context["<pub_after>"] = getUnixTime(pubAfter) * 1000;
  } else {
    delete context["<pub_start>"];
    delete context["<pub_after>"];
  }

  return context;
};
