import type { PageFragment } from "@ihr-radioedit/inferno-webapi";

export interface IPage {
  currentPage: PageFragment | null;
  notFoundPage?: PageFragment;
}

export abstract class BaseStorePage implements IPage {
  abstract currentPage: PageFragment | null;

  abstract notFoundPage?: PageFragment;

  abstract statusCode?: number;

  abstract tagsSet: Set<string>;

  abstract storePage(page: PageFragment | null): void;
  abstract storeStatus(statusCode: number): number;
  abstract storeNotFoundPage(page: PageFragment): PageFragment;

  protected setTagsSet() {
    this.tagsSet = new Set();
    // this optional chaining on tags isn't really needed
    // only added so I don't have to track down every single test fixture
    this.currentPage?.tags?.forEach(hint => this.tagsSet.add(hint.id));
  }
}
