import { isWindowDefined } from "./window";
import { parseKVString, StringObj } from "./key-values";

export interface ICookies {
  get: (key: string) => string;
  set: (key: string, value: string, days?: number) => StringObj;
  setRaw: (key: string, value: string, attributes: string) => StringObj;
  delete: (key: string) => StringObj;
}

export class Cookies implements ICookies {
  data: StringObj = {};

  constructor(private doc: Document) {
    this.parse();
  }

  private parse() {
    this.data = parseKVString(this.doc.cookie, "; ");
  }

  get(key: string) {
    this.parse();
    return this.data[key] || "";
  }

  set(key: string, value: string, days = 7) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    this.doc.cookie = `${key}=${escape(value)}; expires=${date.toUTCString()}; path=/`;
    this.parse();
    return this.data;
  }

  setRaw(key: string, value: string, attributes: string) {
    this.doc.cookie = `${key}=${escape(value)}; ${attributes}`;
    this.parse();
    return this.data;
  }

  delete(key: string) {
    return this.set(key, "", -1);
  }
}

export const cookies = (): ICookies => {
  if (isWindowDefined()) {
    return new Cookies(document);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  return {
    get: (_key: string) => "",
    set: (key: string, value: string, _days) => ({ [key]: value }),
    setRaw: (key: string, value: string, _attributes: string) => ({ [key]: value }),
    delete: (_key: string) => {
      return { "": "" };
    },
  };
  /* eslint-enable @typescript-eslint/no-unused-vars */
};
