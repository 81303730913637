import loadable from "@loadable/component";
import { Title } from "../../core/components/Title.component";
import { Ad } from "../../core/page-blocks/Ad.component";
import { PageBlockRegistry } from "../../core/page-blocks/Block.component";
import Item from "../../core/page-blocks/content/Item.component";
import { ItemReference } from "../../core/page-blocks/content/ItemReference.component";
import { Html } from "../../core/page-blocks/Html.component";
import { ShowLoader } from "../../core/page-blocks/show/ShowLoader.component";

const Subscribe = loadable(() => import("../../core/page-blocks/newsletter/Subscribe.component"));
const Unsubscribe = loadable(() => import("../../core/page-blocks/newsletter/Unsubscribe.component"));

export const premiereBlockComponents: PageBlockRegistry = {
  ad: () => Ad,
  contact: () => loadable(() => import("../../core/page-blocks/contact/Contact.component")),
  contest: () => loadable(() => import("../../core/page-blocks/contest/ContestLoader.component")),
  contests: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/page-blocks/contests/ContestsTileLoader.component"), { ssr: false })
      : loadable(() => import("../../core/page-blocks/contests/ContestsLoader.component")),
  feed: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/page-blocks/datasource/DatasourceTileLoader.component"))
      : loadable(() => import("../../core/page-blocks/datasource/DatasourceLoader.component")),
  header: () => loadable(() => import("../../core/page-blocks/header/Header.component")),
  html: () => Html,
  item: () => Item,
  "item-reference": () => ItemReference,
  newslettersubscribe: () => Subscribe,
  newsletterunsubscribe: () => Unsubscribe,
  onair: () => loadable(() => import("../../core/components/tiles/OnairTileLoader.component")),
  onairschedule: () => loadable(() => import("../../core/components/onair/OnAirSchedule.component")), // This is for the /schedule page, so you don't get confused
  playlist: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/page-blocks/music/MusicTile.component"))
      : loadable(() => import("../../core/page-blocks/music/MusicPage.component")),
  podcasts: () => loadable(() => import("../../core/page-blocks/podcast/PodcastsLoader.component")),
  catalog: () => loadable(() => import("../../core/page-blocks/catalog/Catalog.component")),
  recommendation: () =>
    loadable(() => import("../../core/page-blocks/recommendation/Recommendation.component"), { ssr: false }),
  search: () => loadable(() => import("../../core/page-blocks/search/SearchLoader.component")),
  show: () => ShowLoader,
  title: () => Title,
  topsongs: () => loadable(() => import("../../core/page-blocks/music/MusicPage.component")),
  traffic: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/components/tiles/TrafficTile.component"))
      : loadable(() => import("../../core/page-blocks/traffic/Traffic.component")),
  weather: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/components/tiles/WeatherTileLoader.component"))
      : loadable(() => import("../../core/page-blocks/weather/WeatherLoader.component")),

  asset: () => loadable(() => import("../../core/page-blocks/asset/Asset.component")),
  chartlist: () => loadable(() => import("../../core/page-blocks/chartlist/ChartListPageLoader.component")),
  chart: () => loadable(() => import("../../core/page-blocks/chartlists/ChartListsCollectionPageLoader.component")),
  instagram: () => () => null,
  twitter: () => loadable(() => import("../../core/page-blocks/twitter/Twitter.component")),
  howToListen: () =>
    loadable(() => import("../../core/page-blocks/howToListen/HowToListenLoader.component"), {
      ssr: false,
    }),
  fcc: () => loadable(() => import("../../core/page-blocks/fccApplication/fccApplication.component")),
  youtube: () => loadable(() => import("../../core/page-blocks/youtubePlayer/YouTubePlayer.component")),
};

export const premiereMicrositeComponents: PageBlockRegistry = {
  ...premiereBlockComponents,
  about: () => loadable(() => import("../../core/components/microsite/MicrositeInfo.component")),
  feed: block =>
    block?.tags?.includes("display-hints/tile")
      ? loadable(() => import("../../core/page-blocks/datasource/MicrositeDatasourceTileLoader.component"))
      : loadable(() => import("../../core/page-blocks/datasource/DatasourceLoader.component")),
};
