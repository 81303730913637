export const stringify = (value: any, space?: number) => {
  const cache = new Set<any>();
  return JSON.stringify(
    value,
    (_, v) => {
      if (typeof v === "object" && v !== null) {
        // Duplicate reference found, discard key
        if (cache.has(v)) {
          return "__circular__";
        }

        // Store value in our collection
        cache.add(v);
      }

      if (v instanceof Error) {
        const error = {};

        Object.getOwnPropertyNames(v).forEach(function (propName) {
          // @ts-ignore
          error[propName] = v[propName];
        });

        return error;
      }

      return v;
    },
    space,
  );
};
