import { ILog } from "@ihr-radioedit/inferno-core";
import type { Store } from "@inferno/renderer-shared-core";

const provider = "outbrain";
const log = ILog.logger(`${provider}.ts`);
let hasRunOutbrain = false;

export const setupOutbrain = (store: Store) => {
  // Ensure outbrain is included once and only once !!
  if (hasRunOutbrain) {
    return;
  }
  const body = document.querySelector("body");

  if (
    body &&
    (store.site.config.sections?.partners?.outbrain_switch ||
      store?.request.query?.vp === provider ||
      store.site.contentVideoProvider === provider)
  ) {
    hasRunOutbrain = true;
    if (store.env.OUTBRAIN_WIDGET_URL) {
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = store.env.OUTBRAIN_WIDGET_URL;
      script.onload = () => {
        log.debug(`${provider} loaded`);
        store.onOutbrainLoad.dispatch({ ready: true });
      };

      script.onerror = (
        event: Event | string,
        source?: string,
        fileno?: number,
        columnNumber?: number,
        error?: Error,
      ) => {
        if (error) {
          log.debug(`${provider} could not be loaded", error.message`);
        }
      };
      body.appendChild(script);
    }

    if (store.env.OUTBRAIN_PIXEL_URL && store.site.config.sections?.partners?.outbrain_switch) {
      const img = document.createElement("img");
      img.src = store.env.OUTBRAIN_PIXEL_URL;
      img.alt = `${provider} pixel`;
      img.style.display = "none";
      body.appendChild(img);
    }
  }
};
