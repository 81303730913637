import i18n from "i18next";

import { slugify, removePrefix } from "@ihr-radioedit/inferno-core";

import { isResolvedTitle } from "../../lib/guards";
import { TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../util";

export const titleTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, request } = context;

  if (block?.resolved && isResolvedTitle(block.resolved)) {
    const feed = block.resolved;
    const title = feed.value.title || feed.topicTitle || "";
    let { metadata } = state;

    state.surrogateKeys.add(`topic/${site.index.slug}-${(feed.value.title || feed.topicTitle)?.toLowerCase()}`);

    if (block.tags?.includes("display-hints/topic")) {
      const pathName = slugify(removePrefix(/^\w+\//)(title));
      metadata.set("rss", {
        value: site.getShareUrl(request).replace(pathName, `${pathName}.rss`),
        title,
        type: "link",
        mime: "application/rss+xml",
        rel: "alternate",
      });
    }

    if (title) {
      const name = site.sections.general?.name || "";
      metadata = setGeneralMetaTags(metadata, {
        titles: {
          default: `${title} | ${name}`,
          social: title,
        },
        description: i18n.t("collection_description", { title, name }) || "",
        types: {
          parsely: "sectionpage",
        },
      });
    }

    metadata.set(...generateMetaTag("name", "parsely-section", "topic"));

    return {
      ...state,
      metadata,
    };
  }

  return state;
};
