export enum ListenLive {
  PLAY = "play",
  PAUSE = "pause",
  PROGRESS = "progress",
  ERROR = "error",
  ENDED = "ended",
  READY = "ready",
}
export interface ListenLiveEvent {
  action: ListenLive.PAUSE | ListenLive.PLAY | ListenLive.PROGRESS;
}
