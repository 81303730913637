import { GraphQLResponse } from "@ihr-radioedit/gql-client";
import type { Response } from "express";
import { ILog } from "./logging";

const logger = ILog.logger("Cache");

const SURROGATE_KEY_LIMIT = 16 * 1024;
const SURROGATE_KEY_LENGTH = "Surrogate-Key: ".length;

type cacheTypes =
  | "browser"
  | "browser-home"
  | "cdn"
  | "cdn-home"
  | "redirect"
  | "surrogate"
  | "stale-if-error"
  | "stale-while-revalidate";

export interface ICache {
  data?: any;
  error?: string;
}

interface SurrogateKeyGraphQLResponse<T> extends GraphQLResponse<T> {
  extensions: {
    emits: string[];
    [key: string]: any;
  };
}

function isSurrogateKeyResponse<T>(response: GraphQLResponse<T>): response is SurrogateKeyGraphQLResponse<T> {
  return (
    !!response.extensions &&
    response.extensions.hasOwnProperty("emits") &&
    Array.isArray((response.extensions as any).emits)
  );
}

export const setResponseHeaders = (
  varyHeaders: string,
  response: Response,
  ttls: {
    cdn?: number;
    stale?: number;
    error?: number;
    browser?: number;
  },
  ...additionalHeaders: string[][]
) => {
  if (!response.headersSent) {
    if (varyHeaders) {
      response.setHeader("Vary", varyHeaders);
    }
    if (ttls.browser === 0 && ttls.cdn === 0) {
      response.setHeader("Cache-Control", "private, max-age=0");
      response.setHeader("Surrogate-Control", `private, max-age=0`);
    } else if (ttls.browser === 0) {
      response.setHeader("Cache-Control", "no-cache, no-store, immutable");
    } else {
      response.setHeader("Cache-Control", `public, max-age=${ttls.browser || parseCacheType("browser")}`);
    }
    if (ttls.cdn !== 0) {
      // Surrogate-Control: max-age=(time in seconds)
      response.setHeader(
        "Surrogate-Control",
        `public, ` +
          `max-age=${ttls.cdn || parseCacheType("cdn")}, ` +
          `stale-while-revalidate=${ttls.stale || parseCacheType("stale-while-revalidate")}, ` +
          `stale-if-error=${ttls.error || parseCacheType("stale-if-error")}`,
      );
    }
    if (additionalHeaders?.length) {
      additionalHeaders.forEach(([header, value]) => response.setHeader(header, value));
    }
    if (response.locals.surrogateKeys.size) {
      response.setHeader("Surrogate-Key", getSurrogateKeyHeader(response.locals.surrogateKeys));
    }
  } else {
    logger.debug("Attempted to set response headers after they've been sent: ", response.locals.renderPath.join(", "));
  }
};

export function addSurrogateKeysFromProxy(proxySurrogateKeys: string, outgoingSurrogateKeys: Set<string>) {
  if (proxySurrogateKeys) {
    const keys = proxySurrogateKeys.split(" ");
    for (const key of keys) {
      outgoingSurrogateKeys.add(key);
    }
  }
}

export function addSurrogateKeys<T>(response: GraphQLResponse<T>, surrogateKeys: Set<string>) {
  if (isSurrogateKeyResponse(response)) {
    response.extensions?.emits.forEach(key => {
      surrogateKeys.add(key);
    });
  }
}

export function getSurrogateKeyHeader(surrogateKeys: Set<string>) {
  let header = "";
  for (const key of surrogateKeys.values()) {
    if (header.length < SURROGATE_KEY_LIMIT - SURROGATE_KEY_LENGTH - key.length) {
      if (!header) {
        header = key;
      } else {
        header += ` ${key}`;
      }
    }
  }
  return header;
}

const parseCacheType = (type: cacheTypes) => {
  switch (type) {
    case "browser":
    case "cdn":
      return 43200; // 12hrs
    case "browser-home":
    case "cdn-home":
      return 900; // 15mins
    case "stale-if-error":
    case "stale-while-revalidate":
      return 900; // 15mins
    case "redirect":
      return 3701;
    case "surrogate":
      return 31536000;
    default:
      return 43200;
  }
};

export const parseTtl = (type: cacheTypes, val?: string) => (val ? parseInt(val, 10) : parseCacheType(type));
