import * as Webapi from "@ihr-radioedit/inferno-webapi";
import loadable from "@loadable/component";
import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject, observer } from "mobx-react";
import { createElement, Fragment, useEffect } from "react";
import { blockMapLegacy } from "../../content-blocks";
import { getPodcastEmbed, matchPrefix, PayloadResolverLegacy } from "@ihr-radioedit/inferno-core";
import { BlockBaseLegacy } from "@ihr-radioedit/inferno-core";
import type { BlockFragment, Eyebrow, PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";
import { getBlockSchedule } from "../../lib/content";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { ListenLive, ListenLiveEvent } from "../../lib/listenLive";
import { cookies, getAdFrequency, makeAd } from "@inferno/renderer-shared-core";
import { isAdPosition } from "@inferno/renderer-shared-ui";
import { Container } from "../../ui";
import { DismissContainer } from "../../ui/DismissContainer.component";
import { Toast } from "../../ui/";
import Ad from "../Ad.component";
import { isScheduled } from "../Block.component";
import "./Content.style.scss";
import { ContentPlayerWidgetLoader } from "./ContentPlayerWidgetLoader.component";
import { RelatedContent } from "./RelatedContent.component";
import type { Store } from "@inferno/renderer-shared-core";
import { ShareButtons } from "../../components/integrations/ShareButtons.component";
import { useTranslation } from "react-i18next";

const log = ILog.logger("Content");

const ContentFooter = loadable(() => import("./ContentFooter.component"));
const FrontMatter = loadable(() => import("./FrontMatter.component"));

export type ContentProps = {
  content: PublishRecordFragment;
  updatedContentBlocks?: BlockBaseLegacy[] | null;
  eyebrow?: Eyebrow;
  block: BlockFragment;
  store?: Store;
};

export const ContentPlayerTrigger = inject("store")(
  observer(({ store }: { store?: Store }) => {
    if (!store) {
      return null;
    }

    const design = store.site.config.sections?.design;

    if (!design || !design.ihr_embed_player_bar_switch) {
      return null;
    }

    const { player, site } = store;
    const { playback } = player;
    const { t } = useTranslation();
    const identifier = "dismiss-player-toast";

    useEffect(() => {
      const handleExternalAction = (event: ListenLiveEvent) => {
        if (event.action === ListenLive.PLAY && cookies().get(identifier) === "") {
          cookies().set(identifier, "1", 1);
        }
      };

      player.externalAction.subscribe(handleExternalAction);
      return () => player.externalAction.unsubscribe(handleExternalAction);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (playback && cookies().get(identifier) === "") {
        cookies().set(identifier, "1", 1);
      }
    }, [playback]);

    const override = player.getOverride();
    let title = t("toast_title_live_station", { stationName: site.sections?.general?.name ?? "" });
    let message = t("toast_message_live_station");

    if (override?.kind === "podcast") {
      title = t("toast_title_podcast_station");
      message = t("toast_message_podcast_station");
    }

    return !playback && (player.defaultId || player.overrideId) ? (
      <DismissContainer identifier={"dismiss-player-toast"}>
        <Toast title={title} message={message} />
      </DismissContainer>
    ) : null;
  }),
);

export const Content = inject("store")(({ content, block, store, updatedContentBlocks }: ContentProps) => {
  if (!store || content === null) {
    return null;
  }

  const { site, request, page } = store;
  const resolvedPayload = PayloadResolverLegacy.decode(content.payload);
  if (isLeft(resolvedPayload)) {
    log.error(`Payload did not validate! ${getPaths(resolvedPayload)}`);
    return null;
  }
  const payload = resolvedPayload.right;

  const showToast = page.currentPage?.tags.filter(tag => tag.id.trim() === "display-hints/show-player-toast").length;
  const insertRelatedContentPosition = Math.min(5, Math.floor(payload.blocks.length * 0.66));
  const url = site.getShareUrl(request);

  const containerCss = classnames("content-layout-container", {
    sponsored: payload.is_sponsored,
  });

  const design = site.config.sections?.design;
  const showFeaturedWidget =
    design?.ihr_embed_player_bar_switch &&
    payload.enable_featured_widget &&
    page.currentPage?.tags.filter(tag => tag.id.trim() === "display-hints/show-editorial-widget").length;

  const categories: string[] = [];
  const artists: string[] = [];
  content.subscription.forEach((item: Webapi.Subscription) => {
    artists.push(...item.tags.filter(matchPrefix(/^artists\//)));
    categories.push(...item.tags.filter(matchPrefix(/^categories\//)));
  });

  const adFirstPosition = 2;

  const adBlockData = makeAd("3327", block.value?.ad_split ?? 0);
  const adInsertFrequency = getAdFrequency(block.tags);
  const isPodcastEmbed = getPodcastEmbed(content);

  const contentBlocks = updatedContentBlocks ?? payload.blocks;

  return (
    <>
      <Container className={containerCss}>
        {!block.tags?.includes("display-hints/no-front-matter") ? (
          <FrontMatter content={content} block={block} />
        ) : null}
        <article className="content-detail-container">
          {showFeaturedWidget ? (
            <ContentPlayerWidgetLoader
              category={categories[0]}
              artist={artists[0]}
              catalog={payload.featured_widget}
              isPodcastEmbed={isPodcastEmbed}
            />
          ) : null}
          {contentBlocks.map((b, index) => (
            <Fragment key={index}>
              <ContentBlock block={b} isPodcastEmbed={isPodcastEmbed} />
              {isAdPosition(index, adFirstPosition, adInsertFrequency) ? (
                <Ad block={adBlockData} batchId={index.toString()} isPrimary={false} />
              ) : null}
              {index === insertRelatedContentPosition ? <RelatedContent content={content} payload={payload} /> : null}
            </Fragment>
          ))}
        </article>
        {payload.is_sponsored ? (
          <ContentFooter type="sponsored" author={content?.summary.author} siteName={site.sections.general?.name} />
        ) : null}
        {payload.feed_vendor && payload.feed_permalink ? (
          <ContentFooter
            type="partner"
            author={content?.summary.author}
            feedType={payload.feed_type}
            siteName={payload.feed_vendor}
            url={payload.feed_permalink}
          />
        ) : null}
        <ShareButtons url={url} title={content.summary.title} desc={content.summary.description} />
        <hr className="divider" />
      </Container>
      {showToast ? <ContentPlayerTrigger /> : null}
    </>
  );
});

(Content as any).defaultProps = {
  adInsertPosition: 6,
  adId: "3331",
};

export const ContentBlock = ({ block, isPodcastEmbed }: { block: BlockBaseLegacy; isPodcastEmbed: boolean }) => {
  if (!block) {
    throw new Error("No Block given to ContentBlock");
  }
  const schedule = getBlockSchedule(block);

  if (schedule && !isScheduled(Date.now(), schedule)) {
    return null;
  }

  const el = createElement<BlockBaseLegacy>(blockMapLegacy[block.type], { ...block, isPodcastEmbed });
  const blockClass = classnames("content-block", {
    [`type-${block.type}`]: block.type,
    [`embed-type-${block.embed_type}`]: block.embed_type,
    [`provider-${block.provider}`]: block.provider,
  });

  return <section className={blockClass}>{el}</section>;
};

export default Content;
