import { toJS } from "mobx";

import type { Store } from "../stores";

const cleanStore = (store: Store) => {
  if (store.site.site.sites.find) {
    // Don't ship hostnames, don't need them
    store.site.site.sites.find.hostnames = [];
  }
};

export const normalizeStoreString = (str: string) => {
  return str
    .replace(/</g, "\\u003c")
    .replace(/"(\/[0-9A-Za-z]{32})"/g, (_, id) => `"${encodeURIComponent(id)}"`)
    .replace(/"(\/[0-9A-Za-z]{24})"/g, (_, id) => `"${encodeURIComponent(id)}"`);
};

export const serializeStore = (store: Store) => {
  cleanStore(store);
  const stringifiedStore = JSON.stringify(
    toJS(store),
    (_, v) => {
      if (v instanceof Set || v instanceof Map) {
        return Array.from(v);
      }
      return v;
    },
    0,
  );
  return normalizeStoreString(stringifiedStore);
};
