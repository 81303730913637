export type device = "mobile" | "tablet" | "desktop";

export abstract class BaseStoreDevice {
  protected _userAgent?: string;
  protected _navigator?: Navigator | null;

  abstract enableHeaderSearch: boolean | null;
  abstract refresh(navigator: Navigator): void;
  abstract get isDesktop(): boolean;
  abstract get isSafari(): boolean;
  abstract get isChrome(): boolean;
  abstract get deviceType(): device | undefined;

  protected isTablet = (): boolean | number | null => {
    return (
      this._userAgent!.search(/ipad/i) > -1 ||
      (this._userAgent!.search(/android/i) > -1 && this._userAgent!.search(/mobile/i) <= -1) ||
      (this._userAgent!.match(/Mac/) && !!this._navigator?.maxTouchPoints && this._navigator?.maxTouchPoints > 2) // phaser- introduced this check due to iPadOS, as Safari there now mimick's desktop..
    );
  };

  protected isMobile = (): boolean | null => {
    return (
      this._userAgent!.search(/iphone/i) > -1 ||
      (this._userAgent!.search(/android/i) > -1 && this._userAgent!.search(/mobile/i) > -1)
    );
  };
}
