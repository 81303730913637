import classnames from "classnames";
import { ComponentProps } from "react";
import * as React from "react";

import "./Container.style.scss";

interface ContainerProps extends ComponentProps<any> {
  className?: string;
  block?: boolean;
}

export const Container: React.FunctionComponent<ContainerProps> = ({ className, block, children, ...delegated }) => {
  const containerClass = classnames("component-container", {
    [`${className}`]: className,
    block: block !== false,
  });

  return (
    <div className={containerClass} suppressHydrationWarning={true} {...delegated}>
      {children}
    </div>
  );
};
