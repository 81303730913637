import { CloseIcon } from "../components/icons/CloseIcon.component";
import styles from "./CloseButton.module.scss";

interface CloseButtonProps {
  addClass?: string;
  click?: () => void;
  label?: string;
  width?: 16 | 18 | 24;
  height?: 16 | 18 | 24;
}

export const CloseButton = ({
  addClass: addclass = "",
  label = "Close",
  click,
  width = 24,
  height = 24,
}: CloseButtonProps) => {
  return (
    <button className={`${styles.close} ${styles[addclass]}`} aria-label={label} onClick={() => click?.()} tabIndex={0}>
      <CloseIcon width={width} height={height} />
    </button>
  );
};
