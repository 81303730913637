import type { BlockFragment, PageFragment } from "@ihr-radioedit/inferno-webapi";
import { SITE_THEME } from "@inferno/renderer-shared-core";
import { Fragment } from "react";
import { useParams } from "react-router";
import { Block, PageBlockRegistry } from "./Block.component";

interface RegionProps {
  name: string;
  page: PageFragment;
  blocks: BlockFragment[];
  blockComponents?: PageBlockRegistry;
  params?: ReturnType<typeof useParams>;
  primaryBlockId?: string;
  siteTheme: SITE_THEME;
}

export const Region = ({ name, page, blocks, params, primaryBlockId, siteTheme, blockComponents }: RegionProps) => {
  const now = Date.now();
  /*
   * You may be wondering why our Blocks are wrapped with a fragment
   * and include a seemingly useless and unreferenced hidden input.
   * Well, wonder no more; React hydration is really stupid when
   * it comes to our Remote/Remote List async loaders.
   * During hydration, if top level blocks are different or change
   * during the hydration process, React will put block contents
   * into the wrong containing blocks and re-render whole blocks unnecessarily
   * into classless divs. The solution? Give it other parts of the dom
   * that remain consistent between renders so it can find its way.
   * This is a moderately ugly solution but after spending numerous
   * hours trying different options, it's the least painful.
   */
  return (
    <div className={`component-region region-${name.replace(/:/g, "-")}`} suppressHydrationWarning={true}>
      {blocks.map(b => (
        <Fragment key={b.id}>
          <Block
            key={b.id}
            block={b}
            page={page}
            params={params}
            now={now}
            primaryBlockId={primaryBlockId}
            blockComponents={blockComponents}
            siteTheme={siteTheme}
          />
          <input type="hidden" data-id={b.id} />
        </Fragment>
      ))}
    </div>
  );
};
