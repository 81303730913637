export const withTimeout = <T>(promise: Promise<T>, timeoutMs: number, errorMsg?: string): Promise<T> => {
  const timeoutPromise = new Promise<never>((_, reject) => {
    setTimeout(() => {
      const error = new Error(errorMsg || `Operation timed out after ${timeoutMs}ms`);
      window?.newrelic?.noticeError(error);
      reject(error);
    }, timeoutMs);
  });

  return Promise.race([promise, timeoutPromise]);
};
