import axios, { AxiosInstance } from "axios";
import { format } from "date-fns";
import stringify from "fast-json-stable-stringify";
import qs from "qs";
import { ICache } from "@ihr-radioedit/inferno-core";
import {
  CoastFeaturedQueryVariables,
  CoastSearchItemFragment,
  GetPwsSearchResultsQueryVariables,
  PwsSearchResponseFragment,
} from "@ihr-radioedit/inferno-webapi";
import { PWSSessionState } from "../../auth/backends/pws";
import { Cursor } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import {
  PwsCreateSubscriptionPayload,
  PwsCreateUserPayload,
  PwsCreateUserResponse,
  PwsGiftRecipientResponse,
  PwsListMediaEpisodesParams,
  PwsListMediaEpisodesResponse,
  PwsListSubscriptionPlansResponse,
  PwsLoginResponse,
  PwsMediaConfigResponse,
  PwsMediaEpisode,
  PwsMediaIsAudioResponse,
  PwsOnAirStatusResponse,
  PwsPasswordHintResponse,
  PwsPremiereMediaListResponseWrapper,
  PwsPremiereMediaResponseWrapper,
  PwsPresentGiftSubscriptionPayload,
  PwsPresentGiftSubscriptionResponse,
  PwsSubmittedResponse,
  PwsViewBillingInfoResponse,
  PwsViewMediaEpisodeResponse,
  PwsViewSubscriptionResponse,
  PwsViewUserResponse,
  ZypeEpisodeCategory,
  ZypeTokenResponse,
} from "./types";
import { getSdk, sdkOpts } from "@ihr-radioedit/inferno-core";
// import { ObservableMap } from "mobx";

const sdk = getSdk(sdkOpts());

const log = ILog.logger("PWS Service");

const PWS_SITE_SLUG_TO_PROPERTY: Record<string, string> = {
  "coast-pr": "coast",
};

const siteSlugToProperty = (brand: string) => {
  const match = PWS_SITE_SLUG_TO_PROPERTY[brand];

  if (!match) {
    throw new Error(`No PWS Service site match for brand ${brand}`);
  }

  return match;
};

export const coastMediaEpisodeCategoriesToGroupSlug = (categories: ZypeEpisodeCategory[]) => {
  const categoryToGroupSlug: Record<string, string> = {
    Library: "library",
    "Insider Audio": "insider-audio",
    "Art Bell Vault": "art-bell-vault",
  };

  for (const group of categories) {
    if (group.title === "Group") {
      // gotta be live show
      if (!group.value.length) {
        return categoryToGroupSlug.Library;
      }

      for (const category of group.value) {
        if (category in categoryToGroupSlug) {
          return categoryToGroupSlug[category];
        }
      }
    }
  }

  throw new Error("Couldn't get group slug from categories");
};

export class PWSMediaService {
  private premiereMediaApi: AxiosInstance;
  private mediaApi: AxiosInstance;

  constructor(siteSlug: string, depPWs: string) {
    this.mediaApi = axios.create({
      baseURL: `https://${depPWs}/media/${siteSlugToProperty(siteSlug)}`,
    });
    this.premiereMediaApi = axios.create({
      baseURL: `https://${depPWs}/premieremedia/streamlink`,
    });
  }

  private getBaseHeaders(accessToken?: string | null) {
    return {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      "Content-Type": "application/json",
    };
  }

  async getOnAirEpisode(accessToken: string | null) {
    const { data } = await this.mediaApi.get<PwsViewMediaEpisodeResponse>("/on-air", {
      headers: this.getBaseHeaders(accessToken),
    });
    return data;
  }

  async getOnAirStatus() {
    const { data } = await this.mediaApi.get<PwsOnAirStatusResponse>("/on-air-status");
    return data;
  }

  async getMediaConfig(accessToken: string | null, includeVideos = true, perPage = 12) {
    const { data } = await this.mediaApi.get<PwsMediaConfigResponse>("/config", {
      headers: this.getBaseHeaders(accessToken),
      params: { includeVideos, perPage },
    });
    return data;
  }

  async getIsAudioEpisode(accessToken: string | null, id: string, onAir: boolean) {
    const { data } = await this.mediaApi.get<PwsMediaIsAudioResponse>(`/is-audio/${id}`, {
      headers: this.getBaseHeaders(accessToken),
      params: { onAir },
    });

    return data;
  }

  async getNextEpisode(curEpisode: PwsMediaEpisode, accessToken: string | null) {
    const { data } = await this.mediaApi.get<PwsViewMediaEpisodeResponse>("/next", {
      headers: this.getBaseHeaders(accessToken),
      params: {
        publishedAt: curEpisode.publishedAt,
        groupSlug: coastMediaEpisodeCategoriesToGroupSlug(curEpisode.categories),
      },
    });

    return data;
  }

  async getMediaEpisodeBySlug(slug: string, accessToken?: string | null) {
    if (slug === "live") {
      const { data: liveData } = await this.mediaApi.get<PwsViewMediaEpisodeResponse>("/on-air", {
        headers: this.getBaseHeaders(accessToken),
      });
      return liveData;
    }

    const { data } = await this.mediaApi.get<PwsViewMediaEpisodeResponse>(`/videos/slug/${slug}`);
    return data;
  }
  async getMediaEpisodeBySlugBulk(slugs: string[], accessToken?: string | null) {
    return (
      await Promise.all(slugs.map(slug => this.getMediaEpisodeBySlug(slug, accessToken).catch(e => log.error(e))))
    ).reduce((acc, cur) => {
      if (cur) {
        acc[cur.video.slug] = cur;
      }
      return acc;
    }, {} as Record<string, PwsViewMediaEpisodeResponse>);
  }

  async listMediaEpisodes(resume: string | null = null, params: PwsListMediaEpisodesParams = {}, accessToken?: string) {
    if (resume) {
      const { data: resumeData } = await this.mediaApi.get<PwsListMediaEpisodesResponse>(`/videos`, {
        headers: this.getBaseHeaders(accessToken),
        params: { resume },
      });

      return resumeData;
    }

    const { data } = await this.mediaApi.get<PwsListMediaEpisodesResponse>(`/videos`, {
      headers: this.getBaseHeaders(accessToken),
      params,
    });
    return data;
  }

  async getMediaEpisodesBySlugs(slugs: string[], accessToken?: string) {
    const { data } = await this.mediaApi.get<PwsListMediaEpisodesResponse>(`/videos/slugs`, {
      headers: this.getBaseHeaders(accessToken),
      params: {
        slugs,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return data;
  }

  async getMP3Link(showIdentifier: string, clip: number, accessToken: string) {
    const {
      data: {
        data: { data: res },
      },
    } = await this.premiereMediaApi.get<PwsPremiereMediaResponseWrapper>(`/coast/show/${showIdentifier}/${clip}`, {
      headers: this.getBaseHeaders(accessToken),
    });

    return res;
  }

  async getMP3LinksBulk(showDate: Date | number, accessToken: string) {
    const showIdentifier = format(showDate, "yyyy-MM-dd");

    return (
      await Promise.all(
        [1, 2, 3, 4].map(hour => this.getMP3Link(showIdentifier, hour, accessToken).catch(e => log.error(e))),
      )
    ).reduce((acc, cur) => {
      if (cur) {
        acc[cur.clip] = cur.url;
      }
      return acc;
    }, {} as Record<string, string>);
  }

  async getWMPLink(type: string, showIdentifier: string, session: PWSSessionState) {
    if (!session.accessToken || !session.hasActiveSubscription) {
      return null;
    }

    const {
      data: {
        data: { data: res },
      },
    } = await this.premiereMediaApi.get<PwsPremiereMediaResponseWrapper>(`/coast_win/${type}/${showIdentifier}`, {
      headers: this.getBaseHeaders(session.accessToken),
    });

    return res;
  }

  async getMPWLinksBulk(types: string[], showDate: Date | number, session: PWSSessionState) {
    const showIdentifier = format(showDate, "yyyy-MM-dd");

    return (
      await Promise.all(types.map(type => this.getWMPLink(type, showIdentifier, session).catch(e => log.error(e))))
    ).reduce((acc, cur) => {
      if (cur) {
        acc[cur.type] = cur.url;
      }
      return acc;
    }, {} as Record<string, string>);
  }

  async listHighlightsLinks(ids: string[]) {
    const {
      data: {
        data: {
          data: { media },
        },
      },
    } = await this.premiereMediaApi.get<PwsPremiereMediaListResponseWrapper>(
      `/list/coast_highlights/show/${ids.join(",")}?includeMetadata=1`,
    );

    return media;
  }
}

export class PWSMemberService {
  private api: AxiosInstance;
  accessToken?: string;

  constructor(host: string, siteSlug: string) {
    this.api = axios.create({ baseURL: `https://${host}/v2/member/${siteSlugToProperty(siteSlug)}` });
  }

  private getBaseHeaders(accessToken?: string) {
    return {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      "Content-Type": "application/json",
    };
  }

  async login(username: string, password: string) {
    const { data } = await this.api.post<PwsLoginResponse>("/login", { username, password });

    return data;
  }

  async logout(accessToken: string) {
    const { data } = await this.api.post<PwsLoginResponse>(
      "/logout",
      {},
      {
        headers: this.getBaseHeaders(accessToken),
      },
    );

    return data;
  }

  async validateToken(accessToken: string, refreshToken: string | null) {
    let query = "";

    if (refreshToken) {
      query += `?refresh_token=${refreshToken}`;
    }

    const { data } = await this.api.get<ZypeTokenResponse>(`/token${query}`, {
      headers: this.getBaseHeaders(accessToken),
    });

    return data;
  }

  async listSubscriptionPlans() {
    const { data } = await this.api.get<PwsListSubscriptionPlansResponse>("/plans");

    return data.plans;
  }

  async subscribe(accessToken: string, payload: PwsCreateSubscriptionPayload) {
    const { data } = await this.api.post<PwsViewSubscriptionResponse>("/subscriptions", payload, {
      headers: this.getBaseHeaders(accessToken),
    });

    return data;
  }

  async presentGiftSubscription(accessToken: string, payload: PwsPresentGiftSubscriptionPayload) {
    const { data } = await this.api.post<PwsPresentGiftSubscriptionResponse>("/subscriptions/gift", payload, {
      headers: this.getBaseHeaders(accessToken),
    });

    return data;
  }

  async cancelSubscription(accessToken: string, subscriptionId: string) {
    const { data } = await this.api.delete<PwsViewSubscriptionResponse>(`/subscriptions/${subscriptionId}`, {
      headers: this.getBaseHeaders(accessToken),
    });

    return data;
  }

  async reactivateSubscription(accessToken: string, subscriptionId: string) {
    const { data } = await this.api.put<PwsViewSubscriptionResponse>(
      `/subscriptions/${subscriptionId}/reactivate`,
      {},
      {
        headers: this.getBaseHeaders(accessToken),
      },
    );

    return data;
  }

  async upDownGradeSubscription(accessToken: string, subscriptionId: string, nextPlanId: string) {
    const { data } = await this.api.post<PwsViewSubscriptionResponse>(
      `/subscriptions/${subscriptionId}/next-plan/${nextPlanId}`,
      {},
      {
        headers: this.getBaseHeaders(accessToken),
      },
    );

    return data;
  }

  async verifyEmail(email: string, recaptchaToken: string) {
    const { data } = await this.api.post<PwsSubmittedResponse>(
      `/verify-email/${email}`,
      {},
      {
        params: { recaptchaToken },
      },
    );

    return data;
  }

  async getUser(accessToken: string, includeSubscription = true) {
    const { data } = await this.api.get<PwsViewUserResponse>(`/user`, {
      headers: this.getBaseHeaders(accessToken),
      params: { includeSubscription },
    });

    return data;
  }

  async createUser(emailToken: string, user: PwsCreateUserPayload, doLogin = true) {
    const { data } = await this.api.post<PwsCreateUserResponse>("/user", { user }, { params: { emailToken, doLogin } });

    return data;
  }

  async getBillingInfo(accessToken: string) {
    const { data } = await this.api.get<PwsViewBillingInfoResponse>("/billing", {
      headers: this.getBaseHeaders(accessToken),
    });

    return data;
  }

  async updateBillingInfo(accessToken: string, body: any) {
    const { data } = await this.api.put<PwsViewBillingInfoResponse>("/billing", body, {
      headers: this.getBaseHeaders(accessToken),
    });

    return data;
  }

  async forgotPassword(email: string, recaptchaToken: string) {
    const { data } = await this.api.post<PwsSubmittedResponse>(
      `/password/forgot/${email}?recaptchaToken=${recaptchaToken}`,
    );

    return data;
  }

  async resetPassword(password: string, passwordToken: string, passwordHint?: string) {
    const { data } = await this.api.put<PwsSubmittedResponse>("/password/reset/", {
      password,
      passwordToken,
      passwordHint,
    });

    return data;
  }

  async initEmailUpdate(currentEmail: string, newEmail: string, password: string) {
    const { data } = await this.api.post<PwsSubmittedResponse>("/update-email/init/", {
      currentEmail,
      newEmail,
      password,
    });

    return data;
  }

  async completeEmailUpdate(emailToken: string) {
    const { data } = await this.api.put<PwsViewUserResponse>(`/update-email/complete/?emailToken=${emailToken}`);

    return data;
  }

  async updatePassword(email: string, currentPassword: string, newPassword: string, passwordHint?: string) {
    const { data } = await this.api.put<PwsViewUserResponse>("/password/update/", {
      email,
      currentPassword,
      newPassword,
      passwordHint,
    });

    return data;
  }

  async getPasswordHint(email: string, recaptchaToken: string) {
    const { data } = await this.api.get<PwsPasswordHintResponse>(
      `/password/hint/${email}?recaptchaToken=${recaptchaToken}`,
    );

    return data;
  }

  async ensureGiftReceiverAccount(email: string, recaptchaToken: string, accessToken: string) {
    const { data } = await this.api.post<PwsGiftRecipientResponse>(
      `/user/gift/${email}?recaptchaToken=${recaptchaToken}`,
      {},
      {
        headers: this.getBaseHeaders(accessToken),
      },
    );

    return data;
  }
}

export const DEFAULT_SEARCH_LIMIT_PER_CATEGORY = 5;

export const doPWSSearch = (cache: Map<string, ICache>) => (query: GetPwsSearchResultsQueryVariables) =>
  new Cursor<PwsSearchResponseFragment | null, GetPwsSearchResultsQueryVariables, CoastSearchItemFragment>(
    query,
    async opts => {
      try {
        if (!opts.limit) {
          opts.limit = DEFAULT_SEARCH_LIMIT_PER_CATEGORY;
        }

        return (
          (
            await sdk!.GetPWSSearchResults.queryAsPromise({
              ...opts,
              limit: opts.limit || DEFAULT_SEARCH_LIMIT_PER_CATEGORY,
            })
          ).data?.pws.coastAppContent.search || null
        );
      } catch (e) {
        log.debug("Error in loader", e && e.response ? e.response.data.errors : e);
        throw e;
      }
    },
    (response, opts) => {
      if (!response || !response.value.items.length) {
        return { opts, hasMore: false, newData: [] };
      }

      return {
        opts: { ...opts, cursor: response.pageInfo.nextCursor },
        hasMore: !!response.pageInfo.nextCursor,
        newData: response.value.items,
      };
    },
    opts => `pws-search-${stringify(opts)}`,
    cache,
  );

export const getCoastFeaturedItems = (query: CoastFeaturedQueryVariables = {}) =>
  sdk!.CoastFeatured.queryAsPromise(query).then(r => r.data?.pws.coastAppContent.featured.value || []);
