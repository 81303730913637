import { isRight } from "fp-ts/lib/Either";

import type { TagRef } from "@ihr-radioedit/inferno-webapi";
import { PayloadResolverLegacy, slugify, removePrefix } from "@ihr-radioedit/inferno-core";

import { generateMetaTag, setGeneralMetaTags } from "../util";
import { Tagger, Tags, TagContext, AdTags, AnalyticsTags } from "../../lib/tagging-type";
import { isItemBlock, isFeedResult, isPublishRecord } from "../../lib/guards";

export const micrositeItemTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, microsite } = context;
  if (
    isItemBlock(block) &&
    block.item &&
    microsite &&
    microsite.config &&
    isFeedResult(block.item.result) &&
    isPublishRecord(block.item.result.record)
  ) {
    const content = block.item?.result.record;
    const resolvedPayload = PayloadResolverLegacy.decode(content.payload);
    const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;
    const keywords: string[] = [];

    let title = payload?.seo_title || content.summary.title || "";

    if (title) {
      title = `${title} | ${site.sections.general?.name} | ${microsite.sections.general?.name}`;
    }

    const socialTitle = payload?.social_title
      ? `${payload?.social_title} | ${site.sections.general?.name} | ${microsite.sections.general?.name}`
      : title;

    if (microsite.sections.general?.keywords?.length) {
      keywords.push(...microsite.sections.general.keywords.map((i: TagRef) => removePrefix(/^\w+\//)(i.id)));
    }
    if (payload?.keywords) {
      keywords.push(...payload.keywords.map(removePrefix(/^\w+\//)));
    }

    let { metadata } = state;
    metadata = setGeneralMetaTags(metadata, {
      titles: {
        default: title,
        social: socialTitle,
      },
      keywords,
    });

    metadata.set(...generateMetaTag("name", "robots", "max-image-preview:large"));

    return {
      ...state,
      ads: {
        ...state.ads,
        keywords: keywords.map(item => slugify(item)),
        microsite: microsite?.index?.slug || "",
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          tags: keywords.map(item => slugify(item)),
        },
      } as AnalyticsTags,
      metadata,
    };
  }

  return state;
};
