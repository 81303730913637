import { action, makeObservable, observable } from "mobx";
import type { PageFragment } from "@ihr-radioedit/inferno-webapi";
import { BaseStorePage, IPage } from "../abstracts/store/base-page.abstract";

/** Add new members to Base Abstract */
export class StorePage extends BaseStorePage {
  @observable
  currentPage: PageFragment | null = null;

  @observable
  notFoundPage?: PageFragment;

  @observable
  statusCode? = 200;

  @observable
  tagsSet: Set<string> = new Set();

  constructor({ currentPage, notFoundPage }: IPage) {
    super();
    makeObservable(this);
    if (currentPage) {
      this.currentPage = currentPage;
      this.setTagsSet();
    }

    if (notFoundPage) {
      this.notFoundPage = notFoundPage;
    }
  }

  @action
  storePage = (page: PageFragment | null) => {
    this.currentPage = page;
    this.setTagsSet();
  };

  @action
  storeStatus = (statusCode: number) => (this.statusCode = statusCode);

  @action
  storeNotFoundPage = (page: PageFragment) => (this.notFoundPage = page);
}
