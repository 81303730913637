import { ICache } from "./cache";

export interface CursorLike<V, U> {
  current?: V[];
  ready: boolean;
  hasMore: () => boolean;
  next: () => Promise<V[]>;
  refresh: (opts?: U) => Promise<V[]>;
  cacheKey: () => string;
  options: () => U;
}

// T is the data response
// U is options for the query
// V is an array of data results
export class Cursor<T, U, V> implements CursorLike<V, U> {
  private _hasMore = true;
  current?: V[];
  ready = false;

  constructor(
    private _options: U,
    private loadFn: (opts: U) => Promise<T>,
    private nextFn: (resp: T, opts: U) => { opts?: Partial<U>; hasMore: boolean; newData: V[] },
    private _cacheKey: (opts: U) => string,
    private cache: Map<string, ICache>,
    private opts?: Partial<{ initialData: T; optsClean: (options: U) => U }>,
  ) {
    // Prime cache if initial data provided
    if (opts?.initialData) {
      this.prime(opts?.initialData);
    }

    // Check cache before issuing API call to prevent duplicate load of data
    else if (cache.has(this.cacheKey())) {
      const cacheData = cache.get(this.cacheKey());
      if (cacheData && cacheData.data) {
        this.next().then(() => cache.delete(this.cacheKey()));
      }
    }
  }

  options() {
    return this._options;
  }

  hasMore() {
    return this._hasMore;
  }

  cacheKey() {
    return this._cacheKey(this._options);
  }

  private async _load(cacheKey: string, opts: U) {
    try {
      const results = await this.loadFn(opts);
      if (typeof window === "undefined") {
        this.cache.set(cacheKey, { data: results });
      }
      return results;
    } catch (error) {
      if (typeof window === "undefined") {
        this.cache.set(cacheKey, { error: error.toString() });
      }
      throw error;
    }
  }

  refresh = async (options?: U) => {
    const optsCopy = this.opts?.optsClean?.(this._options) ?? { ...this._options, ...options };
    const cacheKey = this.cacheKey();

    const results = await this._load(cacheKey, optsCopy);

    const { opts, hasMore, newData } = this.nextFn(results, optsCopy);

    if (opts) {
      this._options = { ...optsCopy, ...opts };
    }

    this._hasMore = hasMore;

    this.current = [...newData];
    this.ready = true;

    return this.current;
  };

  next = async () => {
    const optsCopy = { ...this._options };
    const cacheKey = this.cacheKey();
    let results: T;
    const cachedData = this.cache.get(cacheKey);
    if (cachedData?.data) {
      results = cachedData.data;
    } else if (cachedData?.error) {
      throw cachedData.error;
    } else {
      results = await this._load(cacheKey, optsCopy);
    }
    const { opts, hasMore, newData } = this.nextFn(results, optsCopy);

    if (opts) {
      this._options = { ...optsCopy, ...opts };
    }

    this._hasMore = hasMore;

    this.current = [...(this.current || []), ...newData];
    this.ready = true;

    return this.current;
  };

  // We need prime since adding/removing things to the cache directly causes infinite loops.
  prime = async (results: T) => {
    const optsCopy = { ...this._options };
    const { opts, hasMore, newData } = this.nextFn(results, optsCopy);

    if (opts) {
      this._options = { ...optsCopy, ...opts };
    }

    this._hasMore = hasMore;

    this.current = [...(this.current || []), ...newData];
    this.ready = true;

    return this.current;
  };
}
