import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject, observer } from "mobx-react";
import { Fragment, useEffect, useMemo } from "react";

import { RemoteList } from "../../../../core/components/remote/RemoteList.component";
import { FeedBlockValueResolver, getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { lookup } from "@ihr-radioedit/inferno-core";
import type { PageBlockInterface } from "../../../../core/page-blocks/Block.component";
import { getDatasourceBySlug } from "@ihr-radioedit/inferno-core";
import { getDisplayHints } from "../../../../core/services/Sites.utils";
import { getCoastHints } from "@ihr-radioedit/inferno-core";
import { Container, LoadMoreFromCursor } from "../../../../core/ui";
import { Datasource } from "./Datasource.component";
import type { Store } from "@inferno/renderer-shared-core";
import { isFeedBlock } from "@inferno/renderer-shared-core";
const log = ILog.logger("DatasourceLoader");

interface DatasourceLoaderProps extends PageBlockInterface {
  store?: Store;
  last_media_id?: string | null;
  topic_title?: string | null;
}

export const DatasourceLoader = inject("store")(
  observer(({ store, block, isPrimary, topic_title }: DatasourceLoaderProps) => {
    if (!store) {
      return null;
    }
    const { site, cache, microsite } = store;

    if (!isFeedBlock(block)) {
      log.error("No Feed on block", block);
      return null;
    }

    // Decode block value
    const decoded = FeedBlockValueResolver.decode(block.value);
    if (isLeft(decoded)) {
      log.error("Could not decode feed block", getPaths(decoded), block);
      return null;
    }

    const { right: blockValue } = decoded;

    const feedId = blockValue.feed_id;
    if (!feedId) {
      log.error("No feed id or default feed setup!", block);
      return null;
    }

    if (!block.feed) {
      return null;
    }

    // Fetch all required data
    const cursor = getDatasourceBySlug(
      cache,
      { ...block.feed },
      feedId,
      block.id,
    )({
      slug: microsite?.index?.slug || site.index.slug,
      lookup,
    });
    const displayHints = getDisplayHints(block.tags || []);
    const coastHints = useMemo(() => getCoastHints(block.tags), [block.tags]);
    const feedClass = classnames(coastHints);

    // Call store block for tagging
    useEffect(() => {
      if (block && block.feed) {
        store.storeBlock({
          ...block,
          resolved: {
            value: blockValue,
            feed: block.feed,
            topic_title: topic_title || "",
          },
        });
      }
    });

    return (
      <Container className={feedClass} block={false}>
        <RemoteList
          cursor={cursor}
          showLoading={isPrimary}
          fallback={
            <Datasource
              title={blockValue.title || ""}
              content={block.feed.results}
              block={block}
              feedId={feedId}
              displayHints={displayHints}
            />
          }
        >
          {({ data, next, loading, hasNext }) => (
            <Fragment>
              <Datasource
                title={blockValue.title || ""}
                content={data}
                block={block}
                feedId={feedId}
                displayHints={displayHints}
              />
              {hasNext && displayHints.enableLoadMore ? (
                <LoadMoreFromCursor hasNext={hasNext} nextFn={next} loading={loading} sectionName="feed" />
              ) : null}
            </Fragment>
          )}
        </RemoteList>
      </Container>
    );
  }),
);
