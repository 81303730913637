import * as Webapi from "@ihr-radioedit/inferno-webapi";

export function isSiteSection(v: any): v is Webapi.UserConfigFragment {
  return !!(
    v &&
    (v.hasOwnProperty("general") ||
      v.hasOwnProperty("alert") ||
      v.hasOwnProperty("navigation") ||
      v.hasOwnProperty("design") ||
      v.hasOwnProperty("social") ||
      v.hasOwnProperty("contact") ||
      v.hasOwnProperty("partners") ||
      v.hasOwnProperty("ads") ||
      v.hasOwnProperty("analytics"))
  );
}
