export const SearchIcon = () => (
  <svg
    className="svg-icon icon-search"
    tabIndex={-1}
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    focusable="false"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle className="outline" cx="16" cy="16" r="15" fill="none" stroke="none" strokeWidth="2" />
    <path
      className="primary-path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.457 20.343a7.69 7.69 0 111.929-1.591l4.658 5.434a1.25 1.25 0 11-1.898 1.628l-4.69-5.471zm.829-6.652a4.69 4.69 0 11-9.381 0 4.69 4.69 0 019.38 0z"
      fill="none"
    />
  </svg>
);
