import { inject } from "mobx-react";

import { generateCatalogUrl, CatalogLegacyProps, QueryProps } from "@ihr-radioedit/inferno-core";
import { isCurrentlyPlayingTrackDetailFragment } from "../lib/guards";
import "./Catalog.style.scss";

export const CatalogLegacy = inject("store")(
  ({
    store,
    track,
    id,
    kind,
    description,
    related_id,
    query,
    isPodcastEmbed,
  }: { query?: QueryProps } & CatalogLegacyProps) => {
    if (!store) {
      return null;
    }

    const currentTrack = track && isCurrentlyPlayingTrackDetailFragment(track) ? track : track?.track;
    const catalogUrl = generateCatalogUrl(
      {
        national_site_url: store.env.NATIONAL_SITE_URL,
        node_env: store.env.NODE_ENV,
      },
      {
        track: currentTrack,
        id,
        related_id: related_id || "",
        kind,
        embed: true,
        query: {
          ...query,
          keyid: isPodcastEmbed ? store.env.PODCAST_EMBED_KEYID : store.request.query?.keyid,
        },
      },
    );

    if (catalogUrl) {
      const title = description || "Music Catalog";
      return (
        <div className="component-catalog-container">
          <iframe src={catalogUrl} title={title} />
        </div>
      );
    }
    return null;
  },
);

export default CatalogLegacy;
