import { zonedTimeToUtc } from "date-fns-tz";
import { isRight } from "fp-ts/Either";
import url from "url-parse";
import utf8 from "utf8";
import { PayloadResolver } from "@ihr-radioedit/inferno-core";
import { BlockBaseLegacy, matchPrefix, removePrefix } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { getParselyDate } from "@ihr-radioedit/inferno-core";
import { slugify } from "@ihr-radioedit/inferno-core";

import { sanitize } from "../../../utilities/sanitize";
import { isFeedResult, isItemBlock, isPublishRecord } from "../../../lib/guards";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { generateMetaTag, getOriginType, processMetaImage, setGeneralMetaTags } from "../../util";
import { setCacheTtl } from "../../../utilities/header";

export const coastArticleTemplateTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, env, request } = context;
  if (block && isItemBlock(block) && isFeedResult(block.item?.result) && isPublishRecord(block.item?.result.record)) {
    const content = block.item?.result.record;
    if (content) {
      const resolvedPayload = PayloadResolver.decode(content.payload);
      const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;

      const keywords: string[] = [];
      payload?.fields.keywords?.value?.forEach(value => {
        if (value) {
          keywords.push(removePrefix(/^\w+\//)(value));
        }
      });

      const { surrogateKeys } = state;
      if (surrogateKeys) {
        surrogateKeys.add(`content/${content.slug}`);
        surrogateKeys.add(`content/${decodeURIComponent(content.ref_id).replace("/", "")}`);
      }

      let title = payload?.fields.seo_title?.value || content.summary.title || "";

      if (title) {
        title = `${title} | ${site.sections.general?.name}`;
      }

      const description = content.summary.description || "";
      const image = formatImage(content.summary.image || "", env.IMAGE_HOST);

      const categories: string[] = [];
      const topics: string[] = [];
      content.subscription.forEach((item: Webapi.Subscription) => {
        topics.push(
          ...item.tags.filter(matchPrefix(/^collections\//)).map((tag: string) => slugify(removePrefix(/^\w+\//)(tag))),
        );
        categories.push(...item.tags.filter(matchPrefix(/^categories\//)).map(removePrefix(/^categories\//)));
      });

      const galleries = payload?.fields.blocks?.value?.filter((item: BlockBaseLegacy) => item.type === "gallery") || [];
      const galleryExists = !!galleries.length;
      const contentOrigin = removePrefix(/^brands\//)(payload?.targeting.publish_origin || "");

      let authorId = payload?.cuser?.split("/").pop() || "";
      const partner = payload?.ingestion.feed_content_id;
      if (partner) {
        authorId = partner;
      }
      if (payload?.ingestion.feed_vendor) {
        authorId = content.payload.feed_vendor;
      }

      const timezone = site.index.timeZone || "";
      const pubDate = zonedTimeToUtc(content.pub_start, timezone).getTime().toString();
      const contentId =
        typeof btoa !== "undefined"
          ? btoa(utf8.encode(decodeURIComponent(content.ref_id)))
          : Buffer.from(utf8.encode(decodeURIComponent(content.ref_id))).toString("base64");

      let { metadata } = state;
      metadata = setGeneralMetaTags(metadata, {
        titles: {
          default: title,
          social: payload?.fields.social_title?.value
            ? `${payload.fields.social_title.value} | ${site.sections.general?.name}`
            : title,
          parsely: "",
        },
        url: site.getShareUrl(request),
        image: { url: processMetaImage(image, context) },
        description,
        keywords,
        types: {
          openGraph: "article",
          parsely: "post",
        },
        twitter: {
          card: "summary_large_image",
        },
      });

      let canonicalUrl = payload?.canonical_url || "";
      if (canonicalUrl && !url(canonicalUrl).protocol) {
        canonicalUrl = `https:${canonicalUrl}`;
      }

      if (canonicalUrl) {
        canonicalUrl = canonicalUrl.replace("content", "article");
        metadata.set("canonical", { value: canonicalUrl, type: "link", rel: "canonical" });
        metadata.set(...generateMetaTag("name", "parsely-network-canonical", canonicalUrl));
      }

      metadata.set(...generateMetaTag("name", "parsely-pub-date", getParselyDate(content.pub_start)));

      if (content.summary.author) {
        metadata.set(...generateMetaTag("name", "parsely-author", content.summary.author));
      }

      if (block.eyebrow?.topic_name) {
        metadata.set(...generateMetaTag("name", "parsely-section", block.eyebrow.topic_name));
      }

      if (categories.length) {
        metadata.set(...generateMetaTag("name", "categories", categories.join(", ")));
      }

      if (topics.includes("coast-to-coast-am-article") && content.slug) {
        const ampUrl = `https://${site.getPrimaryDomain()}/alternate/amp/article/${content.slug}/`;
        metadata.set("amphtml", { value: ampUrl, type: "link", rel: "amphtml" });
      }

      const uniqueTopics = Array.from(new Set([...topics, ...categories])).map(item => slugify(item));

      return {
        ...state,
        surrogateKeys,
        ttls: {
          ...state.ttls,
          server: setCacheTtl(content.pub_start),
        },
        ads: {
          ...state.ads,
          keywords: keywords.map(item => slugify(item)),
          topics: uniqueTopics,
        } as AdTags,
        analytics: {
          ...state.analytics,
          view: {
            ...state.analytics?.view,
            asset: {
              name: sanitize(content.summary.title) || "",
              id: `content|${content.slug}`,
            },
            authorId,
            tags: keywords.map(item => slugify(item)),
            topics: uniqueTopics,
            photoGalleryExists: galleryExists.toString(),
            photoGalleryPageView: galleryExists.toString(),
            contentOrigin,
            contentOriginType: getOriginType(contentOrigin),
            contentId,
            pubDate,
          },
        } as AnalyticsTags,
        metadata,
      };
    }
  }

  return state;
};
