import { ManageCommon } from "./base-manager.abstract";

export abstract class OneTrustManage extends ManageCommon {
  abstract isOptOut(): boolean;
  abstract toggleInfoDisplay(e: any): void;
  abstract setOptOutConsent(from: "ClientNavigation" | "Load"): void;
  static cookieTokenName: string;
  static cookieIdName: string;
  static cookieProfileIdName: string;
  static cookieUserTypeName: string;
  static cookieEmailName: string;
  static coastConsentId: string;
  static localConsentId: string;
  static category: string;
  static categoryValue: string;
}
