import { inject } from "mobx-react";
import { useEffect, useRef } from "react";
import * as React from "react";

import { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { useCarousel } from "./CoastCarousel.hooks";
import "./CoastCarousel.style.scss";
import { CoastCarouselItem } from "./CoastCarouselItem.component";
import type { Store } from "@inferno/renderer-shared-core";

interface CoastCarouselProps {
  content: FeedResultFragment[];
  store?: Store;
  interval?: number;
}

export const CoastCarousel = inject("store")(({ content, store, interval = 10000 }: CoastCarouselProps) => {
  if (!store || !content) {
    return null;
  }
  const { currentSlide, next, goToSlide } = useCarousel();
  const contentRef: React.RefObject<HTMLUListElement> = useRef(null);
  const viewportRef: React.RefObject<HTMLElement> = useRef(null);
  const totalSlides = content.length;
  const contentStyle = useRef<React.CSSProperties>({});

  // auto slide will update currentSlide on every render at [interval] time (5s default)
  useEffect(() => {
    if (typeof window !== "undefined" && contentRef.current && viewportRef.current) {
      const timeoutId = window.setTimeout(() => {
        next(totalSlides);
      }, interval);
      return () => clearTimeout(timeoutId);
    }
  }, [interval, next, totalSlides]);

  // when currentSlide updates, move the slides
  // had to do it this way instead of using scrollLeft because Safari doesn't support smooth scrolling
  if (contentRef.current?.children) {
    const children = Array.from(contentRef?.current?.children) as HTMLElement[];
    contentStyle.current = {
      transform: `translateX(${children[currentSlide].offsetLeft * -1}px)`,
      transition: "all 0.5s ease",
    };
  }
  return (
    <div className="carousel-container">
      <section className="carousel-inner-container">
        <section className="carousel-viewport" ref={viewportRef}>
          <ul className="carousel-content" ref={contentRef} style={contentStyle.current}>
            {content.map((item, index) => (
              <li key={index} data-index={index}>
                <CoastCarouselItem item={item} />
              </li>
            ))}
          </ul>
        </section>
      </section>
      <section className="carousel-controls">
        {content.map((item, index) => (
          <button
            key={index}
            data-index={index}
            onClick={() => goToSlide(index)}
            data-current={currentSlide === index}
          />
        ))}
      </section>
    </div>
  );
});
