export type EventDate = {
  begin: number | null | undefined;
  enabled: boolean;
  end: number | null | undefined;
};

export const filterEventDates = (eventDates: EventDate[]) => {
  if (eventDates.length > 0) {
    const currentDate = new Date();
    return eventDates.filter(
      eventDate => (eventDate.begin ?? 0) >= currentDate.getTime() || currentDate.getTime() < (eventDate.end ?? 0),
    );
  }

  return eventDates;
};
