import { ILog } from "@ihr-radioedit/inferno-core";
import { isWindowDefined, type Store } from "@inferno/renderer-shared-core";

declare global {
  interface Window {
    hasRunExco: boolean;
  }
}

const provider = "exco";
const log = ILog.logger(`${provider}.ts`);
if (isWindowDefined()) {
  window.hasRunExco = false;
}

export const setupExco = (store: Store) => {
  // Ensure exco is included once and only once !!
  if (window.hasRunExco) {
    return;
  }

  const body = document.querySelector("body");

  if (
    body &&
    (store.page.currentPage?.path === "/content/<slug>/" || window.location.pathname.startsWith("/content/")) &&
    (store?.request.query?.vp === provider || store.site.contentVideoProvider === provider)
  ) {
    window.hasRunExco = true;
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.setAttribute("programmatic", "true");
    script.src = "https://player.ex.co/player/45d09673-d85d-49ca-82db-188758a6c4c0";
    script.onload = () => {
      store.onEmbedScriptAction.dispatch({ ready: true });
      log.debug(`${provider} loaded`);
    };

    script.onerror = (
      event: Event | string,
      source?: string,
      fileno?: number,
      columnNumber?: number,
      error?: Error,
    ) => {
      if (error) {
        log.error(`${provider} could not be loaded", error.message`);
      }
    };
    body.appendChild(script);
  }
};
