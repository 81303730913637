import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { getSdk, initSdkOpts, sdkReject } from "./sdk";

export const getContests = (query: Webapi.GetContestsQueryVariables, surrogateKeys: Set<string>, opts: initSdkOpts) => {
  const sdk = getSdk(opts);
  surrogateKeys.add("aptivada");
  return sdk ? sdk.GetContests.queryAsPromise(query).then(response => response?.data?.aptivada?.apps) : sdkReject();
};

export const getContest = (query: Webapi.GetContestQueryVariables, surrogateKeys: Set<string>, opts: initSdkOpts) => {
  const sdk = getSdk(opts);
  surrogateKeys.add("aptivada");
  return sdk ? sdk.GetContest.queryAsPromise(query).then(response => response?.data?.aptivada?.app) : sdkReject();
};
