import { useState, useCallback } from "react";

export function useCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = useCallback(
    (total = 0) => {
      if (currentSlide + 1 < total) {
        setCurrentSlide(currentSlide + 1);
      } else if (currentSlide + 1 >= total) {
        setCurrentSlide(0);
      }
    },
    [currentSlide],
  );

  return {
    currentSlide,
    goToSlide: setCurrentSlide,
    next,
  };
}
