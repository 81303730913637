export const EmailIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-email"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="primary-path"
      d="M20 5H4C2.9 5 2.01 5.9 2.01 7L2 17C2 18.1 2.9 19 4 19H20C21.1 19 22 18.1 22 17V7C22 5.9 21.1 5 20 5ZM19.6 9.25L12.53 13.67C12.21 13.87 11.79 13.87 11.47 13.67L4.4 9.25C4.15 9.09 4 8.82 4 8.53C4 7.86 4.73 7.46 5.3 7.81L12 12L18.7 7.81C19.27 7.46 20 7.86 20 8.53C20 8.82 19.85 9.09 19.6 9.25Z"
      fill="#27292D"
    />
  </svg>
);
