import i18n from "i18next";

import { slugify } from "@ihr-radioedit/inferno-core";

import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../util";

export const newsletterTags: Tagger = (state: Tags, context: TagContext) => {
  const { site } = context;

  const parselyTitle = i18n.t("newsletter_parsely_title", { station: site.sections.general?.name });
  const description = i18n.t("newsletter_description", { station: site.sections.general?.name });
  const keywords = [
    i18n.t("newsletter").toLowerCase(),
    site.sections.general?.name || "",
    i18n.t("email_newsletter"),
    i18n.t("subscribe_newsletter"),
  ];

  let { metadata } = state;
  metadata = setGeneralMetaTags(metadata, {
    titles: {
      default: `${parselyTitle} | ${site.sections.general?.name}`,
      parsely: parselyTitle,
      social: i18n.t("topsong_social_title", { station: site.sections.general?.name }),
    },
    description,
    keywords,
    types: {
      parsely: "sectionpage",
    },
  });

  metadata.set(...generateMetaTag("name", "parsely-section", "newsletter"));

  return {
    ...state,
    ads: {
      ...state.ads,
      keywords: keywords.map(item => slugify(item)),
    } as AdTags,
    analytics: {
      ...state.analytics,
      view: {
        ...state.analytics?.view,
        tags: keywords.map(item => slugify(item)),
      },
    } as AnalyticsTags,
    metadata,
  };
};
