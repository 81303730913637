import { BaseStoreDevice, device } from "../abstracts/store/base-device.abstract";
import { action, makeObservable, observable } from "mobx";

/** Add new members to Base Abstract */
export class StoreDevice extends BaseStoreDevice {
  @observable
  enableHeaderSearch: boolean | null = null;

  get isDesktop() {
    return this.deviceType === "desktop";
  }

  get isSafari() {
    return this._userAgent!.search(/safari/i) > -1 && !this.isChrome;
  }

  get isChrome() {
    return this._userAgent!.search(/chrome/i) > -1;
  }

  get deviceType(): device {
    return this.isMobile() ? "mobile" : this.isTablet() ? "tablet" : "desktop";
  }

  constructor(ua = "", navigator = null) {
    super();
    makeObservable(this);
    this._navigator = navigator;
    this._userAgent = ua;
  }

  @action
  refresh = (navigator: Navigator): void => {
    this._navigator = navigator;
    this._userAgent = navigator.userAgent;
    this.enableHeaderSearch = this.isDesktop && this.isSafari;
  };
}
