import loadable from "@loadable/component";

const noop = () => false;

/**
 * To add a new component for A/B testing add a new key to abBarrel with the pattern seen here
 * export const abBarrel = {
 *   localOnSiteStreaming: {
 *       A: () => loadable(() => import("../chrome/StaticPlayer.component")),
 *       B: () => loadable(() => import("../chrome/IHRPlayerBar.component")),
 *   }
 * };
 * */
export const abBarrel: { [key: string]: any } = {
  default: {
    A: () => noop(),
    B: () => noop(),
  },
  EditorialTopWidget: {
    A: () => noop(),
    B: () => loadable(() => import("../page-blocks/content/ContentPlayerWidgetLoader.component")),
  },
};

export const abTranslationMap: { [key: string]: any } = {};
