import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";
import { Children, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowIcon } from "../components/icons/ArrowIcon.component";
import { Button, ButtonKind } from "./Button.component";
import { COMPONENT_BREAKPOINTS } from "./constants";
interface CarouselProps {
  children: ReactNode;
  showControls?: boolean;
  minSlidesToShow?: number;
  maxSlidesToShow?: number;
  slidesToScroll?: number;
}

import styles from "./Carousel.module.scss";

export const Carousel = ({
  children,
  showControls = true,
  minSlidesToShow = 3,
  maxSlidesToShow = 5,
  slidesToScroll,
}: CarouselProps) => {
  const { t } = useTranslation();
  const childItems = Children.toArray(children);
  const [controlsVisible, setControlsVisible] = useState(showControls);
  const [slideCount, setSlideCount] = useState(minSlidesToShow);
  const [scrollDistance, setScrollDistance] = useState(minSlidesToShow);
  const [disabled, setDisabled] = useState({ previous: false, next: false });
  const active = useRef<number>(0);
  const slides = useRef<HTMLUListElement | null>(null);

  const rightOffset = 1;

  const navigate = useCallback(
    (e, direction) => {
      if (!slides.current) return;

      e.preventDefault();
      e.currentTarget.focus();

      let child = slides.current.children[active.current + Number(scrollDistance ?? 1) * direction] as HTMLDivElement;

      if (child === undefined) {
        child = (
          direction === 1 ? slides.current.lastElementChild : slides.current.firstElementChild
        ) as HTMLDivElement;
      }

      const left = child.offsetLeft - slides.current.offsetLeft;

      slides.current.scroll({ behavior: "smooth", left });
    },
    [scrollDistance],
  );

  useEffect(() => {
    if (!slides.current) return;

    const observer = new IntersectionObserver(
      entries => {
        const [slide] = Array.from(entries);

        if (!slides.current || !slide.rootBounds) return;
        if (slide.boundingClientRect.x <= slide.rootBounds.x) {
          const element = slide.isIntersecting ? slide.target : slide.target.nextElementSibling;
          active.current = Array.from(slides.current.children).indexOf(element as HTMLDivElement);
        }

        const scrolled = slides.current.scrollLeft;

        // Subtracting a few pixels from the scroll width
        // to account for padding / margins in some layouts
        setDisabled({
          next: scrolled + slides.current.clientWidth >= slides.current.scrollWidth - 3,
          previous: scrolled <= 3,
        });
      },
      {
        root: slides.current,
        rootMargin: `0px ${rightOffset}px 0px 0px`,
        threshold: [0, 1],
      },
    );

    for (const target of Array.from(slides!.current!.children)) {
      observer.observe(target);
    }

    return () => observer.disconnect();
  }, [slides, showControls, slideCount]);

  const handleWindowResize = () => {
    const windowSize = window.innerWidth;
    let slideCountForViewport = minSlidesToShow;

    slideCountForViewport =
      windowSize <= COMPONENT_BREAKPOINTS.carouselSlidesBreakpoint ? minSlidesToShow : maxSlidesToShow;

    setSlideCount(slideCountForViewport);
  };

  useEffect(() => {
    if (isWindowDefined()) {
      const childrenCount = slides.current!.children.length;

      if (showControls) {
        setControlsVisible(childrenCount <= slideCount ? false : true);
      }

      if (slideCount >= childrenCount) {
        setSlideCount(childrenCount);
      }

      if (!!slidesToScroll) {
        setScrollDistance(slidesToScroll);
      }
    }
  }, [showControls, slideCount, slidesToScroll]);

  useEffect(() => {
    if (isWindowDefined()) {
      handleWindowResize();
      resizeWatcher.onWidthChange.subscribe(handleWindowResize);
      return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!children) {
    return null;
  }

  return (
    <section className={styles.carouselWrapper}>
      {!!controlsVisible && (
        <ul className={styles.carouselControls}>
          <li>
            <Button
              disabled={disabled.previous}
              click={e => navigate(e, -1)}
              kind={ButtonKind.SECONDARY}
              size="small"
              className={styles.slideButton}
              label={t("slide_content_left")}
            >
              <ArrowIcon direction="left" />
            </Button>
          </li>
          <li>
            <Button
              disabled={disabled.next}
              click={e => navigate(e, 1)}
              kind={ButtonKind.SECONDARY}
              size="small"
              className={styles.slideButton}
              label={t("slide_content_right")}
            >
              <ArrowIcon direction="right" />
            </Button>
          </li>
        </ul>
      )}
      <ul className={styles.carousel} tabIndex={0} ref={slides}>
        {childItems.map((child, index) => (
          <li className={styles.carouselSlide} key={index} style={{ minWidth: `calc((100% - 2rem) / ${slideCount})` }}>
            {child}
          </li>
        ))}
      </ul>
    </section>
  );
};
