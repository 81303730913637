import { inject, observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";

import { ILog } from "@ihr-radioedit/inferno-core";
import type { BlockFragment, PageFragment, SitesSchedule } from "@ihr-radioedit/inferno-webapi";
import { ClickSection, SITE_THEME, type Store } from "@inferno/renderer-shared-core";
import { coastBlockComponents } from "../../sites/coast/registry-block";
import { embedBlockComponents } from "../../sites/embed/registry-block";
import { localBlockComponents, localMicrositeComponents } from "../../sites/local/registry-block";
import { premiereBlockComponents, premiereMicrositeComponents } from "../../sites/premiere/registry-block";
import { ErrorBoundary } from "../components/ErrorBoundary.component";
import { formatRecursive } from "../lib/format";

const log = ILog.logger("Block.component.tsx");

export type CompliantComponent<P> = ((props: P) => JSX.Element | null) | typeof React.Component;

export interface PageBlockRegistry {
  [type: string]: (block?: BlockFragment) => CompliantComponent<PageBlockInterface>;
}

export interface PageBlockInterface {
  store?: Store;
  block: BlockFragment;
  isPrimary: boolean;
  page?: PageFragment; // page is a context variable, the blocks can't be tested if this is required.
  params?: ReturnType<typeof useParams>;
  context?: object;
}

interface BlockComponentProps {
  page: PageFragment;
  block: BlockFragment;
  params?: ReturnType<typeof useParams>;
  store?: Store;
  blockComponents?: PageBlockRegistry;
  now: number;
  primaryBlockId?: string;
  siteTheme: SITE_THEME;
}

const getBlockRegistry = (siteTheme: SITE_THEME, store: Store, blockComponents?: PageBlockRegistry) => {
  switch (siteTheme) {
    case SITE_THEME.PREMIERE:
      return store.microsite ? premiereMicrositeComponents : premiereBlockComponents;
    case SITE_THEME.COAST:
      return coastBlockComponents;
    case SITE_THEME.EMBED:
      return embedBlockComponents;
    case SITE_THEME.PREVIEW:
    case SITE_THEME.STORYBOOK:
      return blockComponents;
    case SITE_THEME.DEFAULT:
    default:
      return store.microsite ? localMicrositeComponents : localBlockComponents;
  }
};

export function isScheduled(now: number, schedule?: Pick<SitesSchedule, "begin" | "end"> | null) {
  if (!schedule) {
    return true;
  }
  const begin = schedule.begin || now;
  const end = schedule.end || Number.MAX_SAFE_INTEGER;
  return begin <= now && end > now;
}

export const Block = inject("store")(
  observer(({ page, block, params, blockComponents, now, store, primaryBlockId, siteTheme }: BlockComponentProps) => {
    if (!isScheduled(now, block.schedule) || !store) {
      return null;
    }
    const context = {
      ...params,
      topic: params && params.topic ? params.topic : null,
      slug: params ? params.slug : null,
      primary_domain: store.site.getPrimaryDomain(),
    };

    const interpolatedBlock = formatRecursive(block, {
      ...context,
      config: store.site.sections,
      index: store.site.index,
    }) as BlockFragment;

    const isPrimary = primaryBlockId === block.id;

    const component =
      (getBlockRegistry(siteTheme, store, blockComponents) as any)?.[block.type]?.(block) || UnknownBlock;

    const renderedBlock = React.createElement<PageBlockInterface>(component, {
      block: interpolatedBlock,
      isPrimary,
      page,
      params,
      context,
    });

    return (
      <ErrorBoundary location={`block-${block.type}`}>
        <ClickSection.Provider value={block.type}>{renderedBlock}</ClickSection.Provider>
      </ErrorBoundary>
    );
  }),
);

const UnknownBlock = ({ block: { type } }: PageBlockInterface) => {
  log.debug(`Unknown block: ${type}`);
  return <div className={`component-${type}`} />;
};
