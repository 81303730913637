import { isRight } from "fp-ts/Either";
import url from "url-parse";

import { PayloadResolver, removePrefix } from "@ihr-radioedit/inferno-core";
import { TagRef } from "@ihr-radioedit/inferno-webapi";
import { slugify } from "@ihr-radioedit/inferno-core";

import { isFeedResult, isItemBlock, isPublishRecord } from "../../../lib/guards";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../../util";
import { coastArticleTemplateTags } from "./coast-article";

export const coastGuestTemplateTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block } = context;
  if (block && isItemBlock(block) && isFeedResult(block.item?.result) && isPublishRecord(block.item?.result.record)) {
    const content = block.item?.result.record;
    if (content) {
      const resolvedPayload = PayloadResolver.decode(content.payload);
      const payload = isRight(resolvedPayload) ? resolvedPayload.right : null;

      state = coastArticleTemplateTags(state, context);
      const keywords = site.sections?.general?.keywords?.map((i: TagRef) => removePrefix(/^\w+\//)(i.id)) || [];

      let { metadata } = state;
      metadata = setGeneralMetaTags(metadata, {
        keywords,
      });

      let canonicalUrl = payload?.canonical_url || "";
      if (canonicalUrl && !url(canonicalUrl).protocol) {
        canonicalUrl = `https:${canonicalUrl}`;
      }

      if (canonicalUrl) {
        canonicalUrl = canonicalUrl.replace("content", "guest");
        metadata.set("canonical", { value: canonicalUrl, type: "link", rel: "canonical" });
        metadata.set(...generateMetaTag("name", "parsely-network-canonical", canonicalUrl));
      }

      if (content.slug) {
        const ampUrl = `https://${site.getPrimaryDomain()}/alternate/amp/guest/${content.slug}/`;
        metadata.set("amphtml", { value: ampUrl, type: "link", rel: "amphtml" });
      }

      return {
        ...state,
        ads: {
          ...state.ads,
          keywords: keywords.map(item => slugify(item)),
        } as AdTags,
        analytics: {
          ...state.analytics,
          view: {
            ...state.analytics?.view,
            tags: keywords.map(item => slugify(item)),
          },
        } as AnalyticsTags,
        metadata,
      };
    }
  }

  return state;
};
