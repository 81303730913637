import loadable from "@loadable/component";
import { inject } from "mobx-react";
import { Fragment, useMemo } from "react";

import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { ILog } from "@ihr-radioedit/inferno-core";
import { getCoastHints } from "@ihr-radioedit/inferno-core";
import { Heading } from "../../../../core/ui";
import { CoastFeedItemType, getCoastFeedType } from "../../lib/coastFeed";
import type { Store } from "@inferno/renderer-shared-core";
import { isPublishRecord } from "@inferno/renderer-shared-core";

import "./CoastFeed.style.scss";

const CoastFeedShowsTonightLoader = loadable(() => import("./CoastFeedTonightShowsLoader.component"));
const CoastFeedInTheNewsHomepage = loadable(() => import("./CoastFeedInTheNewsHomepage.component"));
const CoastFeedLastNightShowItem = loadable(() => import("./CoastFeedLastNightShowItem.component"));
const CoastFeedArtBellVaultShows = loadable(() => import("./CoastFeedArtBellVaultShows.component"));
const CoastFeedSomewhereInTimeShows = loadable(() => import("./CoastFeedSomewhereInTimeShows.component"));
const CoastFeedUpcomingShowsPreview = loadable(() => import("./CoastFeedUpcomingShowsPreview.component"));
const CoastFeedUpcomingShows = loadable(() => import("./CoastFeedUpcomingShows.component"));
const CoastShowPlaceholder = loadable(() => import("../CoastShowPlaceholder.component"));
const CoastFeedInTheNews = loadable(() => import("./CoastFeedInTheNews.component"));
const CoastFeedArticles = loadable(() => import("./CoastFeedArticles.component"));
const CoastFeedGuests = loadable(() => import("./CoastFeedGuests.component"));
const CoastFeedPhotos = loadable(() => import("./CoastFeedPhotos.component"));
const CoastFeedItem = loadable(() => import("./CoastFeedItem.component"));
const CoastFeedShows = loadable(() => import("./CoastFeedShows.component"));
const CoastFeedCalendar = loadable(() => import("./CoastFeedCalendar.component"));

const log = ILog.logger("Coast Feed");

interface CoastFeedProps {
  content: FeedResultFragment[] | null;
  block: BlockFragment;
  title?: string;
  store?: Store;
}

export const CoastFeed = inject("store")(({ content, block, title, store }: CoastFeedProps) => {
  const hints = useMemo(() => getCoastHints(block.tags), [block.tags]);
  const feedType = getCoastFeedType(hints);

  if (!hints.includes("coast-feed-no-header")) {
    switch (feedType) {
      case CoastFeedItemType.IN_THE_NEWS_HOMEPAGE:
        return <CoastFeedInTheNewsHomepage content={content} title={title || "In the News"} />;
      case CoastFeedItemType.IN_THE_NEWS:
        return <CoastFeedInTheNews block={block} content={content} title={title || "In the News"} />;
      case CoastFeedItemType.SHOW:
      case CoastFeedItemType.CLASSIC_SHOWS:
        return <CoastFeedShows block={block} content={content} title={title || "Shows"} type={feedType} />;
      case CoastFeedItemType.SOMEWHERE_IN_TIME:
        return <CoastFeedSomewhereInTimeShows block={block} content={content} title={title || "Somewhere in Time"} />;
      case CoastFeedItemType.UPCOMING_SHOWS_PREVIEW:
        return (
          <CoastFeedUpcomingShowsPreview
            block={block}
            content={content}
            title={title || "Upcoming Shows"}
            type={feedType}
          />
        );
      case CoastFeedItemType.UPCOMING_SHOWS:
        return (
          <CoastFeedUpcomingShows block={block} content={content} title={title || "Upcoming Shows"} type={feedType} />
        );
      case CoastFeedItemType.SHOW_LAST_NIGHT:
        if (!store || !content?.length || !isPublishRecord(content[0]?.record)) {
          return <CoastShowPlaceholder />;
        }

        const { request } = store;
        const { record } = content[0];

        return !request.path.includes(record?.slug || "") ? (
          <CoastFeedLastNightShowItem title={title || "Last Night"} item={content[0]} />
        ) : (
          <Fragment />
        );
      case CoastFeedItemType.SHOW_TONIGHT:
        return <CoastFeedShowsTonightLoader content={content} title={title || "Tonight"} />;
      case CoastFeedItemType.ART_BELL_VAULT:
        return <CoastFeedArtBellVaultShows block={block} content={content} title={title || "Art Bell Vault"} />;
      case CoastFeedItemType.ARTICLE:
        return <CoastFeedArticles block={block} content={content} title={title || "Articles"} />;
      case CoastFeedItemType.PHOTO:
        return <CoastFeedPhotos block={block} content={content} title={title || "Photos"} />;
      case CoastFeedItemType.GUEST:
        return <CoastFeedGuests block={block} content={content} title={title || "Guests"} />;
      case CoastFeedItemType.CALENDAR:
        return <CoastFeedCalendar block={block} content={content} title={title || ""} />;
      default:
        log.debug(
          `No match for Coast feed type: ${feedType}. Hints: ${hints.join(", ")}`,
          block.tags,
          block.type,
          block.value,
          block.ordinal,
        );
    }
  }

  return content?.length && feedType ? (
    <Fragment>
      {title ? <Heading level={2}>{title}</Heading> : null}

      <div className="feed-cards">
        {content.map(item => (
          <CoastFeedItem key={item.id} item={item} feedType={feedType} />
        ))}
      </div>
    </Fragment>
  ) : null;
});
