import { type ParsedQs } from "qs";

import { ILog, noop } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { type Block_SitesItemBlock_Fragment, type PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";

const log = ILog.logger("content-video-block.ts");

declare global {
  interface Window {
    iHeartExCoPlayer: ExCoPlayerInstance | null;
    ExCoPlayer: {
      connect: () => ExCoPlayerInstance;
    };
  }

  interface ExCoPlayerInstance {
    init: () => void;
    destroy: () => void;
  }
}

type ShouldInsertVideoParams = {
  contentBlocksSize: number;
  mainAudioBlockIndex: number;
  mainContentBlockIndex: number;
  videoContentBlockEnabled: string | boolean;
  videoPageBlockEnabled: boolean;
  videoProvider: string;
  videoProviderConfig: object | null;
};

type GetContentVideoBlocksParams = {
  block: Block_SitesItemBlock_Fragment;
  content: PublishRecordFragment;
  privacyOptOut?: boolean;
  provider: Webapi.Maybe<string> | undefined;
  queryProps?: ParsedQs;
};

const getVideoProviderConfig: { [key: string]: any } = {
  /**
   * Dailymotion
   * 1.) initial page load - script dynamically injected, dynamically inject target container, provider bootstraps (widget rendered / 1 page view)
   * 2.) user navigates away from article via spa - no provider destroy method needed
   * 3.) user navigates back to article via spa - wait for new meta tag action to get fired, script dynamically injected, dynamically inject target container, provider bootstraps (widget rendered / 1 page view)
   */
  dailymotion: {
    meta: {
      scriptInjection: "dynamic",
      requires: ["h1"],
      params: {
        class: "dm-player",
        playerId: "xqjfq",
        sort: "relevance",
        keywordsSelector: "h1",
        fallbackPlaylist: "x85xjc",
        owners:
          "gqmagazine,essencemagazine,condenasttraveler,grunge,thelist,usweekly,thehollywoodreporter,whatstrending,goalcast,celebritywire,HollywoodLife,Esquire,vanityfair,variety-pmc,DeadlineHollywood,LifeStoriesByGoalcast,intouchmagazine,NickiSwift,wired,whatif,thedodosite,Vogue,harpersbazaar,cosmopolitanUSA,allure,elleUSA,epicurious,afarmedia,delish,ArchitecturalDigest,mashed,eater,bon-appetit,narcityusa,goodhousekeeping,lifeandstylemag,twistedfood,TheThrillistGroupNineMedia,designinsider,INSIDER,Fuse,billboard,Genius,rollingstone,verge,newyorker,veuer,ACCUWEATHER,Fortune,time,forbes,entrepreneur,Cheddar,sportsgrid,sportsillustrated,playmakerhq,sbnation,mensjournal",
        mute: "true",
        referrerPolicy: "no-referrer-when-downgrade",
      },
    },
    script: () => ({
      async: true,
      src: "https://statics.dmcdn.net/c/dm-ce.min.js",
      type: "text/javascript",
    }),
    html: ({ dailymotion }: { dailymotion: any }) =>
      `
        <div class=${dailymotion.class} playerId=${dailymotion.playerId} sort=${dailymotion.sort} keywordsSelector=${dailymotion.keywordsSelector}
          fallbackPlaylist=${dailymotion.fallbackPlaylist} owners=${dailymotion.owners} mute=${dailymotion.mute} referrerPolicy=${dailymotion.referrerPolicy}>
        </div>
      `,
    mobileScript: null,
    mobileHtml: null,
    load: null,
    destroy: null,
    refresh: null,
  },
  /**
   * Exco
   * 1.) initial page load - single exco script preloaded, after injecting target container, we need to connect/init exco player manually (widget rendered / 1 page view)
   * 2.) user navigates away from article via spa - destroy exco player (widget destroyed )
   * 3.) user navigates back to article via spa - wait for meta tag action to get fired, inject target container, we init exco player manually (widget rendered / 1 page view)
   */
  exco: {
    meta: {
      scriptInjection: "preloaded",
      requires: ["none"],
      params: {
        id: "45d09673-d85d-49ca-82db-188758a6c4c0",
      },
    },
    script: noop,
    html: ({ exco }: { exco: any }) => `<div id=${exco.id}></div>`,
    load: () => {
      if (!window.ExCoPlayer) {
        log.error("ExCoPlayer Script not found");
        return;
      }

      if (!window.iHeartExCoPlayer) {
        try {
          window.iHeartExCoPlayer = window.ExCoPlayer?.connect();
          log.info("ExCoPlayer connected");
        } catch (error) {
          log.error("ExCoPlayer cannot be connected", error);
          return;
        }
      }

      if (window.iHeartExCoPlayer) {
        try {
          window.iHeartExCoPlayer?.init();
          log.info("ExCoPlayer initialized");
        } catch (err) {
          log.error("ExCoPlayer cannot be initialized", err);
        }
      }
    },
    destroy: () => {
      if (window.iHeartExCoPlayer) {
        window.iHeartExCoPlayer?.destroy();
        log.info("ExCoPlayer destroyed");
      } else {
        log.error("ExCoPlayer cannot be destroyed");
      }
    },
    mobileScript: null,
    mobileHtml: null,
    refresh: null,
  },
  /**
   * Outbrain
   * 1.) initial page load - single outbrain script preloaded, after injecting target container, outbrain bootstraps (widget rendered / 1 page view)
   * 2.) user navigates away from article via spa - no outbrain destroy method needed
   * 3.) user navigates back to article via spa - wait for meta tag action to get fired, inject target container, we refresh outbrain player manually (widget rendered / 1 page view)
   */
  outbrain: {
    meta: {
      scriptInjection: "preloaded",
      requires: ["metaTags"],
      params: {
        class: "OUTBRAIN",
        id: "SVD_1",
      },
    },
    script: noop,
    html: ({ metaOgUrl, outbrain }: { metaOgUrl: string | undefined; outbrain: any }) =>
      `<div class=${outbrain.class} data-src=${metaOgUrl} data-widget-id=${outbrain.id}></div>`,
    refresh: ({ metaOgUrl, outbrain }: { metaOgUrl: string | undefined; outbrain: any }) => {
      const widgetContainer = document.querySelector(`[data-widget-id=${outbrain.id}]`);
      if (widgetContainer && !widgetContainer.getAttribute("data-ob-mark")) {
        log.info("Refreshing Outbrain widget", { metaOgUrl });
        if (window.OBR && window.OBR.extern && typeof window.OBR.extern.renderSpaWidgets === "function") {
          window.OBR.extern.renderSpaWidgets(metaOgUrl ?? "");
        }
      }
    },
    mobileScript: null,
    mobileHtml: null,
    load: null,
    destroy: null,
  },
  /**
   * Sellwild
   * 1.) initial page load - script dynamically injected, dynamically inject target container, provider bootstraps (widget rendered / 1 page view)
   * 2.) user navigates away from article via spa - no provider destroy method needed
   * 3.) user navigates back to article via spa - wait for new meta tag action to get fired, script dynamically injected, dynamically inject target container, provider bootstraps (widget rendered / 1 page view)
   */
  sellwild: {
    meta: {
      scriptInjection: "dynamic",
      requires: [],
      params: {},
    },
    script: () => ({
      defer: true,
      src: "https://widget.sellwild.com/iheart/iheart-iheart-ab.js",
      type: "text/javascript",
      width: "",
      height: "",
    }),
    html: () => `<sellwild-ab></sellwild-ab>`,
    mobileScript: () => ({
      defer: true,
      src: "https://widget.sellwild.com/iheart/iheart-iheart-sellwild-tv-shorts.js",
      type: "text/javascript",
      width: "auto",
      height: "auto",
    }),
    mobileHtml: () => `<sellwild-shorts></sellwild-shorts>`,
    load: null,
    destroy: null,
    refresh: null,
  },
  /**
   * Stn
   * 1.) initial page load - script dynamically injected, dynamically inject target container, provider bootstraps (widget rendered / 1 page view)
   * 2.) user navigates away from article via spa - no provider destroy method needed
   * 3.) user navigates back to article via spa - wait for new meta tag action to get fired, script dynamically injected, dynamically inject target container, provider bootstraps (widget rendered / 1 page view)
   */
  stn: {
    meta: {
      scriptInjection: "dynamic",
      requires: ["metaTags"],
      params: {
        id: "mqp370nq",
      },
    },
    script: ({ stn }: { stn: any }) => ({
      async: true,
      "data-stn-player": stn.id,
      src: `https://embed.sendtonews.com/player3/embedcode.js?fk=${stn.id}`,
      type: "text/javascript",
    }),
    html: ({ stn }: { stn: any }) => `<div data-stn-player=${stn.id}></div>`,
    mobileScript: null,
    mobileHtml: null,
    load: null,
    destroy: null,
    refresh: null,
  },
};

export function getContentVideoBlock({
  block: pageBlock,
  content,
  provider,
  queryProps,
  privacyOptOut,
}: GetContentVideoBlocksParams) {
  /** During the initial (server-side) rendering, we do not have access to the session to verify privacy opt-out status.
   * As a result, this condition will always return false on the SERVER render;
   * which leads to the rendering of updated content blocks if video has been enabled for this site/content article.
   * However, upon client-side hydration, we will call this function again and display default content blocks if the user has opted out.
   **/
  if (privacyOptOut) {
    log.info("User has opted out of video content");
    return null;
  }

  // Site Level - Partners Tab
  const videoProvider = ((queryProps?.vp as string) || provider) ?? "none";

  // Page Level - None

  // Page Level Item Block - Master Site Level - Pages Tab - (Micro Site not impl for MVP)
  const videoPageBlockEnabled = !!pageBlock.tags?.includes("display-hints/enable-video");

  // Content Level - Options Tab
  const contentPayload = content.payload;
  const videoContentBlockEnabled = (queryProps?.ve as string) || contentPayload?.include_video_monetization;

  // Content Level Blocks - Main Tab
  const contentBlocks = Array.isArray(contentPayload?.blocks) ? [...contentPayload.blocks] : [];
  const contentBlocksSize = contentBlocks.length;

  const videoProviderConfig = getVideoProviderConfig[videoProvider] ?? null;

  // Find Main Audio / Content Blocks
  let mainAudioBlockIndex = -1;
  let mainContentBlockIndex = -1;
  for (const [index, block] of contentBlocks.entries()) {
    if (block?.type === "embed" && block?.embed_type === "rich" && block?.url.includes("iheart.com")) {
      mainAudioBlockIndex = index;
      continue;
    }
    if (block.type === "html") {
      mainContentBlockIndex = index;
      break;
    }
  }

  const insertVideo = shouldInsertVideo({
    contentBlocksSize,
    mainAudioBlockIndex,
    mainContentBlockIndex,
    videoContentBlockEnabled,
    videoPageBlockEnabled,
    videoProvider,
    videoProviderConfig,
  });

  if (!insertVideo) {
    return null;
  }

  const updatedContentBlocks = [
    {
      attributes_provider: {
        ...videoProviderConfig,
      },
      provider: videoProvider,
      type: "embedAssetLegacy",
    },
    ...contentBlocks.slice(mainContentBlockIndex),
  ];

  const mainAudioBlock = mainAudioBlockIndex !== -1 ? contentBlocks[mainAudioBlockIndex] : null;

  if (mainAudioBlock) {
    updatedContentBlocks.push(mainAudioBlock);
  }

  return updatedContentBlocks;
}

function shouldInsertVideo(params: ShouldInsertVideoParams) {
  const setOutput = (status: "valid" | "invalid") => {
    log.debug(`Content video requirements is ${status}:`, { ...params });
    return status === "valid" ? true : false;
  };

  if (
    params.videoProvider === "none" ||
    !params.videoProvider ||
    !params.videoContentBlockEnabled ||
    !params.videoPageBlockEnabled ||
    !params.videoProviderConfig ||
    params.contentBlocksSize === 1 || // Skip inserting video if there is one content block, as it is likely to be an embed or an HTML block containing an image
    params.mainContentBlockIndex < 0
  ) {
    return setOutput("invalid");
  }

  return setOutput("valid");
}
