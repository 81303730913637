import axios, { AxiosResponse } from "axios";
import getUnixTime from "date-fns/getUnixTime";
import { v4 as uuidv4 } from "uuid";

import type { UploadEvent } from "@inferno/renderer-shared-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("Igloo Uploader");

export class IglooUploader {
  constructor(private store: Store) {}

  async trackPageView(event: UploadEvent): Promise<boolean> {
    const { env } = this.store;
    const { pageName, action, url, data } = event;
    // the event timestamp fields are defined as milleseconds since epoch
    const timestamp = getUnixTime(new Date()) * 1000;

    // format data to conform with igloo event scheme then POST to API
    // reverse the base64 encoding of the contentId prior to upload to igloo - IHRAL-6655
    let { contentId } = data.view;
    if (contentId) {
      contentId = typeof atob !== "undefined" ? atob(contentId) : new Buffer(contentId, "base64").toString("utf-8");
    }

    const payload = {
      pageName,
      action,
      view: {
        ...data.view,
        contentId,
        pageURL: url,
        tags: data.view.tags?.join(", ") || "",
      },
      device: {
        ...data.device,
        callId: uuidv4(),
        // @ts-ignore this is a non standard fallback.
        gpcEnabled: !!navigator.globalPrivacyControl,
      },
      user: {
        ...data.user,
      },
      event: {
        capturedTimestamp: timestamp,
        loggedTimestamp: timestamp,
      },
      share: { ...data.share },
      station: { ...data.station },
      item: { ...data.item },
    };

    try {
      if (!env.IGLOO_EVENT_HOST) {
        log.debug("Igloo API url not configured");
        return Promise.reject(false);
      }

      return axios
        .post(env.IGLOO_EVENT_HOST, payload, {
          timeout: parseInt(env.REQUEST_TIMEOUT || "0", 10),
        })
        .then((response: AxiosResponse) => {
          // the igloo API returns a 202 on success
          return Promise.resolve(response.status === 202);
        });
    } catch (e) {
      log.error(e.message);
      return Promise.reject(false);
    }
  }
}

export const setupIglooHandler = (store: Store) => {
  const igloo = new IglooUploader(store);
  store.onUploadAction.subscribe((event: UploadEvent) => {
    igloo.trackPageView(event).then(result => log.debug("Igloo Uploaded: ", result));
  });
};
