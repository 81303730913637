import { ILog } from "@ihr-radioedit/inferno-core";
import type { Maybe, PubsubPublishRecord } from "@ihr-radioedit/inferno-webapi";
import { DataSkriveManage, isWindowDefined } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("dataskrive.ts");

type DataSkrivePayload = {
  token: string;
  analyticsToken: string;
};

/** Add new members to Base Abstract */
export class DataSkriveManager extends DataSkriveManage {
  private url = "https://cdn.dataskrive.com/scripts/ds_ga_click.js";
  private id_data = "ds_data";
  private id_script = "dsm_script";
  protected _library?: Promise<void>;

  constructor(protected store: Store) {
    super();
  }

  getCredentials(lookup: PubsubPublishRecord["lookup"]) {
    const dataSkriveCredentials: {
      token?: string;
      analyticsToken?: string;
    } = {};
    lookup?.forEach((el: Maybe<string>) => {
      if (el?.includes("custom:dataskriveContentToken")) {
        dataSkriveCredentials.token = el.split(":")[2];
      }
      if (el?.includes("custom:dataskriveAnalyticsToken")) {
        dataSkriveCredentials.analyticsToken = el.split(":")[2];
      }
    });
    return dataSkriveCredentials;
  }

  init({ enabled, lookup }: { enabled: Maybe<boolean> | undefined; lookup: PubsubPublishRecord["lookup"] }) {
    if (isWindowDefined() && enabled) {
      const { token, analyticsToken } = this.getCredentials(lookup);
      if (!token || !analyticsToken) {
        log.debug("Dataskrive credentials are missing", { token, analyticsToken });
        return;
      }
      this.trackPageView({ token, analyticsToken });
    } else if (!enabled) {
      log.debug("Dataskrive is disabled");
    }
    return this;
  }

  loadLibrary(_payload: DataSkrivePayload) {
    this._library = new Promise((_resolve, _reject) => {
      log.debug("Loading Dataskrive library");
      const spanEl = document.createElement("span");
      spanEl.setAttribute("data-dsid-content", _payload.token);
      spanEl.setAttribute("data-dsid-analytics", _payload.analyticsToken);
      spanEl.setAttribute("id", this.id_data);

      const scriptEl = document.createElement("script");
      scriptEl.setAttribute("id", this.id_script);
      scriptEl.async = true;
      scriptEl.type = "text/javascript";
      scriptEl.src = this.url;

      scriptEl.onload = () => {
        _resolve();
      };
      scriptEl.onerror = e => {
        _reject(e);
      };

      document.body.appendChild(spanEl);
      document.body.appendChild(scriptEl);
      _resolve();
    });

    return this._library;
  }

  destroyLibrary() {
    log.debug("Destroy Dataskrive library");
    const spanEl = document.getElementById(this.id_data);
    if (spanEl) {
      spanEl.remove();
    }
    const scriptEl = document.getElementById(this.id_script);
    if (scriptEl) {
      scriptEl.remove();
    }
  }

  trackPageView(payload: DataSkrivePayload) {
    log.debug("Trigger Dataskrive page view");
    this.loadLibrary(payload)
      .then(() => this.trigger())
      .catch((e: Error) => log.debug(e.message));
  }

  trigger = () => {
    log.debug("Dataskrive Script Loaded");
  };
}
