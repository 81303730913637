import loadable from "@loadable/component";
import type { Component } from "react";

export type FunctionalComponent = (props: any) => JSX.Element | null;
export type ContentBlockMap = Record<string, typeof Component | FunctionalComponent>;

export const RESPONSIVE_EMBED_MIN_WIDTH = 200;

// Article template is the last thing that is using this (all of the Locals content though)
export const blockMapLegacy: ContentBlockMap = {
  html: loadable(() => import("./HtmlEmbedLegacy.component")),
  embed: loadable(() => import("./EmbedLegacy.component"), { ssr: false }),
  embedAssetLegacy: loadable(() => import("./EmbedAssetLegacy.component")),
  asset: loadable(() => import("./AssetLegacy.component")),
  catalog: loadable(() => import("./CatalogLegacy.component")),
  contest: loadable(() => import("./ContestEmbedLegacyLoader.component")),
  gallery: loadable(() => import("./GalleryLegacy.component")),
  iheartcontent: loadable(() => import("./IHeartContentLegacy.component")),
  text: loadable(() => import("./HtmlEmbedLegacy.component")),
  ampasset: loadable(() => import("./AmpAssetLegacy.component")),
  ampcatalog: loadable(() => import("./AmpCatalogLegacy.component")),
  ampiheartcontent: loadable(() => import("./AmpIHeartContentLegacy.component")),
  ampembed: loadable(() => import("./AmpEmbedLegacy.component")),
  ampcontest: loadable(() => import("./AmpContestEmbedLegacyLoader.component")),
  ampgallery: loadable(() => import("./AmpGalleryLegacy.component")),
};

export const blockMap: ContentBlockMap = {
  html: loadable(() => import("./HtmlEmbed.component")),
  embed: loadable(() => import("./Embed.component"), { ssr: false }),
  asset: loadable(() => import("./Asset.component")),
  catalog: loadable(() => import("./Catalog.component")),
  content: loadable(() => import("./IHeartContent.component")),
  gallery: loadable(() => import("./Gallery.component")),
  text: loadable(() => import("./HtmlEmbed.component")),
  plain: loadable(() => import("./PlainText.component")),
  ampasset: loadable(() => import("./AmpAsset.component")),
  ampcatalog: loadable(() => import("./AmpCatalog.component")),
  ampembed: loadable(() => import("./AmpEmbed.component")),
  ampgallery: loadable(() => import("./AmpGallery.component")),
};
