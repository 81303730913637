const PATH_SEP = "/";

/**
 * Suffix match for strings.
 */
function hasSuffix(path: string): boolean {
  return path.toString().slice(-1) === PATH_SEP;
}

/**
 * Prefix match for strings.
 */
function hasPrefix(path: string): boolean {
  return path.toString().slice(0, 1) === PATH_SEP;
}

/**
 * Ensure that a path has no trailing separator.
 */
export function removeTrailingSep(path: string, removeRoot?: boolean): string {
  removeRoot = !!removeRoot;

  if (!hasSuffix(path)) {
    return path;
  }

  const withoutSep = path.toString().slice(0, -1);
  return removeRoot ? withoutSep : withoutSep || PATH_SEP;
}

/**
 * Ensure that a path has no leading separator.
 */
export function removeLeadingSep(path: string): string {
  if (!hasPrefix(path)) {
    return path;
  }
  return path.toString().slice(1);
}

/**
 * Path join (dedupes separators, etc).
 */
export function joinPath(...parts: string[]): string {
  // Remove trailing separator from all but the last
  // Remove leading separator from everything but the first.
  let path = "";

  parts
    .filter(p => !!p)
    .forEach((p, i, arr) => {
      if (i < arr.length - 1) {
        p = removeTrailingSep(p, false);
      }

      if (i > 0 && p) {
        p = removeLeadingSep(p);
        if (!hasSuffix(path)) {
          path += "/";
        }
      }

      path += p;
    });

  return path;
}

function imageUrl(bucket: string, id: string, imageHost: string) {
  const url = joinPath(bucket, id);
  return joinPath(imageHost, "v3", "re", url);
}

const assetPrefix = "asset://";
export function formatImage(asset: string, imageHost: string) {
  if (asset && asset.startsWith(assetPrefix)) {
    // Looks like an asset ref to me
    const segments = asset.slice(assetPrefix.length).split("/");
    return `https://${imageUrl(segments[0], segments[1], imageHost)}`;
  }
  return asset;
}
