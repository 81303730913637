import { toJS } from "mobx";

import { ILog } from "@ihr-radioedit/inferno-core";

import { AdobeManage } from "../abstracts/manager/adobe-manager.abstract";
import {
  AnalyticsEvent,
  AnalyticsEventAction,
  CcpaStatus,
  ContestEvent,
  ShareEvent,
  SharePlatform,
  UploadEvent,
} from "../abstracts/store/base-store.abstract";
import { isIHRSession } from "../auth/backend";
import { getDeviceId } from "../services/variant-test";
import { Store } from "../stores";
import { AnalyticsDevice, AnalyticsTags, ContestExitType } from "./tagging-type";

const log = ILog.logger("AdobeManager/adobe.ts");

declare global {
  interface Window {
    _satellite: {
      pageBottom: () => void;
      track: (eventName: string) => void;
      getVisitorId: () => {
        getMarketingCloudVisitorID: () => string | null;
        [key: string]: any;
      };
    };
    analyticsData: {
      action?: AnalyticsEventAction;
      events: {
        active: (AnalyticsTags & ClientData) | Record<string, unknown>;
        [key: string]: any;
      };
      view?: { [key: string]: { [key: string]: any } | string | null };
      contest?: {
        exitType: ContestExitType;
      };
      share?: {
        platform: SharePlatform;
      };
    };
  }
}

interface ClientData {
  device: DeviceData;
  user: UserData | Record<string, unknown>;
}

interface DeviceData {
  appSessionId: string | null;
  appVersion: string | null;
  dayOfWeek: string;
  hourOfDay: string;
  language: string | null;
  sessionNumber: string | null;
  timezone: string;
  userAgent: string | null;
  id?: string | null;
  callId?: string | null;
  host?: string | null;
  subhost?: string | null;
  path?: string | null;
}

interface RegistrationData {
  type: string | null;
  gender: string | null;
  birthYear: number | null;
  market: string | null;
  country: string | null;
  zip: string | null;
}

interface UserData {
  profileId: string | null;
  registration: RegistrationData | null;
  genreSelected: string | null;
  isTrialEligible: string | null;
  previousSubscriptionTier: string | null;
  skuPromotionType: string | null;
  subscriptionTier: string | null;
  privacyOptOut?: string;
  abTestGroup?: string[];
  visitorId?: string | null;
}

const getDeviceData = (device?: AnalyticsDevice, deviceId?: string): DeviceData => {
  const now = new Date();
  return {
    ...(device || {}),
    id: deviceId || "",
    appSessionId: null,
    appVersion: null,
    dayOfWeek: now.getDay().toString(),
    hourOfDay: now.getHours().toString(),
    language: navigator ? navigator.language : null,
    sessionNumber: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userAgent: navigator ? navigator.userAgent : null,
  };
};

/** Add new members to Base Abstract */
export class AdobeManager extends AdobeManage {
  protected _library?: Promise<void>;
  protected analyticsSnapshot = "";
  protected launchLibId = "adobe-launch-lib";
  protected ccpaStatus?: string;

  constructor(protected store: Store) {
    super();
    // Check if the current site is configured for Adobe analytics
    const { site, env, session } = store;
    if (!site.sections.analytics?.adobe_library) {
      log.debug("Current site is not configured for adobe analytics");
      return;
    } else if (!env.ADOBE_LAUNCH_LIBRARY) {
      log.debug("Adobe library url not configured");
      return;
    }

    // Subscribe to click events and fire Adobe call
    store.onShareAction.subscribe((event: ShareEvent) => {
      this.trackShareAction(event);
    });

    store.onAnalyticsAction.subscribe((event: AnalyticsEvent) => {
      this.trackClickAction(event);
    });

    store.onContestAction.subscribe((event: ContestEvent) => {
      this.trackContestAction(event);
    });

    if (session.currentSession) {
      this.ccpaStatus = store.getCcpaStatus();
      session.currentSession.onStatusChanged.sub(() => {
        this.ccpaStatus = store.getCcpaStatus();
      });
    }

    store.adobe = this;
  }

  // Ensure library is loaded once and only once
  loadLibrary() {
    if (this._library) {
      return this._library;
    }

    const { site, env } = this.store;
    if (!site.sections.analytics?.adobe_library) {
      this._library = Promise.reject(new Error("Adobe analytics not configured"));
      return this._library;
    } else if (!env.ADOBE_LAUNCH_LIBRARY) {
      this._library = Promise.reject(new Error("Adobe library url not configured"));
      return this._library;
    }

    this._library = new Promise<void>((resolve, reject) => {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      if (head && env.ADOBE_LAUNCH_LIBRARY) {
        script.id = this.launchLibId;
        script.async = true;
        script.defer = true;
        script.src = env.ADOBE_LAUNCH_LIBRARY;
        script.onload = () => {
          log.debug("Adobe library loaded");
          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug("Adobe library could not be loaded", error.message);
          }
          reject(new Error("Adobe library could not be loaded"));
        };

        head.appendChild(script);
      } else {
        log.debug(`Adobe library could not be loaded: head? ${!!head} configured? ${env.ADOBE_LAUNCH_LIBRARY}`);
        reject(new Error("Adobe library could not be loaded"));
      }
    });

    return this._library;
  }

  trackPageView() {
    log.debug("Trigger DTM page view\n", JSON.stringify(window.analyticsData));
    this.loadLibrary()
      .then(() => this.collectData())
      .catch((e: Error) => log.debug(e.message));
  }

  trackClickAction(event: AnalyticsEvent) {
    this.loadLibrary()
      .then(() => {
        const format = this.store.site.index.facets.find(f => f.includes("formats/"));
        const { pageName, sectionName, context, action } = event;
        const { analytics } = this.store.tags;
        if (window._satellite) {
          window.analyticsData.events.action = action;
          window.analyticsData.events.location = `${pageName}|${sectionName}|${context}`.toLowerCase();
          window.analyticsData.view = {
            stationCallLetter: this.store.site.index.slug,
            stationFormat: format?.replace("formats/", "") || null,
            stationMarket: this.store.site.index.market?.replace("markets/", "") || null,
          };
          log.debug("Trigger DTM click action");
          window._satellite.track("trackAction");

          // after the adobe call has been made dispatch the analytics data to be uploaded to igloo
          const shareAsset = { ...analytics?.view.asset };
          this.store.onUploadAction.dispatch({
            sectionName,
            pageName,
            action,
            url: typeof window !== "undefined" ? window.location.href : "",
            data: {
              ...analytics,
              device: getDeviceData(analytics?.device, getDeviceId()),
              station: {
                asset: {
                  ...shareAsset,
                },
              },
              item: {
                asset: {
                  ...shareAsset,
                },
              },
            },
          } as UploadEvent);
        }
      })
      .catch((e: Error) => log.debug(e.message));
  }

  trackContestAction(event: ContestEvent) {
    this.loadLibrary()
      .then(() => {
        const format = this.store.site.index.facets.find(f => f.includes("formats/"));
        const { pageName, action, id, name, exitType } = event;
        if (window._satellite) {
          window.analyticsData.events.action = action;
          window.analyticsData.view = {
            pageName,
            asset: {
              id: `contest|${id}`,
              name,
            },
            stationCallLetter: this.store.site.index.slug,
            stationFormat: format?.replace("formats/", "") || null,
            stationMarket: this.store.site.index.market?.replace("markets/", "") || null,
          };

          if (exitType) {
            window.analyticsData.contest = {
              exitType,
            };
          }

          log.debug("Trigger DTM contest action");
          window._satellite.track("trackAction");
        }
      })
      .catch((e: Error) => log.debug(e.message));
  }

  trackShareAction(event: ShareEvent) {
    this.loadLibrary()
      .then(() => {
        const { pageName, sectionName, action, url, share } = event;
        if (this.store.tags.analytics) {
          const { view, device } = this.store.tags.analytics;
          if (window._satellite) {
            window.analyticsData.events.action = action;
            window.analyticsData.share = share;
            const shareData = {
              contentId: view.contentId,
              asset: { ...view.asset },
              authorId: view.authorId,
              pubDate: view.pubDate,
              contentOrigin: view.contentOrigin,
              contentOriginType: view.contentOriginType,
            };

            window.analyticsData.view = shareData;
            log.debug("Trigger DTM share action");
            window._satellite.track("trackAction");

            this.store.onUploadAction.dispatch({
              sectionName,
              pageName,
              action,
              url,
              data: {
                share: { ...share },
                view: { ...shareData },
                device: getDeviceData(device, getDeviceId()),
                station: {
                  asset: {
                    id: shareData.asset.id,
                    name: shareData.asset.name,
                  },
                },
              },
            } as UploadEvent);
          }
        }
      })
      .catch((e: Error) => log.debug(e.message));
  }

  collectData() {
    const { analytics } = this.store.tags;

    if (analytics) {
      const { session } = this.store;
      const deviceData = getDeviceData(analytics.device, getDeviceId());
      let userData: UserData = {
        profileId: null,
        registration: null,
        genreSelected: null,
        isTrialEligible: null,
        previousSubscriptionTier: null,
        skuPromotionType: null,
        subscriptionTier: null,
        privacyOptOut: this.ccpaStatus === CcpaStatus.OptOut ? "true" : "false",
        abTestGroup: [],
        visitorId: null,
      };

      if (session?.currentSession?.state) {
        const { testGroups } = this.store;
        if (isIHRSession(session.currentSession.state) && session.currentSession.state.authenticated) {
          const { profile } = session.currentSession.state;
          if (profile) {
            const registration: RegistrationData =
              this.ccpaStatus === CcpaStatus.OptOut
                ? {
                    type: profile.accountType || null,
                    gender: null,
                    birthYear: null,
                    market: null,
                    country: null,
                    zip: null,
                  }
                : {
                    type: profile.accountType || null,
                    gender: profile.gender || null,
                    birthYear: profile.birthYear || null,
                    market: profile.marketName || null,
                    country: null,
                    zip: profile.zipCode || null,
                  };

            userData = {
              ...userData,
              profileId: profile.profileId ? profile.profileId.toString() : null,
              registration,
            };

            if (profile.sessionId) {
              deviceData.appSessionId = profile.sessionId;
            }
          }
        }

        if (testGroups) {
          userData = {
            ...userData,
            abTestGroup: Object.entries({ ...testGroups?.groups }).map(([k, v]) => `${k}|${v}`),
          };
        }
      }

      if (window._satellite.getVisitorId()) {
        userData = {
          ...userData,
          visitorId: window._satellite.getVisitorId().getMarketingCloudVisitorID(),
        };
      }

      const clientData = {
        device: deviceData,
        user: userData,
      };

      window.analyticsData = toJS({
        events: {
          action: "page_view",
          active: { ...analytics, ...clientData },
        },
      });

      this.trigger();

      // Dynamic Beacon Call
      if (window.deferAnalyticsVendorBeacons && window.invokeAnalyticsVendorBeacons) {
        window.invokeAnalyticsVendorBeacons({
          ccpa: this.ccpaStatus === CcpaStatus.OptOut,
        });
      }

      // after the adobe call has been made dispatch the analytics data to be uploaded to igloo
      this.store.onUploadAction.dispatch({
        sectionName: "",
        pageName: analytics.pageName,
        action: "page_view",
        url: typeof window !== "undefined" ? window.location.href : "",
        data: {
          ...analytics,
          ...clientData,
        },
      });
    }
  }

  trigger = () => {
    if (window._satellite) {
      try {
        (window.setImmediate || window.setTimeout)(() => {
          const callAnalyticsData = JSON.stringify(window.analyticsData);
          if (this.analyticsSnapshot !== callAnalyticsData) {
            this.analyticsSnapshot = callAnalyticsData;
            log.debug("Trigger DTM call", this.analyticsSnapshot);
            window._satellite.track("trackPage");
          }
        });
      } catch (err) {
        log.error("failed to load DTM", err.message);
      }
    }
  };
}
