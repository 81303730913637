import type {
  AptivadaAppFragment,
  BlockFragment,
  CalendarEventFragment,
  ChartV2Fragment,
  Eyebrow,
  GetMicrositeByQueryQuery,
  GetSiteByQueryQuery,
  NavigationMicrositeFragment,
} from "@ihr-radioedit/inferno-webapi";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import * as io from "io-ts";
import { isSiteSection } from "../guards/is-site-section";
import { ResolvedFeed, ResolvedTitle } from "../lib/datasource";
import { ILog } from "../lib/logging";
import { OnAirScheduleBlockResolved } from "../lib/onair";
import { RequestProps } from "../lib/request";
import { ResolvedChartListBlock } from "../services/Charts";
import { isObjectEmpty } from "../utils/is-object-empty";
import { SubscriptionArrayResolver } from "./PubSub.types";
import { MaybeStringArrayResolver, MaybeStringResolver } from "./util.types";

const log = ILog.logger("Sites.types");

export const ImageRefResolver = io.type({
  id: io.string,
  bucket: io.string,
});
export type ImageRef = io.TypeOf<typeof ImageRefResolver>;

export const CatalogRefResolver = io.type({
  id: io.string,
  kind: io.string,
  country: io.string,
});
export type CatalogRef = io.TypeOf<typeof CatalogRefResolver>;

export const ScheduleResolver = io.partial({
  begin: io.number,
  end: io.number,
});
export type Schedule = io.TypeOf<typeof ScheduleResolver>;

const TargetResolver = io.type({
  tags: MaybeStringArrayResolver,
});
export type Target = io.TypeOf<typeof TargetResolver>;

export const CollectionQuerySubscriptionResolver = io.partial({
  subscription: SubscriptionArrayResolver,
  tagset: io.string,
  limit: io.number,
  offset: io.number,
});
export type CollectionQuerySubscription = io.TypeOf<typeof CollectionQuerySubscriptionResolver>;

export const HeaderBlockValueResolver = io.partial({
  background_image: io.union([io.string, io.null]),
  background_color: io.union([io.string, io.null]),
  title: io.union([io.string, io.null]),
  subtitle: io.union([io.string, io.null]),
});
export type HeaderBlockValue = io.TypeOf<typeof HeaderBlockValueResolver>;

export const CalendarBlockValueResolver = io.partial({
  category: io.union([io.string, io.null]),
  limit: io.number,
  title: io.union([io.string, io.null]),
  schedule: io.union([ScheduleResolver, io.null]),
});
export type CalendarBlockValue = io.TypeOf<typeof CalendarBlockValueResolver>;

export const PageBlockLinkResolver = io.partial({
  label: io.string,
  ref: io.string,
  target: io.string,
  type: io.string,
});

export type PageBlockLinkValue = io.TypeOf<typeof PageBlockLinkResolver>;

export const FeedBlockValueResolver = io.partial({
  feed_id: io.string,
  feed_index: io.number,
  title: io.union([io.string, io.null]),
  size: io.number,
  link: io.union([io.array(PageBlockLinkResolver), io.UnknownArray, io.null, io.undefined]),
});
export type FeedBlockValue = io.TypeOf<typeof FeedBlockValueResolver>;

export const ItemBlockValueResolver = io.partial({
  feed_id: io.string,
  feed_index: io.number,
  title: io.union([io.string, io.null]),
  ad_split: io.union([io.number, io.null]),
  position: io.union([io.string, io.null]),
});
export type ItemBlockValue = io.TypeOf<typeof ItemBlockValueResolver>;

export const ContentBlockValueResolver = io.partial({
  id: io.string,
  schedule: io.union([ScheduleResolver, io.null]),
});
export type ContentBlockValue = io.TypeOf<typeof ContentBlockValueResolver>;

export const HtmlBlockValueResolver = io.type({
  content: io.string,
});
export type HtmlBlockValue = io.TypeOf<typeof HtmlBlockValueResolver>;

export const TwitterBlockValueResolver = io.type({
  handle: io.union([io.string, io.null]),
  use_site_handle: io.union([io.boolean, io.null]),
});
export type TwitterBlockValue = io.TypeOf<typeof TwitterBlockValueResolver>;

export const TitleBlockValueResolver = io.partial({
  title: io.union([io.string, io.null]),
  description: io.union([io.string, io.null]),
});
export type TitleBlockValue = io.TypeOf<typeof TitleBlockValueResolver>;

export const RecommendationBlockValueResolver = io.type({
  widget_id: io.string,
});
export type RecommendationBlockValue = io.TypeOf<typeof RecommendationBlockValueResolver>;

export const ShowResolver = io.type({
  _id: io.string,
  slug: io.string,
  ownBrand: io.string,
  configByLookup: io.type({
    common: io.partial({
      branding: io.partial({
        name: io.string,
        logo: io.partial({
          asset: io.partial({
            href: io.string,
          }),
        }),
      }),
    }),
  }),
});

export type Show = io.TypeOf<typeof ShowResolver>;

export const MeetTheShowPersonalityResolver = io.type({
  title: io.string,
  link: io.type({
    urls: io.type({
      web: io.string,
    }),
  }),
  image: io.string,
});
export type MeetTheShowPersonality = io.TypeOf<typeof MeetTheShowPersonalityResolver>;

export const HowToListenBlockResolver = io.type({
  showName: io.string,
  tabs: io.type({
    list: io.type({
      display: io.boolean,
      showMap: io.boolean,
      label: io.string,
    }),
    map: io.type({
      showTotalCount: io.boolean,
      label: io.string,
    }),
    online: io.type({
      display: io.boolean,
      label: io.string,
    }),
  }),
  clustering: io.boolean,
  defaultTab: io.string,
  initCoords: io.type({
    lat: io.number,
    lng: io.number,
  }),
  geolocation: io.string,
  startZoom: io.number,
  notFoundMessage: io.union([io.string, io.null]),
  mapStyle: io.union([io.string, io.null]),
});
export type HowToListenBlock = io.TypeOf<typeof HowToListenBlockResolver>;

// TODO - just replace this with BlockFragment throughout the app
export type BlockInterface = BlockFragment;
export type ResolvedBlockData =
  | AptivadaAppFragment
  | CalendarEventFragment
  | CalendarEventFragment[]
  | OnAirScheduleBlockResolved
  | ResolvedFeed
  | ResolvedTitle
  | ChartV2Fragment
  | ResolvedChartListBlock;
export type ResolvedBlockInterface = BlockFragment & {
  eyebrow?: Eyebrow;
  resolved?: ResolvedBlockData;
};

export const AdsResolver = io.intersection([
  io.type({
    facebook_ad_track_pixel_id: io.union([io.string, io.null]),
    facebook_ad_track_pixel_switch: io.union([io.boolean, io.null]),
    header_bidding: io.union([io.boolean, io.null]),
    interstitial_switch: io.union([io.boolean, io.null]),
  }),
  io.partial({
    dfp_switch: io.union([io.boolean, io.null]),
    simplifi_cid: io.union([io.string, io.null]),
    simplifi_segment: io.union([io.string, io.null]),
    simplifi_sifi_tuid: io.union([io.string, io.null]),
    simplifi_switch: io.union([io.boolean, io.null]),
    tronc_switch: io.union([io.boolean, io.null]),
    yieldmo_switch: io.union([io.boolean, io.null]),
  }),
]);

export const HideResolver = io.partial({
  startDate: io.union([io.number, io.null]),
  endDate: io.union([io.number, io.null]),
  redirectTo: io.union([io.string, io.null]),
});
export type Hide = io.TypeOf<typeof HideResolver>;

export const VideoBlockResolver = io.partial({
  title: MaybeStringResolver,
  groupid: MaybeStringResolver,
  episodeid: MaybeStringResolver,
});
export type VideoBlock = io.TypeOf<typeof VideoBlockResolver>;

export const VideoPlaylistBlockResolver = io.partial({
  year: io.string,
  month: io.string,
  day: io.string,
});
export type VideoPlaylistBlock = io.TypeOf<typeof VideoPlaylistBlockResolver>;

export class Site {
  constructor(
    public site: GetSiteByQueryQuery,
    public micrositeReferences: NavigationMicrositeFragment[] = [],
    public surrogateKeys: string[] = [],
  ) {
    if (!this.sections) {
      throw new Error("No Site Config");
    }
    if (!isSiteSection(this.sections)) {
      const errorMessage = `Section did not validate!`;
      log.error(errorMessage);
    }

    // Backfill general.name from summary
    if (this.sections.general && !this.sections.general.name) {
      this.sections.general.name = this.index.summary.name;
    }

    if (!this.index.stream) {
      this.index.stream = {} as Webapi.SiteConfigFragment["stream"];
    }
  }

  get contentVideoProvider() {
    return this.sections.partners?.video_monetization_provider;
  }

  get index() {
    if (!this.site.sites.find) {
      throw new Error("No IndexRecord");
    }
    return this.site.sites.find;
  }

  get sections() {
    if (!this.config.sections) {
      throw new Error("No Site Config");
    }
    return this.config.sections;
  }

  get config() {
    const config = this.index.configByLookup;
    if (!config) {
      log.debug(`No Config Record for slug: ${this.index.slug}`);
      throw new Error(`No Config Record on Index: ${this.index._id} for slug: ${this.index.slug}`);
    }
    return config;
  }

  // checks override by slug
  private streamNotActiveBySlug() {
    const streamActiveByBrandOverrideEnabled = this.index.stream;

    if (isObjectEmpty(streamActiveByBrandOverrideEnabled)) {
      return true;
    }

    return streamActiveByBrandOverrideEnabled?.isActive === "false";
  }

  // checks override by brand
  private streamNotActiveByBrand() {
    const streamActiveByBrandOverrideEnabled =
      this.index.liveConfig?.sections?.design?.ihr_embed_player_bar_stream_override;
    const streamActiveByBrandOverrideRecordEnabled =
      this.index.liveConfig?.sections?.design?.ihr_embed_player_bar_stream_override?.record;
    const streamActiveByBrandOverrideRecordAmpEnabled =
      this.index.liveConfig?.sections?.design?.ihr_embed_player_bar_stream_override?.record?.amp;

    if (
      isObjectEmpty(streamActiveByBrandOverrideEnabled) &&
      isObjectEmpty(streamActiveByBrandOverrideRecordEnabled) &&
      isObjectEmpty(streamActiveByBrandOverrideRecordAmpEnabled)
    ) {
      return { isActive: null };
    }

    return streamActiveByBrandOverrideRecordAmpEnabled?.isActive === false ? { isActive: false } : { isActive: true };
  }

  get playerIsNotActive() {
    const brandResult = this.streamNotActiveByBrand();
    if (brandResult.isActive === null || brandResult.isActive === false) {
      return this.streamNotActiveBySlug();
    }
    return false;
  }

  get routes() {
    return this.config.routes;
  }

  get integration() {
    return this.index.integration;
  }

  get defaultShows() {
    return this.sections.general?.default_shows || [];
  }

  get micrositeRefs() {
    return this.micrositeReferences;
  }

  getStreamId() {
    return this.index.stream?.id?.toString();
  }

  get recentlyPlayedEnabled() {
    return !!this.index.stream?.recentlyPlayedEnabled;
  }

  getPrimaryDomain() {
    // Get primary domain for local station
    return this.index.canonicalHostname;
  }

  getShareUrl(request: RequestProps, path?: string) {
    return `${request.protocol.replace(":", "")}://${this.getPrimaryDomain()}${path || request.path}`;
  }

  getMicrosite(brand: string) {
    return this.micrositeReferences.find(({ ownBrand }) => ownBrand === brand);
  }
}

export class Microsite {
  constructor(public microsite: GetMicrositeByQueryQuery) {
    if (!this.config.sections) {
      throw new Error("No Microsite Config");
    }
  }

  get sections() {
    if (!this.config.sections) {
      throw new Error("No Microsite Config");
    }
    return this.config.sections;
  }

  get index() {
    return this.microsite.sites.find?.inboundRef;
  }

  get config() {
    const config = this.index?.configByLookup;
    if (!config) {
      log.debug(`No Config Record for slug: ${this.index?.slug}`);
      throw new Error(`No Config Record on Index: ${this.index?._id} for slug: ${this.index?.slug}`);
    }
    return config;
  }

  getPrimaryDomain() {
    return this.index?.inboundKeys?.canonicalHostname || "";
  }

  getShareUrl(request: RequestProps) {
    const primaryDomain = this.getPrimaryDomain();
    if (primaryDomain) {
      return `${request.protocol.replace(":", "")}://${primaryDomain}${request.path}`;
    }
    return "";
  }
}
