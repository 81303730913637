import { FunctionComponentElement, isValidElement } from "react";
import { createPortal } from "react-dom";
import { isWindowDefined } from "@inferno/renderer-shared-core";

export const AppTrayInjector = ({ children }: { children: FunctionComponentElement<any> }) => {
  if (isWindowDefined()) {
    return createPortal(
      <>{isValidElement(children) ? children : null}</>,
      document.querySelector(".component-apptray")!,
    );
  }

  return null;
};
