import { getCoastBlockType } from ".";

export enum CoastGenericBlockType {
  COAST_ZONE = "coast-zone",
  FEATURED = "featured",
  SIGNUP = "signup",
}

const CoastHintsToGenericBlockType = {
  "coast-zone": CoastGenericBlockType.COAST_ZONE,
  "coast-featured": CoastGenericBlockType.FEATURED,
  "coast-signup": CoastGenericBlockType.SIGNUP,
};

export const getCoastGenericBlockType = (hints: string[]) => getCoastBlockType(hints, CoastHintsToGenericBlockType);
