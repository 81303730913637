import type { GetSitemapContentQueryVariables } from "@ihr-radioedit/inferno-webapi";
import { getSdk, sdkOpts } from "./sdk";

const sdk = getSdk(sdkOpts());

export const getHistoricalContent = (query: GetSitemapContentQueryVariables) =>
  sdk!.GetHistoricalContent.queryAsPromise(query).then(
    r => r.data?.pubsub?.query || { items: [], page: { nextToken: null } },
  );

export const getSitemapContent = (query: GetSitemapContentQueryVariables) =>
  sdk!.GetSitemapContent.queryAsPromise(query).then(r => r.data?.pubsub?.query?.items || []);
