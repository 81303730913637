import { Sizes, SrcSet } from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";

export interface ResponsiveImageProps {
  src: string;
  alt: string;
  srcset: SrcSet[];
  sizes?: Sizes[];
  placeholder?: string;
  placeholderSrcset?: SrcSet[];
  initialWidth?: number;
  initialHeight?: number;
  lazy?: boolean;
}

export function ResponsiveImage(props: ResponsiveImageProps) {
  const { lazy = true } = props;
  const srcset: string[] = [];
  const sizes: string[] = [];
  const placeholderSrcset: string[] = [];
  props.srcset.forEach(item => {
    srcset.push(`${item.url} ${item.descriptor}`);
  });
  if (props.sizes) {
    props.sizes.forEach(item => {
      sizes.push(`${item.media} ${item.size}`);
    });
  }
  if (props.placeholderSrcset) {
    props.placeholderSrcset.forEach(item => {
      placeholderSrcset.push(`${item.url} ${item.descriptor}`);
    });
  }

  let dimensions = {};
  if (props.initialWidth && props.initialHeight) {
    dimensions = {
      width: props.initialWidth,
      height: props.initialHeight,
    };
  }

  const imgSrc = lazy ? props.placeholder || placeholderImage : props.src;
  const imgSrcSet = lazy ? placeholderSrcset.join(",") : srcset.join(",");

  return (
    <img
      src={imgSrc}
      data-src={lazy ? props.src : null}
      data-srcset={lazy ? srcset.join(",") : null}
      sizes={sizes.join(",")}
      alt={props.alt}
      className={lazy ? "lazyload" : ""}
      srcSet={imgSrcSet}
      {...dimensions}
    />
  );
}
