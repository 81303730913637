import i18next from "i18next";
import { inject, observer } from "mobx-react";
import { Fragment, Component, SyntheticEvent } from "react";
import { Translation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

import { base64Decode } from "../../lib/utilities";
import { ILog } from "@ihr-radioedit/inferno-core";
import { getRecipient, getRecipientSubscriptions, getStation, sendSubscriptionLists } from "../../services/PostUp";
import { Button, Container, Spinner } from "../../ui";
import { ButtonKind } from "../../ui";
import type { PageBlockInterface } from "../Block.component";
import "./Newsletter.style.scss";
import { Subscribe } from "./Subscribe.component";
import { PostupListSubscriptionStatus } from "@ihr-radioedit/inferno-webapi";

import type { Store } from "@inferno/renderer-shared-core";
import type {
  PostupStation,
  PostupListSubscriptionCreateInput,
  PostupStationList,
  PostupRecipient,
  PostupListSubscription,
} from "@ihr-radioedit/inferno-webapi";

const log = ILog.logger("Unsubscribe");

interface UnsubscribeProps extends PageBlockInterface {
  store?: Store;
}

interface UnsubscribeState {
  unsubscribed: boolean;
  email: string;
  stationLists?: PostupStation | null;
  subscriber?: PostupRecipient[] | null;
  subscriberLists?: PostupListSubscription[] | null;
}

@inject("store")
@observer
export class Unsubscribe extends Component<UnsubscribeProps, UnsubscribeState> {
  private saveInProgress = false;
  mounted = false;

  constructor(props: UnsubscribeProps) {
    super(props);

    this.state = {
      unsubscribed: false,
      email: "",
    };
  }

  async componentDidMount() {
    this.mounted = true;
    if (!this.props.store) {
      return;
    }

    const { site, request, api_base_uri } = this.props.store;
    const base_uri = api_base_uri || "/api/v4";

    let email = "";
    if (request) {
      email = request && request.query ? base64Decode(request.query.u || "") : "";
    }

    if (site && api_base_uri) {
      const stationData = await getStation(site.index.slug, base_uri);

      let subscriberLists: PostupListSubscription[] = [];
      let unsubscribed: boolean = this.state.unsubscribed;

      if (email) {
        const subscriber = await getRecipient({
          variables: {
            slug: site.index.slug,
            emailAddress: email,
          },
          base_uri,
        });

        if (subscriber) {
          const listData = await getRecipientSubscriptions({
            variables: {
              slug: site.index.slug,
              emailAddress: email,
            },
            base_uri,
          });

          if (listData) {
            subscriberLists = listData.map(list => {
              if (list) {
                list.status = PostupListSubscriptionStatus.Unsub;
              }
              return list as PostupListSubscription;
            });

            const input: PostupListSubscriptionCreateInput[] = subscriberLists.map(
              list => list as PostupListSubscriptionCreateInput,
            );
            const result = await sendSubscriptionLists({ variables: { input }, base_uri });

            if (result) {
              unsubscribed = true;
            }
          }
        }
      }

      if (this.mounted) {
        this.setState({
          unsubscribed,
          stationLists: stationData,
          subscriberLists,
          email,
        });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  save = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!this.props.store || !this.state.subscriberLists || this.saveInProgress) {
      return;
    }

    const { api_base_uri } = this.props.store;
    const base_uri = api_base_uri || "/api/v4";

    this.saveInProgress = true;

    try {
      const result = await sendSubscriptionLists({
        variables: { input: this.state.subscriberLists as PostupListSubscriptionCreateInput[] },
        base_uri,
      });

      if (result) {
        log.info("Subscriber data updated");
        toast.info(i18next.t("subscriptions_success"), {
          autoClose: 10000,
          toastId: "subscriptions-update-notify",
        });

        this.saveInProgress = false;
      }
    } catch (err) {
      log.info("An error occurred while updating subscriber data: ", err);
    }
  };

  private changeListStatus = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    if (target && this.state.subscriberLists && this.mounted) {
      const subscriberLists = this.state.subscriberLists.map(list => {
        if (list) {
          if (target.value === list.listId.toString()) {
            const status: PostupListSubscriptionStatus = !target.checked
              ? PostupListSubscriptionStatus.Unsub
              : PostupListSubscriptionStatus.Normal;
            list.status = status;
          }
        }
        return list;
      });

      this.setState({
        subscriberLists,
      });
    }
  };

  render() {
    if (!this.props.store) {
      return null;
    }

    const { site } = this.props.store;
    const header = !this.state.unsubscribed ? (
      <Translation>{t => <h1 className="headline">{t("subscriptions_unsubscribe_wait")}</h1>}</Translation>
    ) : (
      <Translation>
        {t => (
          <h1 className="headline">
            {t("subscriptions_unsubscribe_success", {
              station: `${site.sections.general?.name}`,
            })}
          </h1>
        )}
      </Translation>
    );

    const optionsHeader = (
      <Translation>
        {t => (
          <div className="header">
            {t("subscriptions_unsubscribe_options", {
              email: `${this.state.email || ""}`,
            })}
          </div>
        )}
      </Translation>
    );

    const showSpinner = !this.state.unsubscribed;

    const stationListsForm = !showSpinner ? (
      <section className="user">
        <form role="form" noValidate={true}>
          <div className="options">
            {optionsHeader}
            <ul className="station-lists">
              {this.state.stationLists &&
                this.state.stationLists.lists?.map((list: PostupStationList, index: number) => {
                  if (list.publicSignup) {
                    return (
                      <li key={index}>
                        <input
                          type="checkbox"
                          name={`stationsLists[${index}]`}
                          id={`station-list-${list.listId}`}
                          value={list.listId}
                          defaultChecked={Subscribe.checkListStatus(list.listId, this.state.subscriberLists || [])}
                          onChange={this.changeListStatus}
                          aria-labelledby={`station-list-label-${list.listId}`}
                        />
                        <label htmlFor={`station-list-${list.listId}`} id={`station-list-label-${list.listId}`}>
                          {list.friendlyTitle || list.title}
                        </label>
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
          <Translation>
            {t => (
              <div className="button-wrapper">
                <Button className={"save-btn"} kind={ButtonKind.PRIMARY} click={this.save}>
                  {t("subscriptions_save")}
                </Button>
              </div>
            )}
          </Translation>
        </form>
      </section>
    ) : null;

    return (
      <Fragment>
        <Container className="component-newsletter">
          {header}
          <Spinner visible={showSpinner} />
          {stationListsForm}
        </Container>
        <ToastContainer autoClose={5000} position="bottom-center" />
      </Fragment>
    );
  }
}

export default Unsubscribe;
