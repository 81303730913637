import * as io from "io-ts";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/pipeable";

export const getPaths = <A>(v: io.Validation<A>) => {
  return pipe(
    v,
    E.fold(
      errors =>
        errors.map(error => {
          const { type, actual } = error.context.slice(-1)[0];
          const actualValue = typeof actual === "object" ? JSON.stringify(actual, null, 2) : actual;
          const path = error.context
            .reduce((p, { key }) => [...p, key], [] as string[])
            .filter(Boolean)
            .filter(errorKey => isNaN(parseInt(errorKey, 10)));
          return `${path.join(".")} is expected to be type ${
            type.name
          }, but is actually ${actualValue}, type ${typeof actual}`;
        }),
      () => ["no errors"],
    ),
  );
};
