import i18n from "i18next";

import { isFeedBlock } from "../../lib/guards";
import { Tagger, Tags, TagContext } from "../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../util";

export const micrositeFeedTags: Tagger = (state: Tags, context: TagContext) => {
  const { site: parent, block, microsite } = context;

  if (block && isFeedBlock(block) && microsite && microsite.config) {
    let { metadata } = state;
    const { sections } = microsite;

    if (metadata && sections.general?.name) {
      const description =
        sections.general?.description && sections.general?.description.trim()
          ? sections.general.description.trim()
          : i18n.t("microsite_description");

      metadata = setGeneralMetaTags(metadata, {
        titles: { default: `${sections.general?.name} - ${parent.sections.general?.name}` },
        description,
      });

      metadata.set(...generateMetaTag("name", "parsely-section", sections.general.name));

      return {
        ...state,
        metadata,
      };
    }
  }
  return state;
};
