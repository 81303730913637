import * as React from "react";
import classNames from "classnames";

export interface TabProps {
  label: string;
  active?: boolean;
  onClick?: (label: string) => void;
  children?: React.ReactNode;
}

export const Tab = (props: TabProps) => {
  const { active, label, onClick } = props;
  const className = classNames("tab", { active });

  return (
    <li className={className}>
      <button className="button-text" onClick={() => onClick && onClick(label)}>
        {label}
      </button>
    </li>
  );
};
