import { objectToGetParams } from "@ihr-radioedit/inferno-core";
import { EmailIcon } from "../icons/EmailIcon.component";

interface EmailButtonProps {
  subject?: string;
  body?: string;
  url?: string;
  height?: number;
  width?: number;
  callback?: () => void;
}

type Options = {
  body?: string;
  separator?: string;
  subject?: string;
};

const emailLink = (url: string, { subject, body, separator }: Options) => {
  return "mailto:" + objectToGetParams({ subject, body: body ? body + separator + url : url });
};

export const EmailButton = ({ subject, body = "", url = "", callback }: EmailButtonProps) => {
  const clickHandler = () => {
    callback?.();
    const link = emailLink(url, { subject, separator: " ", body });
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <button
      data-platform="email"
      className="custom-button email-button"
      aria-label="Share this page in Email"
      onClick={clickHandler}
    >
      <EmailIcon />
    </button>
  );
};
