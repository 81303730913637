import type { Store } from "../stores/index";

export enum SITE_THEME {
  EMBED = "embed",
  DEFAULT = "default",
  PREMIERE = "premiere",
  COAST = "coast",
  PREVIEW = "_preview",
  STORYBOOK = "storybook",
}

export interface SiteThemeProps {
  store?: Store;
  siteTheme: SITE_THEME;
}
