import { ILog } from "@ihr-radioedit/inferno-core";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import { PrintIcon } from "../icons/PrintIcon.component";
const log = ILog.logger("PrintButton.component");

interface PrintButtonProps {
  height?: number;
  width?: number;
  callback?: () => Promise<void>;
  children?: React.ReactNode;
}

export const PrintButton = ({ callback, children }: PrintButtonProps) => {
  if (isWindowDefined() && !window.print) {
    log.debug("window.print is not supported");
    return null;
  }

  const clickHandler = async () => {
    await callback?.();
    window.print();
  };

  return (
    <button
      data-platform="print"
      className="custom-button printer-button"
      aria-label="Print this page"
      onClick={clickHandler}
    >
      <PrintIcon />
      {children}
    </button>
  );
};
