import i18n from "i18next";

import { slugify } from "@ihr-radioedit/inferno-core";
import { formatImage } from "@ihr-radioedit/inferno-core";

import { isAptivadaAppFragment } from "../../lib/guards";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, processMetaImage, setGeneralMetaTags } from "../util";

export const contestsTags: Tagger = (state: Tags, context: TagContext) => {
  const { site } = context;
  const parselyTitle = `${site.sections.general?.name} Contests | Tickets, Trips & More`;

  const keywords = [
    i18n.t("contest").toLowerCase(),
    i18n.t("contests").toLowerCase(),
    i18n.t("sweepstakes"),
    i18n.t("enter"),
    i18n.t("win"),
    i18n.t("radio"),
    i18n.t("free"),
    site.sections.general?.name || "",
    site.sections.contact?.location_city || "",
    site.sections.contact?.location_state || "",
  ];

  let { metadata } = state;
  metadata = setGeneralMetaTags(metadata, {
    titles: {
      default: parselyTitle,
      parsely: parselyTitle,
      social: i18n.t("contest_social_title", { station: site.sections.general?.name }),
    },
    description: i18n.t("contests_description", { station: site.sections.general?.name }) || "",
    keywords,
    types: {
      parsely: "sectionpage",
    },
  });

  metadata.set(...generateMetaTag("name", "parsely-section", "contests"));

  return {
    ...state,
    ads: {
      ...state.ads,
      keywords: keywords.map(item => slugify(item)),
    } as AdTags,
    analytics: {
      ...state.analytics,
      view: {
        ...state.analytics?.view,
        tags: keywords.map(item => slugify(item)),
      },
    } as AnalyticsTags,
    metadata,
  };
};

export const contestTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, env } = context;
  if (block?.resolved && isAptivadaAppFragment(block.resolved)) {
    const contest = block.resolved;
    const title = contest.title || i18n.t("contest");
    const slug = block.value && block.value.slug && typeof block.value.slug === "string" ? block.value.slug : "";
    const parselyTitle = `${title} | ${site.sections.general?.name}`;
    const image = formatImage(contest.facebookShareImage || contest.gridImage, env.IMAGE_HOST);

    state.surrogateKeys?.add(`contest/${site.index.slug}-${slugify(contest.title).toLowerCase()}`);

    const keywords = [
      i18n.t("contest").toLowerCase(),
      i18n.t("contests").toLowerCase(),
      i18n.t("sweepstakes"),
      i18n.t("enter"),
      i18n.t("win"),
      i18n.t("radio"),
      i18n.t("free"),
      site.sections.general?.name || "",
      site.sections.contact?.location_city || "",
      site.sections.contact?.location_state || "",
    ];

    if (contest.tags) {
      keywords.push(...contest.tags.split(",").map((tag: string) => tag.trim()));
    }

    keywords.push(contest.appId, contest.parentAppId);

    let { metadata } = state;
    metadata = setGeneralMetaTags(metadata, {
      titles: {
        default: `${parselyTitle} | ${site.sections.general?.name}`,
        parsely: parselyTitle,
        social: parselyTitle,
      },
      image: { url: processMetaImage(image, context) },
      description: contest.shareTitle || i18n.t("contest_description", { station: site.sections.general?.name }) || "",
      keywords,
      types: {
        parsely: "post",
      },
    });

    metadata.set(...generateMetaTag("name", "parsely-section", "contests"));

    return {
      ...state,
      ads: {
        ...state.ads,
        keywords: keywords.map(item => slugify(item)),
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          asset: {
            id: `contest|${slug}`,
            name: title,
          },
          tags: keywords.map(item => slugify(item)),
        },
      } as AnalyticsTags,
      metadata,
    };
  }

  return state;
};
