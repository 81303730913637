import type { Store } from "../stores";

export interface SitemapProps {
  store?: Store;
  year?: string;
  month?: string;
  section?: string;
}

export interface SitemapMonth {
  number: number;
  name: string;
}

export const sitemapMonths: SitemapMonth[] = [
  { number: 1, name: "month-january" },
  { number: 2, name: "month-february" },
  { number: 3, name: "month-march" },
  { number: 4, name: "month-april" },
  { number: 5, name: "month-may" },
  { number: 6, name: "month-june" },
  { number: 7, name: "month-july" },
  { number: 8, name: "month-august" },
  { number: 9, name: "month-september" },
  { number: 10, name: "month-october" },
  { number: 11, name: "month-november" },
  { number: 12, name: "month-december" },
];

export const getSitemapMonth = (month: string) => sitemapMonths[parseInt(month, 10) - 1]?.name ?? null;

export const sitemapResultsMaxLimit = 50000;
export const sitemapResultsPerSection = 1250;

export enum SitemapPaths {
  SitemapBase = "/sitemap/",
  SitemapYearMonth = "/sitemap/:year/:month",
  SitemapYearMonthSection = "/sitemap/:year/:month/:section",
}
