import { ILog } from "@ihr-radioedit/inferno-core";
import { ParselyManage, AnalyticsEvent } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("ParselyManager/parsely.ts");

declare global {
  interface Window {
    PARSELY: {
      autotrack: boolean;
      config: {
        [key: string]: any;
      };
      beacon: {
        trackPageView: (opts: { [key: string]: any }) => void;
        [key: string]: any;
      };
      [key: string]: any;
    };
  }
}

/** Add new members to Base Abstract */
export class ParselyManager extends ParselyManage {
  protected _library?: Promise<void>;

  constructor(protected store: Store) {
    super();
    // Check if the current site is configured for Parsely analytics
    const { site, env } = store;
    if (!site.sections.analytics?.parsely_switch) {
      log.debug("Current site is not configured for parsely analytics");
      return;
    } else if (!env.PARSELY_LIBRARY) {
      log.debug("Parsely library url not configured");
      return;
    }

    // Subscribe to click events and fire Parsely call
    store.onAnalyticsAction.subscribe((event: AnalyticsEvent) => {
      this.trackAction(event);
    });

    store.parsely = this;
  }

  // Ensure library is loaded once and only once
  loadLibrary() {
    if (this._library) {
      return this._library;
    }

    const { site, env } = this.store;

    if (!site.sections.analytics?.parsely_switch) {
      this._library = Promise.reject(new Error("Parsely analytics not configured"));
      return this._library;
    } else if (!env.PARSELY_LIBRARY) {
      this._library = Promise.reject(new Error("Parsely library url not configured"));
      return this._library;
    }

    this._library = new Promise<void>((resolve, reject) => {
      window.PARSELY = window.PARSELY || {
        autotrack: false,
        config: {},
      };

      const body = document.querySelector("body");
      const hostname = site.getPrimaryDomain();
      if (body && env.PARSELY_LIBRARY && hostname) {
        const srcUrl = env.PARSELY_LIBRARY.replace("%%HOSTNAME%%", hostname);
        const script = document.createElement("script");

        script.id = this.parselyLibId;
        script.async = true;
        script.defer = true;
        script.src = srcUrl;
        script.onload = () => {
          log.debug("Parsely library loaded");
          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug("Parsely library could not be loaded", error.message);
          }
          reject(new Error("Parsely library could not be loaded"));
        };

        body.appendChild(script);
      } else {
        log.debug(`Parsely library could not be loaded: body? ${!!body} configured? ${env.PARSELY_LIBRARY}`);
        reject(new Error("Parsely library could not be loaded"));
      }
    });

    return this._library;
  }

  trackPageView() {
    log.debug("Trigger Parsely page view");

    const page = this.store.page.currentPage;

    this.loadLibrary()
      .then(() =>
        this.trigger({
          sectionName: "",
          pageName: page ? `${this.store.microsite ? "microsite_" : ""}${page.name}` : "",
          action: "pageview",
          url: location.href,
          referrer: document.referrer,
        } as AnalyticsEvent),
      )
      .catch((e: Error) => log.debug(e.message));
  }

  trackAction(event: AnalyticsEvent) {
    log.debug("Trigger Parsely click view");
    this.loadLibrary()
      .then(() => this.trigger(event))
      .catch((e: Error) => log.debug(e.message));
  }

  trigger = (event: AnalyticsEvent) => {
    const { pageName, sectionName, context, action, url, referrer: urlref } = event;
    try {
      (window.setImmediate || window.setTimeout)(() => {
        log.debug("Trigger Parsely call");
        window.PARSELY.beacon.trackPageView({
          url,
          urlref,
          action,
          data: {
            pageName,
            sectionName,
            context,
          },
          js: 1,
        });
      });
    } catch (err) {
      log.error("Failed to load Parsely", err.message);
    }
  };
}
