import { Microsite, Site } from "@ihr-radioedit/inferno-core";
import type { SitesPageRoute } from "@ihr-radioedit/inferno-webapi";
import { matchPath } from "react-router";
import { match } from "react-router-dom";
import { SitemapPaths } from "./sitemap";

export const RouteRegistry: { [key: string]: string } = {
  ...SitemapPaths,
};

export function getRouteRegistryRoutes() {
  return Object.keys(RouteRegistry).map(key => ({
    name: key,
    path: RouteRegistry[key],
  }));
}

export interface routeMatchPair {
  route: Pick<SitesPageRoute, "name" | "path">;
  // eslint-disable-next-line @typescript-eslint/ban-types
  routeMatch: match<{}>;
}

export function matchPathByRoutes(routes: any[], pathname: string): routeMatchPair | null {
  let routePair: { route: (typeof routes)[0]; routeMatch: match<Record<string, any>> } | null = null;

  for (const r of routes) {
    const m = matchPath(pathname, { path: r.path, exact: true, strict: false });
    if (m) {
      routePair = { route: r, routeMatch: m };
      break;
    }
  }

  return routePair;
}

export function reverseRoute(
  site: Site | Microsite | undefined,
  pageName: string,
  params: { [key: string]: string | null | undefined } = {},
) {
  // Look up page
  const page = site?.config?.routes.find(route => route.name === pageName);
  if (!page) {
    return;
  }

  // Replace params in url
  let url: string = page.path;
  Object.keys(params).forEach(key => {
    const param = params[key];
    if (param) {
      url = url.replace(`<${key}>`, param);
    }
  });

  // Ensure url has a trailing slash, then return it
  if (!url.endsWith("/")) {
    url += "/";
  }
  if (site instanceof Microsite) {
    url = `/featured/${site.index?.slug}${url}`;
  }
  return url;
}

export const hasPage = (site: Site | Microsite | undefined, pageName: string) =>
  !!site?.config?.routes.some(route => route.name === pageName);

export const isPreviewRequest = (path: string) => /^\/_preview$/.test(path);
