if (typeof performance !== "undefined") {
  performance.mark("Inferno - First Byte");
}

import "core-js/stable";

// tslint:disable-next-line:no-submodule-imports
import "regenerator-runtime/runtime";

import "core-js/es";
import "core-js/proposals/url";

import "intersection-observer";
