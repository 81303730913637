import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../locales/en/translation.json";
import translationES from "../locales/es/translation.json";

type initOptions = "test";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

export const i18nConfig = {
  resources,
  fallbackLng: "en",
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
    prefix: "%(",
    suffix: ")s",
  },
  nsSeparator: "|||",
};

/**
 * Needs to return i18n instance via promise to get rid of type error: "react-i18next:: You will need to pass in an i18next instance by using initReactI18next"
 */
export default async (lng: string, initOptions?: initOptions) => {
  await i18n.use(initReactI18next).init({
    ...i18nConfig,
    lng,
  });

  if (initOptions === "test") {
    i18n.t = i18n.t.bind(i18n);
  }

  return i18n;
};
