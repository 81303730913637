import type { TFunction } from "i18next";
import { inject } from "mobx-react";
import { createElement, Fragment } from "react";

import type { ABTestConfig } from "@inferno/renderer-shared-core";
import { abBarrel, abTranslationMap } from "./abBarrel";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("abHelper");

const appendTestGroup = (key: string, abConfig: ABTestConfig | undefined) => {
  const abGroup = abConfig?.groups?.[abTranslationMap[key]] || "A";
  if (abGroup !== "A") {
    return `${key}_${abGroup}`;
  }
  return key;
};

export function abTranslator(translationKey: string, abConfig: ABTestConfig | undefined, t: TFunction) {
  // This translation wrapper of %( )s is a hold-over from Fire.
  // src/client/i18n.ts:24
  const regex = /%\((.*)\)s/;
  if (regex.test(translationKey)) {
    const [, match] = regex.exec(translationKey) || [];
    if (match) {
      if (abConfig?.groups?.[abTranslationMap[match]]) {
        const replacedKey = translationKey.replace(regex, t(appendTestGroup(match, abConfig)));
        log.debug("Have Test Group - Replacing Match: ", replacedKey);
        return replacedKey;
      } else {
        const replacedKey = translationKey.replace(regex, t(match));
        log.debug("Don't Have Test Group - Replacing Match: ", replacedKey);
        return replacedKey;
      }
    }
  }
  if (abConfig?.groups?.[abTranslationMap[translationKey]]) {
    const appendedKey = t(appendTestGroup(translationKey, abConfig));
    log.debug("Appending Test Group: ", appendedKey);
    return appendedKey;
  }
  const translatedKey = t(translationKey);
  log.debug("No match, returning key: ", translatedKey);
  return translatedKey;
}

export const ABSwitcher = inject("store")(
  ({ abKey, store, props }: { abKey: keyof typeof abBarrel; store?: Store; props?: Record<string, unknown> }) => {
    log.debug("A/B Switcher Key: ", abKey);
    if (abBarrel[abKey]) {
      let abConfig = store?.testGroups?.groups?.[abKey] || "A";
      if (!abBarrel[abKey][abConfig]) {
        log.error(`Received unexpected variant for ${abKey}: ${abConfig}`);
        abConfig = "A";
      }
      // Note, adding a span here can break css if this functionality is used elsehwere.
      // This span is here because of top-level jsx shenanigans due to playerbar/live playerbar.
      return (
        <Fragment key={`${abKey}-${abConfig}`}>
          {createElement(abBarrel[abKey][abConfig](), { ...props })}
          <input type="hidden" suppressHydrationWarning={true} data-key={`${abKey}-${abConfig}`} />
        </Fragment>
      );
    }
    return null;
  },
);
