import classnames from "classnames";
import { inject } from "mobx-react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import type { SharePlatform } from "@inferno/renderer-shared-core";
import { ClickSection } from "@inferno/renderer-shared-core";
import { EmailButton } from "./EmailButton.component";
import { NativeShareButton } from "./NativeShareButton.component";
import { PrintButton } from "./PrintButton.component";
import { FacebookIcon } from "@inferno/renderer-shared-ui";
import { XIcon } from "@inferno/renderer-shared-ui";
import "./ShareButtons.style.scss";
import type { Store } from "@inferno/renderer-shared-core";

export interface ShareButtonsProps {
  url?: string;
  title?: string;
  desc?: string;
  className?: string;
  store?: Store;
  setStyles?: boolean;
}

export const ShareButtons = inject("store")(({ className, desc, title, url = "", store }: ShareButtonsProps) => {
  const classNames = className ? className.split(" ") : [];
  const iconSize = 40;

  const clickHandler = (platform: SharePlatform, sectionName = "") => {
    if (store) {
      store.onShareAction.dispatch({
        share: {
          platform,
        },
        sectionName,
        pageName: store.page.currentPage ? `${store.microsite ? "microsite_" : ""}${store.page.currentPage.name}` : "",
        context: "",
        action: "share",
        url,
      });
    }
  };

  return (
    <div className={classnames("share-icons", classNames)}>
      <ClickSection.Consumer>
        {sectionName => (
          <FacebookShareButton
            data-platform={"facebook"}
            // quote={desc}
            hashtag={`#${title}`}
            url={url}
            aria-label="Share this page on Facebook"
            onClick={() => clickHandler("facebook", sectionName)}
          >
            <FacebookIcon />
          </FacebookShareButton>
        )}
      </ClickSection.Consumer>
      <ClickSection.Consumer>
        {sectionName => (
          <TwitterShareButton
            data-platform={"x"}
            title={title}
            url={url}
            aria-label="Share this page on X"
            onClick={() => clickHandler("x", sectionName)}
          >
            <XIcon />
          </TwitterShareButton>
        )}
      </ClickSection.Consumer>
      <ClickSection.Consumer>
        {sectionName => (
          <EmailButton subject={title} body={desc} url={url} callback={() => clickHandler("email", sectionName)} />
        )}
      </ClickSection.Consumer>
      <ClickSection.Consumer>
        {sectionName => (
          <PrintButton width={iconSize} height={iconSize} callback={async () => clickHandler("print", sectionName)} />
        )}
      </ClickSection.Consumer>
      <ClickSection.Consumer>
        {sectionName => (
          <NativeShareButton title={title} url={url} callback={() => clickHandler("native", sectionName)} />
        )}
      </ClickSection.Consumer>
    </div>
  );
});
