import { isAxiosError } from "@ihr-radioedit/sdk-network";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Component, Suspense } from "react";

import { ILog } from "@ihr-radioedit/inferno-core";
import { Spinner } from "../../ui";
import { AsyncFallbackWrapper } from "./AsyncFallbackWrapper.component";
import type { Store } from "@inferno/renderer-shared-core";
const log = ILog.logger("Remote.component");

interface RemoteChildProps<V> {
  data: V | null;
}

interface RemoteProps<V> {
  loader: () => Promise<V>;
  showLoading?: boolean;
  fallback?: JSX.Element;
  children: (props: RemoteChildProps<V>) => JSX.Element | null;
  cacheKey: string;
  store?: Store;
}

@inject("store")
@observer
export class Remote<V> extends Component<RemoteProps<V>> {
  render() {
    const { loader, children, cacheKey, showLoading } = this.props;
    const fallback = this.props.fallback || <Spinner visible={showLoading || false} />;
    if (typeof window !== "undefined" && this.props.store?.hasCacheValue(cacheKey)) {
      const cacheData = this.props.store.getCacheValue(cacheKey);
      if (cacheData?.data) {
        return (
          <RemoteImpl loader={loader} cacheKey={cacheKey} key={cacheKey}>
            {(props: RemoteChildProps<V>) => children(props)}
          </RemoteImpl>
        );
      }
    }

    return (
      <Suspense fallback={<AsyncFallbackWrapper>{fallback}</AsyncFallbackWrapper>}>
        <RemoteImpl loader={loader} cacheKey={cacheKey} key={cacheKey}>
          {(props: RemoteChildProps<V>) => children(props)}
        </RemoteImpl>
      </Suspense>
    );
  }
}

@inject("store")
class RemoteImpl<V> extends Component<RemoteProps<V>> {
  load = async () => {
    if (this.props.store?.cache) {
      if (this.props.store.hasCacheValue(this.props.cacheKey)) {
        return this.props.store.getCacheValue(this.props.cacheKey);
      }
      try {
        const data = await this.props.loader();
        this.props.store.storeCacheValue(this.props.cacheKey, { data });
        return data;
      } catch (error) {
        this.props.store.storeCacheValue(this.props.cacheKey, {
          error: isAxiosError(error)
            ? `${error.response?.status} - ${
                error.response?.data.message || error.response?.data.reason || error.toString()
              }`
            : error.response?.toString() || error.toString(),
        });
        return error;
      }
    }
  };

  render() {
    const { children } = this.props;
    if (this.props.store?.cache) {
      const cacheData = this.props.store.getCacheValue(this.props.cacheKey);
      if (cacheData) {
        const { data, error } = cacheData;
        if (data !== undefined) {
          return children({
            data,
          });
        } else if (error) {
          log.error(this.props.cacheKey, error);
        }
      } else {
        throw this.load();
      }
      log.debug(`Remote with cacheKey ${this.props.cacheKey} returning null.\nCache Data:`, toJS(cacheData));
    }
    return null;
  }
}
