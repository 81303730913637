import { BlockFragment } from "@ihr-radioedit/inferno-webapi";

export const makeAd = (position: string, adSplit?: number | null): BlockFragment => ({
  id: "fakeAd",
  ordinal: 1,
  region: "ad:dynamic",
  type: "ad",
  value: {
    position: position || 0,
    ad_split: adSplit,
  },
});
