import { action, makeObservable, observable } from "mobx";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { BaseStoreBlock } from "../abstracts/store/base-block.abstract";

/** Add new members to Base Abstract */
export class StoreBlock extends BaseStoreBlock {
  @observable
  currentBlock: BlockFragment | undefined = undefined;

  constructor(currentBlock?: BlockFragment) {
    super();
    makeObservable(this);
    this.currentBlock = currentBlock;
  }

  @action
  storeBlock = (block: BlockFragment) => {
    this.currentBlock = block;
  };
}
