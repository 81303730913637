import "./ArrowIcon.style.scss";

export const ArrowIcon = ({ direction = "right" }) => (
  <svg
    tabIndex={-1}
    focusable="false"
    className={`svg-icon icon-arrow direction-${direction}`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      tabIndex={-1}
      d="M9.30812 6.71002C8.91813 7.10002 8.91813 7.73002 9.30812 8.12002L13.1881 12L9.30812 15.88C8.91813 16.27 8.91813 16.9 9.30812 17.29C9.69813 17.68 10.3281 17.68 10.7181 17.29L15.3081 12.7C15.6981 12.31 15.6981 11.68 15.3081 11.29L10.7181 6.70002C10.3381 6.32002 9.69813 6.32002 9.30812 6.71002Z"
      fill="#3F4447"
      className="primary-path"
    />
  </svg>
);

export default ArrowIcon;
