// Global regex that are in a constant scope are stateful
// See the wtf: https://stackoverflow.com/questions/3811890/javascript-regular-expression-fails-every-other-time-it-is-called
export const RE_SLUG_SPACE = () => /[-\s]+/g;

export const slugify = (value = "", max = 0) => {
  const RE_SLUG_UNWANTED = /[^\w\s-]+/g;
  const slug = value.normalize("NFKD").toLowerCase().replace(RE_SLUG_UNWANTED, "").replace(RE_SLUG_SPACE(), "-");
  return limit_by_words(slug, max, "-");
};

export const limit_by_words = (value: string, max: number, delimiter = "-") => {
  if (max < 1) {
    return value;
  }

  return value.split(delimiter).slice(0, max).join(delimiter);
};
