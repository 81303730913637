import { Site } from "../decoders/Sites.types";

export const LANGUAGES = {
  Spanish: "es",
  English: "en",
};

export const ENDATE = {
  content: "MMM dd, yyyy",
  pubUpdated: "yyyy-MM-dd hh:mm:ss",
  pubDate: "MMM d, yyyy",
  separator: "MMM d, yyyy",
};

export const ESDATE = {
  content: "dd MMM yyyy",
  pubUpdated: "dd MMM yyyy",
  pubDate: "dd MMM yyyy",
  separator: "d MMM yyyy",
};

export const DATEFORMAT: { [key: string]: any } = {
  en: ENDATE,
  es: ESDATE,
};

export const getLang = (site: Site) => getLocale(site).split("-")[0];

export const getLocale = (site: Site) => site.sections.general?.locale?.taxo?.name || LANGUAGES.English + "-US";

export function getDateFormat(site: Site, key = "content") {
  const localeDateformat = DATEFORMAT[getLang(site)];
  return localeDateformat[key];
}

export const getParselyDate = (pubDate: number) => {
  return new Date(pubDate).toISOString().replace(/\.\d{3,}Z/, "Z");
};
