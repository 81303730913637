import { getMediaServerUrl, formatImage, removePrefix, slugify } from "@ihr-radioedit/inferno-core";

import { Tagger, Tags, TagContext, AdTags, AnalyticsTags } from "../lib/tagging-type";
import { sanitize } from "../utilities/sanitize";
import { processMetaImage, setGeneralMetaTags } from "./util";

export const pageTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, page, env } = context;
  if (page) {
    const { sections } = site;
    const { surrogateKeys } = state;
    if (surrogateKeys) {
      surrogateKeys.add(`vtype/${page.layoutId}`);
      surrogateKeys.add(`vname/${page.name}`);
    }

    let titles = null;
    if (page.pageConfig?.title) {
      titles = { default: `${sanitize(page.pageConfig.title)} | ${sections.general?.name}` };
    }

    const keywords: string[] = page.pageConfig?.keywords?.map(removePrefix(/^\w+\//)) || [];

    const thumbnail = page.pageConfig?.thumbnail;
    let image = null;
    let pageImage = "";
    if (thumbnail) {
      if (typeof thumbnail === "string") {
        pageImage = formatImage(thumbnail, env.IMAGE_HOST);
      } else if (thumbnail.bucket && thumbnail.id) {
        const { bucket, id } = thumbnail;
        pageImage = getMediaServerUrl(env.IMAGE_HOST, bucket, id);
      }
    }

    if (pageImage) {
      image = { url: processMetaImage(pageImage, context) };
    }

    let { metadata } = state;
    metadata = setGeneralMetaTags(metadata, {
      titles,
      image,
      description: page.pageConfig?.description || "",
      keywords,
    });

    let pageType = page?.name || state.ads?.type || "";
    if (["home", "home_premiere"].includes(page?.name)) {
      pageType = "home";
    }

    return {
      ...state,
      surrogateKeys,
      ads: {
        ...state.ads,
        keywords: [...(state.ads?.keywords || []), ...keywords.map(item => slugify(item))],
        type: pageType,
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          asset: {
            ...state.analytics?.view.asset,
            name: page.pageConfig?.title ? sanitize(page.pageConfig.title) : state.analytics?.view.asset.name || "",
          },
          tags: [...(state.ads?.keywords || []), ...keywords.map(item => slugify(item))],
        },
      } as AnalyticsTags,
      metadata,
    };
  }

  return state;
};
