import { SimpleEventDispatcher } from "ste-simple-events";
import { getAndStoreTestGroupInformation } from "../../services/variant-test";
import { Deferred } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { IHRSessionState, SessionBackend, IHeartUserProfile } from "../../lib/backend";
import { IHRLoginOptions } from "../../lib/ihm";
import { localStorage } from "../../services/local-storage";
import { IHRCallback, IHRInitializeSync, IHRPromiseValue, IHRStatusResponse } from "../backend";
import type { Store } from "../../stores";

const log = ILog.logger("IHM Session/ihm.ts");

declare global {
  interface Window {
    IHR_READY: boolean; // set from head script
    IHR: {
      initialized: boolean;
      initializeSync: (opts: IHRInitializeSync) => IHRPromiseValue;
      checkAuthStatus: (cb?: () => void) => Promise<IHRStatusResponse>;
      login: (opts?: { signup: boolean; success?: (status?: IHRStatusResponse) => void }) => Promise<IHRStatusResponse>;
      signup: (opts?: { success: () => void }) => Promise<IHRPromiseValue>;
      api: {
        logout: (opts?: IHRCallback) => Promise<IHRPromiseValue>;
        profile: {
          get: (opts?: IHRCallback) => Promise<IHeartUserProfile>;
          logout: (opts?: IHRCallback) => Promise<IHRPromiseValue>;
        };
      };
    };
  }
}

export class IHRSessionBackend implements SessionBackend {
  private _sdk?: Promise<void>;
  name = "ihr-auth";
  container = "ihr-auth-container";
  state: IHRSessionState = { authenticated: false, pid: "", aid: "" };
  sdkSettings: IHRInitializeSync | null = null;
  pendingLoginPromise: Deferred<IHRSessionState> | null = null;

  private _onStatusChanged = new SimpleEventDispatcher<IHRSessionState>();

  constructor(private store: Store, private depAbTestHost: string, private depRequestTimeout: number) {
    const { env } = this.store;

    if (!env.IHR_AUTH_SDK || !env.WWW_AUTH_HOST) {
      log.debug("IHR Auth SDK library not configured");
      this._sdk = Promise.reject(new Error("IHR Auth SDK not configured"));
      return;
    }

    this.sdkSettings = {
      alwaysPrompt: true,
      container: this.container,
      embed: true,
      ampHost: `https://${env.AMP_HOST}/api/`,
      host: env.WWW_AUTH_HOST,
      iframeId: "ihr-frame",
    } as IHRInitializeSync;

    const stored = this.local();
    if (stored) {
      this.state = stored;
      this._onStatusChanged.dispatch(this.state);
    }
  }

  loadSDK() {
    if (this._sdk || window.IHR_READY) {
      return (this._sdk = this._sdk || new Promise(resolve => resolve()));
    }

    const { env } = this.store;

    this._sdk = new Promise<void>((resolve, reject) => {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      if (head && env.IHR_AUTH_SDK) {
        script.id = this.name;
        script.async = true;
        script.defer = true;
        script.src = env.IHR_AUTH_SDK;
        script.onload = () => {
          try {
            window.IHR.initializeSync(this.sdkSettings as IHRInitializeSync);
            log.debug("IHR Auth SDK Loaded & Initialized");
          } catch (err) {
            log.error(err);
          }
          resolve();
        };

        script.onerror = (
          event: Event | string,
          source?: string,
          fileno?: number,
          columnNumber?: number,
          error?: Error,
        ) => {
          if (error) {
            log.debug("IHR Auth SDK could not be loaded", error.message);
          }
          reject(new Error("IHR Auth SDK could not be loaded"));
        };

        head.appendChild(script);
      } else {
        log.debug(`IHR Auth SDK could not be loaded: head? ${!!head} configured? ${env.BRIDGE_SRC}`);
        reject(new Error("IHR Auth SDK could not be loaded"));
      }
    });

    return this._sdk;
  }

  private static stateDataValid(state: IHRSessionState) {
    if (state.authenticated && state.pid && state.aid) {
      return true;
    }
    log.warn("State data is invalid: ", state);
    return false;
  }

  private local() {
    const localData = localStorage.getItem(this.name);
    if (localData) {
      try {
        const state = JSON.parse(localData) as IHRSessionState;
        if (IHRSessionBackend.stateDataValid(state)) {
          log.debug("Found local session data");
          return state;
        } else {
          log.warn("Invalid session data stored, removing it from localStorage");
          localStorage.removeItem(this.name);
        }
      } catch (e) {
        log.debug("Invalid payload in local storage");
      }
    }
    return null;
  }

  private persist(update: IHRSessionState) {
    log.debug("Persisting session data", update);
    this.state = { ...update };
    localStorage.setItem(this.name, JSON.stringify(this.state));
  }

  private async sessionUpdated(state: IHRSessionState) {
    const { authenticated, aid, pid } = state;

    await getAndStoreTestGroupInformation(this.store, this.depAbTestHost, this.depRequestTimeout);

    if (authenticated) {
      try {
        const profile = await window.IHR.api.profile.get({
          success: () => true,
          error: () => false,
        });

        if (!profile) {
          throw new Error("Retrieved user profile was empty");
        }

        if (profile?.accountType?.toLowerCase() === "anonymous") {
          log.warn("Invalid Account Type: ", profile.accountType.toLowerCase());
          this.persist({
            authenticated,
            pid: "",
            aid: "",
          });
        } else {
          this.persist({
            authenticated,
            pid,
            aid,
            profile,
          });
        }
      } catch (e) {
        log.error(e.message);
        this.persist({ authenticated, pid: "", aid: "" });
      }
    } else {
      this.persist({
        authenticated,
        pid: "",
        aid: "",
      });
    }

    this._onStatusChanged.dispatch(this.state);
    return this.state;
  }

  get onStatusChanged() {
    return this._onStatusChanged.asEvent();
  }

  get authenticated() {
    return this.state.authenticated;
  }

  get hasPrivacy() {
    return !!this.state.profile?.privacyCompliance;
  }

  async login(options?: Partial<IHRLoginOptions>) {
    return this.loadSDK()
      .then(async () => {
        if (!this.pendingLoginPromise) {
          this.pendingLoginPromise = new Deferred<IHRSessionState>();
        }

        const result = await window.IHR.login({ ...(options || { signup: false }) } as IHRLoginOptions);
        if (result) {
          const { aid, pid, auth } = result;
          const state = await this.sessionUpdated({
            ...this.state,
            aid,
            pid,
            authenticated: auth === "true",
          });

          if (this.pendingLoginPromise) {
            if (state.authenticated) {
              this.pendingLoginPromise.resolve(state);
            } else {
              this.pendingLoginPromise.reject(state);
            }
          }
        }

        return this.pendingLoginPromise.promise;
      })
      .catch((state: IHRSessionState) => {
        return Promise.reject<IHRSessionState>(state);
      });
  }

  async signup(options?: IHRLoginOptions) {
    return this.login({ ...(options || {}), signup: true } as IHRLoginOptions);
  }

  async logout() {
    return this.loadSDK()
      .then(async () => {
        const result = await window.IHR.api.profile.logout({
          success: () => true,
          error: () => false,
        });

        if (result) {
          return Promise.resolve<IHRSessionState>(this.sessionUpdated({ authenticated: false, aid: "", pid: "" }));
        }

        return Promise.reject<IHRSessionState>(this.state);
      })
      .catch((state: IHRSessionState) => {
        return Promise.reject<IHRSessionState>(state);
      });
  }

  async refresh() {
    return this.loadSDK()
      .then(
        async () => {
          const result = await window.IHR.checkAuthStatus();
          if (result) {
            const { aid, pid } = result;
            return Promise.resolve<IHRSessionState>(
              this.sessionUpdated({
                ...this.state,
                aid,
                pid,
                authenticated: true,
              }),
            );
          }

          return Promise.resolve<IHRSessionState>(this.sessionUpdated({ authenticated: false, aid: "", pid: "" }));
        },
        () => {
          return Promise.reject<IHRSessionState>(this.state);
        },
      )
      .catch((state: IHRSessionState) => {
        return Promise.reject<IHRSessionState>(state);
      });
  }
}
