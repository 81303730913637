import { Container, Skeleton } from "../../ui";
import "./ContentSkeleton.style.scss";

export const ContentSkeleton = ({ pulse = true }: { pulse?: boolean }) => {
  return (
    <Container className="content-skeleton-container">
      <div className="col-left">
        <Skeleton pulse={pulse} type="text" name="eyebrow" size={6} width="40%" />
        <Skeleton pulse={pulse} type="text" name="title line 1" size={3} />
        <Skeleton pulse={pulse} type="text" name="title line 2" size={3} width="60%" />
        <Skeleton pulse={pulse} type="text" name="byline" size={5} width="25%" />
        <Skeleton pulse={pulse} type="text" name="publish date" size={5} width="10%" />
        <Skeleton pulse={pulse} type="image" name="main image" shape="16x9" />
        <section className="social-icons">
          <Skeleton pulse={pulse} type="image" name="social icon" shape="circle" width="32px" />
          <Skeleton pulse={pulse} type="image" name="social icon" shape="circle" width="32px" />
          <Skeleton pulse={pulse} type="image" name="social icon" shape="circle" width="32px" />
          <Skeleton pulse={pulse} type="image" name="social icon" shape="circle" width="32px" />
        </section>
        <Skeleton pulse={pulse} type="text" name="article text" size={5} />
        <Skeleton pulse={pulse} type="text" name="article text" size={5} />
        <Skeleton pulse={pulse} type="text" name="article text" size={5} />
        <Skeleton pulse={pulse} type="text" name="article text" size={5} />
      </div>
      <div className="col-right">
        <Skeleton pulse={pulse} type="image" name="right column" shape="tall" width="300px" height="600px" />
      </div>
    </Container>
  );
};
