import i18n from "i18next";

import { slugify, matchPrefix, removePrefix } from "@ihr-radioedit/inferno-core";

import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../util";

export const playlistTags: Tagger = (state: Tags, context: TagContext) => {
  const { site } = context;

  const parselyTitle = i18n.t("playlist_parsely_title", { station: site.sections.general?.name });
  const description = i18n.t("playlist_description", {
    station: site.sections.general?.name,
    positioner: site.sections.general?.positioner,
  });

  const keywords = [
    i18n.t("songs"),
    i18n.t("list_recent"),
    i18n.t("new_songs"),
    i18n.t("list_new"),
    i18n.t("new_playlist"),
    i18n.t("recent_songs"),
    i18n.t("latest_songs"),
    i18n.t("recently_played_songs"),
    i18n.t("last_played_song"),
    site.sections.general?.name?.toLowerCase() || "",
  ];

  if (site.sections.contact?.location_city) {
    keywords.push(site.sections.contact.location_city.toLowerCase());
  }

  if (site.index.facets) {
    const formats = site.index.facets.filter(matchPrefix(/^formats\//)).map(removePrefix(/^formats\//));
    keywords.push(formats.join(", ").toLowerCase());
  }

  if (site.integration) {
    keywords.push(
      ...site.integration.plugin.broadcastFacilities
        .map(b => b.broadcastCallLetters?.toLowerCase() || "")
        .filter(Boolean),
    );
  }

  let { metadata } = state;
  metadata = setGeneralMetaTags(metadata, {
    titles: {
      default: `${parselyTitle} | ${site.sections.general?.name}`,
      parsely: parselyTitle,
      social: i18n.t("playlist_social_title", { station: site.sections.general?.name }),
    },
    description,
    keywords,
    types: {
      parsely: "sectionpage",
    },
  });

  metadata.set(...generateMetaTag("name", "parsely-section", "music"));

  return {
    ...state,
    ads: {
      ...state.ads,
      keywords: keywords.map(item => slugify(item)),
    } as AdTags,
    analytics: {
      ...state.analytics,
      view: {
        ...state.analytics?.view,
        tags: keywords.map(item => slugify(item)),
      },
    } as AnalyticsTags,
    metadata,
  };
};

export const topsongsTags: Tagger = (state: Tags, context: TagContext) => {
  const { site } = context;
  const parselyTitle = i18n.t("topsong_parsely_title", { station: site.sections.general?.name });
  const description = i18n.t("topsong_description", {
    station: site.sections.general?.name || "",
    positioner: site.sections.general?.positioner || "",
  });

  const keywords = [
    i18n.t("songs"),
    i18n.t("list_top"),
    i18n.t("list_best"),
    i18n.t("playlists"),
    i18n.t("top_songs").toLowerCase(),
    i18n.t("best_songs"),
    i18n.t("recent_songs"),
    i18n.t("new_songs"),
    site.sections.general?.name?.toLowerCase() || "",
  ];

  if (site.sections.contact?.location_city) {
    keywords.push(site.sections.contact.location_city.toLowerCase());
  }

  if (site.index.facets) {
    const formats = site.index.facets.filter(matchPrefix(/^formats\//)).map(removePrefix(/^formats\//));
    keywords.push(formats.join(", ").toLowerCase());
  }

  if (site.integration) {
    keywords.push(
      ...site.integration.plugin.broadcastFacilities
        .map(b => b.broadcastCallLetters?.toLowerCase() || "")
        .filter(Boolean),
    );
  }

  let { metadata } = state;
  metadata = setGeneralMetaTags(metadata, {
    titles: {
      default: `${parselyTitle} | ${site.sections.general?.name}`,
      parsely: parselyTitle,
      social: i18n.t("topsong_social_title", { station: site.sections.general?.name }),
    },
    description,
    keywords,
    types: {
      parsely: "sectionpage",
    },
  });

  metadata.set(...generateMetaTag("name", "parsely-section", "music"));

  return {
    ...state,
    ads: {
      ...state.ads,
      keywords: keywords.map(item => slugify(item)),
    } as AdTags,
    analytics: {
      ...state.analytics,
      view: {
        ...state.analytics?.view,
        tags: keywords.map(item => slugify(item)),
      },
    } as AnalyticsTags,
    metadata,
  };
};
