import { MapUrl } from "./MapUrl.component";

export interface VenueProps {
  venueName?: string | null;
  venueAddress?: string | null;
}

export function Venue({ venueName, venueAddress }: VenueProps) {
  if (venueName) {
    return (
      <address className="venue-info">
        {venueAddress ? <MapUrl venueName={venueName} venueAddress={venueAddress} /> : venueName}
      </address>
    );
  } else {
    return <address className="venue-info">{venueAddress}</address>;
  }
}
