export interface StringObj {
  [k: string]: string;
}

export function parseKVString(kvStr: string, delimiter: string, kvSplit = "=") {
  return kvStr
    .split(delimiter)
    .map(kv => {
      const inx = kv.indexOf(kvSplit);
      const pos = inx >= 0 ? inx : kv.length;
      return [kv.slice(0, pos), kv.slice(pos + 1)];
    })
    .reduce((p, [k, v]) => ({ ...p, [k]: v }), {} as StringObj);
}
