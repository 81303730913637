import i18n from "i18next";

import { slugify } from "@ihr-radioedit/inferno-core";

import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../util";

export const trafficTags: Tagger = (state: Tags, context: TagContext) => {
  const { site } = context;
  const trafficMarket = site.sections.general?.weather_market ? `${site.sections.general?.weather_market} ` : "";

  const keywords = [
    i18n.t("traffic").toLowerCase(),
    i18n.t("construction"),
    i18n.t("cameras"),
    i18n.t("closures"),
    i18n.t("highways"),
    i18n.t("roads"),
    i18n.t("freeways"),
  ];

  if (trafficMarket) {
    keywords.push(...trafficMarket.trim().toLowerCase().split(", "));
  }

  const parselyTitle = i18n.t("traffic_parsely_title", { weatherMarket: trafficMarket });

  let { metadata } = state;
  metadata = setGeneralMetaTags(metadata, {
    titles: {
      default: `${parselyTitle} | ${site.sections.general?.name}`,
      parsely: parselyTitle,
    },
    description: i18n.t("traffic_description", { weatherMarket: trafficMarket }) || "",
    keywords,
    types: { parsely: "sectionpage" },
  });

  metadata.set(...generateMetaTag("name", "parsely-section", "traffic"));

  return {
    ...state,
    ads: {
      ...state.ads,
      keywords: keywords.map(item => slugify(item)),
    } as AdTags,
    analytics: {
      ...state.analytics,
      view: {
        ...state.analytics?.view,
        tags: keywords.map(item => slugify(item)),
      },
    } as AnalyticsTags,
    metadata,
  };
};
