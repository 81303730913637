import type { BackendSessionState } from "@inferno/renderer-shared-core";
import { md5 } from "@ihr-radioedit/inferno-core";
import { CcpaStatus, IHRSessionState } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

declare global {
  interface Window {
    ats?: {
      start: (props: { placementID: number; storageType: string; logging: string; emailHashes: string[] }) => void;
    };
  }
}

const sendUserInfo = (email = "") => {
  if (email) {
    email = email.trim().toLowerCase();
    window.ats?.start({
      placementID: 2102,
      storageType: "localStorage",
      logging: "error",
      emailHashes: [md5(email)],
    });
  }
};

let atsScriptSetup = false;
export const setupLiveRamp = (store: Store) => {
  const initLiveRamp = (status?: BackendSessionState) => {
    if (store.getCcpaStatus() !== CcpaStatus.OptOut && status?.authenticated) {
      if (!atsScriptSetup && store.env.LIVERAMP_LIBRARY_URL) {
        const atsScript = document.createElement("script");
        atsScript.onload = () => sendUserInfo((store.session.currentSession?.state as IHRSessionState).profile?.email);
        atsScript.src = store.env.LIVERAMP_LIBRARY_URL;
        document.body.appendChild(atsScript);
        atsScriptSetup = true;
      } else {
        sendUserInfo((store.session.currentSession?.state as IHRSessionState).profile?.email);
      }
    }
  };
  initLiveRamp(store.session.currentSession?.state);
  store.session.currentSession?.onStatusChanged.sub(initLiveRamp);
};
