import { environ } from "@ihr-radioedit/sdk-utils";
import { endOfMonth, startOfMonth } from "date-fns";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { PageFragment } from "@ihr-radioedit/inferno-webapi";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { getSitemapMonth, SitemapProps, sitemapResultsPerSection } from "@inferno/renderer-shared-core";
import { getSitemapContent, getQueryDate, MonthNumber } from "@ihr-radioedit/inferno-core";
import { Remote } from "../remote/Remote.component";
import "./Page.style.scss";
import "./Sitemap.style.scss";
import { MagicLink } from "../../ui";

export const SitemapSection = inject("store")(
  observer((props: SitemapProps) => {
    if (!props.store) {
      return null;
    }
    const { store, year, month, section } = props;
    if (!store || !year || !month) {
      return null;
    }

    if (props.store.page.currentPage) {
      const page: PageFragment = { ...props.store.page.currentPage, name: "sitemap_section" };
      props.store.storePage(page);
    }

    const { t } = useTranslation();

    const date = getQueryDate(parseInt(year, 10), (parseInt(month, 10) || 1) as MonthNumber);
    const searchStart = startOfMonth(date);
    const searchEnd = endOfMonth(date);

    const now = new Date().getTime();
    const interval = environ.getInt("FEED_CONTEXT_TIMESTAMP_INTERVAL", 3600) * 1000;
    const timestamp = Math.ceil(now / interval) * interval;

    const query: Webapi.PubsubQueryInput = {
      limit: sitemapResultsPerSection,
      lookup: [`publish_origin:${store.site.index.ownBrand}`],
      offset: section ? sitemapResultsPerSection * (parseInt(section, 10) - 1) : 0,
      pub_after: searchStart.valueOf(),
      pub_start: searchEnd.valueOf(),
      pub_until: timestamp,
    };

    /* tslint:disable:react-a11y-anchors */
    const fallback = (
      <div className="sections">
        <h3>No Results Found</h3>
        <a href="/sitemap/">Go Back</a>
      </div>
    );

    const siteMapMonth = getSitemapMonth(month);

    return (
      <main className="component-page two-column">
        <section className="col-left">
          <div className="component-sitemap">
            <div className="component-container component-title block">
              <h1>{t("sitemap-title")}</h1>
              {siteMapMonth ? (
                <div className="breadcrumbs">
                  <MagicLink to="/sitemap/">Sitemap</MagicLink>
                  <MagicLink to={`/sitemap/${year}/${month}/`}>
                    &gt;&nbsp;{t(siteMapMonth)}&nbsp;{year}
                  </MagicLink>
                  <span className="current-location">&gt;&nbsp;Section {section}</span>
                </div>
              ) : null}
            </div>
            <Remote
              loader={() => getSitemapContent({ query })}
              cacheKey={`sitemap-month-${month}-${section}`}
              showLoading={false}
              fallback={fallback}
            >
              {({ data: items }) => {
                if (!items || !siteMapMonth) {
                  return fallback;
                }

                return (
                  <div className="component-container items">
                    {items.map((item, index) => (
                      <div key={index} className="item">
                        <MagicLink rel="canonical" to={`/content/${item.slug}/`}>
                          {item.summary.title}
                        </MagicLink>
                      </div>
                    ))}
                  </div>
                );
              }}
            </Remote>
          </div>
        </section>
      </main>
    );
  }),
);
