function createScriptElement([src, crossorigin = ""]: string[]): HTMLScriptElement {
  const script = document.createElement("script");
  script.defer = true;
  script.src = src;
  script.type = "text/javascript";
  if (crossorigin) {
    script.crossOrigin = crossorigin;
  }
  return script;
}

export function setupSocialMedia() {
  const scripts = [
    ["//connect.facebook.net/en/sdk.js#xfbml=1&version=v3.2&autoLogAppEvents=1", "anonymous"],
    ["https://platform.twitter.com/widgets.js"],
    ["https://platform.instagram.com/en_US/embeds.js"],
  ].map(script => createScriptElement(script));
  document.querySelector("body")!.append(...scripts);
}
