import { environ } from "@ihr-radioedit/sdk-utils";
import * as React from "react";
import { ILog } from "@ihr-radioedit/inferno-core";

const log = ILog.logger("ErrorBoundary");

interface ErrorBoundaryProps {
  location?: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error?: Error }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError = (error: Error) => ({ error });

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (environ.get("ENVIRONMENT", "") === "storyshots") {
      log.error("error: ", this.props.location, error);
      log.error("errorInfo: ", this.props.location, errorInfo);
    }
    if (typeof window !== "undefined") {
      window.newrelic?.noticeError(error);
    }
  }

  render() {
    if (this.state.error) {
      log.error(this.props.location, this.state.error.toString());
      return null;
    }

    return this.props.children;
  }
}
