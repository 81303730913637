import { ILog } from "@ihr-radioedit/inferno-core";

import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { environ } from "@ihr-radioedit/sdk-utils";

import axios, { AxiosResponse } from "axios";

type SendRecipientMutationType = { status: number; data: Webapi.Maybe<Webapi.PostUpRecipientFragment> | undefined };

const log = ILog.logger("PostUp");

export const POSTUP_RECIPIENT_SOURCE = "newsletter";
export const POSTUP_RECIPIENT_CHANNEL = "E";

const emptyData = (response: AxiosResponse, postupQuery: string) => {
  const payload = response.data?.postup[postupQuery];

  return (
    (Array.isArray(payload) || payload === Object(payload)) &&
    Object.keys(payload as Array<unknown> | Record<string, unknown>).length === 0
  );
};

const handleResolverError = (error: any) => {
  if (error && error.response) {
    log.debug(
      "Error in PostUp resolver",
      error.response.data && error.response.data.errors ? error.response.data.errors : error.response.data,
    );
  } else {
    log.debug("Error in PostUp resolver", error);
  }
};

const handleRecipientMutation = async (
  base_uri: string,
  variables: Webapi.UpdateRecipientMutationVariables | Webapi.CreateRecipientMutationVariables,
): Promise<SendRecipientMutationType> => {
  const path = `${base_uri}/postup/recipient`;
  const config = {
    timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
  };

  let response;
  let data;

  if ("recipientId" in variables.input) {
    response = await axios.put<Webapi.UpdateRecipientMutation>(path, { variables }, config);
    data = !emptyData(response, "updateRecipient") ? response.data.postup.updateRecipient : null;
  } else {
    response = await axios.post<Webapi.CreateRecipientMutation>(path, { variables }, config);
    data = !emptyData(response, "createRecipient") ? response.data.postup.createRecipient : null;
  }

  return { status: response.status, data };
};

export const getStation = async (slug: Webapi.GetStationQueryVariables["slug"], base_uri: string) => {
  log.debug("getStation", slug);

  try {
    const response = await axios.get<Webapi.GetStationQuery>(`${base_uri}/postup/station/${slug}`, {
      timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
    });

    if (response.status === 400 || emptyData(response, "station")) {
      log.debug("getStation Webapi error", response.data);
      return null;
    }

    return response.data?.postup.station;
  } catch (error) {
    handleResolverError(error);

    return null;
  }
};

export const getRecipient = async ({
  base_uri,
  variables,
}: {
  variables: Webapi.GetRecipientQueryVariables;
  base_uri: string;
}) => {
  const { slug, emailAddress } = variables;

  log.debug("getRecipient", slug, emailAddress);
  try {
    const response = await axios.get<Webapi.GetRecipientQuery>(`${base_uri}/postup/recipient/${slug}/${emailAddress}`, {
      timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
    });

    if (response.status === 400 || emptyData(response, "recipient")) {
      log.debug("getRecipient Webapi error", response.data);
      return null;
    }

    return response.data?.postup.recipient;
  } catch (error) {
    handleResolverError(error);

    return null;
  }
};

export const getRecipientSubscriptions = async ({
  base_uri,
  variables,
}: {
  variables: Webapi.GetRecipientSubscriptionsQueryVariables;
  base_uri: string;
}) => {
  const { slug, emailAddress } = variables;

  try {
    const response = await axios.get<Webapi.GetRecipientSubscriptionsQuery>(
      `${base_uri}/postup/subscription/${slug}/${emailAddress}`,
      {
        timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
      },
    );

    if (response.status === 400 || emptyData(response, "recipient")) {
      log.debug("getRecipientSubscriptions Webapi error", response.data);
      return null;
    }

    return response.data?.postup.recipient
      ?.map(list => {
        if (list.subscriptions) return list.subscriptions;
      })
      .flat();
  } catch (error) {
    if (error) {
      handleResolverError(error);

      return null;
    }
  }
};

export const sendRecipient = async ({
  base_uri,
  variables,
}: {
  variables: Webapi.UpdateRecipientMutationVariables | Webapi.CreateRecipientMutationVariables;
  base_uri: string;
}) => {
  log.debug("sendRecipient", variables.slug, variables.input);

  try {
    const response = await handleRecipientMutation(base_uri, variables);

    if (response.status === 400 || !response.data) {
      log.debug("sendRecipient Webapi error", response.data);
      return null;
    }

    return response.data;
  } catch (error) {
    handleResolverError(error);

    return null;
  }
};

export const sendSubscriptionLists = async ({
  variables,
  base_uri,
}: {
  variables: Webapi.UpdateSubscriptionsMutationVariables;
  base_uri: string;
}) => {
  log.debug("sendSubscriptionLists", variables.input);

  try {
    const response = await axios.put<Webapi.UpdateSubscriptionsMutation>(
      `${base_uri}/postup/updateListSubscriptions`,
      {
        variables,
      },
      {
        timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
      },
    );

    if (response.status === 400 || emptyData(response, "updateSubscription")) {
      log.debug("sendSubscriptionLists Webapi error", response.data);
      return null;
    }

    return response.data?.postup.updateSubscriptions;
  } catch (error) {
    handleResolverError(error);

    return null;
  }
};

export const sendEmail = async ({
  variables,
  base_uri,
  recaptchaResponse,
}: {
  variables: Webapi.SendMailMutationVariables;
  base_uri: string;
  recaptchaResponse: string;
}) => {
  try {
    const response = await axios.post<Webapi.SendMailMutation>(
      `${base_uri}/postup/send`,
      {
        variables,
        recaptchaResponse,
      },
      {
        timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
      },
    );

    if (response.status === 400 || emptyData(response, "sendEmail")) {
      log.debug("sendMail Webapi error", response.data);
      return null;
    }

    return response.data?.postup.sendMail;
  } catch (error) {
    handleResolverError(error);

    return null;
  }
};
