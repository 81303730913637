import { Tagger, Tags, TagContext } from "../lib/tagging-type";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const previewTags: Tagger = (state: Tags, context: TagContext) => {
  return {
    ...state,
    ttls: {
      ...state.ttls,
      cdn: 0,
      browser: 0,
    },
  };
};
