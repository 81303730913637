import { Container, Grid } from "../../ui";
import { CategoryTileSkeleton } from "../../components/skeletons/CategoryTileSkeleton.component";
import "./CategoryCollectionSkeleton.style.scss";

export const CategoryCollectionSkeleton = ({ pulse = true }: { pulse?: boolean }) => {
  const cards = [];
  for (let counter = 0; counter < 12; counter++) {
    cards.push(<CategoryTileSkeleton pulse={pulse} key={counter} />);
  }
  return (
    <Container className="category-collection-skeleton-container">
      <Grid columns={3}>{cards.map(card => card)}</Grid>
    </Container>
  );
};
