import { AnalyticsEvent, isWindowDefined } from "@inferno/renderer-shared-core";
import { inject } from "mobx-react";
import { FunctionComponentElement, isValidElement, useCallback, useState } from "react";
import type { Store } from "@inferno/renderer-shared-core";
import { DismissComponentProps } from "../lib/dismiss";
import { ClickSection } from "@inferno/renderer-shared-core";
import { CloseButton } from "./CloseButton.component";
import styles from "./Toast.module.scss";

export type ArrowLocations = "" | "arrow-top" | "arrow-right" | "arrow-bottom" | "arrow-left";
export type ToastLocation = "top-right" | "top-left" | "bottom-right" | "bottom-left";

interface ToastProps {
  toastLocation?: ToastLocation;
  hasArrow?: boolean;
  arrowLocation?: ArrowLocations;
  title?: string;
  message?: string;
  store?: Store;
  children?: FunctionComponentElement<any>;
  themeOverride?: boolean;
}

export const Toast = inject("store")(
  ({
    toastLocation = "bottom-left",
    hasArrow = true,
    title,
    arrowLocation = "arrow-bottom",
    message,
    store,
    open = false,
    callback,
    children,
    themeOverride,
  }: ToastProps & DismissComponentProps): FunctionComponentElement<any> | null => {
    const [isOpen, setIsOpen] = useState(open);

    if (!store) {
      return null;
    }

    const dismissToast = useCallback(
      (trackSectionName: string) => {
        setIsOpen(false);
        if (store) {
          const page = store.page.currentPage;
          const pageName = page ? `${store.microsite ? "microsite_" : ""}${page.name}` : "";

          let referrer = "";
          if (isWindowDefined()) {
            referrer = window.location.href;
          }

          store.onAnalyticsAction.dispatch({
            sectionName: trackSectionName,
            pageName,
            context: "toast",
            action: "click",
            url: "",
            referrer,
          } as AnalyticsEvent);
        }
        callback?.();
      },
      [store, callback],
    );

    // message should include the site position
    if (isWindowDefined()) {
      return (
        <div
          data-cy="toast-on-article-page"
          className={`${styles.toastNotification} ${styles[toastLocation]} elevation-01
                    ${isOpen ? styles.open : ""}
                    ${hasArrow ? styles.arrow : ""}
                    ${hasArrow && arrowLocation ? styles[arrowLocation] : ""}
                    ${themeOverride ? styles.override : ""} `}
        >
          <ClickSection.Consumer>
            {sectionVal => (
              <CloseButton
                height={16}
                width={16}
                label="Close this Notification"
                addClass={"close-toast"}
                click={() => dismissToast(sectionVal)}
              />
            )}
          </ClickSection.Consumer>
          <div className={styles.toastContent}>
            <h2>{title}</h2>
            {message && <p>{message}</p>}
            {isValidElement(children) ? children : null}
          </div>
        </div>
      );
    }

    return null;
  },
);
