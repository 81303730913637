import i18n from "i18next";

import { parseTtl, removePrefix, slugify } from "@ihr-radioedit/inferno-core";

import { isFeedBlock } from "../../lib/guards";
import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { generateMetaTag, setGeneralMetaTags } from "../util";

export const feedTags: Tagger = (state: Tags, context: TagContext) => {
  const { site, block, request } = context;

  if (block && isFeedBlock(block)) {
    state.ttls ??= {};
    if (Object.keys(state.ttls).length === 0) {
      state.ttls.cdn = parseTtl("cdn");
    }

    let titles = null;
    let description = "";
    let { title } = block.value;
    let { metadata } = state;
    const topics: string[] = [];

    if (title) {
      const name = site.sections.general?.name || "";
      title = `${title} | ${name}`;

      titles = {
        default: title,
        social: title,
      };

      description = i18n.t("collection_description", { title, name }) || "";
    }

    if (block.feed?.resume) {
      const { "<topic>": topic } = block.feed.resume.context;
      if (topic) {
        const topicName = removePrefix(/^\w+\//)(topic);
        topics.push(topicName);
        const pathName = slugify(topicName);

        metadata.set("rss", {
          value: site.getShareUrl(request).replace(pathName, `${pathName}.rss`),
          title:
            (topicName &&
              topicName
                .split("-")
                .map(sub => sub[0].toUpperCase() + sub.slice(1))
                .join(" ")) ||
            "",
          type: "link",
          mime: "application/rss+xml",
          rel: "alternate",
        });
      }
    }

    metadata = setGeneralMetaTags(metadata, {
      titles,
      description,
      types: {
        parsely: "sectionpage",
      },
    });

    metadata.set(...generateMetaTag("name", "parsely-section", "topic"));

    return {
      ...state,
      ads: {
        ...state.ads,
        topics,
      } as AdTags,
      analytics: {
        ...state.analytics,
        view: {
          ...state.analytics?.view,
          topics,
        },
      } as AnalyticsTags,
      metadata,
    };
  }
  return state;
};
