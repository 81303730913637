import { Card, Skeleton } from "../../ui";
import "./ContentTileSkeleton.style.scss";

interface CategoryTileSkeletonProps {
  pulse?: boolean;
}
export const CategoryTileSkeleton = ({ pulse = true }: CategoryTileSkeletonProps) => {
  return (
    <Card elevation="01">
      <figure className="content-tile-skeleton">
        <section className="thumb">
          <Skeleton pulse={pulse} type="image" name="card thumbnail" shape="16x9" />
        </section>
        <figcaption>
          <Skeleton pulse={pulse} type="text" name="title line 1" size={5} />
        </figcaption>
      </figure>
    </Card>
  );
};
