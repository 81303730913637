import loadable from "@loadable/component";
import { useMemo } from "react";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { PageBlockInterface } from "../../../../core/page-blocks/Block.component";
import { getCoastHints } from "@ihr-radioedit/inferno-core";
import { CoastGenericBlockType, getCoastGenericBlockType } from "../../lib/coastGeneric";

const CoastFeaturedLoader = loadable(() => import("../../components/featured/CoastFeaturedLoader.component"));
const CoastSignUp = loadable(() => import("../../components/user/sign-up/CoastSignUp.component"));
const CoastZone = loadable(() => import("./CoastZone.component"));

const log = ILog.logger("CoastGeneric.component");

export const CoastGeneric = ({ block, isPrimary }: PageBlockInterface) => {
  const hints = useMemo(() => getCoastHints(block.tags), [block.tags]);
  const blockType = getCoastGenericBlockType(hints);

  switch (blockType) {
    case CoastGenericBlockType.FEATURED:
      return <CoastFeaturedLoader block={block} isPrimary={isPrimary} />;
    case CoastGenericBlockType.SIGNUP:
      return <CoastSignUp />;
    case CoastGenericBlockType.COAST_ZONE:
      return <CoastZone coastHints={hints} />;
    default:
      log.debug(`No match for Coast generic block type: ${blockType}. Hints: ${hints}`);
      return null;
  }
};
