import { UserConfigFragment } from "@ihr-radioedit/inferno-webapi/src/generated/webapi.g";

declare global {
  interface Window {
    MSStream: any;
  }
}

export function contain(url: string, maxWidth = 800, maxHeight = 600, quality = 80) {
  return `${url}?ops=gravity("center"),maxcontain(${maxWidth},${maxHeight}),quality(${quality})`;
}

export function mediaServerImage(hostname: string, bucket: string, id: string) {
  return `https://${hostname}/v3/re/${bucket}/${id}`;
}

export function mediaServerCatalogImage(
  hostname: string,
  namespace: string,
  id: number | string,
  maxWidth = 48,
  maxHeight = 48,
  noOps = false,
) {
  if (noOps) {
    return `https://${hostname}/v3/catalog/${namespace}/${id}`;
  }
  return `https://${hostname}/v3/catalog/${namespace}/${id}?ops=fit(${maxWidth},${maxHeight})`;
}

export function mediaServerPlaylistImage(
  hostname: string,
  userId: string,
  id: string,
  maxWidth = 224,
  maxHeight = 224,
) {
  return `https://${hostname}/v3/user/${userId}/collection/${id}?ops=fit(${maxWidth},${maxHeight})`;
}

export function stripNamespace(tag?: string | null) {
  return tag ? tag.split("/")[1] : tag;
}

export enum AgentOSType {
  WINDOWS,
  IOS,
  ANDROID,
}

export const getOsTypeFromAgent = () => {
  if (typeof window !== "undefined") {
    const agent = window.navigator.userAgent;

    if (!agent) {
      return null;
    }

    if (!window.MSStream && /ipad|iphone|ios/i.test(agent)) {
      return AgentOSType.IOS;
    }

    if (/android/i.test(agent)) {
      return AgentOSType.ANDROID;
    }

    if (/windows/i.test(agent)) {
      return AgentOSType.WINDOWS;
    }
  }

  return null;
};
export function getSiteTheme(sections: UserConfigFragment) {
  const theme = sections.design?.color_scheme?.id === "site-color-schemes/night" ? "night" : "light";

  return theme;
}

export function hexToHsl(hex: string, darken?: number) {
  let r = 0;
  let g = 0;
  let b = 0;
  if (hex.length === 4) {
    r = parseInt("0x" + hex[1] + hex[1]);
    g = parseInt("0x" + hex[2] + hex[2]);
    b = parseInt("0x" + hex[3] + hex[3]);
  } else if (hex.length === 7) {
    r = parseInt("0x" + hex[1] + hex[2]);
    g = parseInt("0x" + hex[3] + hex[4]);
    b = parseInt("0x" + hex[5] + hex[6]);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  let darkerPercentage = darken ? darken : -15;

  if ((h === 0 && s === 0) || l === 0) {
    darkerPercentage = 10;
  }

  l = l + darkerPercentage;
  return "hsl(" + h + "," + s + "%," + l + "%)";
}

export function overflowUnset() {
  document.body.style.overflow = "unset";
}
