import { CcpaStatus, localStorage } from "@inferno/renderer-shared-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("iab.ts");

const IAB_USPS_STORAGE_KEY = "iab_usps";
const iabUspsVersion = "1";
const iabExplicitNotice = "-";
const iabPublisherSignatory = "-";

// US Privacy String (usps)
// format: [spec version][explicit notice (Y/N/-)][user opt out status (Y/N/-)][publisher signatory (Y/N/-)]
// example: 1YY-, 1YNY

export enum UspsCommand {
  get = "getuspdata",
}

interface UspDataObj {
  version: number;
  uspString?: string;
}
type UspApiCallbackProps = (uspData: UspDataObj, success: boolean) => string;
export type UspApiFunc = (command: string, version: number, callback?: UspApiCallbackProps) => void;

let hasRunIab = false;

const formatUsps = (ccpaStatus: CcpaStatus) => {
  let userStatus = "-";
  if (ccpaStatus === CcpaStatus.OptOut) {
    userStatus = "Y";
  } else if (ccpaStatus === CcpaStatus.OptIn) {
    userStatus = "N";
  }
  return `${iabUspsVersion}${iabExplicitNotice}${userStatus}${iabPublisherSignatory}`;
};

const setIabUsps = (ccpaStatus: CcpaStatus) => {
  const usps = formatUsps(ccpaStatus);
  localStorage.setItem(IAB_USPS_STORAGE_KEY, usps);
};

// return a default if there was no value in storage
const getStoredUsps = () => {
  return localStorage.getItem(IAB_USPS_STORAGE_KEY) || formatUsps(CcpaStatus.Anonymous);
};

export const uspApi: UspApiFunc = (command = UspsCommand.get, version = 1, callback) => {
  const uspData: UspDataObj = {
    version,
  };

  if (command.toLowerCase() === UspsCommand.get) {
    const usps = getStoredUsps();
    uspData.uspString = usps;
  }

  log.debug("[uspApi] ", { uspData });
  callback?.(uspData, true);
};

export const initIab = (store: Store, refresh = false) => {
  if (refresh) {
    hasRunIab = false;
  }
  if (hasRunIab || !store) {
    return;
  }
  const ccpaStatus = store.getCcpaStatus();
  setIabUsps(ccpaStatus);
  hasRunIab = true;
};
