import { inject, observer } from "mobx-react";
import * as React from "react";
import { useEffect } from "react";
import { match, Route, RouteComponentProps, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";

import {
  getLocale,
  getMicrositeByQuery,
  getPage,
  getPubDatesContext,
  ILog,
  lookup,
  Microsite,
} from "@ihr-radioedit/inferno-core";
import type { PageFragment, SitesPageRoute } from "@ihr-radioedit/inferno-webapi";
import { SITE_THEME, type Store } from "@inferno/renderer-shared-core";
import { COAST_CATEGORY_PLACEHOLDER, RouteRegistry } from "@inferno/renderer-shared-core";
import { getPageRegistry, Page } from "../core/components/pages/Page.component";
import { SitemapByMonth } from "../core/components/pages/SitemapByMonth.component";
import { SitemapIndex } from "../core/components/pages/SitemapIndex.component";
import { SitemapSection } from "../core/components/pages/SitemapSection.component";
import { Remote } from "../core/components/remote/Remote.component";
import { CategoryCollectionSkeleton } from "../core/components/skeletons/CategoryCollectionSkeleton.component";
import { ContentSkeleton } from "../core/components/skeletons/ContentSkeleton.component";
import { HomePageSkeleton } from "../core/components/skeletons/HomePageSkeleton.component";
import { PageSkeleton } from "../core/components/skeletons/PageSkeleton.component";

const log = ILog.logger("Routes.tsx");

interface RoutesProps {
  store?: Store;
  useStoredPage?: boolean;
  headerGroup?: React.ReactNode;
  footerGroup?: React.ReactNode;
  siteTheme: SITE_THEME;
}

interface RouteProps extends RouteComponentProps {
  page: PageFragment;
  params: ReturnType<typeof useParams>;
  store?: Store;
  siteTheme: SITE_THEME;
}

const InfernoPage = inject("store")((props: RouteProps) => {
  useEffect(() => {
    props.store?.storePage(props.page);
  }, [props.page, props.store]);
  if (getPageRegistry(props.siteTheme, props.page.layoutId) === undefined) {
    log.error(`No page type definition for ${props.page.layoutId}`);
    return null;
  }
  return <Page {...props} fallback={<div>Loading Site...</div>} />;
});

interface RenderedRouteProps extends RouteComponentProps {
  route: SitesPageRoute;
  store: Store;
  path: string;
  useStoredPage?: boolean;
  siteTheme: SITE_THEME;
}

export const buildRouteContext = (store: Store, routeMatch: match<Record<string, string>>) => {
  const { params: routeParams } = routeMatch;
  const { site, request, env } = store;

  // Build up context
  const context: { [key: string]: any } = {
    "<locale>": getLocale(site),
    // these variables are currently only used for Coast to Coast AM (Coast theme)
    ...(store.site.index.slug === "coast-pr" ? getPubDatesContext(routeMatch.path, routeParams) : {}),
  };

  const scopes = [];
  let fallback = <PageSkeleton />;
  let from = "";

  if (routeMatch) {
    if ("topic" in routeParams && !!routeParams?.topic) {
      context["<topic>"] = routeParams.topic;
      from = routeParams.from || "";
    }
    if ("slug" in routeParams) {
      context["<slug>"] = routeParams.slug;
    }
    if ("coastCategory" in routeParams && routeParams.coastCategory !== COAST_CATEGORY_PLACEHOLDER) {
      context["<category>"] = routeParams.coastCategory;
    }

    if (request.query && env.QUERY_PARAM_WHITELIST) {
      const whitelist = new Set(env.QUERY_PARAM_WHITELIST.split(","));
      Object.entries(request.query)
        .filter(([key, value]) => whitelist.has(key) && value !== undefined && value !== null)
        .forEach(([key, value]) => (context[`<${key}>`] = encodeURIComponent(String(value))));
    }

    if (store.microsite && store.site) {
      scopes.push(`embeddedInSlug:${store.site.index.slug}`);
    }

    if ("slug" in routeParams || "eventId" in routeParams) {
      fallback = <ContentSkeleton />;
    }
    if ("topic" in routeParams || "category" in routeParams) {
      fallback = <CategoryCollectionSkeleton />;
    }
    if (routeMatch.path === "/" && store.site.sections.design?.theme === "default") {
      fallback = <HomePageSkeleton />;
    }
  }

  return { context, scopes, fallback, from };
};

const isCurrentPage = (currentPage: PageFragment, path: string, microsite?: Microsite) => {
  if (!currentPage) {
    return false;
  }
  if (microsite && !path.includes("featured")) {
    return false;
  }

  return currentPage.path === path;
};

const RenderedRoute = (props: RenderedRouteProps) => {
  const { route, store, path, match: routeMatch, useStoredPage } = props;

  store.storeRouteParams(routeMatch.params);
  if (store.page.currentPage && (useStoredPage || isCurrentPage(store.page.currentPage, path, store.microsite))) {
    log.debug("Rendering - Stored: ", path);
    const {
      match: { params },
    } = props;
    return <InfernoPage {...props} params={params as any} page={store.page.currentPage} />;
  }
  const cacheKey = JSON.stringify(path);
  log.debug("Rendering: ", path);

  let context: Record<string, any>;
  let scopes: string[];
  let fallback: JSX.Element;
  let from: string;
  let loader: () => Promise<0 | PageFragment | undefined>;

  try {
    ({ context, scopes, fallback, from } = buildRouteContext(store, routeMatch as any));

    loader = () =>
      getPage(
        {
          name: route.name,
          lookup,
          slug: path.includes("/featured/")
            ? store.microsite?.index?.slug || store.site.index.slug
            : store.site.index.slug,
          context,
          scopes,
          from,
        },
        store.tags.surrogateKeys,
      ).catch(() => store.page.storeStatus(404) && store.page.notFoundPage);
  } catch (e) {
    log.debug(e);
    store.page.storeStatus(404);
    loader = async () => store.page.notFoundPage;
    fallback = <PageSkeleton />;
  }

  return (
    <Remote cacheKey={cacheKey} loader={loader} fallback={fallback}>
      {({ data: page }) => {
        if (page) {
          return <InfernoPage {...props} params={routeMatch.params as any} page={page} />;
        }
        store.storePage(null);
        return null;
      }}
    </Remote>
  );
};

export const getRoutes = (store: Store) => {
  const { routes } = store.site.config;
  const renderedRoutes: Pick<SitesPageRoute, "name" | "path">[] = [];
  routes.forEach(route => {
    renderedRoutes.push(route);
    if (route.path.includes("newsletter")) {
      renderedRoutes.push({
        ...route,
        path: `${route.path}embed/`,
      });
    }
  });
  if (store.microsite?.config && store.microsite.index) {
    renderedRoutes.push(
      ...store.microsite.config.routes.map(route => ({
        ...route,
        path: `/featured/${store.microsite?.index?.slug}${route.path}`,
      })),
    );
  }

  return renderedRoutes.map(route => ({
    ...route,
    path: route.path.replace(/</g, ":").replace(/>/g, ""),
  }));
};

export const GetRoutes = ({
  store,
  children,
}: {
  store: Store;
  children: (props: {
    routes: {
      path: string;
      name: string;
    }[];
  }) => JSX.Element | null;
}) => {
  const routeMatch = useRouteMatch<{ slug: string }>("/featured/:slug");
  if (routeMatch) {
    const { slug } = routeMatch.params;
    if (store.microsite?.index?.slug !== slug) {
      const queryParams = {
        micrositeSlug: slug,
        slug: store.site.index.slug,
        lookup,
      };
      const loader = () =>
        getMicrositeByQuery(queryParams, store.tags.surrogateKeys).catch(err => {
          log.error(err);
          return null;
        });
      return (
        <Remote
          loader={loader}
          cacheKey={`getMicrositeByQuery-${JSON.stringify(queryParams)}`}
          fallback={<PageSkeleton />}
        >
          {({ data: microsite }) => {
            if (microsite?.index && microsite.config && microsite.index.status.isActive !== false) {
              store.storeMicrosite(microsite);
            }
            return children({
              routes: getRoutes(store),
            });
          }}
        </Remote>
      );
    }
  }
  return children({ routes: getRoutes(store) });
};

export const Routes = inject("store")(
  observer(({ store, useStoredPage, headerGroup, footerGroup, siteTheme }: RoutesProps) => {
    if (!store) {
      return null;
    }

    const location = useLocation();

    return (
      <div className="component-routes">
        {headerGroup}
        <GetRoutes store={store}>
          {({ routes }) => (
            <Switch location={location}>
              <Route
                path={RouteRegistry.SitemapYearMonthSection}
                exact={false}
                key={"sitemap-section"}
                render={props => {
                  return (
                    <SitemapSection
                      store={store}
                      year={props.match.params.year}
                      month={props.match.params.month}
                      section={props.match.params.section}
                    />
                  );
                }}
              />
              <Route
                path={RouteRegistry.SitemapYearMonth}
                exact={false}
                key={"sitemap-month"}
                render={props => {
                  return (
                    <SitemapByMonth store={store} year={props.match.params.year} month={props.match.params.month} />
                  );
                }}
              />
              <Route
                path={RouteRegistry.SitemapBase}
                exact={true}
                key={"sitemap"}
                render={() => <SitemapIndex store={store} />}
              />
              {routes.map(route => (
                <Route
                  path={route.path}
                  exact={true}
                  key={route.path}
                  render={props => (
                    <RenderedRoute
                      route={route}
                      store={store}
                      path={route.path}
                      useStoredPage={useStoredPage}
                      siteTheme={siteTheme}
                      {...props}
                    />
                  )}
                />
              ))}
              <Route
                render={props => {
                  store.page.storeStatus(404);
                  return store.page.notFoundPage ? (
                    <InfernoPage
                      {...props}
                      siteTheme={siteTheme}
                      params={props.match.params}
                      page={store.page.notFoundPage}
                    />
                  ) : null;
                }}
              />
            </Switch>
          )}
        </GetRoutes>
        {footerGroup}
      </div>
    );
  }),
);
