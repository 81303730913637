import { GetYoutubeVideosByIdQueryVariables } from "@ihr-radioedit/inferno-webapi";
import { toDate, zonedTimeToUtc } from "date-fns-tz";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router";

import {
  getContest,
  getTopic,
  getYoutubeVideosById,
  ILog,
  isPrimaryBlock,
  LANGUAGES,
  lookup,
} from "@ihr-radioedit/inferno-core";
import type { BlockFragment, PageFragment } from "@ihr-radioedit/inferno-webapi";
import { type Store, isFeedResult, isItemBlock, isPublishRecord, SITE_THEME } from "@inferno/renderer-shared-core";
import { coastPageComponents } from "../../../sites/coast/registry-page";
import { localPageComponents } from "../../../sites/local/registry-page";
import { premierePageComponents } from "../../../sites/premiere/registry-page";
import { isYouTubeBlock } from "../../lib/guards";
import { PageBlockRegistry } from "../../page-blocks/Block.component";
import { Remote } from "../remote/Remote.component";
import { ContentSkeleton } from "../skeletons/ContentSkeleton.component";
import "./Page.style.scss";
import { Regions } from "./Regions.component";

const log = ILog.logger("PrefetchPage.component");

interface PrefetchInputs {
  params?: ReturnType<typeof useParams>;
  primaryBlock: BlockFragment;
  baseUri: string;
  store: Store;
}

export const getPageRegistry = (siteTheme: SITE_THEME, pageTemplateId: string) => {
  switch (siteTheme) {
    case SITE_THEME.PREMIERE:
      return premierePageComponents[pageTemplateId as keyof typeof premierePageComponents];
    case SITE_THEME.COAST:
      return coastPageComponents[pageTemplateId as keyof typeof premierePageComponents];
    case SITE_THEME.EMBED:
      return localPageComponents[pageTemplateId as keyof typeof premierePageComponents];
    case SITE_THEME.DEFAULT:
    default:
      return localPageComponents[pageTemplateId as keyof typeof premierePageComponents];
  }
};

// These are the block types that we will prefetch the data for so we can show a 404 if the item is not found
const PREFETCH_BLOCK: { [key: string]: any } = {
  item: ({ primaryBlock, params, store }: PrefetchInputs) => {
    const slug = params?.slug || store.site.index.slug;
    return {
      loader: () => {
        return isItemBlock(primaryBlock) &&
          isFeedResult(primaryBlock.item?.result) &&
          isPublishRecord(primaryBlock.item?.result?.record)
          ? primaryBlock.item?.result?.record
          : null;
      },
      cacheKey: `itemloader-${slug}`,
    };
  },
  "item-reference": ({ primaryBlock, params, store }: PrefetchInputs) => {
    const slug = params?.slug || store.site.index.slug;
    return {
      loader: () => {
        return isItemBlock(primaryBlock) &&
          isFeedResult(primaryBlock.item?.result) &&
          isPublishRecord(primaryBlock.item?.result?.record)
          ? primaryBlock.item?.result?.record
          : null;
      },
      cacheKey: `itemloader-${slug}`,
    };
  },
  contest: ({ params, store }: PrefetchInputs) => {
    const { site, tags, getSdkOpts } = store;
    const accountId = site.index.slug;
    const appId = (params?.slug || accountId).split("-").slice(-1)[0];
    return {
      loader: () =>
        getContest({ accountId, appId }, tags.surrogateKeys, getSdkOpts()).then(contest => {
          if (contest?.endDate) {
            const timezone = site.index.timeZone || "UTC";

            const now = zonedTimeToUtc(toDate(new Date(), { timeZone: timezone }), timezone);
            const endDate = zonedTimeToUtc(toDate(contest.endDate, { timeZone: timezone }), timezone);

            if (endDate.getTime() <= now.getTime()) {
              return null;
            }
          }
          return contest;
        }),
      cacheKey: `contestloader-${accountId}-${appId}`,
    };
  },
  feed: ({ params, primaryBlock, store }: PrefetchInputs) => {
    const { site, getSdkOpts } = store;
    const locale = site.sections.general?.locale?.taxo?.name ?? LANGUAGES.English;
    return {
      loader: () => (params?.topic ? getTopic(params.topic, locale, getSdkOpts()) : {}),
      cacheKey: `topic-${params?.topic || primaryBlock.id}`,
    };
  },
  title: ({ params, primaryBlock, store }: PrefetchInputs) => {
    const { site, getSdkOpts } = store;
    const locale = site.sections.general?.locale?.taxo?.name ?? LANGUAGES.English;
    return {
      loader: () => (params?.topic ? getTopic(params.topic, locale, getSdkOpts()) : {}),
      cacheKey: `topic-${params?.topic || primaryBlock.id}`,
    };
  },
  youtube: ({ params, primaryBlock, store }: PrefetchInputs) => {
    const { site } = store;
    const defaultId =
      isYouTubeBlock(primaryBlock) && primaryBlock.default_video_id && primaryBlock.default_video_id !== "{slug}"
        ? primaryBlock.default_video_id
        : "";

    const query: GetYoutubeVideosByIdQueryVariables = {
      lookup,
      slug: site.index.slug,
      name: store?.page.currentPage?.name ?? "",
      blockId: primaryBlock.id,
      videoIds: [params?.slug ?? defaultId],
    };

    return {
      loader: () => {
        return params?.slug ?? defaultId
          ? getYoutubeVideosById(query).then(result => {
              if (result.videos && result.videos?.length > 0) {
                return result;
              }

              return null;
            })
          : {};
      },
      cacheKey: `youtube-${primaryBlock.id}-${params?.slug ?? defaultId}`,
    };
  },
};

export interface PageProps {
  page: PageFragment;
  store?: Store;
  params?: ReturnType<typeof useParams>;
  fallback: JSX.Element;
  blockComponents?: PageBlockRegistry;
  primaryBlockId?: string;
  siteTheme: SITE_THEME;
}

export const Page = inject("store")(
  observer((props: PageProps) => {
    if (!props.store) {
      return null;
    }
    const { page, params, store } = props;
    // in case of multiple primaryBlocks we are using first primaryBlock
    const primaryBlock = page.blocks.find(block => isPrimaryBlock(block.tags));
    const primaryBlockId = primaryBlock?.id;
    if (primaryBlockId) {
      store.storePrimaryBlockId(primaryBlockId);
    } else {
      log.info(`No primary block found for ${page.name}`);
    }

    const prefetch = primaryBlock !== undefined && PREFETCH_BLOCK[primaryBlock.type] !== undefined;
    const baseUri = store.api_base_uri;
    if (prefetch && primaryBlock) {
      const { loader, cacheKey } = PREFETCH_BLOCK[primaryBlock.type]({ params, primaryBlock, baseUri, store });
      return (
        <Remote cacheKey={cacheKey} loader={loader} showLoading={true} fallback={<ContentSkeleton />}>
          {({ data }) => {
            if (data) {
              return <Regions {...props} primaryBlockId={primaryBlockId} />;
            }
            if (store.page.notFoundPage) {
              store.page.storeStatus(404);
              store.storePage(store.page.notFoundPage);
              return <Regions {...props} page={store.page.notFoundPage} primaryBlockId={primaryBlockId} />;
            }
            return null;
          }}
        </Remote>
      );
    } else {
      return <Regions {...props} primaryBlockId={primaryBlockId} />;
    }
  }),
);

export default Page;
