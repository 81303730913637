import i18n from "i18next";

import { slugify } from "@ihr-radioedit/inferno-core";

import { AdTags, AnalyticsTags, TagContext, Tagger, Tags } from "../../lib/tagging-type";
import { setGeneralMetaTags } from "../util";

export const contactTags: Tagger = (state: Tags, context: TagContext) => {
  const { site } = context;
  const parselyTitle = i18n.t("contact_parsely_title", { station: site.sections.general?.name });

  const keywords = [
    site.sections.general?.name || "",
    i18n.t("contact_info"),
    i18n.t("address"),
    i18n.t("number"),
    i18n.t("call").toLowerCase(),
    i18n.t("text").toLowerCase(),
    i18n.t("advertising_info"),
    i18n.t("job_info"),
  ];

  let { metadata } = state;
  metadata = setGeneralMetaTags(metadata, {
    titles: {
      default: `${parselyTitle} | ${site.sections.general?.name}`,
      parsely: parselyTitle,
    },
    description: i18n.t("contact_description", { station: site.sections.general?.name }) || "",
    keywords,
    types: {
      parsely: "sectionpage",
    },
  });

  return {
    ...state,
    ads: {
      ...state.ads,
      keywords: keywords.map(item => slugify(item)),
    } as AdTags,
    analytics: {
      ...state.analytics,
      view: {
        ...state.analytics?.view,
        tags: keywords.map(item => slugify(item)),
      },
    } as AnalyticsTags,
    metadata,
  };
};
