import { ILog } from "@ihr-radioedit/inferno-core";
import { nativeShare, ShareData } from "@inferno/renderer-shared-core";
import { NativeShareIcon } from "../icons/NativeShareIcon.component";
import "./NativeShareButton.style.scss";

const log = ILog.logger("NativeShareButton.component.tsx");

interface NativeShareButtonProps extends ShareData {
  callback?: () => void;
}

export const NativeShareButton = (props: NativeShareButtonProps) => {
  const { title, url, callback } = props;

  if (typeof navigator === "undefined") {
    return null;
  }

  const shareData = { title, url };
  log.debug("navigator.share = ", !!navigator.share);
  log.debug("navigator.canShare = ", navigator.canShare?.(shareData));

  if (!navigator.share || !navigator.canShare?.(shareData)) {
    return null;
  }

  const clickHandler = () => {
    nativeShare(shareData);
    callback?.();
  };

  return (
    <button
      className="custom-button native-share-button"
      aria-label="More sharing options"
      onClick={() => clickHandler()}
    >
      <NativeShareIcon />
    </button>
  );
};
