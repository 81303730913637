import type { IHRSessionState } from "../lib/backend";
import type { PWSSessionState } from "./backends/pws";

export function isIHRSession(v?: any | undefined | null): v is IHRSessionState {
  return !!(
    v &&
    v.hasOwnProperty("profile") &&
    v.profile &&
    v.profile.hasOwnProperty("accountType") &&
    v.profile.accountType
  );
}

export function isPWSSession(v?: any | undefined | null): v is PWSSessionState {
  return !!(
    v &&
    v.hasOwnProperty("profile") &&
    v.profile &&
    v.profile.hasOwnProperty("rss_token") &&
    v.profile.rss_token
  );
}

export interface IHRInitialize {
  alwaysPrompt: boolean;
  container: string;
  embed: boolean;
  host: string;
  iframe?: HTMLIFrameElement | null | undefined;
  iframeId: string;
}

export interface IHRInitializeSync extends IHRInitialize {
  ampHost: string;
}

export type IHRPromiseValue = string | object | Error | null | undefined;

export interface IHRCallback {
  success: (result: string) => void;
  error: (error: string) => void;
}

export interface IHRStatusResponse {
  aid: string;
  pid: string;
  auth?: string;
}

export class SessionStateError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "SessionStateError";
  }
}
