import { action, makeObservable, observable } from "mobx";
import { BaseStoreSession } from "../abstracts/store/base-session.abstract";

import type { BackendSessionState } from "../auth/backends";
import { SessionBackend } from "../lib/backend";
import { IHRLoginOptions } from "../lib/ihm";

/** Add new members to Base */
export class StoreSession<T = BackendSessionState> extends BaseStoreSession<T> {
  @observable
  currentSession: SessionBackend<T, IHRLoginOptions> | null = null;

  constructor(currentSession: SessionBackend<T, IHRLoginOptions> | null) {
    super();
    makeObservable(this);
    this.currentSession = currentSession;
  }

  @action
  storeSession = (session: any) => {
    this.currentSession = session;
  };
}
