import { isFeedResult, isItemBlock, isPublishRecord } from "../../../lib/guards";
import { TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { ampArticleTemplateTags } from "./article";
import { ampCalendarArticleTemplateTags } from "./calendar-article";

const TEMPLATE_TAGS: { [key: string]: Tagger } = {
  content: ampArticleTemplateTags,
  "content:calendar": ampCalendarArticleTemplateTags,
};

export const ampItemTags: Tagger = (state: Tags, context: TagContext) => {
  const { block } = context;
  if (block && isItemBlock(block) && isFeedResult(block.item?.result) && isPublishRecord(block.item?.result.record)) {
    const content = block.item?.result.record;
    if (content && TEMPLATE_TAGS[content.type]) {
      return TEMPLATE_TAGS[content.type](state, context) || state;
    }
  }
  return state;
};
