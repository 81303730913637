import { MagicLink } from "./index";

export function MapUrl({ venueName, venueAddress }: { venueName: string; venueAddress: string }) {
  const url = "//maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=" + encodeURI(venueAddress);
  return (
    <MagicLink to={url} target="_blank">
      {venueName}
    </MagicLink>
  );
}
