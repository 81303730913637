/* eslint-disable no-console */
import { environ } from "@ihr-radioedit/sdk-utils";
import { StringUtils } from "../utils/string-utils";
import { stringify } from "../utils/stringify";

export type LogFunction = (message?: any, ...optionalParams: any[]) => void;
export type AllLevels = "debug" | "info" | "warn" | "error";

export interface LogLevel {
  name: AllLevels;
  priority: number;
  prefix: string;
  style?: string;
}

export interface LoggerPrefs {
  levelName: AllLevels;
  enabled: boolean;
}

declare global {
  interface Window {
    logging: LoggingCore;
  }
}

export const LogLevels: { [k in AllLevels]: LogLevel } = {
  debug: { name: "debug", priority: 20, prefix: "DBUG", style: "font-weight: light;" },
  info: { name: "info", priority: 30, prefix: "INFO", style: "color: darkblue" },
  warn: { name: "warn", priority: 40, prefix: "WARN" },
  error: { name: "error", priority: 50, prefix: "ERROR", style: "color: red; font-weight: bold;" },
};

export function noop() {
  // Stupid empty blocks.
}

export const levelFns: { [k in AllLevels]: LogFunction } = {
  debug: !!console ? console.log : noop,
  info: !!console ? console.info || console.log : noop,
  warn: !!console ? console.warn || console.log : noop,
  error: !!console ? console.error || console.log : noop,
};

export const defaults: LoggerPrefs = {
  levelName: LogLevels.warn.name,
  enabled: true,
};

export class LoggingCore {
  static MSG_QUEUE_SIZE = 1000;
  static KEY_LOCAL_STORE = "ihr-logger";
  messageQueue: Array<[LogFunction, any[]]> = [];
  hasLocalStorage: boolean;
  preferences: LoggerPrefs;
  path = [];
  currentLevel: LogLevel;

  constructor(preferences?: Partial<LoggerPrefs>) {
    this.hasLocalStorage = typeof window !== "undefined" && !!window.localStorage;
    this.preferences = { ...this.loadPreferences(), ...(preferences || {}) };
    this.currentLevel = LogLevels[this.preferences.levelName] || LogLevels.info;
  }

  private loadPreferences() {
    if (this.hasLocalStorage) {
      try {
        const prefData = window.localStorage.getItem(LoggingCore.KEY_LOCAL_STORE);
        if (prefData) {
          const preferences = JSON.parse(prefData) as Partial<LoggerPrefs>;
          return { ...defaults, ...preferences };
        }
      } catch (e) {
        return defaults;
      }
    }
    return defaults;
  }

  private persist(prefs: Partial<LoggerPrefs>) {
    this.preferences = { ...this.preferences, ...prefs };
    if (this.hasLocalStorage) {
      try {
        window.localStorage.setItem(LoggingCore.KEY_LOCAL_STORE, JSON.stringify(this.preferences));
      } catch (e) {
        this.emit(LogLevels.error, this.path, e.message);
      }
    }
  }

  private runtime() {
    if (typeof window !== "undefined" && window.performance && window.performance.timing) {
      return window.performance.now();
    } else {
      return null;
    }
  }

  enable() {
    this.persist({ enabled: true });
  }

  disable() {
    this.persist({ enabled: false });
  }

  setLevel(name: AllLevels) {
    const level = LogLevels[name];
    if (level) {
      this.currentLevel = level;
      this.persist({ levelName: level.name });
    } else {
      throw new Error(`Invalid Level ${name}`);
    }
  }

  format(level: LogLevel, path: string[], extra: any[]): [any, ...any[]] {
    const output: string[] = [];
    const rt = this.runtime();

    if (
      typeof window !== "undefined" &&
      typeof process !== "undefined" &&
      process.env.NODE_ENV !== "test" &&
      level.style
    ) {
      output.push("%c");
      extra = [level.style, ...extra];
    }

    output.push(`[${level.prefix}]`);

    if (rt) {
      if (rt >= 1000) {
        output.push(`${(rt / 1000).toFixed(1)}s`);
      } else {
        output.push(`${rt.toFixed(0)}ms`);
      }
    }
    if (path.length) {
      output.push(`(${path.join("::")})`);
    }

    if (typeof window !== "undefined") {
      return [`${output.join(" ")}`, extra];
    }

    if (output.length && !extra.length) {
      return [output.join(" ")];
    } else if (output.length && extra.length === 1 && typeof extra[0] === "string") {
      return [`${output.join(" ")}${extra[0]}`];
    } else {
      return environ.get("NODE_ENV", "development") === "production"
        ? [`${output.join(" ")}${extra.length ? stringify(extra) : ""}`]
        : [`${output.join(" ")}${extra.length ? stringify(extra, 4) : ""}`];
    }
  }

  replay(filter?: string | RegExp) {
    const printQueue = this.messageQueue.map(([fn, args]) => {
      if (
        !filter ||
        (filter && filter instanceof RegExp && filter.test(args[0])) ||
        (filter && typeof filter === "string" && args[0].includes(filter))
      ) {
        return [true, fn, args] as [boolean, LogFunction, [any?, ...any[]]];
      }
      return [false, fn, args] as [boolean, LogFunction, [any?, ...any[]]];
    });

    let groupOpen = false;
    console.group("Log Replay");
    printQueue.forEach(([print, fn, args]) => {
      if (print) {
        if (groupOpen) {
          groupOpen = false;
          console.groupEnd();
        }
        fn.apply(console, args);
      } else {
        if (!groupOpen) {
          groupOpen = true;
          console.groupCollapsed("Context");
        }
        fn.apply(console, args);
      }
    });
    console.groupEnd();
    return `${this.messageQueue.length} messages`;
  }

  emit(level: LogLevel, path: string[], extra: any[]) {
    const args = this.format(level, path, extra);
    const outputFn = levelFns[level.name] || noop;

    this.messageQueue.push([outputFn, args]);
    if (this.messageQueue.length > LoggingCore.MSG_QUEUE_SIZE) {
      this.messageQueue.shift();
    }
    if (this.preferences.enabled && level.priority >= this.currentLevel.priority) {
      outputFn.apply(console, args);
    }
  }
}

let counter = 0;

const logLevel = environ.get("DEBUG", "") === "1" ? "debug" : environ.get("LOG_LEVEL", "info").toLowerCase();
const logging = new LoggingCore({ levelName: logLevel as AllLevels });

export class ILog {
  private coreHandle: LoggingCore;
  private path: string[];
  constructor(coreHandle: LoggingCore, path: string[]) {
    this.coreHandle = coreHandle;
    this.path = path;
  }

  static logger(source: string) {
    if (StringUtils.isBlank(source)) {
      throw new Error("Invalid source");
    }
    const logger = new ILog(logging, [source]);
    if (typeof window !== "undefined") {
      window.logging = logging;

      if (!counter && !window.navigator.userAgent.includes("jsdom")) {
        counter = 1;
        /**
         * logger.debug(`Central Logging:
         * - Add 'logging.ts' to Chrome's blackbox list for origin tags (source:line)
         * - logging.replay([search]) will replay portions (or all) of the log for you`);
         */
      }
    }
    return logger;
  }

  setLevel(name: AllLevels) {
    this.coreHandle.setLevel(name);
  }

  /**
   * @deprecated use `ILog.logger()` instead.
   */
  getChild(name: string) {
    return new ILog(this.coreHandle, [...this.path, name]);
  }

  private log(level: LogLevel, args: any[]) {
    if (!this.coreHandle) {
      console.error("Logging handle not present");
      console.log(args);
      return;
    }
    this.coreHandle.emit(level, this.path, args);
  }

  debug(...args: any[]) {
    this.log(LogLevels.debug, args);
  }

  info(...args: any[]) {
    this.log(LogLevels.info, args);
  }

  warn(...args: any[]) {
    this.log(LogLevels.warn, args);
  }

  error(...args: any[]) {
    this.log(LogLevels.error, args);
  }
}
