import { ILog } from "@ihr-radioedit/inferno-core";

import { CcpaStatus } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("FB Helper/facebook.ts");

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: {
      init: (opts: any) => void;
      XFBML: {
        parse?: () => void;
      };
    };
    fbq: (key: string, opts: any, rangeStart?: number, rangeEnd?: number) => void;
  }
}

let hasRun = false;
export function initFbSDK(store: Store) {
  if (!store.site?.sections.social?.facebook_app_id) {
    return;
  }
  if (hasRun) {
    //  To re-parse all of the XFBML on a page, so that FB comment box appear everytime
    if (typeof window !== "undefined" && window.FB && window.FB.XFBML.parse) {
      log.info("FB is already init, re-parse all of the XFBML on a page");
      window.FB.XFBML.parse();
    }
    return;
  }
  hasRun = true;

  if (typeof window !== "undefined") {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: store.site?.sections.social?.facebook_app_id,
        xfbml: true,
        version: "v3.3",
      });
    };
  }
  // Load the SDK asynchronously
  log.debug("FB init");
  (function (d, s, id) {
    if (d.getElementById(id)) {
      return;
    }
    const fjs = d.getElementsByTagName(s)[0];
    if (fjs) {
      const js = d.createElement(s);
      js.id = id;
      (js as any).src = store.env.FACEBOOK_SDK_URL;
      if (fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    }
  })(document, "script", "facebook-jssdk");
}

let hasRunFbPixel = false;
export const initFbPixel = (store: Store) => {
  const { site } = store;
  const adPixelId = site?.config.sections?.ads?.facebook_ad_track_pixel_id;
  const adPixelSwitch = site?.config.sections?.ads?.facebook_ad_track_pixel_switch;

  if (!adPixelSwitch || !adPixelId || hasRunFbPixel) {
    log.debug("FaceBook AD Pixel Tracking not configured");
    return;
  }
  const body = document.querySelector("body");

  // If a user has opted out for CCPA purposes, do not include the pixel
  if (body && store.getCcpaStatus() !== CcpaStatus.OptOut) {
    hasRunFbPixel = true;
    // load fb pixel
    const script = document.createElement("script");
    script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js')
        fbq('init', ${adPixelId});
        fbq("track", "PageView");`;
    script.onload = () => {
      log.debug("FB Ad Pixel loaded");
    };

    script.onerror = (
      event: Event | string,
      source?: string,
      fileno?: number,
      columnNumber?: number,
      error?: Error,
    ) => {
      if (error) {
        log.debug("FaceBook Pixel could not be loaded", error.message);
      }
    };

    body.appendChild(script);
  }
};

export const triggerPixelPageView = (store: Store) => {
  if (store.getCcpaStatus() !== CcpaStatus.OptOut) {
    window.fbq?.("track", "PageView");
  }
};
