import { HtmlBlockValueResolver } from "@ihr-radioedit/inferno-core";
import { useHashAnchorLinks } from "@inferno/renderer-shared-ui";
import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject } from "mobx-react";
import { useEffect, useRef } from "react";
import { activateEmbeds } from "../lib/embed";
import { Container } from "../ui";
import { PageBlockInterface } from "./Block.component";
import styles from "./Html.module.scss";

export const Html = inject("store")(({ block, store }: PageBlockInterface) => {
  if (!store) {
    return null;
  }

  const contentRef = useRef<HTMLDivElement>(null);

  useHashAnchorLinks(contentRef, store);

  useEffect(() => {
    store.storeBlock(block);

    if (contentRef.current) {
      activateEmbeds(contentRef.current);
    }
  }, [block, store]);

  const value = HtmlBlockValueResolver.decode(block.value);
  if (isLeft(value) || !value.right.content) {
    return null;
  }

  const htmlContainerClass = classnames(styles.componentHtml, {
    [styles.htmlContentStyle]: block.tags?.includes("display-hints/html-content-style"),
  });

  return (
    <Container data-test-type={block.type} className={htmlContainerClass}>
      <div dangerouslySetInnerHTML={{ __html: value.right.content }} ref={contentRef} />
    </Container>
  );
});
