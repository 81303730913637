import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { objectToGetParams } from "../utils/object-to-get-params";
import { slugify } from "../utils/slugify";
import { QueryProps } from "./request";
import { type PodcastEmbed } from "./podcast-embed";

export interface CatalogLegacyProps extends PodcastEmbed {
  id?: string | number;
  related_id?: string | number;
  description?: string;
  track?: Webapi.CurrentlyPlayingTrackDetailFragment | Webapi.TopTrackDetailFragment;
  kind: CatalogKindTypes;
  store?: any;
}

export enum CatalogKindTypes {
  artist = "artist",
  album = "album",
  station = "station",
  podcast = "podcast",
  curated = "curated",
  song = "track",
}

export interface CatalogUrlProps {
  track?: Webapi.TrackDetailFragment | Webapi.CurrentlyPlayingTrackDetailFragment;
  id?: string | number;
  related_id?: string | number;
  kind: CatalogKindTypes;
  embed?: boolean;
  query?: QueryProps;
  api_base_uri?: string;
  proxy?: boolean;
}

export interface CatalogEnv {
  national_site_url: string;
  node_env: string;
}

export const generateCatalogUrl = (env: CatalogEnv, props: CatalogUrlProps) => {
  let url = "";
  if (props.track) {
    const { track } = props;
    const artistSlug = `${slugify(track.artist?.artistName)}-${track.artistId}`;
    switch (props.kind) {
      case CatalogKindTypes.artist:
        url = `${env.national_site_url}/artist/${artistSlug}/`;
        break;
      case CatalogKindTypes.album:
        const albumSlug = `${slugify(track?.albumName)}-${track.albumId}`;
        url = `${env.national_site_url}/artist/${artistSlug}/albums/${albumSlug}/`;
        break;
      case CatalogKindTypes.song:
        const songSlug = `${slugify(track.title)}-${track.trackId}`;
        url = `${env.national_site_url}/artist/${artistSlug}/songs/${songSlug}/`;
        break;
    }
  } else if (props.id) {
    const catalogProxyUrl = getCatalogProxy(env.node_env);
    switch (props.kind) {
      case CatalogKindTypes.artist:
        const artistPath = `artist/${props.id}/`;
        url = props.proxy ? catalogProxyUrl(artistPath) : `${env.national_site_url}/${artistPath}`;
        break;
      case CatalogKindTypes.station:
        const stationPath = `live/${props.id}/`;
        url = props.proxy ? catalogProxyUrl(stationPath) : `${env.national_site_url}/${stationPath}`;
        break;
      case CatalogKindTypes.podcast:
        if (props.related_id) {
          const podcastEpisodePath = `podcast/${props.id}/episode/${props.related_id}/`;
          url = props.proxy ? catalogProxyUrl(podcastEpisodePath) : `${env.national_site_url}/${podcastEpisodePath}`;
        } else {
          const podcastPath = `podcast/${props.id}/`;
          url = props.proxy ? catalogProxyUrl(podcastPath) : `${env.national_site_url}/${podcastPath}`;
        }
        break;
      case CatalogKindTypes.curated:
        const [id, hash] = props.id.toString().split("::");
        const curatedPath = `playlist/${id}-${hash}/`;
        url = props.proxy ? catalogProxyUrl(curatedPath) : `${env.national_site_url}/${curatedPath}`;
        break;
    }
  }

  if (!url || !props.embed) {
    return url;
  }

  const urlWithQueryParams = new URL(url);
  const params = {
    ...(props.query || {}),
    ihrnetwork: "true",
    embed: "true",
  };

  urlWithQueryParams.search = objectToGetParams(params);
  return urlWithQueryParams.toString();
};

const getCatalogProxy = (node_env: string) => (path: string) => {
  const basePath = `/api/v4/player/${path}`;
  return node_env === "development" ? `http:localhost:3000${basePath}` : `${location.origin}${basePath}`;
};
