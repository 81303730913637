import classnames from "classnames";
import { useCallback, useEffect, useRef } from "react";
import * as React from "react";
import { createPortal } from "react-dom";
import { bodyScroll } from "../lib/utilities";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import { CloseButton } from "./CloseButton.component";
import "./Modal.style.scss";

interface ModalProps {
  /* The parent component needs to control if the modal is open or closed */
  open: boolean;
  addClass?: string;
  children: React.ReactNode;
  closeButtonSize?: 16 | 18 | 24;
  closeButtonPosition?: "absolute" | "relative";
  callback?: () => void;
  autoDismiss?: number;
}

interface ScrollBlockerProps {
  scrollBlockerClass: string;
  dismissModal?: () => void;
}

export const ScrollBlocker = ({ scrollBlockerClass, dismissModal }: ScrollBlockerProps) => {
  if (isWindowDefined()) {
    return <div className={scrollBlockerClass} onClick={dismissModal} />;
  }
  return null;
};

export const Modal = ({
  open,
  children,
  addClass,
  closeButtonSize = 24,
  closeButtonPosition = "relative",
  callback,
  autoDismiss,
}: ModalProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const dismissModal = useCallback(() => {
    bodyScroll(true, "modal");
    callback?.();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [timeoutRef, callback]);

  const closeOnEscapeKeyDown = useCallback(
    (e: { charCode: any; keyCode: any }) => {
      if ((e.charCode || e.keyCode) === 27) {
        dismissModal();
      }
    },
    [dismissModal],
  );

  useEffect(() => {
    if (isWindowDefined()) {
      if (open) {
        // turn off scrolling
        bodyScroll(false, "modal");
      } else {
        bodyScroll(true, "modal");
      }

      window.addEventListener("keydown", closeOnEscapeKeyDown);
      return () => {
        if (isWindowDefined()) {
          window.removeEventListener("keydown", closeOnEscapeKeyDown);
        }
      };
    }
  }, [closeOnEscapeKeyDown, open]);

  useEffect(() => {
    if (autoDismiss) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (open) {
        timeoutRef.current = setTimeout(dismissModal, autoDismiss);
      }
    }
  }, [open, autoDismiss, timeoutRef, dismissModal]);

  const modalClass = classnames("modal", {
    [`${addClass}`]: addClass,
    open,
  });
  const scrollBlockerClass = classnames({
    "scroll-blocker": open,
  });

  if (typeof window === "undefined" || typeof process !== "undefined") {
    return (
      <React.Fragment key="server-modal">
        <div className={modalClass}>
          <div className="modal-content">
            <CloseButton
              addClass={closeButtonPosition}
              height={closeButtonSize}
              width={closeButtonSize}
              label="close the modal"
              click={dismissModal}
            />
            {children}
          </div>
        </div>
        {open && <ScrollBlocker scrollBlockerClass={scrollBlockerClass} dismissModal={dismissModal} />}
      </React.Fragment>
    );
  }

  return createPortal(
    <React.Fragment key="client-modal">
      <div className={modalClass} onClick={dismissModal}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <CloseButton
            addClass={closeButtonPosition}
            height={closeButtonSize}
            width={closeButtonSize}
            label="close the modal"
            click={dismissModal}
          />
          {children}
        </div>
      </div>
      <ScrollBlocker scrollBlockerClass={scrollBlockerClass} dismissModal={dismissModal} />
    </React.Fragment>,
    document.querySelector("body")!,
  );
};
