import { canHaveOps } from "../lib/image";
import type { TagType, TagContext, MetaTags } from "../lib/tagging-type";

export const GOOGLE_SITE_VERIFICATION = "p2FWrUKxtwBmQgHV4CjD80oKdZZBjmMNByoWvZMsYso";
export const MICROSOFT_VALIDATE = "E98E323249F89ACF6294F958692E230B";
export const FORMAT_DETECTION = "telephone=no";
export const META_IMAGE_WIDTH = "1200";
export const META_IMAGE_HEIGHT = "675";

export const twitterName = (handle: string) => {
  if (!handle) {
    return "";
  }
  if (handle[0] === "@") {
    return handle;
  }
  return "@" + handle;
};

export const generateStructuredDataMetaTag = (value: { [key: string]: any }): [string, TagType] => {
  const dataType = value["@type"].toLowerCase();
  return [
    `script-${dataType}`,
    {
      attrName: "type",
      attrValue: "application/ld+json",
      value,
      type: "script",
    },
  ];
};

export const generateMetaTag = (attrName: string, name: string, value: string): [string, TagType] => {
  return [
    `meta-${attrName}-${name}`,
    {
      attrName,
      name,
      value,
      type: "meta",
    },
  ];
};

export const getOriginType = (origin: string) => {
  if (origin.toLowerCase().includes("iheartradio") || origin.toLowerCase().includes("default")) {
    return "National";
  } else if (origin.toLowerCase().includes("-pr")) {
    return "Premiere";
  }

  return "Local";
};

export const processMetaImage = (image: string, { request, log }: TagContext) => {
  let metaImage = image;
  if (metaImage) {
    let protocol = request.protocol;
    if (typeof process !== "undefined") {
      protocol = process.env.PROTOCOL || protocol;
    }

    try {
      metaImage = metaImage.startsWith("//") ? `${protocol.replace(":", "")}:${metaImage}` : metaImage;
      const imageUrl = new URL(metaImage);
      if (canHaveOps(metaImage) && !imageUrl.searchParams.get("ops")) {
        metaImage = `${metaImage}?ops=gravity(%22north%22),fit(${META_IMAGE_WIDTH},${META_IMAGE_HEIGHT}),quality(65)`;
      }
    } catch (e) {
      log?.debug(e);
    }
  }

  return metaImage;
};

export const setGeneralMetaTags = (
  meta: MetaTags,
  opts?: {
    titles?: { default: string; social?: string; parsely?: string } | null;
    description?: string;
    keywords?: string[];
    url?: string;
    image?: { url: string; width?: string; height?: string } | null;
    types?: { openGraph?: string; parsely?: string };
    twitter?: { card?: string; handle?: string; tile?: boolean };
  },
) => {
  const { titles, description, keywords, url, image, types, twitter } = opts || {};

  if (meta) {
    if (titles) {
      meta.set("title", { value: titles.default, type: "title" });
      meta.set(...generateMetaTag("property", "og:title", titles?.social || titles.default));
      meta.set(...generateMetaTag("property", "twitter:title", titles?.social || titles.default));
      meta.set(...generateMetaTag("itemProp", "name", titles.default));
      meta.set(...generateMetaTag("name", "parsely-title", titles.parsely || titles.default));
    }

    if (description) {
      meta.set(...generateMetaTag("name", "description", description));
      meta.set(...generateMetaTag("property", "og:description", description));
      meta.set(...generateMetaTag("property", "twitter:description", description));
      meta.set(...generateMetaTag("itemProp", "description", description));

      if (twitter?.tile) {
        meta.set(...generateMetaTag("property", "twitter:tile:image:alt", description));
      }
    }

    if (keywords && keywords.length > 0) {
      meta.set(...generateMetaTag("name", "keywords", keywords.filter(kw => kw.trim() !== "").join(", ")));
      meta.set(...generateMetaTag("name", "parsely-tags", keywords.filter(kw => kw.trim() !== "").join(", ")));
    }

    if (url) {
      meta.set(...generateMetaTag("property", "og:url", url));
      meta.set(...generateMetaTag("property", "twitter:url", url));
      meta.set(...generateMetaTag("itemProp", "url", url));
      meta.set(...generateMetaTag("name", "parsely-link", url));
    }

    if (image) {
      const { url: imageUrl, width = META_IMAGE_WIDTH, height = META_IMAGE_HEIGHT } = image;
      meta.set("image_src", { value: imageUrl, type: "link", rel: "image_src" });
      meta.set(...generateMetaTag("name", "thumbnail", imageUrl));
      meta.set(...generateMetaTag("property", "og:image", imageUrl));
      meta.set(...generateMetaTag("property", "og:image:width", width));
      meta.set(...generateMetaTag("property", "og:image:height", height));
      meta.set(...generateMetaTag("property", "twitter:image", imageUrl));
      meta.set(...generateMetaTag("name", "parsely-image-url", imageUrl));
      meta.set(...generateMetaTag("itemProp", "image", imageUrl));
      meta.set(...generateMetaTag("name", "msapplication-TileImage", imageUrl));
      meta.set("pagemap", { value: imageUrl, type: "pagemap" });

      if (twitter?.tile) {
        meta.set(...generateMetaTag("property", "twitter::tile:image", imageUrl));
      }
    }

    if (types) {
      const { parsely, openGraph = "website" } = types;
      if (openGraph) {
        meta.set(...generateMetaTag("property", "og:type", openGraph));
      }

      if (parsely) {
        meta.set(...generateMetaTag("name", "parsely-type", parsely));
      }
    }

    if (twitter) {
      const { card = "Summary", handle, tile } = twitter;
      if (card) {
        meta.set(...generateMetaTag("property", "twitter:card", card));
      }

      if (handle) {
        meta.set(...generateMetaTag("property", "twitter:site", handle));
        meta.set(...generateMetaTag("property", "twitter:creator", handle));
      }

      if (tile) {
        meta.set(...generateMetaTag("property", "twitter:tile:template:testing", "1"));
      }
    }
  }

  return meta;
};

export const DEFAULT_NEWS_ARTICLE_STRUCTURED_DATA = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": "",
  },
  headline: "",
  image: [""],
  datePublished: "",
  dateModified: "",
  author: {
    "@type": "Person",
    name: "",
  },
  publisher: {
    "@type": "Organization",
    name: "",
    logo: {
      "@type": "ImageObject",
      url: "",
    },
  },
  name: "",
  speakable: {
    "@type": "SpeakableSpecification",
    xPath: ["/html/head/title", "/html/head/meta[@name='description']/@content"],
    url: "",
  },
};
