import { SignalDispatcher } from "strongly-typed-events";
import { ILog } from "@ihr-radioedit/inferno-core";

const log = ILog.logger("Resize Watcher");

export class ResizeWatcher {
  private RESIZE_WAIT = 500;
  private resizeTimer = 0;
  private resizeLastWidth = typeof window !== "undefined" ? window.outerWidth : 0;
  private resizeLastHeight = typeof window !== "undefined" ? window.outerHeight : 0;
  private resizeWidthChanged = false;
  private resizeHeightChanged = false;
  private _resizeInProgress = false;
  private _onWidthResizeEnd = new SignalDispatcher();
  private _onHeightResizeEnd = new SignalDispatcher();

  constructor() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        this.handleResizeEvent();
      });
    }
  }

  get resizeInProgress() {
    return this._resizeInProgress;
  }

  get onHeightChange() {
    return this._onHeightResizeEnd.asEvent();
  }

  get onWidthChange() {
    return this._onWidthResizeEnd.asEvent();
  }

  private handleResizeEvent() {
    this._resizeInProgress = true;

    if (this.resizeTimer) {
      window.clearTimeout(this.resizeTimer);
    }

    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;

    if (currentHeight !== this.resizeLastHeight) {
      this.resizeHeightChanged = true;
      this.resizeLastHeight = currentHeight;
    }

    if (currentWidth !== this.resizeLastWidth) {
      this.resizeWidthChanged = true;
      this.resizeLastWidth = currentWidth;
    }

    this.resizeTimer = window.setTimeout(() => {
      this._resizeInProgress = false;
      if (this.resizeHeightChanged) {
        this._onHeightResizeEnd.dispatch();
        log.debug("Detected height resize");
        this.resizeHeightChanged = false;
      }
      if (this.resizeWidthChanged) {
        this._onWidthResizeEnd.dispatch();
        log.debug("Detected width resize");
        this.resizeWidthChanged = false;
      }
    }, this.RESIZE_WAIT);
  }
}

export const resizeWatcher = new ResizeWatcher();
