import loadable from "@loadable/component";
import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";

import type { BlockFragment, Block_SitesShowBlock_Fragment } from "@ihr-radioedit/inferno-webapi";

const ShowCollection = loadable(() => import("./ShowCollection.component"));

interface ShowLoaderProps {
  block?: BlockFragment;
  store?: Store;
}

function isShowBlock(block: any): block is Block_SitesShowBlock_Fragment {
  if (block.shows && Array.isArray(block.shows) && block.shows.length) {
    return true;
  }
  return false;
}
export const ShowLoader = inject("store")(({ block, store }: ShowLoaderProps) => {
  if (!block || !store || !isShowBlock(block)) {
    return null;
  }
  return <ShowCollection shows={block.shows} block={block} />;
});
