// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
export const premierePageComponents = {
  generic_80_20: noop,
  generic_full: noop,
  generic_80_20_with_title: noop,
  detail: noop,
  micro_detail: noop,
  home: noop,
  aggregate_full: noop,
  microhome: noop,
  microhome_rail: noop,
  plaintext: noop,
};
