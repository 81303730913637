import * as io from "io-ts";
import { MaybeStringResolver } from "./util.types";

export interface ResourceRef {
  bucket: string;
  id: string;
}

export interface AppleNewsRef {
  ref_id: string;
  revision_id: string;
}

const EyebrowRequired = io.type({
  topic_name: io.string,
});
const EyebrowOptional = io.partial({
  topic: io.string,
});
export const EyebrowResolver = io.intersection([EyebrowRequired, EyebrowOptional]);
export type EyebrowInterface = io.TypeOf<typeof EyebrowResolver>;

export const SubscriptionResolver = io.type({
  tags: io.array(io.string),
});
export const SubscriptionArrayResolver = io.array(SubscriptionResolver);
export type Subscription = io.TypeOf<typeof SubscriptionResolver>;

export const AssetOptional = io.partial({
  caption: io.string,
  alt_text: io.string,
  source_credit: io.string,
  counter: io.string,
  use_caption: io.boolean,
});
export const AssetResolver = io.intersection([
  io.type({
    bucket: io.string,
    id: io.string,
    new_tab: io.boolean,
    schedule: io.type({}),
    title: io.string,
    type: MaybeStringResolver,
    url: io.string,
  }),
  io.partial({
    link: MaybeStringResolver,
    attributes: io.partial({
      mimetype: io.string,
      type: io.string,
    }),
  }),
  AssetOptional,
]);
export type AssetInterface = io.TypeOf<typeof AssetResolver>;

const AssetBlockResolver = io.type({
  type: io.string,
  schedule: io.type({}),
  value: AssetResolver,
});
export type AssetBlockInterface = io.TypeOf<typeof AssetBlockResolver>;

export const ImageAssetRequired = io.type({
  imgUrl: io.string,
  title: io.string,
  url: io.string,
  new_tab: io.boolean,
});
export const ImageAssetResolver = io.intersection([ImageAssetRequired, AssetOptional]);
export type ImageAssetInterface = io.TypeOf<typeof ImageAssetResolver>;

const ContestRequired = io.type({
  call_letters: io.string,
  contest_id: io.number,
  html: io.string,
  schedule: io.type({}),
  type: io.string,
  url: io.string,
});
export type ContestInterface = io.TypeOf<typeof ContestRequired>;

const ContestDetailsRequired = io.type({
  app_type: io.string,
  app_id: io.string,
  account: io.string,
  type: io.string,
  title: io.string,
  subtitle: io.string,
  tags: io.string,
  start_time: io.string,
  end_time: io.string,
  grid_status: io.string,
  primary_image: io.string,
  mobile_image: io.string,
  grid_image: io.string,
  prize: io.string,
  sort: io.string,
  grid_redirect: io.string,
  page_url: io.string,
  schedule_status: io.string,
  display_image: io.string,
  account_id: io.string,
  start_date: io.string,
  end_date: io.string,
  description: io.string,
});
export type ContestDetails = io.TypeOf<typeof ContestDetailsRequired>;

const GalleryItemRequired = io.type({
  alt_text: io.string,
  bucket: io.string,
  caption: io.string,
  id: io.string,
  title: io.string,
  source_credit: io.string,
  use_caption: io.boolean,
  url: io.string,
  new_tab: io.boolean,
});
export type GalleryItem = io.TypeOf<typeof GalleryItemRequired>;

const GalleryLegacyRequired = io.type({
  type: io.string,
  schedule: io.type({}),
  assets: io.array(GalleryItemRequired),
});
export type GalleryLegacyInterface = io.TypeOf<typeof GalleryLegacyRequired>;

const GalleryRequired = io.type({
  type: io.string,
  schedule: io.type({}),
  value: io.array(GalleryItemRequired),
});
export type GalleryInterface = io.TypeOf<typeof GalleryRequired>;

const IHeartContentSummary = io.type({
  author: io.string,
  description: io.string,
  image: io.string,
  title: io.string,
});

export const IHeartContentLegacyRequired = io.type({
  id: io.string,
  schedule: io.type({}),
  type: io.string,
  summary: IHeartContentSummary,
  canonical_url: io.string,
});
export type IHeartContentLegacyInterface = io.TypeOf<typeof IHeartContentLegacyRequired>;

export const IHeartContentResolver = io.intersection([
  io.type({
    id: io.string,
    title: io.string,
    description: io.string,
    sponsored: io.boolean,
    image: io.intersection([
      io.type({
        new_tab: io.boolean,
        title: io.string,
        type: MaybeStringResolver,
        url: io.string,
      }),
      io.partial({
        bucket: io.string,
        id: io.string,
        schedule: io.type({}),
        attributes: io.partial({
          mimetype: io.string,
          type: io.string,
        }),
      }),
      AssetOptional,
    ]),
  }),
  io.partial({
    author: io.string,
    primary_slug: io.string,
    canonical_url: io.string,
    slugs: io.array(io.string),
    keywords: io.array(io.string),
  }),
]);

export const IHeartContentRequired = io.type({
  schedule: io.type({}),
  type: io.string,
  value: IHeartContentResolver,
});
export type IHeartContentInterface = io.TypeOf<typeof IHeartContentRequired>;

const ContentEmbedRequired = io.type({
  url: io.string,
  image: io.string,
  title: io.string,
});

const ContentEmbedOptional = io.partial({
  summary: io.string,
  author: io.string,
});
const ContentEmbedResolver = io.intersection([ContentEmbedRequired, ContentEmbedOptional]);
export type ContentEmbedInterface = io.TypeOf<typeof ContentEmbedResolver>;
