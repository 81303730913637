import { isAxiosError } from "@ihr-radioedit/sdk-network";
import { COAST_MESSAGES } from "../../sites/coast/constants";

export function parsePWSError(
  e: any,
  fallback = COAST_MESSAGES.UNEXPECTED_ERROR,
  reasonOverwrite: Record<number, string> = {},
) {
  const status = e.response?.status;
  const reason = e.response.data.reason;

  if (!isAxiosError(e) || !status || !reason) {
    return fallback;
  }

  return reasonOverwrite[status] ?? reason ?? fallback;
}

export class PremiereWebServiceError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "PremiereWebServiceError";
  }
}
