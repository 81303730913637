import loadable from "@loadable/component";

const Drawer = loadable(() => import("./drawer/Drawer.component"));
const LoadMoreFromCursor = loadable(() => import("./LoadMoreFromCursor.component"));
const Card = loadable(() => import("./Card.component"));
const Heading = loadable(() => import("./Heading.component"));
const Grid = loadable(() => import("./Grid.component"));
const Slider = loadable(() => import("./Slider.component"));
const DateTimeDisplay = loadable(() => import("./DateTimeDisplay.component"));

// TODO: fix __mocks__/@loadable/component.tsx to account for split
const MagicLink = loadable(
  () => import("../../../../../../../packages/renderer/shared/ui/src/elements/MagicLink.component"),
);
const ImageTile = loadable(() => import("./ImageTile.component"));
const Spinner = loadable(() => import("./Spinner.component"));
const ViewMoreLink = loadable(() => import("./ViewMoreLink.component"));
const Summary = loadable(() => import("./Summary.component"));
const Overlay = loadable(() => import("./Overlay.component"));
const Select = loadable(() => import("./Select.component"));
const FauxButton = loadable(() => import("./FauxButton.component"));

export { Button, ButtonKind } from "./Button.component";
export { CloseButton } from "./CloseButton.component";
export { Container } from "@inferno/renderer-shared-ui";
export { Modal } from "./Modal.component";
export { SearchBox } from "./SearchBox.component";
export { Skeleton } from "../components/skeletons/Skeleton.component";
export { Tab } from "./Tab.component";
export { Tabs } from "./Tabs.component";
export { Carousel } from "./Carousel.component";
export { Toast } from "./Toast.component";
export { DisplayTime } from "./DisplayTime.component";
export { Venue } from "./Venue.component";
// export { MapUrl } from "./MapUrl.component";

// export all the loadable components
export {
  Grid,
  Heading,
  Card,
  Drawer,
  LoadMoreFromCursor,
  Slider,
  DateTimeDisplay,
  MagicLink,
  ImageTile,
  Spinner,
  ViewMoreLink,
  Summary,
  Overlay,
  Select,
  FauxButton,
};
